import React, { useState, useCallback, useRef, memo } from 'react';
import * as PropTypes from 'prop-types';

import { ReactComponent as UploadIcon } from 'assets/icons/common/upload.svg';
import TrashIcon from 'assets/icons/common/trash.svg';
import CheckIcon from 'assets/icons/common/check-long.svg';

import useDevice from 'hooks/useDevice';
import { uid } from 'services/uid';

import { getNumPages } from 'services/file';
import { splitModelInput } from 'services/am-utils';

import { Document, Page } from 'components/core/ReactPdf';
import Expire from 'components/core/Expire';
import Tooltip from 'components/layout/Tooltip';
import Input from 'components/fields/Input';
import KeywordSkillSetTemplateModal from 'features/AM/components/layout/KeywordSkillSetTemplateModal';

import {
  typeFileMap,
  fileSizeOnSystemOS,
  getModelConfig,
} from '../../../constants';

const SampleDataTemplateLocal = ({
  form,
  data,
  templates,
  previewFiles,
  setPreviewFiles,
  setMessageUpload,
  disableUpload,
  disableField,
}) => {
  const inputRef = useRef(null);
  const [showTemplate, setShowTemplate] = useState(false);

  const { isMobileDevice } = useDevice();

  const {
    maxPage,
    typeFileUpload,
    limitUpload,
    limitSize,
    validate,
    formInput,
    renderFormRequirement,
    renderUploadLabel,
  } = getModelConfig(data?.title);

  const { png, jpg, jpeg, pdf, csv, xlsx, doc, docx } = typeFileMap;

  const modelInput = data?.modelInput
    ? data.modelInput.split(/\s*[0-9]\s*\.\s*/g)
    : '';

  const {
    control,
    reset,
    formState: { errors },
  } = form;

  const renderModelRequirement = (
    <div className="am-preview-subtab__local-files__text">
      <span className="requirement">{modelInput[0]}</span>
      <ol>
        {modelInput &&
          modelInput.slice(1).map(input => {
            const [li, ...ul] = splitModelInput(input);
            const note = input.match(/Note:.*/g);
            return (
              <React.Fragment key={li}>
                <li>
                  <span className="order-item">{li}</span>
                  {ul.length > 0 && (
                    <ul>
                      {ul.map(bi => (
                        <li key={bi} className="un-order-item">
                          {bi}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
                {note && <div className="order-item">{note}</div>}
              </React.Fragment>
            );
          })}
      </ol>
    </div>
  );

  const renderPreviewTryModel = file => {
    if (file.type === pdf) {
      return (
        <Document file={file}>
          <Page pageNumber={1} width={78} renderAnnotationLayer={false} />
        </Document>
      );
    }
    if ([png, jpg, jpeg].includes(file.type)) {
      return (
        <img
          alt="Preview file"
          className="am-preview-subtab__local-files__preview--image"
          src={URL.createObjectURL(file)}
        />
      );
    }
    if ([csv, doc, docx, xlsx].includes(file.type)) {
      return (
        <div className="am-preview-subtab__local-files__preview--file-name">
          File: {file.name}
        </div>
      );
    }
  };

  const onOpenTemplateModal = () => {
    if (!disableUpload) {
      setShowTemplate(true);
    }
  };

  const onCloseTemplateModal = () => {
    setShowTemplate(false);
  };

  const onUploadFiles = async e => {
    if (previewFiles.length >= limitUpload) return;
    let uploadedFiles = [...previewFiles];
    const uploadingFiles = [...e.target.files];
    const finalFiles = uploadingFiles.map(async (file, i) => {
      if (
        !typeFileUpload.includes(file.type) ||
        file.size / (fileSizeOnSystemOS * fileSizeOnSystemOS) > limitSize
      ) {
        return false;
      }
      const uploadFile = async () =>
        new Promise(resolve => {
          if (uploadedFiles.length < limitUpload) {
            file.id = new Date().getTime() + i;
            uploadedFiles = [...uploadedFiles, file];
            resolve(true);
          } else {
            resolve(false);
          }
        });
      const valid = await validate(file);
      if (valid?.error) {
        return false;
      }
      if (file.type === pdf) {
        const numPages = await getNumPages(file);
        if (numPages && numPages.length > maxPage) return false;
      }
      const res = await uploadFile();
      return res;
    });
    const flag = await Promise.all(finalFiles);
    setMessageUpload(!flag.includes(false));
    setPreviewFiles([...uploadedFiles]);
  };

  const onRemoveFile = useCallback(
    fileIndex => {
      const files = [...previewFiles].filter((_, i) => i !== fileIndex);
      setPreviewFiles(files);
    },
    [previewFiles],
  );

  const onGetKeywordSkillSetTemplate = useCallback(
    submitFile => {
      submitFile.optional = true;
      setPreviewFiles(prev => [...prev, submitFile]);
    },
    [inputRef],
  );

  return (
    <div className="am-preview-subtab__model-content">
      <span className="am-preview-subtab__local-files__text requirement">
        {renderModelRequirement}
      </span>
      {renderFormRequirement && renderFormRequirement()}
      <form>
        <div className="am-preview-subtab__local-form">
          {formInput.map(item => (
            <React.Fragment key={item.name}>
              <Input
                type={item.type}
                name={item.name}
                label={item.label}
                control={control}
                limit={item.rules.maxLength}
                placeholder={item.placeholder}
                rules={item.rules}
                error={errors[item.name]}
                disabled={disableField}
              />
            </React.Fragment>
          ))}
        </div>
      </form>
      <div className="am-preview-subtab__local-files">
        {renderUploadLabel && renderUploadLabel()}
        <label
          htmlFor="try-mode-am"
          aria-hidden
          className={`am-preview-subtab__local-files__input${
            disableUpload ? ' inActivated' : ''
          }`}
          onClick={() => {
            inputRef.current.value = null;
          }}
        >
          <input
            id="try-mode-am"
            ref={inputRef}
            multiple
            type="file"
            accept={typeFileUpload?.join(',')}
            onChange={onUploadFiles}
            disabled={disableUpload}
          />
          <UploadIcon />
          <p className="am-preview-subtab__local-files__input--title">
            Upload from {isMobileDevice ? 'Devices' : 'Computer'}
          </p>
        </label>
        <div className="am-preview-subtab__local-files__list">
          <div className="am-preview-subtab__local-files__preview">
            {previewFiles.length
              ? [...previewFiles].map((file, i) => (
                  <div
                    key={file.id || uid()}
                    className="am-preview-subtab__local-files__preview--file"
                  >
                    {renderPreviewTryModel(file)}
                    <Tooltip placement="top" content={file.name}>
                      <div className="am-preview-subtab__local-files__preview--backdrop">
                        <button
                          type="button"
                          className="am-preview-subtab__local-files__preview--trash"
                          onClick={() => onRemoveFile(i)}
                        >
                          <img src={TrashIcon} alt="Remove" />
                        </button>
                      </div>
                    </Tooltip>
                    <Expire delay={10000}>
                      <img
                        className="am-preview-subtab__local-files__preview--success"
                        alt="Success"
                        src={CheckIcon}
                      />
                    </Expire>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      {templates.map(item => (
        <React.Fragment key={item.name}>
          <div className="am-preview-subtab__local-template">
            Not sure what to include for {item.label}?
            <br />
            Use our{' '}
            <span
              className={`am-preview-subtab__local-template__label ${
                disableUpload ? 'disabled' : ''
              }`}
              role="presentation"
              onClick={onOpenTemplateModal}
            >
              {item.label} Template
            </span>
          </div>
          {showTemplate && (
            <KeywordSkillSetTemplateModal
              data={item?.file}
              isShowModal={showTemplate}
              onReset={reset}
              onSubmit={onGetKeywordSkillSetTemplate}
              setShowModal={onCloseTemplateModal}
              onClose={onCloseTemplateModal}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

SampleDataTemplateLocal.propTypes = {
  form: PropTypes.object,
  data: PropTypes.shape({
    title: PropTypes.string,
    modelInput: PropTypes.string,
  }),
  disableUpload: PropTypes.bool,
  disableField: PropTypes.bool,
  previewFiles: PropTypes.array,
  templates: PropTypes.array,
  setMessageUpload: PropTypes.func,
  setPreviewFiles: PropTypes.func,
};

export default memo(SampleDataTemplateLocal);
