import { call, put, delay, takeLatest, takeEvery } from 'redux-saga/effects';

import { getData, postData } from '../services/api';

import {
  DATA_MARKETPLACE_FILTER_OPTION_API_PATH,
  DATA_MARKETPLACE_SAMPLE_DATA_API_PATH,
  DATA_MARKETPLACE_SUGGESTED_KEYWORD_API_PATH,
  DATA_MARKETPLACE_LINEAGE_DATA_API_PATH,
  DATA_MARKETPLACE_OVERALL_DATA_API_PATH,
  DATA_MARKETPLACE_GET_CONFIDENCE_SCORE_API_PATH,
  DATA_MARKETPLACE_SUGGESTION_API_PATH,
  DATA_MARKETPLACE_SEARCH_RESULT_API_PATH,
} from '../constants/paths';

import {
  getFilterOptionRequest,
  getFilterOptionSuccess,
  getFilterOptionFailure,
  getDataLineageRequest,
  getDataLineageSuccess,
  getDataLineageFailure,
  getSampleDataActionsRequest,
  getSampleDataActionsFailure,
  getSampleDataActionsSuccess,
  postSuggestedKeywordDataMarketplaceRequest,
  postSuggestedKeywordDataMarketplaceSuccess,
  postSuggestedKeywordDataMarketplaceFailure,
  getOverallDataSuccess,
  getOverallDataFailure,
  getOverallDataRequest,
  getConfidenceScoreRequest,
  getConfidenceScoreSuccess,
  getConfidenceScoreFailure,
  postDataMarketplaceSuggestionRequest,
  postDataMarketplaceSuggestionSuccess,
  postDataMarketplaceSuggestionFailure,
  postDataMarketplaceSearchResultRequest,
  postDataMarketplaceSearchResultSuccess,
  postDataMarketplaceSearchResultFailure,
} from '../slices/dataMarketplace';

export function* getFilterOption({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: DATA_MARKETPLACE_FILTER_OPTION_API_PATH,
      params: payload,
    });

    yield delay(800);
    yield put(getFilterOptionSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getFilterOptionFailure(message));
  }
}

export function* getSampleDataActions({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: DATA_MARKETPLACE_SAMPLE_DATA_API_PATH,
      params: payload,
    });

    yield delay(800);
    yield put(getSampleDataActionsSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getSampleDataActionsFailure(message));
  }
}

export function* getDataLineageActions({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: DATA_MARKETPLACE_LINEAGE_DATA_API_PATH,
      params: payload,
    });

    yield delay(800);
    yield put(getDataLineageSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getDataLineageFailure(message));
  }
}

export function* postSuggestedKeywordDataMarketplace({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: DATA_MARKETPLACE_SUGGESTED_KEYWORD_API_PATH,
      data: payload,
    });

    yield delay(800);
    yield put(postSuggestedKeywordDataMarketplaceSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(postSuggestedKeywordDataMarketplaceFailure(message));
  }
}

export function* getOverallDataActions({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: DATA_MARKETPLACE_OVERALL_DATA_API_PATH,
      params: payload,
    });

    yield delay(800);
    yield put(getOverallDataSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getOverallDataFailure(message));
  }
}

export function* getConfidenceScoreActions({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: DATA_MARKETPLACE_GET_CONFIDENCE_SCORE_API_PATH,
      params: payload,
    });

    yield delay(800);
    yield put(getConfidenceScoreSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getConfidenceScoreFailure(message));
  }
}

export function* postDataMarketplaceSuggestion({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: DATA_MARKETPLACE_SUGGESTION_API_PATH,
      data: payload,
    });

    yield delay(800);
    yield put(postDataMarketplaceSuggestionSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(postDataMarketplaceSuggestionFailure(message));
  }
}

export function* postDataMarketplaceSearchResult({ payload }) {
  const { params, prop } = payload;
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: DATA_MARKETPLACE_SEARCH_RESULT_API_PATH,
      data: params,
    });

    yield delay(800);
    yield put(
      postDataMarketplaceSearchResultSuccess({
        data,
        isPagination: params.isPagination,
        prop,
      }),
    );
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(postDataMarketplaceSearchResultFailure({ prop, error: message }));
  }
}

export default function* dataMarketplaceSaga() {
  yield takeLatest(getFilterOptionRequest().type, getFilterOption);
  yield takeLatest(getSampleDataActionsRequest().type, getSampleDataActions);
  yield takeLatest(getDataLineageRequest().type, getDataLineageActions);
  yield takeLatest(
    postSuggestedKeywordDataMarketplaceRequest().type,
    postSuggestedKeywordDataMarketplace,
  );
  yield takeLatest(getOverallDataRequest().type, getOverallDataActions);
  yield takeLatest(getConfidenceScoreRequest().type, getConfidenceScoreActions);
  yield takeLatest(
    postDataMarketplaceSuggestionRequest().type,
    postDataMarketplaceSuggestion,
  );
  yield takeEvery(
    postDataMarketplaceSearchResultRequest().type,
    postDataMarketplaceSearchResult,
  );
}
