import {
  call,
  put,
  delay,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects';

import { getData, patchData, deleteData } from '../services/api';

import {
  NOTIFICATIONS_API_PATH,
  NOTIFICATIONS_MARK_ALL_READ_API_PATH,
  NOTIFICATIONS_SAVE_TIME_API_PATH,
  NOTIFICATIONS_NEW_QUANTITY_API_PATH,
} from '../constants/paths';
import { NOTIFICATION } from '../constants/breakcrumb';

import {
  PROPS_NOTIFICATIONS,
  notificationsAllSelector,
  getNotificationsRequest,
  getNotificationsSuccess,
  getNotificationsFailure,
  markAllReadRequest,
  markAllReadSuccess,
  markAllReadFailure,
  markReadRequest,
  markReadSuccess,
  markReadFailure,
  deleteNotificationRequest,
  deleteNotificationSuccess,
  deleteNotificationFailure,
  saveTimeOpenClosePopupRequest,
  saveTimeOpenClosePopupSuccess,
  saveTimeOpenClosePopupFailure,
  getNewNotificationQuantityRequest,
  getNewNotificationQuantitySuccess,
  getNewNotificationQuantityFailure,
} from '../slices/notifications';

export function* getNotifications({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: NOTIFICATIONS_API_PATH,
      params: payload.params,
    });

    yield delay(800);
    yield put(getNotificationsSuccess({ data, prop: payload.prop }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getNotificationsFailure(message));
  }
}

export function* getNotificationsAll() {
  const typeArr = Object.values(PROPS_NOTIFICATIONS);
  const notificationsAll = yield select(notificationsAllSelector);
  const isShowPage = window.location.pathname === NOTIFICATION.id;
  const { isShowPopup } = notificationsAll;

  for (const type of typeArr) {
    const isPopup = type === PROPS_NOTIFICATIONS.NOTIFICATIONS_POPUP;
    const { data } = notificationsAll[type];

    if ((isShowPopup && isPopup) || (isShowPage && !isPopup)) {
      yield call(getNotifications, {
        payload: {
          params: {
            pageIndex: data.currentPage || 1,
            pageSize: data.pageSize || (isPopup ? 5 : 10),
          },
          prop: type,
        },
      });
    }
  }
}

export function* markAllRead({ payload }) {
  try {
    yield call(patchData, {
      url: NOTIFICATIONS_MARK_ALL_READ_API_PATH,
    });

    yield delay(800);
    yield call(getNotificationsAll);
    yield put(markAllReadSuccess(payload.prop));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(markAllReadFailure({ message, prop: payload.prop }));
  }
}

export function* markRead({ payload }) {
  const { id, prop } = payload;
  try {
    yield call(patchData, {
      url: `${NOTIFICATIONS_API_PATH}/${id}/mark-as-read`,
    });

    yield delay(800);
    yield call(getNotificationsAll);
    yield put(markReadSuccess(prop));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(markReadFailure({ message, prop }));
  }
}

export function* deleteNotification({ payload }) {
  const { id, prop } = payload;
  try {
    yield call(deleteData, {
      url: `${NOTIFICATIONS_API_PATH}/${id}`,
    });

    yield delay(800);
    yield call(getNotificationsAll);
    yield put(deleteNotificationSuccess(prop));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(deleteNotificationFailure({ message, prop }));
  }
}

export function* saveTimeOpenClosePopup() {
  try {
    yield call(getData, {
      url: NOTIFICATIONS_SAVE_TIME_API_PATH,
    });

    yield delay(800);
    yield put(saveTimeOpenClosePopupSuccess());
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(saveTimeOpenClosePopupFailure(message));
  }
}

export function* getNewNotificationQuantity() {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: NOTIFICATIONS_NEW_QUANTITY_API_PATH,
    });

    yield delay(800);
    yield call(getNotificationsAll);
    yield put(getNewNotificationQuantitySuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getNewNotificationQuantityFailure({ message }));
  }
}

export default function* notificationSaga() {
  yield takeEvery(getNotificationsRequest().type, getNotifications);
  yield takeLatest(markAllReadRequest().type, markAllRead);
  yield takeEvery(markReadRequest().type, markRead);
  yield takeEvery(deleteNotificationRequest().type, deleteNotification);
  yield takeLatest(
    saveTimeOpenClosePopupRequest().type,
    saveTimeOpenClosePopup,
  );
  yield takeLatest(
    getNewNotificationQuantityRequest().type,
    getNewNotificationQuantity,
  );
}
