import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'domain';
export const PROPS = {
  BUSINESS_DOMAIN: 'businessDomain',
  DATA_DOMAIN: 'dataDomain',
  MASTER_AND_REFERENCE_DOMAIN: 'masterAndReferenceDomain',
  GENERAL_DOMAIN: 'generalDomain',
  EXTERNAL_DOMAIN: 'externalDomain',
};

const initialState = {
  [PROPS.BUSINESS_DOMAIN]: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  [PROPS.DATA_DOMAIN]: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  [PROPS.MASTER_AND_REFERENCE_DOMAIN]: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  [PROPS.GENERAL_DOMAIN]: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  [PROPS.EXTERNAL_DOMAIN]: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  ebg: {
    data: {
      items: [],
    },
    status: 'loading',
    error: null,
  },
};

const domainSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getDomainRequest(state, action) {
      state[action.payload.prop].status = 'loading';
    },
    getDomainSuccess(state, action) {
      const { prop, data, isPagin } = action.payload;

      if (!isPagin && data.currentPage !== 1) {
        data.items = [...state[prop].data.items, ...data.items];
      }

      state[prop].data = data;
      state[prop].status = 'succeeded';
    },
    getDomainFailure(state, action) {
      state[action.payload.prop].status = 'failed';
      state[action.payload.prop].data = {};
      state[action.payload.prop].error = action.payload.error;
    },
    getTotalDomainsRequest(state, action) {
      state[action.payload.prop].status = 'loading';
    },
    getTotalDomainsSuccess(state, action) {
      const { prop, data } = action.payload;
      state[prop].data = {
        items: data,
      };
      state[prop].status = 'succeeded';
    },
    getTotalDomainsFailure(state, action) {
      state[action.payload.prop].status = 'failed';
      state[action.payload.prop].data = [];
      state[action.payload.prop].error = action.payload.error;
    },
    favouriteDomain(state, action) {
      const { id, prop, isRemove } = action.payload;

      if (isRemove) {
        state[prop].data.items = state[prop].data.items.filter(
          item => item.id !== id,
        );
      } else {
        const index = state[prop].data.items.findIndex(item => item.id === id);

        if (index !== -1) {
          state[prop].data.items[index].isWorkspace =
            !state[prop].data.items[index].isWorkspace;
        }
      }
    },
    getEbgRequest(state) {
      state.ebg.status = 'loading';
    },
    getEbgSuccess(state, action) {
      state.ebg.data = action.payload;
      state.ebg.status = 'succeeded';
    },
    getEbgFailure(state, action) {
      state.ebg.status = 'failed';
      state.ebg.data = {};
      state.ebg.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = domainSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getDomainRequest,
  getDomainSuccess,
  getDomainFailure,
  getTotalDomainsRequest,
  getTotalDomainsSuccess,
  getTotalDomainsFailure,
  clearDomainRequest,
  favouriteDomain,
  getEbgRequest,
  getEbgSuccess,
  getEbgFailure,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const domainSelectorByProp = prop =>
  createSelector(rootSelector, state => state[prop]);
export const totalDomainsSelectorByProp = prop =>
  createSelector(rootSelector, state => state[prop]);
export const ebgSelector = createSelector(rootSelector, ({ ebg }) => ebg);
