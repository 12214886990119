import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { trimSpace } from 'services/string';

import DescriptionEditor from 'components/layout/DescriptionEditor';
import Field from '../Field';

import './styles.scss';

const Input = ({
  isTrimSpace,
  isShowIconInfo,
  infoContent,
  control,
  id,
  className,
  label,
  name,
  placeholder,
  type,
  disabled,
  rules,
  error,
  limit,
  rows,
  ...rest
}) => {
  const updatedError = useMemo(() => {
    let errorMessage;
    switch (error?.type) {
      case 'required':
        errorMessage = `${label} is required.`;
        break;
      case 'maxLength':
        errorMessage = `Length not more than ${rules?.maxLength}.`;
        break;
      case 'minLength':
        errorMessage = `Length not less than ${rules?.minLength}.`;
        break;
      case 'max':
        errorMessage = `Value not greater than ${rules?.max}.`;
        break;
      case 'min':
        errorMessage = `Value not less than ${rules?.min}.`;
        break;
      case 'pattern':
        errorMessage = `Does not contain ${rules?.pattern}.`;
        break;
      default:
        errorMessage = error?.message || `${label} is required.`;
        break;
    }

    return {
      ...error,
      message: errorMessage,
    };
  }, [error]);

  const defaultRules = useMemo(() => {
    if (rules?.validate) {
      return rules;
    }

    if (rules?.required) {
      return {
        ...rules,
        validate: {
          required(val) {
            if (type === 'editor')
              return val.replace(/<(.|\n)*?>/g, '').trim().length > 0;

            return !!val.trim();
          },
        },
      };
    }

    return rules;
  }, [rules]);

  return (
    <Field
      id={id}
      isShowIconInfo={isShowIconInfo}
      rules={defaultRules}
      className={className}
      label={label}
      infoContent={infoContent}
      disabled={disabled}
      error={updatedError}
    >
      <Controller
        name={name}
        control={control}
        rules={defaultRules}
        render={({ field }) => {
          if (type === 'textarea') {
            return (
              <>
                <textarea
                  id={id}
                  className="edh-input edh-input--textarea custom-scrollbar"
                  type={type}
                  placeholder={placeholder}
                  disabled={disabled}
                  rows={rows}
                  maxLength={limit || null}
                  onBlur={() =>
                    isTrimSpace ? trimSpace(field.value) : field.value
                  }
                  value={field.value}
                  onChange={field.onChange}
                />
                {!disabled && (
                  <p className="edh-input__max-length">
                    {field.value?.length || 0}/{limit} characters
                  </p>
                )}
              </>
            );
          }
          if (type === 'editor') {
            return (
              <DescriptionEditor
                disabled={disabled}
                value={field?.value}
                maxLength={limit || null}
                onChange={field?.onChange}
              />
            );
          }
          return (
            <input
              id={id}
              className="edh-input edh-input--text"
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              onBlur={() => {
                field.onChange(trimSpace(field.value));
              }}
              maxLength={limit || null}
              value={field.value}
              onChange={field.onChange}
              {...rest}
            />
          );
        }}
      />
    </Field>
  );
};

Input.propTypes = {
  control: PropTypes.any.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  infoContent: PropTypes.string,
  isShowIconInfo: PropTypes.bool,
  disabled: PropTypes.bool,
  isTrimSpace: PropTypes.bool,
  rules: PropTypes.object,
  error: PropTypes.object,
  limit: PropTypes.number,
  rows: PropTypes.number,
};

Input.defaultProps = {
  id: '',
  className: '',
  label: '',
  name: '',
  placeholder: '',
  type: 'text',
  infoContent: '',
  isShowIconInfo: false,
  disabled: false,
  rules: {
    required: false,
  },
  isTrimSpace: true,
  error: {},
  limit: 0,
  rows: 4,
};

export default memo(Input);
