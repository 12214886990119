import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'userManagement';
const initialState = {
  listUser: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  searchUser: {
    data: [],
    status: 'idle',
    error: null,
  },
  postAssignModularRoleUser: {
    data: [],
    status: 'idle',
    error: null,
  },
  getRoleAssignCard: {
    data: {},
    status: {},
    error: {},
  },
  getDQMRoleAssignCard: {
    data: {},
    status: {},
    error: {},
  },
  updateStatusUser: {
    data: {},
    status: 'idle',
    error: null,
  },
  updateStatusRequestDQM: {
    data: {},
    status: 'idle',
    error: null,
  },
  listSubmittedTemplate: {
    data: [],
    status: 'idle',
    error: null,
  },
  tdcManagement: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  dqManagement: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  dqManagementCreateRequest: {
    data: {},
    status: 'idle',
    error: null,
  },
  dqManagementRequiredReviewers: {
    data: [],
    status: 'idle',
    error: null,
  },
  submissionId: {
    data: [],
    status: 'idle',
    error: null,
  },
  dqManagementSubmissionId: {
    data: [],
    status: 'idle',
    error: null,
  },
  assignEditor: {
    data: [],
    status: 'idle',
    error: null,
  },
  postAssignRequestDQM: {
    data: [],
    status: 'idle',
    error: null,
  },
};

const userManagementSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getListUserRequest(state) {
      state.listUser.status = 'loading';
    },
    getListUserSuccess(state, action) {
      state.listUser.data = action.payload;
      state.listUser.status = 'succeeded';
      state.listUser.error = null;
    },
    getListUserFailure(state, action) {
      state.listUser.status = 'failed';
      state.listUser.data = {};
      state.listUser.error = action.payload;
    },
    searchUserRequest(state) {
      state.searchUser.status = 'loading';
    },
    searchUserSuccess(state, action) {
      state.searchUser.data = action.payload;
      state.searchUser.status = 'succeeded';
      state.searchUser.error = null;
    },
    searchUserFailure(state, action) {
      state.searchUser.status = 'failed';
      state.searchUser.data = {};
      state.searchUser.error = action.payload;
    },
    postAssignModularRoleUserRequest(state) {
      state.postAssignModularRoleUser.status = 'loading';
    },
    postAssignModularRoleUserSuccess(state, action) {
      state.postAssignModularRoleUser.data = action.payload;
      state.postAssignModularRoleUser.status = 'succeeded';
      state.postAssignModularRoleUser.error = null;
    },
    postAssignModularRoleUserFailure(state, action) {
      state.postAssignModularRoleUser.status = 'failed';
      state.postAssignModularRoleUser.data = {};
      state.postAssignModularRoleUser.error = action.payload;
    },
    getRoleAssignCardRequest(state, action) {
      const { id } = action.payload;

      state.getRoleAssignCard.status[id] = 'loading';
    },
    getRoleAssignCardSuccess(state, action) {
      const { id, data } = action.payload;

      state.getRoleAssignCard.data[id] = data;
      state.getRoleAssignCard.status[id] = 'succeeded';
      state.getRoleAssignCard.error[id] = null;
    },
    getRoleAssignCardFailure(state, action) {
      const { id, data } = action.payload;

      state.getRoleAssignCard.status[id] = 'failed';
      state.getRoleAssignCard.data[id] = {};
      state.getRoleAssignCard.error[id] = data;
    },
    getDQMRoleAssignCardRequest(state, action) {
      const { id } = action.payload;

      state.getDQMRoleAssignCard.status[id] = 'loading';
    },
    getDQMRoleAssignCardSuccess(state, action) {
      const { id, data } = action.payload;

      state.getDQMRoleAssignCard.data[id] = data;
      state.getDQMRoleAssignCard.status[id] = 'succeeded';
      state.getDQMRoleAssignCard.error[id] = null;
    },
    getDQMRoleAssignCardFailure(state, action) {
      const { id, data } = action.payload;

      state.getDQMRoleAssignCard.status[id] = 'failed';
      state.getDQMRoleAssignCard.data[id] = {};
      state.getDQMRoleAssignCard.error[id] = data;
    },
    updateStatusUserRequest(state) {
      state.updateStatusUser.status = 'loading';
    },
    updateStatusUserSuccess(state, action) {
      state.tdcManagement.data.items = state.tdcManagement.data.items.map(
        item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
            };
          }
          return item;
        },
      );
      state.updateStatusUser.status = 'succeeded';
      state.updateStatusUser.error = null;
    },
    updateStatusUserFailure(state, action) {
      state.updateStatusUser.status = 'failed';
      state.updateStatusUser.data = {};
      state.updateStatusUser.error = action.payload;
    },
    updateStatusRequestDQMRequest(state) {
      state.updateStatusRequestDQM.status = 'loading';
    },
    updateStatusRequestDQMSuccess(state, action) {
      state.dqManagement.data.items = state.dqManagement.data.items.map(
        item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
            };
          }
          return item;
        },
      );
      state.updateStatusRequestDQM.status = 'succeeded';
      state.updateStatusRequestDQM.error = null;
    },
    updateStatusRequestDQMFailure(state, action) {
      state.updateStatusRequestDQM.status = 'failed';
      state.updateStatusRequestDQM.data = {};
      state.updateStatusRequestDQM.error = action.payload;
    },
    getListSubmittedTemplateRequest(state) {
      state.listSubmittedTemplate.status = 'loading';
    },
    getListSubmittedTemplateSuccess(state, action) {
      state.listSubmittedTemplate.data = action.payload;
      state.listSubmittedTemplate.status = 'succeeded';
      state.listSubmittedTemplate.error = null;
    },
    getListSubmittedTemplateFailure(state, action) {
      state.listSubmittedTemplate.status = 'failed';
      state.listSubmittedTemplate.data = {};
      state.listSubmittedTemplate.error = action.payload;
    },
    getTDCManagementRequest(state) {
      state.tdcManagement.status = 'loading';
    },
    getTDCManagementSuccess(state, action) {
      state.tdcManagement.data = action.payload;
      state.tdcManagement.status = 'succeeded';
      state.tdcManagement.error = null;
    },
    getTDCManagementFailure(state, action) {
      state.tdcManagement.status = 'failed';
      state.tdcManagement.data = {};
      state.tdcManagement.error = action.payload;
    },
    getDQManagementRequest(state) {
      state.dqManagement.status = 'loading';
    },
    getDQManagementSuccess(state, action) {
      state.dqManagement.data = action.payload;
      state.dqManagement.status = 'succeeded';
      state.dqManagement.error = null;
    },
    getDQManagementFailure(state, action) {
      state.dqManagement.status = 'failed';
      state.dqManagement.data = {};
      state.dqManagement.error = action.payload;
    },
    postCreateDQMRequest(state) {
      state.dqManagementCreateRequest.status = 'loading';
    },
    postCreateDQMSuccess(state, action) {
      state.dqManagementCreateRequest.data = action.payload;
      state.dqManagementCreateRequest.status = 'succeeded';
      state.dqManagementCreateRequest.error = null;
    },
    postCreateDQMFailure(state, action) {
      state.dqManagementCreateRequest.status = 'failed';
      state.dqManagementCreateRequest.data = {};
      state.dqManagementCreateRequest.error = action.payload;
    },
    getDQManagementRequiredReviewersRequest(state) {
      state.dqManagementRequiredReviewers.status = 'loading';
    },
    getDQManagementRequiredReviewersSuccess(state, action) {
      state.dqManagementRequiredReviewers.data = action.payload;
      state.dqManagementRequiredReviewers.status = 'succeeded';
      state.dqManagementRequiredReviewers.error = null;
    },
    getDQManagementRequiredReviewersFailure(state, action) {
      state.dqManagementRequiredReviewers.status = 'failed';
      state.dqManagementRequiredReviewers.data = {};
      state.dqManagementRequiredReviewers.error = action.payload;
    },
    getSubmissionIdRequest(state) {
      state.submissionId.status = 'loading';
    },
    getSubmissionIdSuccess(state, action) {
      state.submissionId.data = action.payload;
      state.submissionId.status = 'succeeded';
      state.submissionId.error = null;
    },
    getSubmissionIdFailure(state, action) {
      state.submissionId.status = 'failed';
      state.submissionId.data = {};
      state.submissionId.error = action.payload;
    },
    getDQMSubmissionIdRequest(state) {
      state.dqManagementSubmissionId.status = 'loading';
    },
    getDQMSubmissionIdSuccess(state, action) {
      state.dqManagementSubmissionId.data = action.payload;
      state.dqManagementSubmissionId.status = 'succeeded';
      state.dqManagementSubmissionId.error = null;
    },
    getDQMSubmissionIdFailure(state, action) {
      state.dqManagementSubmissionId.status = 'failed';
      state.dqManagementSubmissionId.data = {};
      state.dqManagementSubmissionId.error = action.payload;
    },
    assignEditorRequest(state) {
      state.assignEditor.status = 'loading';
    },
    assignEditorSuccess(state, action) {
      state.assignEditor.data = action.payload;
      state.assignEditor.status = 'succeeded';
      state.assignEditor.error = null;
    },
    assignEditorFailure(state, action) {
      state.assignEditor.status = 'failed';
      state.assignEditor.data = {};
      state.assignEditor.error = action.payload;
    },
    postAssignRequestDQMRequest(state) {
      state.postAssignRequestDQM.status = 'loading';
    },
    postAssignRequestDQMSuccess(state, action) {
      state.postAssignRequestDQM.data = action.payload;
      state.postAssignRequestDQM.status = 'succeeded';
      state.postAssignRequestDQM.error = null;
    },
    postAssignRequestDQMFailure(state, action) {
      state.postAssignRequestDQM.status = 'failed';
      state.postAssignRequestDQM.data = {};
      state.postAssignRequestDQM.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = userManagementSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getListUserRequest,
  getListUserSuccess,
  getListUserFailure,
  searchUserRequest,
  searchUserSuccess,
  searchUserFailure,
  postAssignModularRoleUserRequest,
  postAssignModularRoleUserSuccess,
  postAssignModularRoleUserFailure,
  getRoleAssignCardRequest,
  getRoleAssignCardSuccess,
  getRoleAssignCardFailure,
  getDQMRoleAssignCardRequest,
  getDQMRoleAssignCardSuccess,
  getDQMRoleAssignCardFailure,
  updateStatusUserRequest,
  updateStatusUserSuccess,
  updateStatusUserFailure,
  updateStatusRequestDQMRequest,
  updateStatusRequestDQMSuccess,
  updateStatusRequestDQMFailure,
  getListSubmittedTemplateRequest,
  getListSubmittedTemplateSuccess,
  getListSubmittedTemplateFailure,
  getTDCManagementRequest,
  getTDCManagementSuccess,
  getTDCManagementFailure,
  getDQManagementRequest,
  getDQManagementSuccess,
  getDQManagementFailure,
  postCreateDQMRequest,
  postCreateDQMSuccess,
  postCreateDQMFailure,
  getDQManagementRequiredReviewersRequest,
  getDQManagementRequiredReviewersSuccess,
  getDQManagementRequiredReviewersFailure,
  getSubmissionIdRequest,
  getSubmissionIdSuccess,
  getSubmissionIdFailure,
  getDQMSubmissionIdRequest,
  getDQMSubmissionIdSuccess,
  getDQMSubmissionIdFailure,
  assignEditorRequest,
  assignEditorSuccess,
  assignEditorFailure,
  postAssignRequestDQMRequest,
  postAssignRequestDQMSuccess,
  postAssignRequestDQMFailure,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};

export const listUserSelector = createSelector(
  rootSelector,
  ({ listUser }) => listUser,
);
export const searchUserSelector = createSelector(
  rootSelector,
  ({ searchUser }) => searchUser,
);
export const postAssignModularRoleUserSelector = createSelector(
  rootSelector,
  ({ postAssignModularRoleUser }) => postAssignModularRoleUser,
);
export const getRoleAssignCardSelector = createSelector(
  rootSelector,
  ({ getRoleAssignCard }) => getRoleAssignCard,
);
export const getDQMRoleAssignCardSelector = createSelector(
  rootSelector,
  ({ getDQMRoleAssignCard }) => getDQMRoleAssignCard,
);
export const listSubmittedTemplateSelector = createSelector(
  rootSelector,
  ({ listSubmittedTemplate }) => listSubmittedTemplate,
);

export const listTDCManagementSelector = createSelector(
  rootSelector,
  ({ tdcManagement }) => tdcManagement,
);

export const listDQManagementSelector = createSelector(
  rootSelector,
  ({ dqManagement }) => dqManagement,
);

export const dqManagementCreateRequestSelector = createSelector(
  rootSelector,
  ({ dqManagementCreateRequest }) => dqManagementCreateRequest,
);

export const listDQMRequiredReviewersSelector = createSelector(
  rootSelector,
  ({ dqManagementRequiredReviewers }) => dqManagementRequiredReviewers,
);

export const submissionIdSelector = createSelector(
  rootSelector,
  ({ submissionId }) => submissionId,
);

export const dqManagementSubmissionIdSelector = createSelector(
  rootSelector,
  ({ dqManagementSubmissionId }) => dqManagementSubmissionId,
);

export const assignEditorSelector = createSelector(
  rootSelector,
  ({ assignEditor }) => assignEditor,
);

export const postAssignRequestDQMSelector = createSelector(
  rootSelector,
  ({ postAssignRequestDQM }) => postAssignRequestDQM,
);
