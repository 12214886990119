import { Fragment, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';

import useTakeEventGA from 'hooks/useTakeEventGA';

import { convertDuration } from 'services/date';

import * as breadcrumb from 'constants/breakcrumb';

const pages = Object.values(breadcrumb);

const TrackingSpendTime = () => {
  const { pathname } = useLocation();

  const { onSendTrackingSpendTime } = useTakeEventGA();

  let startTime = Date.now();

  const currentPage = pages.find(
    page =>
      !!matchPath(pathname, {
        path: page.href,
        exact: true,
      }),
  );

  const handleSendTrackingSpendTime = () => {
    const time = convertDuration(
      new Date(Date.now() - startTime).toUTCString().split(' ')[4],
    );

    startTime = Date.now();

    onSendTrackingSpendTime({
      page: currentPage?.label,
      pagePath: pathname,
      time,
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleSendTrackingSpendTime);

    return () => {
      handleSendTrackingSpendTime();
      window.removeEventListener('beforeunload', handleSendTrackingSpendTime);
    };
  }, [pathname]);

  return Fragment;
};

export default TrackingSpendTime;
