import axios from 'axios';
import _ from 'lodash';
import XLSX from 'xlsx';

import { ID_TOKEN } from '../../constants/storageKeys';

import { getStorage } from '../storage';
import settings from '../../settings';

const { BASE_URL } = settings;

const setInstance = () => {
  const token = getStorage(ID_TOKEN);

  const instance = axios.create({
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob',
  });

  return instance;
};

const generateLinkElement = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  if (fileName) {
    link.setAttribute('download', fileName);
  }
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const exportDownload = ({ isDebounce = true } = {}) => {
  const exportFunc = ({ item, callback }) => {
    const instance = setInstance();
    const { fileUrl, fileName, fileType, id } = item;

    if (fileUrl?.includes(`${process.env.BASE_URL}api`)) {
      try {
        instance.get(fileUrl).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          generateLinkElement(url, `${fileName}.${fileType}`);
        });
      } catch (error) {
        return false;
      }
    } else {
      generateLinkElement(fileUrl, fileName);
    }

    callback?.(id);
  };
  if (isDebounce) {
    const debouncedExport = _.debounce(exportFunc, 250);
    return debouncedExport;
  }

  return exportFunc;
};

export const guideDownload = ({ apiPath, fileName }) => {
  const instance = setInstance();
  try {
    instance.get(BASE_URL + apiPath).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    return false;
  }
  return true;
};

export const guidePostRulesLibDownload = ({ apiPath, fileName, dqRuleIds }) => {
  const instance = setInstance();

  try {
    instance.post(apiPath, { dqRuleIds }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    return false;
  }
  return true;
};

export const aiModelResultDownload = async ({ apiPath, fileName }) => {
  const instance = setInstance();

  try {
    await instance.post(apiPath, { id: fileName }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    return false;
  }
  return true;
};

export const offlineModelDownload = ({
  apiPath,
  fileName,
  payload,
  callback,
}) => {
  const instance = setInstance();

  try {
    instance.post(apiPath, payload).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      callback(response.status === 200);
    });
  } catch (error) {
    callback();
    return false;
  }
  return true;
};

export const downloadURI = (uri, name) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const convertDataTableToExcelFile = (dataRow, fileName, tabName) => {
  const newData = dataRow.map(row => row);

  const workSheet = XLSX.utils.json_to_sheet(newData);

  const workBook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workBook, workSheet, tabName);

  XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

  XLSX.writeFile(workBook, `${fileName}.xlsx`);
};

export const convertJsonToExcelFileFormat = ({
  jsonData,
  fileName,
  tabName,
  exportOpts,
}) => {
  const newData = jsonData.map(row => row);
  const workSheet = XLSX.utils.json_to_sheet(newData, exportOpts);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, tabName);
  const arrayBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
  return new File([arrayBuffer], `${fileName}.xlsx`, {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
};

export const formatFileName = () => {
  const currentDate = new Date();
  const formattedDate = currentDate
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
    .replace(/ /g, '');
  return `DQ Rules_${formattedDate}.csv`;
};
