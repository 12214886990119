import React, { memo, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import useQuery from 'hooks/useQuery';

import { useEffectOnceWhen } from 'hooks/useEffectOnceWhen';
import { INTERACTIVE_MODELS_KEY } from 'constants/analyticsmarketplace';

import { SUBTAB_ID, tabExploreComponent } from './constants';
import AMTabs from './AMTabs';

import './styles.scss';
import { TAB_ID } from '../constants';

const AMExploreTab = ({ detail }) => {
  const { query, onAddToQuery } = useQuery();

  const [currentTab, setCurrentTab] = useState(SUBTAB_ID.PREVIEW_AND_TRY);
  const tabs =
    detail.modelType !== INTERACTIVE_MODELS_KEY || detail.downloadable // Temporarily move UAUC to Interactive Model
      ? tabExploreComponent.filter(
          item => item.id !== SUBTAB_ID.DATA_AVAILABILITY_IN_MARKETPLACE,
        )
      : tabExploreComponent;

  const onClickSubTab = tab => {
    setCurrentTab(tab.id);
    onAddToQuery({ ...query, sub: tab.id, page: '', size: '' });
  };

  useEffectOnceWhen(() => {
    if (query.sub && tabs.find(item => item.id === query.sub)) {
      setCurrentTab(query.sub);
    } else {
      onAddToQuery({ ...query, sub: SUBTAB_ID.PREVIEW_AND_TRY });
    }
  }, !!detail.modelType);

  useEffect(() => {
    if (
      query.tab === TAB_ID.EXPLORE &&
      query.sub === SUBTAB_ID.PREVIEW_AND_TRY &&
      query.sub !== currentTab
    ) {
      setCurrentTab(SUBTAB_ID.PREVIEW_AND_TRY);
    }
  }, [query]);

  return (
    <div className="am-tab-explore">
      <AMTabs
        tabs={tabs}
        defaultTab={currentTab}
        onClick={onClickSubTab}
        detail={detail}
      />
    </div>
  );
};

AMExploreTab.propTypes = {
  detail: PropTypes.shape({
    modelType: PropTypes.string,
    downloadable: PropTypes.bool,
  }),
};

export default memo(AMExploreTab);
