import moment from 'moment';

export const createWeekOptions = () => {
  const result = moment().isoWeeksInYear();

  const weeks = [];
  for (let index = 1; index <= result; index++) {
    weeks.push({
      id: index,
      label: `Week ${index}`,
      value: index,
    });
  }

  return weeks;
};
export const createYearOptions = () => {
  const date = new Date();
  const years = [];
  const year = date.getFullYear();

  // eslint-disable-next-line no-plusplus
  for (let i = year; i > year - 5 && i >= 2020; i--) {
    years.push({
      label: i,
      value: i,
    });
  }

  return years;
};

// Start: Date Format Constants
export const DATETIME_LOCAL_MS = 'YYYY-MM-DD[T]HH:mm:ss.SSS';
export const DATETIME_CUSTOM_FORMAT = 'DD MMM YYYY [at] LT';
export const DATETIME_CUSTOM_FORMAT_2 = 'DD MMM yyyy [at] hh:mm A';
export const DATETIME_CUSTOM_FORMAT_3 = 'DD/MM/yyyy HH:mm';
export const DEF_DATE_FORMAT = 'YYYY-MM-DD';
export const DEF_FULL_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const SHORT_DATE_FORMAT = 'D MMM YY';
export const SHORT_DATE_FORMAT_1 = 'd MMM yyyy';
export const SHORT_DATE_FORMAT_2 = 'D MMM YYYY';
export const SHORT_DATE_FORMAT_3 = 'DD MMM YYYY';
export const SHORT_DATE_FORMAT_4 = 'DD MMM yyyy';
export const FULL_DATE_FORMAT = 'D MMMM YYYY';
export const FULL_DATE_TIME_FORMAT = 'D MMMM YYYY, h:mma';
export const FULL_DATE_YEAR_FORMAT = 'D MMM YYYY';
export const FULL_DATE_YEAR_FORMAT_2 = 'D MMMM YYYY';
export const FULL_DATE_YEAR_FORMAT_3 = 'DD MMMM YYYY';
export const MOMENT_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const YEAR_DATE = 'YYYY/MM/DD';
export const MONTH_FORMAT = 'MMM';
export const MONTH_FORMAT_FULL_TEXT = 'MMMM';
export const YEAR_FORMAT = 'YYYY';
export const TIME_FORMAT = 'hh:mm A';
// End: Date Format

// Start: Other Constants

export const WAIT_INTERVAL = 600;
export const SCHEDULE_YEARS_FUTURE = 2;
export const SCHEDULE_YEARS_PAST = 5;

export const MONTHLY = 1;
export const QUARTERLY = 2;
export const YEARLY = 8;
export const DAILY = 6;
export const WEEKLY = 7;
export const YEAR_TO_DATE = 3;
export const YEAR_END_PROJECTION = 9;
export const ONE_DAY = 10;
export const TWO_WEEKS = 11;
export const ONE_MONTH = 12;
export const THREE_MONTHS = 13;
export const SIX_MONTHS = 14;
export const ONE_YEAR = 15;
export const FREQUENCY_OPTIONS = [
  { value: WEEKLY, label: 'Weekly' },
  { value: MONTHLY, label: 'Monthly' },
  { value: QUARTERLY, label: 'Quarterly' },
  { value: YEARLY, label: 'Yearly' },
];
export const FULL_FREQUENCY_OPTIONS = [
  { value: DAILY, label: 'Daily' },
  { value: WEEKLY, label: 'Weekly' },
  { value: MONTHLY, label: 'Monthly' },
  { value: QUARTERLY, label: 'Quarterly' },
  { value: YEARLY, label: 'Yearly' },
  { value: YEAR_TO_DATE, label: 'Year To Date' },
  { value: YEAR_END_PROJECTION, label: 'Year End Projection' },
];

export const WEEK_OPTIONS = createWeekOptions();
export const MONTH_OPTIONS = [
  {
    id: '38af9586-a6c9-443c-8df6-68ab18fd85dd',
    label: 'January',
    value: 1,
  },
  {
    id: '97a64558-12a2-43c8-8925-a366e2fb2f2f',
    label: 'February',
    value: 2,
  },
  {
    id: 'a8b1a2fb-7832-4b74-ae53-b745491d147f',
    label: 'March',
    value: 3,
  },
  {
    id: '961deed7-aa10-48ce-8ca7-a8e4e45deba0',
    label: 'April',
    value: 4,
  },
  {
    id: '2611f1f3-9e1e-4225-9df7-817bfcca8b5d',
    label: 'May',
    value: 5,
  },
  {
    id: '94dcc7f5-33b1-4d2c-8215-bf61bd9314c6',
    label: 'June',
    value: 6,
  },
  {
    id: '32dcc34b-37ff-40d5-a31d-1e9204d51f64',
    label: 'July',
    value: 7,
  },
  {
    id: '4cfb88fb-072d-4a16-9952-055e41b3f543',
    label: 'August',
    value: 8,
  },
  {
    id: 'e8fecb51-c65d-4820-be42-ad0f0c6c8d3c',
    label: 'September',
    value: 9,
  },
  {
    id: 'b02ed066-88a6-4623-9419-e654fd4ce36e',
    label: 'October',
    value: 10,
  },
  {
    id: 'f6f15806-51f8-4be4-861a-4fb4ebcd58d6',
    label: 'November',
    value: 11,
  },
  {
    id: 'ff6a9b76-4dd3-4deb-9e44-1a9cb9fb8cff',
    label: 'December',
    value: 12,
  },
];
export const QUARTER_OPTIONS = [
  {
    id: 'e8fecb51-c65d-4820-be42-ad0f0c6c8d3c',
    label: 'Q1',
    value: 1,
  },
  {
    id: 'b02ed066-88a6-4623-9419-e654fd4ce36e',
    label: 'Q2',
    value: 2,
  },
  {
    id: 'f6f15806-51f8-4be4-861a-4fb4ebcd58d6',
    label: 'Q3',
    value: 3,
  },
  {
    id: 'ff6a9b76-4dd3-4deb-9e44-1a9cb9fb8cff',
    label: 'Q4',
    value: 4,
  },
];
export const YEAR_OPTIONS = createYearOptions();

export const MONTH_INITIAL_VALUE = MONTH_OPTIONS[0];
export const YEAR_INITIAL_VALUE = {
  label: new Date().getFullYear(),
  value: new Date().getFullYear(),
};
export const DATE_RANGE = [
  {
    id: 'ff6a9b76-4dd3-4deb-9e44-1a9cb9fb8cff',
    label: '1 day',
    value: ONE_DAY,
  },
  {
    id: 'ff6a9b76-4dd3-4deb-9e44-1a9cb9fb8cff',
    label: '2 weeks',
    value: TWO_WEEKS,
  },
  {
    id: 'ff6a9b76-4dd3-4deb-9e44-1a9cb9fb8cff',
    label: '1 month',
    value: ONE_MONTH,
  },
  {
    id: 'ff6a9b76-4dd3-4deb-9e44-1a9cb9fb8cff',
    label: '3 months',
    value: THREE_MONTHS,
  },
  {
    id: 'ff6a9b76-4dd3-4deb-9e44-1a9cb9fb8cff',
    label: '6 months',
    value: SIX_MONTHS,
  },
  {
    id: 'ff6a9b76-4dd3-4deb-9e44-1a9cb9fb8cff',
    label: '1 year',
    value: ONE_YEAR,
  },
];

// Start: End Constants

export const generateYear = (minDate, maxDate) => {
  const years = [];
  for (
    let i = new Date(minDate).getFullYear();
    i <= new Date(maxDate).getFullYear();
    i++
  ) {
    years.push({
      id: i,
      value: i,
    });
  }

  return years;
};

export const getLastTimeUpdated = lastTime => {
  if (lastTime) {
    const now = moment(moment().format()); // todays date
    const end = moment(lastTime); // another date
    const duration = moment.duration(now.diff(end));

    if (duration.days() >= 1) {
      return `${duration.days()} days ago`;
    }
    if (duration.hours() >= 1) {
      return `${duration.hours()} hours ago`;
    }
    if (duration.minutes() >= 1) {
      return `${duration.minutes()} mins ago`;
    }
    if (duration.seconds() >= 1 || duration.seconds() === 0) {
      return '1 mins ago';
    }
  }

  return null;
};

export const getTimeAgo = time => {
  if (!time) return 'Valid time';

  const timeNow = moment();
  const timeEnd = moment(time);
  const duration = moment.duration(timeNow.diff(timeEnd));

  const yearCount = duration.years();
  const monthCount = duration.months();
  const weekCount = duration.weeks();
  const dayCount = duration.days();
  const hourCount = duration.hours();

  if (yearCount > 0) {
    return `${yearCount} ${yearCount > 1 ? 'years' : 'year'} ago`;
  }
  if (monthCount > 0) {
    return `${monthCount} ${monthCount > 1 ? 'months' : 'month'} ago`;
  }
  if (weekCount > 0) {
    return `${weekCount} ${weekCount > 1 ? 'weeks' : 'week'} ago`;
  }
  if (dayCount > 0) {
    return `${dayCount} ${dayCount > 1 ? 'days' : 'day'} ago`;
  }
  return `${hourCount} ${hourCount > 1 ? 'hrs' : 'hr'} ago`;
};
