const classnames = (...rest) =>
  rest
    .reduce((previousValue, currentValue) => {
      if (!currentValue) return previousValue;

      if (typeof currentValue === 'string')
        return [...previousValue, currentValue];

      if (typeof currentValue === 'object') {
        for (const [key, value] of Object.entries(currentValue)) {
          if (value) {
            previousValue.push(key);
          }
        }

        return previousValue;
      }

      return previousValue;
    }, [])
    .join(' ');

export default classnames;
