import { ANALYTICS_MARKETPLACE_VALIDATE_WORD_FILE } from '../constants/paths';
import { postData } from '../services/api';

export const getWordFilePage = async ({ id, file }) => {
  const url = ANALYTICS_MARKETPLACE_VALIDATE_WORD_FILE;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('id', id);
  const response = await postData({
    url,
    data: formData,
  });
  return response.data.data;
};
