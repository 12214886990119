/* eslint-disable react/no-danger */
import React, { memo, useRef, useState } from 'react';
import BButton from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

import useDevice from 'hooks/useDevice';

import AdminIcon from 'assets/icons/common/admin.svg';

import Avatar from 'components/core/Avatar';

import ConfirmModal from 'components/layout/ConfirmModal';
import RichTextClamp from 'components/core/RichTextClamp';

import './styles.scss';

const CommentCard = ({
  id,
  description,
  fullName,
  avatarImg,
  index,
  onReply,
  onDelete,
  isComment = true,
  isDelete = true,
  isPosting = false,
  isAMAdmin = false,
  isClamp = false,
}) => {
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const { isMobileDevice } = useDevice();

  const commentRef = useRef();

  const commentName = isAMAdmin ? 'Analytics Marketplace Admin' : fullName;

  const avatarProps = {
    name: commentName,
    src: avatarImg,
  };

  if (isAMAdmin) {
    avatarProps.icon = AdminIcon;
    avatarProps.style = { background: 'none' };
  }

  const replyAction = () => (
    <>
      {' '}
      {isPosting ? (
        <span className="reply__posting" />
      ) : (
        <div
          className={`reply__action ${
            isComment && isDelete ? `vertical-divider` : ''
          }`}
        >
          {isComment && (
            <BButton
              className="reply__button"
              onClick={() => {
                onReply();
              }}
            >
              Reply
            </BButton>
          )}
          {isDelete && (
            <BButton
              className="reply__button"
              onClick={() => setShowConfirmPopup(true)}
            >
              Delete
            </BButton>
          )}
        </div>
      )}
    </>
  );

  const replyMargin = isMobileDevice ? 31 : 45;
  return (
    <div ref={commentRef} key={id} className="reply">
      <div
        key={id}
        id={id}
        className={`reply__card ${index === 2 ? 'bg' : ''}`}
        style={{
          marginLeft: index > 0 ? replyMargin * index : replyMargin,
        }}
      >
        <Avatar className="reply__avatar" {...avatarProps} />
        <div id={id} className="reply__content">
          <div className="reply__info">
            <div className={`reply__${isAMAdmin ? 'admin' : 'user'}`}>
              {commentName}
            </div>
            {!isMobileDevice && replyAction()}
          </div>
          <RichTextClamp
            className="reply__description"
            unsafeHTML={description}
            maxLine={isMobileDevice ? 2 : 1}
            basedOn="letters"
            ellipsisHTML="<span>...Show more</span>"
            defaultClamp={isClamp}
          />
          {isMobileDevice && replyAction()}
        </div>
      </div>
      <ConfirmModal
        show={showConfirmPopup}
        category="CONFIRMATION"
        type="danger"
        title={`Delete ${isComment ? 'Comment' : 'Reply'}`}
        message={`Are you sure you want to delete your ${
          isComment ? 'comment' : 'reply'
        }?`}
        onHide={() => setShowConfirmPopup(false)}
        onCancelClick={() => setShowConfirmPopup(false)}
        onYesClick={() => {
          onDelete(id);
          setTimeout(() => {
            setShowConfirmPopup(false);
          }, 300);
        }}
        customText="Yes, proceed"
      />
    </div>
  );
};

CommentCard.propTypes = {
  id: PropTypes.string,
  fullName: PropTypes.string,
  avatarImg: PropTypes.string,
  description: PropTypes.string,
  isComment: PropTypes.bool,
  isDelete: PropTypes.bool,
  isPosting: PropTypes.bool,
  isAMAdmin: PropTypes.bool,
  isClamp: PropTypes.bool,
  index: PropTypes.number,
  onReply: PropTypes.func,
  onDelete: PropTypes.func,
};

export default memo(CommentCard);
