import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import Field from '../Field';
import AMSelect from '../../core/AMSelect';

import './styles.scss';

const AMSelectField = ({
  control,
  id,
  className,
  label,
  placeholder,
  name,
  disabled,
  options,
  rules,
  error,
  onSetManualSelectedType,
}) => {
  const {
    field: { value, onChange },
  } = useController({ name, control, rules });

  const handleSelect = useCallback(selectedOption => {
    onChange(selectedOption.value);
    onSetManualSelectedType(selectedOption.value);
  }, []);

  return (
    <Field
      rules={rules}
      id={id}
      className={className}
      label={label}
      disabled={disabled}
      error={error}
    >
      <AMSelect
        value={value}
        defaultLabel={placeholder}
        options={options}
        disabled={disabled}
        onSelect={handleSelect}
      />
    </Field>
  );
};

AMSelectField.propTypes = {
  control: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  rules: PropTypes.object,
  error: PropTypes.object,
  onSetManualSelectedType: PropTypes.func,
};

AMSelectField.defaultProps = {
  id: '',
  className: '',
  label: '',
  name: '',
  placeholder: '',
  disabled: false,
  rules: {},
  error: {},
  onSetManualSelectedType() {},
};

export default memo(AMSelectField);
