import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const PROPS = {
  MOST_DOWNLOADED_INTERNAL: 'mostDownloadedInternal',
  MOST_DOWNLOADED_POPUP_INTERNAL: 'mostDownloadedPopupInternal',
  MOST_DOWNLOADED_EXTERNAL: 'mostDownloadedExternal',
  MOST_DOWNLOADED_POPUP_EXTERNAL: 'mostDownloadedPopupExternal',
  TOTAL_DATA_INTERNAL: 'totalDataInternal',
  TOTAL_DATA_EXTERNAL: 'totalDataExternal',
  TOTAL_DATA_DQ: 'totalDataDQ',
};

export const ROOT_STATE_NAME = 'overviews';
const initialState = {
  dataQualityScore: {
    data: [],
    status: 'loading', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
  [PROPS.MOST_DOWNLOADED_INTERNAL]: {
    data: {},
    status: 'idle',
    error: null,
  },
  [PROPS.MOST_DOWNLOADED_POPUP_INTERNAL]: {
    data: {},
    status: 'idle',
    error: null,
  },
  [PROPS.MOST_DOWNLOADED_EXTERNAL]: {
    data: {},
    status: 'idle',
    error: null,
  },
  [PROPS.MOST_DOWNLOADED_POPUP_EXTERNAL]: {
    data: {},
    status: 'idle',
    error: null,
  },
  [PROPS.TOTAL_DATA_INTERNAL]: {
    data: {},
    status: 'idle',
    error: null,
  },
  [PROPS.TOTAL_DATA_EXTERNAL]: {
    data: {},
    status: 'idle',
    error: null,
  },
  [PROPS.TOTAL_DATA_DQ]: {
    data: {},
    status: 'idle',
    error: null,
  },
};

const overviewsSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getDataQualityScoreRequest(state) {
      state.dataQualityScore.status = 'loading';
    },
    getDataQualityScoreSuccess(state, action) {
      state.dataQualityScore.data = action.payload;
      state.dataQualityScore.status = 'succeeded';
    },
    getDataQualityScoreFailure(state, action) {
      state.dataQualityScore.status = 'failed';
      state.dataQualityScore.data = [];
      state.dataQualityScore.error = action.payload;
    },
    getTotalDataRequest(state, action) {
      state[action.payload.prop].status = 'loading';
    },
    getTotalDataSuccess(state, action) {
      const { prop, data } = action.payload;
      state[prop].data = data;
      state[prop].status = 'succeeded';
    },
    getTotalDataFailure(state, action) {
      const { prop, message } = action.payload;
      state[prop].status = 'failed';
      state[prop].error = message;
    },
    getMostDownloadedDataRequest(state, action) {
      state[action.payload.prop].status = 'loading';
    },
    getMostDownloadedDataSuccess(state, action) {
      const { prop, data } = action.payload;
      state[prop].data = data;
      state[prop].status = 'succeeded';
    },
    getMostDownloadedDataFailure(state, action) {
      const { prop, message } = action.payload;
      state[prop].status = 'failed';
      state[prop].error = message;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = overviewsSlice;
// Export the reducer, either as a Overview or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getDataQualityScoreRequest,
  getDataQualityScoreSuccess,
  getDataQualityScoreFailure,
  getTotalDataRequest,
  getTotalDataSuccess,
  getTotalDataFailure,
  getMostDownloadedDataRequest,
  getMostDownloadedDataSuccess,
  getMostDownloadedDataFailure,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const dataQualityScoreSelector = createSelector(
  rootSelector,
  ({ dataQualityScore }) => dataQualityScore,
);
export const totalDataSelector = prop =>
  createSelector(rootSelector, state => state[prop]);
export const mostDownloadedDataSelector = prop =>
  createSelector(rootSelector, state => state[prop]);
