import React, { useMemo } from 'react';

import FlipMove from 'react-flip-move';

import useToasts from 'hooks/useToasts';

import Toast from './Toast';

import './styles.scss';

const Toasts = () => {
  const { toastsMessages, onRemoveToastsMessage } = useToasts();

  const updatedToastsMessages = useMemo(
    () =>
      toastsMessages.data.map(message => ({
        ...message,
        onClose() {
          onRemoveToastsMessage(message);
        },
      })),
    [toastsMessages],
  );

  return (
    <div className="edh-toasts">
      <FlipMove typeName="ul">
        {updatedToastsMessages.map(item => (
          <li key={item.id}>
            <Toast {...item} />
          </li>
        ))}
      </FlipMove>
    </div>
  );
};

export default Toasts;
