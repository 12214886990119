import roles from './roles';

export const LIST_STATUS_ENTITLEMENT = {
  InReview: {
    Approved: true,
    AdjustedAndApproved: true,
    OnBoarded: false,
    Rejected: true,
  },
  Approved: {
    Approved: false,
    OnBoarded: true,
    Rejected: false,
  },
  AdjustedAndApproved: {
    Approved: false,
    OnBoarded: true,
    Rejected: false,
  },
};

export const LIST_STATUS_NEW_REQUEST = {
  Submitted: {
    InReview: true,
    InDiscussion: false,
    Approved: false,
    OnboardingInProgress: false,
    OnBoarded: false,
    Rejected: true,
  },
  InReview: {
    InReview: false,
    InDiscussion: true,
    Approved: false,
    OnboardingInProgress: false,
    OnBoarded: false,
    Rejected: true,
  },
  InDiscussion: {
    InReview: false,
    InDiscussion: false,
    Approved: true,
    OnboardingInProgress: false,
    OnBoarded: false,
    Rejected: true,
  },
  Approved: {
    InReview: false,
    InDiscussion: false,
    Approved: false,
    OnboardingInProgress: true,
    OnBoarded: false,
    Rejected: false,
  },
  OnboardingInProgress: {
    InReview: false,
    InDiscussion: false,
    Approved: false,
    OnboardingInProgress: false,
    OnBoarded: true,
    Rejected: false,
  },
};
export const LIST_STATUS_NEW_REQUEST_DATA_STEWARD = {
  ...LIST_STATUS_NEW_REQUEST,
  AdjustedAndApproved: {
    InReview: false,
    InDiscussion: false,
    Approved: false,
    OnboardingInProgress: true,
    OnBoarded: false,
    Rejected: false,
  },
};

export const REQUEST_STATUS_DEFINE = {
  Submitted: { code: 'Submitted', name: 'Submitted' },
  SubmittedToMyGenie: {
    code: 'SubmittedToMyGenie',
    name: 'Submitted to MyGenie+',
  },
  InReview: { code: 'InReview', name: 'In Review' },
  Approved: { code: 'Approved', name: 'Approved' },
  AdjustedAndApproved: {
    code: 'AdjustedAndApproved',
    name: 'Adjusted and Approved',
  },
  OnBoarded: { code: 'OnBoarded', name: 'Onboarded' },
  Rejected: { code: 'Rejected', name: 'Rejected' },
  Cancelled: { code: 'Cancelled', name: 'Cancelled' },
  InDiscussion: { code: 'InDiscussion', name: 'In Discussion' },
  OnboardingInProgress: {
    code: 'OnboardingInProgress',
    name: 'Onboarding In Progress',
  },
  Revoked: { code: 'Revoked', name: 'Revoked' },
};

export const generateToRequestStatusType = () => {
  const requestStatus = {};

  Object.keys(REQUEST_STATUS_DEFINE).forEach(type => {
    requestStatus[REQUEST_STATUS_DEFINE[type].name] = type;
    return type;
  });

  return requestStatus;
};

export const CATEGORY_TYPE = {
  DATASET_ENTITLEMENT_REQUEST: {
    name: 'Dataset Entitlement Request',
    code: 'DatasetEntitlementRequest',
  },
  NEW_DATASET_REQUEST: {
    name: 'Add New Dataset Request',
    code: 'NewDatasetRequest',
  },
  CHANGE_CURRENT_DATASET: {
    name: 'Change Current Dataset Request',
    code: 'ChangeCurrentDatasetRequest',
  },
  ENTITLEMENT_EXTENSION_REQUEST: {
    name: 'Dataset Entitlement Extension Request',
    code: 'DatasetEntitlementExtensionRequest',
  },
};

export const CATEGORY_TAB_LIST = [
  {
    value: CATEGORY_TYPE.NEW_DATASET_REQUEST.code,
    label: 'New Dataset Request List',
  },
  {
    value: CATEGORY_TYPE.DATASET_ENTITLEMENT_REQUEST.code,
    label: CATEGORY_TYPE.DATASET_ENTITLEMENT_REQUEST.name,
  },
];

export const DATASET_MANAGEMENT_TAB_LIST = [
  {
    value: 'MyEntitledData',
    label: 'My Entitled Data',
    roles: [roles.user, roles.dataSteward],
  },
  {
    value: 'DataEntitlementRequest',
    label: 'Data Entitlement Request',
    roles: [roles.user, roles.dataSteward],
  },
  {
    value: 'DatasetRequest',
    label: 'New/Change Dataset Request',
    roles: [roles.user, roles.dataSteward, roles.dgaAdmin, roles.dqAdmin],
  },
  {
    value: 'DataEntitlementManagement',
    label: 'Data Entitlement Management',
    roles: [
      roles.dataSteward,
      roles.dgaAdmin,
      roles.dqAdmin,
      roles.admin,
      roles.eds,
    ],
  },
  {
    value: 'DatasetRequestManagement',
    label: 'Dataset Request Management',
    roles: [roles.admin],
  },
  {
    value: 'DataCatalogueManagement',
    label: 'Data Catalogue Management',
    roles: [roles.admin],
    isAccessUMDCM: true,
    isAccessDMDCM: true,
  },
  {
    value: 'ModelRequest',
    label: 'Model Request',
    roles: [roles.admin],
    isAccessModelRequestPage: true,
  },
  {
    value: 'DataQualityManagement',
    label: 'Data Quality Management',
    roles: [roles.admin],
    isAccessUMDQM: true,
    isAccessDMDQM: true,
    isHidden: true,
  },
];

export const STATUS_BLOCK_DEFINE = {
  MY_DATASETS: {
    id: 'myDatasets',
    title: 'My Datasets',
    color: '#3F3C4C',
    position: 0,
  },
  APPROVED: {
    id: 'approved',
    title: 'Approved',
    color: '#00A19C',
    background: '#dcf3f2',
    position: 1,
  },
  ADJUSTED_AND_APPROVED: {
    id: 'adjustedAndApproved',
    title: 'Adjusted and Approved',
    color: '#308196',
    background: '#D9F2F7',
    position: 2,
  },
  ONBOARDED: {
    id: 'onboarded',
    title: 'Onboarded',
    color: '#00A19C',
    background: '#dcf3f2',
    position: 3,
  },
  PENDING: {
    id: 'pending',
    title: 'Pending',
    color: '#FCAA1B',
    background: '#fdf5e6',
    isShowMonth: false,
    position: 4,
  },
  REJECTED: {
    id: 'rejected',
    title: 'Rejected',
    color: '#E82121',
    background: '#f9e9e9',
    position: 5,
  },
};

export const CHANGE_STATUS_DEFINE = {
  APPROVED_ALL: {
    id: 'Approved',
    title: 'Approve All',
  },
  REJECTED_ALL: {
    id: 'Rejected',
    title: 'Reject All',
  },
  ONBOARD_ALL: {
    id: 'Onboarded',
    title: 'Onboard All',
  },
};

export default {
  LIST_STATUS_ENTITLEMENT,
  LIST_STATUS_NEW_REQUEST,
  REQUEST_STATUS_DEFINE,
  CATEGORY_TYPE,
  CATEGORY_TAB_LIST,
  DATASET_MANAGEMENT_TAB_LIST,
  STATUS_BLOCK_DEFINE,
  CHANGE_STATUS_DEFINE,
  generateToRequestStatusType,
};
