import { call, put, delay, takeLatest } from 'redux-saga/effects';
import {
  DATASET_FOOTPRINT_DATA_TABLE,
  DATASET_FOOTPRINT_HIERARCHY_TREE,
  DATASET_TABLE_FOOTPRINT_HIERARCHY_TREE,
  TAG_COUNTER_FOOTPRINT,
} from 'constants/paths';

import { postData } from '../services/api';

import {
  getDatasetFootprintRequest,
  getDatasetFootprintSuccess,
  getDatasetFootprintFailure,
  getHierarchyTreeRequest,
  getHierarchyTreeSuccess,
  getHierarchyTreeFailure,
  getDatasetTableFootprintSuccess,
  getDatasetTableFootprintFailure,
  getDatasetTableFootprintRequest,
  getTagCounterFootprintSuccess,
  getTagCounterFootprintFailure,
  getTagCounterFootprintRequest,
} from '../slices/datasetFootprint';

export function* getDatasetFootprint({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: DATASET_FOOTPRINT_DATA_TABLE,
      data: payload,
    });
    yield delay(800);
    yield put(getDatasetFootprintSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getDatasetFootprintFailure(message));
  }
}

export function* getHierarchyTree({ payload }) {
  try {
    const {
      data: { data, isSuccess },
    } = yield call(postData, {
      url: DATASET_FOOTPRINT_HIERARCHY_TREE,
      data: payload,
    });
    
    if (isSuccess && data) {
      const updatedData = data.map(item => ({
        ...item,
        path: payload.ancestors,
        parent: payload.parent
          ? {
              ...payload.parent,
              children:
                payload.parent?.treeDepth === 0
                  ? []
                  : 
                      data.map(x => ({
                        ...x,
                        value: x?.label.concat(`_${payload.parent?.value}`),
                      })),
            }
          : [],
        value:
          payload.ancestors.length > 0
            ? item.label.concat(`_${payload.parent?.value}`)
            : item.label,
        treeDepth: payload.parent?.treeDepth + 1 || 0,
      }));
      
      yield put(
        getHierarchyTreeSuccess({
          data: { items: updatedData, parent: payload?.parent },
          params: payload,
        }),
      );
      payload?.callback(true, updatedData);
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getHierarchyTreeFailure(message));
  }
}

export function* getDatasetTableFootprint({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: DATASET_TABLE_FOOTPRINT_HIERARCHY_TREE,
      data: payload,
    });
    yield delay(800);
    yield put(getDatasetTableFootprintSuccess({ data, params: payload }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getDatasetTableFootprintFailure(message));
  }
}

export function* getTagCounterFootprint({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: TAG_COUNTER_FOOTPRINT,
      data: payload,
    });
    yield delay(800);
    yield put(getTagCounterFootprintSuccess({ data, params: payload }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getTagCounterFootprintFailure(message));
  }
}

export default function* datasetFootprintSaga() {
  yield takeLatest(getDatasetFootprintRequest().type, getDatasetFootprint);
  yield takeLatest(getHierarchyTreeRequest().type, getHierarchyTree);
  yield takeLatest(
    getDatasetTableFootprintRequest().type,
    getDatasetTableFootprint,
  );
  yield takeLatest(
    getTagCounterFootprintRequest().type,
    getTagCounterFootprint,
  );
}
