import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { matchPath, useLocation, Link, useHistory } from 'react-router-dom';

import settings from 'settings';
import { getStorage } from 'services/storage';
import { formatDate } from 'services/date';
import useQuery from 'hooks/useQuery';

import { USER_HUB_PATH } from 'constants/paths';
import { ID_TOKEN } from 'constants/storageKeys';
import { DQ_EXPORT_DATA_MESSAGE, TYPES } from 'constants/exportType';
import { BAD_DATA, DATA_QUALITY } from 'constants/dataQuality';
import BREAKCRUMB_DATA, {
  HOME,
  LANDING_PAGE_SEARCH,
  DQ_DATA_QUALITY,
  DATA_MARKETPLACE,
  DATA_MARKETPLACE_SEARCH,
  DATA_MARKETPLACE_INTERNAL_SOURCE,
  DATA_MARKETPLACE_EXTERNAL_SOURCE,
  VIEW_DOMAIN_EXTERNAL,
  ANALYTICS_MARKETPLACE,
  MY_WORKSPACE,
  INTERNAL_DATASETS_PAGE,
  EXTERNAL_DATASETS_PAGE,
  DQ_DOMAIN_DETAIL,
  MANAGEMENT_DASHBOARD,
  NEWSFEED,
  EBG,
  GET_STARTED,
  ANALYTICS_MARKETPLACE_DETAIL,
} from 'constants/breakcrumb';
import {
  AM_DOMAIN_LIST,
  AM_THEME_OVERVIEW_ROUTE,
} from 'constants/analyticsmarketplace';
import { FILTER_VALUE } from 'pages/AMDetailedInformationPage/constants';

import routes from 'routes';

import { SHORT_DATE_FORMAT_3 } from 'constants/dateTime';
import { getMainPath } from 'services/string';

import useSignalR from 'hooks/useSignalR';
import useExportHistory from 'hooks/useExportHistory';
import useToasts from 'hooks/useToasts';
import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';
import useDevice from 'hooks/useDevice';
import useDataset from 'hooks/useDataset';
import useAnalyticsNotice from 'hooks/useAnalyticsNotice';
import useAlerts from 'hooks/useAlerts';

import { PROPS } from 'slices/dataset';

import EDHLogoDark from 'assets/icons/logo/edh-logo-dark.svg';
import EDHLogoNew from 'assets/icons/logo/edh-logo-new.svg';
import TimeReverse from 'assets/icons/common/time-reverse.svg';
import TimeReverseDark from 'assets/icons/common/time-reverse-dark.svg';
import Bell from 'assets/icons/common/bell.svg';
import BellDark from 'assets/icons/common/bell-dark.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/common/menu.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/common/warning.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/common/arrow.svg';

import Button from 'components/core/Button';
import UserGuide from 'components/layout/UserGuide';
import Breadcrumb from 'components/layout/Breadcrumb';
import ExportHistory from 'components/layout/ExportHistory';
import NotificationModal from 'components/layout/NotificationModal';
import LoggedUser from '../LoggedUser';

import './styles.scss';

const TopNavigate = ({ userInfo, onShowSidebar }) => {
  const [breakCrumbItems, setBreakCrumbItems] = useState([
    BREAKCRUMB_DATA[HOME.id],
  ]);

  const history = useHistory();
  const location = useLocation();

  const { onGetExportHistoryPopup } = useExportHistory();
  const { onGetDatasets } = useDataset(PROPS.DATASETS);

  const { onAddToastsMessage, onRemoveToastsMessage } = useToasts();
  const { updateRunCognitiveModelResult } = useAnalyticsMarketplace();
  const { onAddToQuery } = useQuery();
  const { addNoticeRunModel } = useAnalyticsNotice();
  const { onRemoveAlertMessage, onAddAlertMessage } = useAlerts();

  const { isMobileDevice } = useDevice();

  const isDarkIcon = ![
    DATA_MARKETPLACE.id,
    DATA_MARKETPLACE_INTERNAL_SOURCE.id,
    DATA_MARKETPLACE_EXTERNAL_SOURCE.id,
    VIEW_DOMAIN_EXTERNAL.id,
    DATA_MARKETPLACE_SEARCH.id,
    ...AM_DOMAIN_LIST,
  ].includes(location.pathname);

  const isHideLogo = ![HOME.id, LANDING_PAGE_SEARCH.id].includes(
    location.pathname,
  );

  const isHideBreadcrumb =
    ![
      HOME.id,
      LANDING_PAGE_SEARCH.id,
      DATA_MARKETPLACE.id,
      DATA_MARKETPLACE_INTERNAL_SOURCE.id,
      DATA_MARKETPLACE_EXTERNAL_SOURCE.id,
      VIEW_DOMAIN_EXTERNAL.id,
      ANALYTICS_MARKETPLACE.id,
      DATA_MARKETPLACE_SEARCH.id,
      DQ_DATA_QUALITY.id,
    ].includes(location.pathname) &&
    !location.pathname.includes(DQ_DOMAIN_DETAIL.url);

  useEffect(() => {
    if (location.pathname) {
      setBreakCrumbItems(BREAKCRUMB_DATA[location.pathname]);
    }
  }, [location.pathname]);

  const getContentToast = ({
    isSuccess,
    title,
    type,
    columnQuantity,
    rowQuantity,
    fromDate,
    toDate,
  }) => {
    let variant;
    let titleToast;
    let description;
    let buttonText;
    if (isSuccess) {
      variant = 'success';
      titleToast = 'Preparation for Download Successful';
      buttonText = 'Go to My Workspace';

      const sampleText = (
        <p>
          Your dataset <b>“{title}”</b> is now ready for download.
        </p>
      );
      const allDataText = (
        <p>
          Your dataset <b>“{title}”</b> is now ready for download:
        </p>
      );
      const columnQuantityText = (
        <p style={{ marginTop: 12 }}>{columnQuantity} Columns selected</p>
      );
      const rowQuantityText = `No of available records is ${rowQuantity} rows`;
      const customRecordText = ` based on selected date range of ${formatDate(
        fromDate,
        `${SHORT_DATE_FORMAT_3}`,
      )} - ${formatDate(toDate, `${SHORT_DATE_FORMAT_3}`)}`;

      switch (type) {
        // sample data
        case TYPES[0].value:
          description = sampleText;
          break;

        // all data
        case TYPES[1].value:
          // custom column & custom record
          if (columnQuantity && fromDate && toDate) {
            description = (
              <>
                {allDataText}
                {columnQuantityText}
                <p>
                  {rowQuantityText}
                  {customRecordText}.
                </p>
              </>
            );
          } else if (columnQuantity) {
            // custom column
            description = (
              <>
                {allDataText}
                {columnQuantityText}
                <p>{rowQuantityText}.</p>
              </>
            );
          } else if (fromDate && toDate) {
            // custom record
            description = (
              <>
                {allDataText}
                <p style={{ marginTop: 12 }}>
                  {rowQuantityText}
                  {customRecordText}.
                </p>
              </>
            );
          } else {
            // all column & all record
            description = sampleText;
          }
          break;

        // data quality
        case DQ_EXPORT_DATA_MESSAGE[0].type:
          titleToast = DQ_EXPORT_DATA_MESSAGE[0].titleToast;
          description = <p>{DQ_EXPORT_DATA_MESSAGE[0].description}</p>;
          buttonText = DQ_EXPORT_DATA_MESSAGE[0].buttonText;
          break;
        case DQ_EXPORT_DATA_MESSAGE[1].type:
          titleToast = DQ_EXPORT_DATA_MESSAGE[1].titleToast;
          description = <p>{DQ_EXPORT_DATA_MESSAGE[1].description}</p>;
          buttonText = DQ_EXPORT_DATA_MESSAGE[1].buttonText;
          break;

        default:
          break;
      }
    } else {
      variant = 'danger';
      titleToast = 'Preparation for Download is Not Successful';
      description = <p>Please try again with different download parameters.</p>;
    }
    return { variant, title: titleToast, description, buttonText };
  };

  useSignalR(
    {
      url: `${settings.ROOT_BASE_URL}${USER_HUB_PATH}`,
      options: {
        accessTokenFactory: () => getStorage(ID_TOKEN),
      },
      event: {
        methodName: 'NotifyExport',
        action(data) {
          const toastId = Date.now();
          onGetExportHistoryPopup({
            params: { pageIndex: 1, pageSize: 5 },
            hasLoading: false,
          });

          onGetDatasets({ hasLoading: false });

          const { variant, title, description, buttonText } =
            getContentToast(data);

          if (isMobileDevice) {
            return;
          }

          onAddToastsMessage({
            id: toastId,
            variant,
            title,
            description,
            buttonText,
            onClick() {
              onRemoveToastsMessage({ id: toastId });
              if (data.isSuccess) {
                if (history.location.pathname === MY_WORKSPACE.id) {
                  return history.replace(
                    `${MY_WORKSPACE.id}?isGoToDatasetTab=true`,
                  );
                }
                if (data.type === BAD_DATA || data.type === DATA_QUALITY) {
                  history.push(MY_WORKSPACE.id);
                  onAddToQuery({ tab: 'dataQuality' });
                } else {
                  history.push(MY_WORKSPACE.id);
                }
              }
            },
          });
        },
      },
    },
    [],
  );

  useSignalR(
    {
      url: `${settings.ROOT_BASE_URL}${USER_HUB_PATH}`,
      options: {
        accessTokenFactory: () => getStorage(ID_TOKEN),
      },
      event: {
        methodName: 'NotifyRunModel',
        action: addNoticeRunModel,
      },
    },
    [],
  );

  useSignalR(
    {
      url: `${settings.ROOT_BASE_URL}${USER_HUB_PATH}`,
      options: {
        accessTokenFactory: () => getStorage(ID_TOKEN),
      },
      event: {
        methodName: 'NotifyRunCognitiveModel',
        action({
          isSuccess,
          files,
          processingTime,
          aiModelName,
          parentModelId,
          aiModelId,
          executedAt,
          deviceType,
        }) {
          const toastId = Date.now();
          const submitInquiryUrl = `${window.location.origin}${
            ANALYTICS_MARKETPLACE_DETAIL.href
          }?id=${
            parentModelId ? `${parentModelId}&subModel=${aiModelId}` : aiModelId
          }&tab=inquiry&title=Cognitive Digitalization&typeOfForm=${
            FILTER_VALUE.REPORT_BUGS
          }`;
          const onSuccess = () =>
            updateRunCognitiveModelResult({
              aiModelId,
              modelName: aiModelName,
              files,
              processingTime,
              executedAt,
              deviceType,
              status: 'success',
            });
          const onFail = () =>
            updateRunCognitiveModelResult({
              parentModelId,
              aiModelId,
              files: [],
              modelName: aiModelName,
              status: 'failed',
            });

          const onWaitConfirmation = () => {
            updateRunCognitiveModelResult({
              aiModelId,
              files: [],
              modelName: aiModelName,
              status: 'waiting',
            });
          };
          if (isSuccess) {
            const { numberOfSuccessful, numberOfFailed } = files.reduce(
              (acc, current) => {
                if (current.isValid) {
                  acc.numberOfSuccessful += 1;
                  return acc;
                }

                acc.numberOfFailed += 1;
                return acc;
              },
              { numberOfSuccessful: 0, numberOfFailed: 0 },
            );

            if (!numberOfFailed) {
              onSuccess();
              return;
            }

            onWaitConfirmation();

            const alertId = Date.now();
            onAddAlertMessage({
              id: alertId,
              variant: 'warning-plus',
              onClose() {
                onFail();
              },
              content: (
                <div
                  className="edh-am-cognitive-upload-alert__alert-content"
                  style={{ width: 'max-content' }}
                >
                  <div className="edh-am-cognitive-upload-alert__alert-content--icon">
                    <WarningIcon size={18} color="#E29E20" />
                  </div>
                  <div className="edh-am-cognitive-upload-alert__content">
                    <p className="heading">Model Result</p>
                    <p>Your Result has been generated</p>
                    <p>
                      <b>
                        Result: {numberOfSuccessful} image
                        {numberOfSuccessful > 1 && 's'} uploaded successfully,{' '}
                        {numberOfFailed} failed.
                      </b>
                    </p>
                    <p>
                      Click &apos;See Result&apos; to view or you can retry to
                      generate other results by selecting other document type.
                    </p>
                    <p>
                      Please click the following link to submit your valuable
                      feedback:
                      <a
                        href={submitInquiryUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Submit Your Inquiry
                      </a>
                    </p>

                    <button
                      type="button"
                      onClick={() => {
                        onSuccess();
                        onRemoveAlertMessage({ id: alertId });
                      }}
                    >
                      See Result <ArrowIcon />
                    </button>
                  </div>
                </div>
              ),
            });
          } else {
            onFail();

            onAddToastsMessage({
              id: toastId,
              variant: 'danger',
              title: `Model result can not be generated`,
              isAutoHide: true,
              duration: 10000,
              description: (
                <>
                  <p>
                    Please try run your model{' '}
                    <strong>&ldquo;{aiModelName}&rdquo;</strong> again.
                  </p>
                  <br />
                  <p>
                    Please click the following link to submit your valuable
                    feedback:{' '}
                    <a href={submitInquiryUrl} target="_blank" rel="noreferrer">
                      Submit Your Inquiry
                    </a>
                  </p>
                </>
              ),
              buttonText: 'Okay',
              onClick() {
                onFail();
                onRemoveToastsMessage({ id: toastId });
              },
            });
          }
        },
      },
    },
    [],
  );

  const MODULE_NAME =
    {
      [AM_THEME_OVERVIEW_ROUTE]: 'am-theme-page',
    }[
      matchPath(location.pathname, {
        path: routes.map(item => item.path),
        exact: true,
      }).path
    ] || '';

  const renderNavLeft = useCallback(() => {
    if (!isMobileDevice) {
      return null;
    }

    const isLogo = [
      MY_WORKSPACE.id,
      DATA_MARKETPLACE.id,
      DATA_MARKETPLACE_SEARCH.id,
      DATA_MARKETPLACE_INTERNAL_SOURCE.id,
      DATA_MARKETPLACE_EXTERNAL_SOURCE.id,
      INTERNAL_DATASETS_PAGE.id,
      EXTERNAL_DATASETS_PAGE.id,
      DQ_DATA_QUALITY.id,
      DQ_DOMAIN_DETAIL.id,
      ...AM_DOMAIN_LIST,
      MANAGEMENT_DASHBOARD.id,
      NEWSFEED.id,
      EBG.id,
      GET_STARTED.id,
    ].some(pathNameItem => location.pathname.includes(pathNameItem));

    const sidebarButtonClassName = (() => {
      switch (location.pathname) {
        case INTERNAL_DATASETS_PAGE.id:
        case EXTERNAL_DATASETS_PAGE.id:
          return `edh-sidebar-button__${getMainPath(location.pathname).slice(
            1,
          )}--datasets`;
        default:
          return `edh-sidebar-button__${getMainPath(location.pathname).slice(
            1,
          )}`;
      }
    })();

    return (
      <>
        <Button
          onClick={onShowSidebar}
          className={['edh-sidebar-button', sidebarButtonClassName, MODULE_NAME]
            .filter(Boolean)
            .join(' ')}
        >
          <MenuIcon />
        </Button>
        {isLogo && (
          <Button onClick={() => history.push('/')}>
            <img
              className="edh-top-navs_logo"
              src={isDarkIcon ? EDHLogoDark : EDHLogoNew}
              alt="logo"
            />
          </Button>
        )}
      </>
    );
  }, [location.pathname, isMobileDevice, isDarkIcon, onShowSidebar]);

  return (
    <header
      className={[
        'edh-top-navs',
        `edh-top-navs--${getMainPath(location.pathname)?.slice(1) || 'edh'}`,
        `edh-top-navs--${MODULE_NAME}`,
      ].join(' ')}
    >
      <div className="edh-top-navs__container">
        <div className="edh-top-navs__left">
          {isHideLogo && !isMobileDevice && (
            <Link to="/">
              <img
                className="edh-top-navs_logo"
                src={isDarkIcon ? EDHLogoDark : EDHLogoNew}
                alt="logo"
              />
            </Link>
          )}
          {renderNavLeft()}
        </div>
        <div className="edh-top-navs__right">
          {!isMobileDevice && (
            <>
              <UserGuide isDarkIcon={isDarkIcon} />
              <ExportHistory
                icon={isDarkIcon ? TimeReverseDark : TimeReverse}
              />
            </>
          )}
          <NotificationModal icon={isDarkIcon ? BellDark : Bell} />
          <LoggedUser userInfo={userInfo} />
        </div>
      </div>
      {isHideBreadcrumb && <Breadcrumb items={breakCrumbItems} />}
    </header>
  );
};

TopNavigate.propTypes = {
  userInfo: PropTypes.object,
  onShowSidebar: PropTypes.func,
};

TopNavigate.defaultProps = {
  userInfo: {},
  onShowSidebar() {},
};

export default memo(TopNavigate);
