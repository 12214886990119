/* eslint-disable react/no-danger */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { graphConfig } from '../../../msalConfig';

import useNotifications from '../../../hooks/useNotifications';

import { SHORT_DATE_FORMAT_3, TIME_FORMAT } from '../../../constants/dateTime';

import { formatDate } from '../../../services/date';

import Avatar from '../../core/Avatar';

import './styles.scss';

const NotificationItem = ({ data, action, onClickItem }) => {
  const { id, fullName, type, content, isRead, time, email, url } = data;

  const history = useHistory();

  const { onMarkRead } = useNotifications();

  const userPhotoUrl = useMemo(
    () => (email ? graphConfig.getGraphUserPhotoEndpoint(email) : ''),
    [email],
  );

  const handleClickItem = () => {
    const [pathname = '', search = ''] = url.split('?');
    if (url)
      history.push({ pathname, search, state: { id, clickAt: Date.now() } });
    if (onClickItem) onClickItem();
    if (!isRead) onMarkRead(id);
  };

  return (
    <div
      className={['edh-notification-item', isRead ? '' : 'unread'].join(' ')}
    >
      <div
        className="edh-notification-item__left"
        onClick={handleClickItem}
        role="button"
        tabIndex={-1}
      >
        {!isRead && <span className="edh-notification-item__dot" />}
        <Avatar name={fullName} size="sm" src={userPhotoUrl} email={email} />
        <div className="edh-notification-item__content">
          <div className="edh-notification-item__title">{type}</div>
          <div className="edh-notification-item__action">
            <span
              className="edh-notification-item__action--content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          <div className="edh-notification-item__time">
            {formatDate(time, `${SHORT_DATE_FORMAT_3} ${TIME_FORMAT}`)}
          </div>
        </div>
      </div>
      {action}
    </div>
  );
};

NotificationItem.propTypes = {
  data: PropTypes.object,
  action: PropTypes.element,
  onClickItem: PropTypes.func,
};

export default memo(NotificationItem);
