import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'apisObserverSlice';
const initialState = {
  observer: {},
};

const apisObserverSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    onAddAPIsToObserver(state, action) {
      const { observerKey, data } = action.payload;

      state.observer[observerKey] = [
        ...(state.observer[observerKey] || []),
        data,
      ];
    },

    onRemoveAPIsOutObserver(state, action) {
      const { observerKey, id } = action.payload;
      const objWithIdIndex = state.observer[observerKey]?.findIndex(
        obj => obj.id === id,
      );

      if (objWithIdIndex > -1) {
        state.observer[observerKey].splice(objWithIdIndex, 1);
      }
    },
  },
});

const { actions, reducer } = apisObserverSlice;

export default reducer;

export const { onAddAPIsToObserver, onRemoveAPIsOutObserver } = actions;

export const rootSelector = state => state[ROOT_STATE_NAME] || {};

export const apisObserverSelector = createSelector(
  rootSelector,
  ({ observer }) => observer,
);
