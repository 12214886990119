import React, { memo, useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';

import AMDataExtract from 'components/layout/AMDataExtract';
import LoadingBalls from 'components/core/LoadingBalls';
import Pagination from 'components/layout/Pagination';
import MessageNoData from 'components/core/MessageNoData';

import EmptyStateImg from 'assets/images/common/empty-state.svg';

import useQuery from 'hooks/useQuery';
import useDataset from 'hooks/useDataset';
import useDevice from 'hooks/useDevice';
import { useDidUpdate } from 'hooks/useDidUpdate';
import { useDidMount } from 'hooks/useDidMount';

import { PROPS } from 'slices/dataset';

import { DATASET_SEARCH_TYPE } from 'constants/datasetTypes';
import { PAGE_SIZE_OPTIONS } from '../../constants';

import './styles.scss';

const DEFAULT_PAGINATION = {
  pageSize: 12,
  pageIndex: 1,
};

const idList = 'am-data-availability-list';

const AMDataAvailability = ({ detail }) => {
  const { datasets, onGetDatasets } = useDataset(PROPS.DATASETS);
  const { status } = datasets;

  const {
    items,
    totalCount,
    totalPages,
    pageSize,
    currentPage,
    hasNextPage,
    hasPreviousPage,
  } = datasets.data;

  const { query, onAddToQuery } = useQuery();

  const { isMobileDevice } = useDevice();

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  useDidMount(() => {
    onGetDatasets({
      params: {
        SearchDataSetTypes: DATASET_SEARCH_TYPE.AnalyticMarketplace,
        AnalyticMarketplaceId: detail?.id || query.id,
        pageIndex: +query.page || DEFAULT_PAGINATION.pageIndex,
        pageSize: +query.size || DEFAULT_PAGINATION.pageSize,
      },
    });
  });

  useDidUpdate(() => {
    if (pagination) {
      onGetDatasets({
        params: {
          SearchDataSetTypes: DATASET_SEARCH_TYPE.AnalyticMarketplace,
          AnalyticMarketplaceId: detail?.id || query.id,
          ...pagination,
        },
      });
      onAddToQuery({ page: pagination.pageIndex, size: pagination.pageSize });
    }
  }, [pagination]);

  const onSetPageSize = useCallback(newPageSize => {
    setPagination(previousPagination => ({
      ...previousPagination,
      pageIndex: 1,
      pageSize: newPageSize,
    }));
  }, []);

  const onNextPage = useCallback(() => {
    setPagination(previousPagination => ({
      ...previousPagination,
      pageIndex: currentPage + 1,
    }));
  }, [currentPage]);

  const onPreviousPage = useCallback(() => {
    setPagination(previousPagination => ({
      ...previousPagination,
      pageIndex: currentPage - 1,
    }));
  }, [currentPage]);

  const onGotoPage = useCallback(newPageIndex => {
    setPagination(previousPagination => ({
      ...previousPagination,
      pageIndex: newPageIndex + 1,
    }));
  }, []);

  return (
    <div className="am-tab-availability">
      {status === 'loading' && <LoadingBalls isLoading />}
      <div className="am-tab-availability__model">
        <p className="am-tab-availability__model__title">
          Data Extracted Using this Model
        </p>
        <div className="am-tab-availability__model__content">
          <div
            className={`am-tab-availability__model__list ${
              items?.length === 0 && status !== 'loading' ? 'center' : ''
            }`}
            id={idList}
          >
            {items?.length > 0
              ? items?.map(data => <AMDataExtract {...data} key={data.id} />)
              : status !== 'loading' && (
                  <MessageNoData
                    img={EmptyStateImg}
                    title="No Data Availability"
                    description=""
                  />
                )}
          </div>
          {totalCount > 12 && (
            <div>
              <Pagination
                page={items}
                totalCount={totalCount}
                totalPages={totalPages}
                pageSize={pageSize}
                pageIndex={currentPage - 1}
                canNextPage={hasNextPage}
                canPreviousPage={hasPreviousPage}
                onSetPageSize={onSetPageSize}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                onGotoPage={onGotoPage}
                isShowPageSize={!isMobileDevice}
                isShowTotalCount={!isMobileDevice}
                isShowGotoPage={!isMobileDevice}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AMDataAvailability.propTypes = {
  detail: PropTypes.any,
};

export default memo(AMDataAvailability);
