export const PAGE_SIZE_OPTIONS = [
  {
    title: 'Select page',
    accessor: 10,
    sort: '10 per page',
  },
  {
    title: '10 per page',
    accessor: 10,
    sort: '10 per page',
  },
  {
    title: '30 per page',
    accessor: 30,
    sort: '30 per page',
  },
  {
    title: '50 per page',
    accessor: 50,
    sort: '50 per page',
  },
];

export const PAGE_SIZE_OPTIONS_SEARCH = [
  {
    title: 'Select page',
    accessor: 20,
    sort: '20 per page',
  },
  {
    title: '20 per page',
    accessor: 20,
    sort: '20 per page',
  },
  {
    title: '40 per page',
    accessor: 40,
    sort: '40 per page',
  },
  {
    title: '80 per page',
    accessor: 80,
    sort: '80 per page',
  },
];

export const PAGE_SIZE_OPTIONS_NEWSFEED = [
  {
    title: 'Select page',
    accessor: 12,
    sort: '12 per page',
  },
  {
    title: '12 per page',
    accessor: 12,
    sort: '12 per page',
  },
  {
    title: '24 per page',
    accessor: 24,
    sort: '24 per page',
  },
  {
    title: '36 per page',
    accessor: 36,
    sort: '36 per page',
  },
];

export const PAGE_SIZE_OPTIONS_REQUEST_DETAIL = [
  {
    title: 'Select page',
    accessor: 5,
    sort: '5 per page',
  },
  {
    title: '5 per page',
    accessor: 5,
    sort: '5 per page',
  },
  {
    title: '10 per page',
    accessor: 10,
    sort: '10 per page',
  },
  {
    title: '15 per page',
    accessor: 15,
    sort: '15 per page',
  },
];

export const PAGE_SIZE_OPTIONS_DQ_DOMAIN_TABLE = [
  {
    title: 'Select page',
    accessor: 1,
    sort: '1 per page',
  },
  {
    title: '1 per page',
    accessor: 1,
    sort: '1 per page',
  },
  {
    title: '2 per page',
    accessor: 2,
    sort: '2 per page',
  },
  {
    title: '3 per page',
    accessor: 3,
    sort: '3 per page',
  },
  {
    title: '4 per page',
    accessor: 4,
    sort: '4 per page',
  },
];

export const PAGE_SIZE_OPTIONS_USER_REVIEW = [
  {
    title: 'Select page',
    accessor: 10,
    sort: '10 per page',
  },
  {
    title: '10 per page',
    accessor: 10,
    sort: '10 per page',
  },
  {
    title: '30 per page',
    accessor: 30,
    sort: '30 per page',
  },
  {
    title: '50 per page',
    accessor: 50,
    sort: '50 per page',
  },
];

export const PAGE_SIZE_OPTIONS_DQ_VIEW_MODIFY = [
  {
    title: 'Select page',
    accessor: 10,
    sort: '10 per page',
  },
  {
    title: '5 per page',
    accessor: 5,
    sort: '5 per page',
  },
  {
    title: '10 per page',
    accessor: 10,
    sort: '10 per page',
  },
  {
    title: '15 per page',
    accessor: 15,
    sort: '15 per page',
  },
];

export const PAGE_SIZE_OPTIONS_IS = [
  {
    title: 'Select page',
    accessor: 10,
    sort: '10 per page',
  },
  {
    title: '3 per page',
    accessor: 3,
    sort: '3 per page',
  },
];
