import { call, put, delay, takeLatest } from 'redux-saga/effects';
import {
  GET_DATA_SAMPLE,
  GET_STARTED_SUGGESTION,
  GET_STARTED_ACQUIRING_STEPS,
  GET_STARTED_SUGGESTION_KEYWORD,
} from 'constants/paths';

import { getData, postData } from '../services/api';

import {
  getDataSampleRequest,
  getDataSampleSuccess,
  getDataSampleFailure,
  getDataSampleSuggestionRequest,
  getDataSampleSuggestionSuccess,
  getDataSampleSuggestionFailure,
  getDataSampleAcquiringStepsRequest,
  getDataSampleAcquiringStepsSuccess,
  getDataSampleAcquiringStepsFailure,
  postDataSampleSuggestionRequest,
  postDataSampleSuggestionSuccess,
  postDataSampleSuggestionFailure,
} from '../slices/dataSample';

export function* getDataSampleList({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: GET_DATA_SAMPLE,
      data: payload,
    });
    yield delay(800);
    yield put(getDataSampleSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getDataSampleFailure(message));
  }
}

export function* getDataSampleSuggestion({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_STARTED_SUGGESTION,
      params: payload,
    });

    yield delay(800);
    yield put(getDataSampleSuggestionSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getDataSampleSuggestionFailure(message));
  }
}

export function* getDataSampleAcquiringSteps({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_STARTED_ACQUIRING_STEPS,
      params: payload,
    });

    yield delay(800);
    yield put(getDataSampleAcquiringStepsSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getDataSampleAcquiringStepsFailure(message));
  }
}

export function* postDataSampleSuggestion({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_STARTED_SUGGESTION_KEYWORD,
      params: payload,
    });

    yield delay(800);
    yield put(postDataSampleSuggestionSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(postDataSampleSuggestionFailure(message));
  }
}

export default function* dataSampleSaga() {
  yield takeLatest(getDataSampleRequest().type, getDataSampleList);
  yield takeLatest(
    getDataSampleSuggestionRequest().type,
    getDataSampleSuggestion,
  );
  yield takeLatest(
    getDataSampleAcquiringStepsRequest().type,
    getDataSampleAcquiringSteps,
  );
  yield takeLatest(
    postDataSampleSuggestionRequest().type,
    postDataSampleSuggestion,
  );
}
