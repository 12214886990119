import React, {
  memo,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { utf8ToBase64 } from 'services/string';

import useClickOutside from 'hooks/useClickOutside';

import LoadingBalls from 'components/core/LoadingBalls';
import SearchInput from 'components/core/SearchInput';
import KeywordsSuggestion from 'components/core/KeywordsSuggestion';

import './styles.scss';

const SearchBarWithSuggestion = ({
  isLoading,
  isSubmitForm,
  isCurrentSearch,
  placeholder,
  keySubmit,
  currentSearch,
  suggestionData,
  dropdownHeaderTitle,
  onCustomItemDropdown,
  onSearch,
  onSubmit,
  onClickKeyWordSuggestion,
  onClearKeyword,
}) => {
  const [keywords, setKeywords] = useState('');
  const [focused, setFocused] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const refSearchBar = useRef(null);

  useEffect(() => {
    if (!_.isEmpty(currentSearch)) {
      setKeywords(currentSearch[keySubmit || 'keyword']);
      setFocused(false);
      document.activeElement.blur();
    }
  }, [currentSearch, keySubmit]);

  const setHideDropdown = () => {
    setShowDropdown(false);
    setFocused(false);
  };

  useClickOutside(refSearchBar, setHideDropdown);

  useEffect(() => {
    setShowDropdown(focused && !!keywords?.length);
  }, [focused, keywords?.length]);

  const onSetFocused = useCallback(() => {
    setFocused(true);
    if (keywords?.length) {
      onSearch(utf8ToBase64(keywords));
    }
  }, [keywords?.length, onSearch]);

  const submit = useCallback(
    newKeywords => {
      onSubmit(newKeywords);
      setFocused(false);
      document.activeElement.blur();
    },
    [onSubmit],
  );

  const searchKeywordsDebounced = useCallback(
    _.debounce(newKeywords => {
      onSearch(utf8ToBase64(newKeywords));
    }, 700),
    [onSearch],
  );

  const onSetKeywords = useCallback(
    newKeywords => {
      setKeywords(newKeywords);
      searchKeywordsDebounced(newKeywords);
    },
    [keywords, onSearch],
  );

  const onClickOptionSuggestion = (option, e) => {
    e.preventDefault();
    onClickKeyWordSuggestion(option);
    if (!isCurrentSearch) {
      if (onCustomItemDropdown || keySubmit) {
        setKeywords(option[keySubmit]);
      } else {
        setKeywords(option.keyword);
      }
    }
    setFocused(false);
  };

  const onClearSearchKeyword = emptyKeyword => {
    onClearKeyword(emptyKeyword);
  };

  const contentDropdownElement = useMemo(
    () => (
      <div className="edh-search-bar-with-suggestion__dropdown--container">
        <div className="edh-search-bar-with-suggestion__dropdown--header-title">
          {dropdownHeaderTitle}
        </div>
        {suggestionData?.length > 0 ? (
          <div className="edh-search-bar-with-suggestion__dropdown--menu-list">
            {suggestionData?.map(item => (
              <div
                role="button"
                tabIndex="0"
                onClick={e => onClickOptionSuggestion(item, e)}
                className="edh-search-bar-with-suggestion__dropdown--item"
              >
                {onCustomItemDropdown ? (
                  onCustomItemDropdown(item, keywords)
                ) : (
                  <KeywordsSuggestion
                    textToHighlight={item.keyword}
                    searchWords={keywords}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          !isLoading && (
            <div className="edh-search-bar-with-suggestion__dropdown--no-options">
              No options
            </div>
          )
        )}
      </div>
    ),
    [keywords, isLoading, suggestionData],
  );

  return (
    <div ref={refSearchBar} className="edh-search-bar-with-suggestion">
      <div className="edh-search-bar-with-suggestion__search">
        <SearchInput
          hasRemoveAllKeywords
          isLoading={isLoading}
          isSubmitForm={isSubmitForm}
          placeholder={placeholder}
          value={keywords}
          onSetValue={onSetKeywords}
          onFocus={onSetFocused}
          onSubmit={submit}
          onClearKeyword={onClearSearchKeyword}
        />
      </div>
      <div
        className={`edh-search-bar-with-suggestion__dropdown  ${
          showDropdown && 'edh-search-bar-with-suggestion__dropdown--show'
        }`}
      >
        <LoadingBalls backdrop isLoading={isLoading} />
        {contentDropdownElement}
      </div>
    </div>
  );
};

SearchBarWithSuggestion.propTypes = {
  isLoading: PropTypes.bool,
  isSubmitForm: PropTypes.bool,
  isCurrentSearch: PropTypes.bool,
  dropdownHeaderTitle: PropTypes.string,
  placeholder: PropTypes.string,
  keySubmit: PropTypes.string,
  currentSearch: PropTypes.object,
  suggestionData: PropTypes.array,
  onCustomItemDropdown: PropTypes.func,
  onSearch: PropTypes.func,
  onSubmit: PropTypes.func,
  onClickKeyWordSuggestion: PropTypes.func,
  onClearKeyword: PropTypes.func,
};

SearchBarWithSuggestion.defaultProps = {
  isLoading: false,
  isSubmitForm: true,
  isCurrentSearch: false,
  placeholder: 'Search...',
  keySubmit: '',
  dropdownHeaderTitle: 'SUGGESTED KEYWORDS',
  suggestionData: [],
  onSearch() {},
  onSubmit() {},
  onClickKeyWordSuggestion() {},
  onClearKeyword() {},
};

export default memo(SearchBarWithSuggestion);
