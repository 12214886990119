import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { BsCheck, BsRecordFill } from 'react-icons/bs';
import { uid } from 'services/uid';
import Tooltip from '../../layout/Tooltip';

import './styles.scss';

const Checkbox = ({
  disabled,
  isReverse,
  checked,
  checkedNumber,
  type,
  size,
  label,
  onChange,
  isComingFeature,
  isShowTooltip,
  placement,
  isNoneTick,
}) => {
  const styleClasses = ['edh-check'];

  const id = uid();

  if (disabled) {
    if (checked) styleClasses.push('edh-check--disabled');
    else {
      styleClasses.push('edh-checked--disabled edh-check--disabled');
    }
  }

  if (isReverse) {
    styleClasses.push('edh-check--is-reverse');
  }

  if (type === 'radio') {
    styleClasses.push('edh-check--radio');
  } else if (type === 'switch') {
    styleClasses.push('edh-check--switch');
  } else {
    styleClasses.push('edh-check--checkbox');
  }

  if (size === 'sm') {
    styleClasses.push('edh-check--sm');
  } else if (size === 'md') {
    styleClasses.push('edh-check--md');
  } else if (size === 'lg') {
    styleClasses.push('edh-check--lg');
  }

  if (checked) {
    styleClasses.push('edh-check--is-checked');
  }

  return (
    <div
      className={styleClasses.join(' ')}
      role="button"
      tabIndex={-1}
      onClick={e => {
        if (!disabled) {
          onChange(!checked, e);
        }
      }}
      onKeyDown={e => {
        if (e.keyCode === 32 && !disabled) {
          onChange(!checked, e);
        }
      }}
    >
      <div
        className={`edh-check__input ${isNoneTick && 'none-tick'} ${
          checked && 'edh-check__input--is-checked'
        }`}
        tabIndex={disabled ? -1 : 0}
      >
        {type === 'checkbox' &&
          (checkedNumber ? (
            <span
              style={{
                color: '#FFFFFF',
                fontSize: 8,
                fontWeight: 'bold',
                lineHeight: '12px',
              }}
            >
              {checkedNumber}
            </span>
          ) : (
            <BsCheck color="#FFFFFF" />
          ))}
        {type === 'radio' && (
          <BsRecordFill color={checked ? '#00a19c' : '#FFFFFF'} size={12} />
        )}
        {type === 'switch' && <BsRecordFill color="#FFFFFF" size={18} />}
      </div>
      {label &&
        (isComingFeature || isShowTooltip ? (
          <Tooltip
            content={isComingFeature ? 'New Feature Coming Soon!' : label}
            id={`label-${id}`}
            placement={placement}
          >
            <div className="edh-check__label">
              {isShowTooltip ? <p id={`label-${id}`}>{label}</p> : label}
            </div>
          </Tooltip>
        ) : (
          <div className="edh-check__label">{label}</div>
        ))}
    </div>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  isReverse: PropTypes.bool,
  isNoneTick: PropTypes.bool,
  checked: PropTypes.bool,
  checkedNumber: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  type: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  isComingFeature: PropTypes.bool,
  isShowTooltip: PropTypes.bool,
  placement: PropTypes.string,
};

Checkbox.defaultProps = {
  disabled: false,
  isReverse: false,
  isNoneTick: false,
  checked: false,
  checkedNumber: false,
  type: 'checkbox',
  size: 'default',
  label: '',
  onChange() {},
  isComingFeature: false,
  isShowTooltip: false,
  placement: 'bottom',
};

export default memo(Checkbox);
