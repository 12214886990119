import { createSelector, createSlice } from '@reduxjs/toolkit';

export const ROOT_STATE_NAME = 'signalr';

const initialState = {
  connections: {},
};

const signalrSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    addConnection(state, action) {
      state.connections[action.payload.baseUrl] = action.payload;
    },
    removeConnection(state, action) {
      delete state.connections[action.payload.baseUrl];
    },
  },
});

const { actions, reducer } = signalrSlice;

export default reducer;

export const { addConnection, removeConnection } = actions;

export const rootSelector = state => state[ROOT_STATE_NAME] || {};

export const connectionsSelector = createSelector(
  rootSelector,
  ({ connections }) => connections,
);
