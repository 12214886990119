import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  deleteData,
  getData,
  patchData,
  postData,
  putData,
} from '../services/api';

const useAxios = ({
  method = 'GET',
  autoRun = true,
  url,
  initialData,
  params,
  formData,
  deps = [],
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initialData);

  const func = useMemo(() => {
    switch (method.toUpperCase()) {
      case 'GET':
        return getData;
      case 'POST':
        return postData;
      case 'PUT':
        return putData;
      case 'PATCH':
        return patchData;
      case 'DELETE':
        return deleteData;
      default:
        break;
    }
  }, [method]);

  const handleCallApi = useCallback(
    // eslint-disable-next-line no-shadow
    async ({ params, data, callback }) => {
      setIsLoading(true);
      try {
        const {
          data: { data: dataResponse },
        } = await func({ url, params, data });

        setData(dataResponse);
        callback?.();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`[${method.toUpperCase()}] - ${url} failed at: `, error);
        throw Error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [url, func],
  );

  useEffect(() => {
    if (!autoRun) return;

    handleCallApi({
      params,
      data: formData,
    });
  }, deps);

  return { isLoading, data, onCallApi: handleCallApi };
};

export default useAxios;
