import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { uid } from 'services/uid';

import Tooltip from 'components/layout/Tooltip';

import './styles.scss';

const TooltipHover = ({
  component: Component = 'div',
  className,
  content = '',
  numberOfLine = 1,
  onClick = () => {},
  placement = 'top',
  ...rest
}) => {
  const id = uid();

  const props = {
    id,
    className: [className, 'edh-tooltip-hover', `line-${numberOfLine}`].join(
      ' ',
    ),
    onClick,
  };

  return (
    <Tooltip placement={placement} {...rest} id={id} content={content}>
      <Component {...props}>{content}</Component>
    </Tooltip>
  );
};

TooltipHover.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.string,
  placement: PropTypes.string,
  numberOfLine: PropTypes.number,
  onClick: PropTypes.func,
};

export default memo(TooltipHover);
