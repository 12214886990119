import React from 'react';
import PropTypes from 'prop-types';

const SampleOutputArrow = ({ color }) => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7983 9.42139C11.062 9.15771 11.062 8.78857 10.7983 8.5249L3.09912 0.720215C2.83545 0.456543 2.41357 0.456543 2.20264 0.720215L1.14795 1.7749C0.884277 2.03857 0.884277 2.40771 1.14795 2.67139L7.37061 8.99951L1.14795 15.2749C0.884278 15.5386 0.884278 15.9604 1.14795 16.1714L2.20264 17.2261C2.41357 17.4897 2.83545 17.4897 3.09912 17.2261L10.7983 9.42139Z"
      fill={color}
    />
  </svg>
);

SampleOutputArrow.propTypes = {
  color: PropTypes.string,
};

SampleOutputArrow.defaultProps = {
  color: 'white',
};

export default SampleOutputArrow;
