import React from 'react';
import moment from 'moment-timezone';

import './styles.scss';

const Footer = () => (
  <footer className="edh-footer">
    <p>Copyright ©{moment().year()} PETRONAS. All rights reserved</p>
  </footer>
);

export default Footer;
