import React, { memo, useMemo } from 'react';
import { PropTypes } from 'prop-types';

import ReactTableCustom from 'components/layout/ReactTableCustom';
import MessageNoData from 'components/core/MessageNoData';
import Image from 'components/core/Image';
import AMImageZoom from 'components/core/AMImageZoom';

import useDevice from 'hooks/useDevice';
import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';

import EmptyStateImg from 'assets/images/common/empty-state.svg';

import {
  TechnicalTableColumn,
  emailRegex,
  remarkDataDefault,
} from '../constants';

import './styles.scss';

const AMTechnicalDetails = ({ detail }) => {
  const { aiModel } = useAnalyticsMarketplace();
  const { status } = aiModel;
  const { isMobileDevice } = useDevice();

  const remarksText = useMemo(() => {
    const remarks = [];
    const remarksData = detail?.remarks || remarkDataDefault;

    let match = null;
    match = emailRegex.exec(remarksData);
    const index = remarksData.indexOf(match[0]);
    remarks[0] = remarksData.substring(0, index);
    [remarks[1]] = match;
    remarks[2] = remarksData.substring(index + match[0].length);
    return remarks;
  }, [detail]);

  const technicalData = useMemo(() => {
    let technical = '';
    let technicalResponse = [];
    if (detail.technicalDetail !== '') {
      technical = ` ${detail?.technicalDetail}`;
      technical = technical.split(/\d+\.\s*/);
      technicalResponse = technical.map((item, index) => ({
        id: `${index}-${item}`,
        index,
        name: item,
      }));
      technicalResponse.shift();
    }
    return technicalResponse;
  }, [detail]);

  const renderTechnicalBody = () => {
    if (detail?.workflowDiagram?.fileName) {
      if (isMobileDevice) {
        return (
          <AMImageZoom scaleOffset={1} step={0.5} range={[0.5, 6]}>
            <Image
              className="am-technical-details-workflows"
              src={detail.workflowDiagram.fileName}
            />
          </AMImageZoom>
        );
      }
      return (
        <Image
          className="am-technical-details-workflows"
          src={detail.workflowDiagram.fileName}
        />
      );
    }

    return (
      <>
        <div className="am-tab-technical__title">
          <p>Technical details</p>
        </div>
        <div className="am-tab-technical__description">
          <p>This model will extract the following attributes:</p>
        </div>
        <div className="am-tab-technical__table">
          {technicalData.length === 0 && status !== 'loading' ? (
            <MessageNoData
              title="No Technical Details Availability"
              description=""
              img={EmptyStateImg}
              isLoading={status === 'loading'}
            />
          ) : (
            <ReactTableCustom
              id="am-technical-details-table"
              isLoading={status === 'loading'}
              columns={TechnicalTableColumn}
              data={technicalData}
              hasPagination={false}
              messageTitleNoData="No Technical Details Availability"
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div className="am-tab-technical">
      <div className="am-tab-technical__body">
        {isMobileDevice ? (
          <div className="am-tab-technical__body--content">
            {renderTechnicalBody()}
          </div>
        ) : (
          renderTechnicalBody()
        )}
        {remarksText.length > 0 && (
          <div className="am-tab-technical__footer">
            <p>
              {remarksText[0]}
              <b>
                <a href={`mailto: ${remarksText[1]}`}>
                  <u>{remarksText[1]}</u>
                </a>
              </b>
              {remarksText[2]}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

AMTechnicalDetails.propTypes = { detail: PropTypes.any };

export default memo(AMTechnicalDetails);
