import { sidebarData } from 'constants/sidebar';
import useTakeEventGA from 'hooks/useTakeEventGA';
import { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const pageTitles = sidebarData.reduce(
  (acc, curr) => ({
    ...acc,
    [curr.route]: curr.name,
  }),
  {},
);

pageTitles['/'] = 'Home';

const PageAccess = () => {
  const { pathname } = useLocation();
  const { onSendPageAccess } = useTakeEventGA();

  useEffect(() => {
    const title = pageTitles[pathname];

    if (title) {
      onSendPageAccess({
        pageTitle: title,
        pagePath: pathname,
        pageLocation: window.location.href,
      });
    }
  }, [pathname]);

  return Fragment;
};

export default PageAccess;
