import React, { useState, useEffect, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { GoInfo } from 'react-icons/go';

import DownLoadIcon from 'assets/icons/common/download-outline.svg';
import { ANALYTICS_MARKETPLACE_DOWNLOAD_PATH } from 'constants/paths';

import Loading from 'components/core/LoadingBalls';
import AMExportResult from 'components/layout/AMExportResult';
import ReactTableCustom from 'components/layout/ReactTableCustom';

import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';
import useAlerts from 'hooks/useAlerts';
import { aiModelResultDownload } from 'services/download';
import settings from 'settings';

import { typeMap } from 'constants/analyticsmarketplace';
import { getAIModelByNameSelector } from 'slices/analyticsMarketplace';
import { getModelConfig } from '../../constants';

import './styles.scss';

const { BASE_URL } = settings;

const ModelResult = ({ modelName }) => {
  const [validFiles, setValidFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [modalFiles, setModalFiles] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [fileSizes, setFileSizes] = useState([]);
  const { png, jpg, jpeg, csv, json, xlsx, pdf, docx } = typeMap;

  const { updateRunModelResult } = useAnalyticsMarketplace();

  const { onAddAlertMessage } = useAlerts();

  const { directDownload } = getModelConfig(modelName);

  const { files, processingTime, status } = useSelector(state =>
    getAIModelByNameSelector(state, modelName),
  );

  useEffect(() => {
    if (status === 'loading') {
      setSelectedFiles([]);
      setModalFiles([]);
      setFileSizes([]);
      setValidFiles([]);
    }
  }, [status]);

  useEffect(() => {
    if (files && files.length) {
      setValidFiles(files.filter(file => file.isValid));
    }
  }, [files, processingTime]);

  useEffect(() => {
    document.querySelector('.edh-react-table__no-data.td')?.remove();
  }, [files, processingTime, status]);

  useEffect(
    () => () => {
      updateRunModelResult({ modelName, data: {} });
    },
    [],
  );
  const getListFileByType = useCallback((listFile, type, callback) => {
    const list = [];
    for (const file of listFile) {
      for (const item of file.fileList) {
        if (item.fileExtension === type) {
          list.push(item);
          callback?.(item);
        }
      }
    }
    return list;
  }, []);

  const onDownloadSingleFile = useCallback(fileName => {
    aiModelResultDownload({
      apiPath: `${BASE_URL}${ANALYTICS_MARKETPLACE_DOWNLOAD_PATH}`,
      fileName,
    }).then(isDownloaded => {
      if (!isDownloaded) {
        onAddAlertMessage({
          id: Date.now(),
          variant: 'warning',
          isAutoHide: false,
          className: 'edh-alerts-highest',
          content: (
            <p className="edh-export-data-modal__alert-content">
              <GoInfo
                size={18}
                color="#3F3C4C"
                style={{ marginRight: '10px' }}
              />
              <span>Model result is failed to download, please try again</span>
            </p>
          ),
        });
      }
    });
  }, []);

  const onClickDownloadButton = useCallback(listFile => {
    if (directDownload) {
      [jpg, png, jpeg, pdf, docx].forEach(type => {
        getListFileByType(listFile, type, file =>
          onDownloadSingleFile(file?.storageFileName),
        );
      });
    } else {
      const fileSizeMap = {};
      [json, csv, xlsx].forEach(type => {
        getListFileByType(listFile, type, file => {
          fileSizeMap[type] = (fileSizeMap[type] || 0) + file.originalSize;
        });
      });

      setFileSizes(fileSizeMap);
      setModalFiles(listFile);
      setIsShow(true);
    }
  }, []);

  const onDownload = useCallback(
    (fileDownload, fileType) => {
      if (fileDownload.length) {
        getListFileByType(fileDownload, fileType, item =>
          onDownloadSingleFile(item?.storageFileName),
        );
      }
    },
    [onDownloadSingleFile],
  );

  const onCheck = useCallback(({ selectedFlatRows }) => {
    setSelectedFiles(selectedFlatRows.map(item => item.values.id));
  }, []);

  const onCloseModal = useCallback(() => {
    setIsShow(false);
  }, []);

  const idCol = cellProps => <div className="">{cellProps.value.id + 1}</div>;

  const downloadCol = (cellProps, onClick) => {
    const file = cellProps.value;
    return (
      <>
        <p className="row--text">{file?.displayFileName}</p>
        <div className="row-actions">
          <img
            src={DownLoadIcon}
            alt="Download"
            onClick={() => onClick([file])}
            aria-hidden
          />
        </div>
      </>
    );
  };

  const columns = [
    {
      id: 'id',
      Header: 'ID',
      accessor: row => row,
      disableSortBy: true,
      width: 39,
      minWidth: 39,
      flex: 0,
      preventDefaultRender: true,
      Cell: cellProps => idCol(cellProps),
    },
    {
      Header: 'Source Filename',
      accessor: 'sourceFileName',
      disableSortBy: true,
      width: 150,
      flex: 0,
      preventDefaultRender: true,
    },
    {
      Header: 'Processing Time',
      disableSortBy: true,
      preventDefaultRender: true,
      width: 150,
      flex: 0,
      Cell: () => (processingTime ? `${processingTime}s` : null),
    },
    {
      id: 'displayFileName',
      Header: 'Result Filename/Download',
      accessor: row => row,
      disableSortBy: true,
      flex: 1,
      preventDefaultRender: true,
      Cell: cellProps => downloadCol(cellProps, onClickDownloadButton),
    },
  ];

  return (
    <div className="am-preview-model-result">
      <div className="am-preview-model-result__title">
        <p className="am-preview-model-result__title-text">Result</p>
      </div>
      <Loading isLoading={false} />
      <div className="am-preview-model-result__content">
        <ReactTableCustom
          id="am-preview-sample-table-result"
          columns={columns}
          data={validFiles.map((item, index) => ({ ...item, id: index }))}
          hasStickyHeader
          hasSelection
          onSelectedRows={onCheck}
          hasPagination={false}
        />
      </div>
      <div className="am-preview-model-result__footer">
        <p className="am-preview-model-result__footer__message">
          {status === 'failed' || files.some(item => !item.isValid) ? (
            <>
              Uh Oh! The files uploaded do not match the model requirements.
              Please try again with the correct data.
              <br />
              The table only shows the result of successfully uploaded files.
            </>
          ) : null}
        </p>
        <button
          type="button"
          className="am-preview-model-result__footer--primary"
          onClick={() => onClickDownloadButton(selectedFiles)}
          disabled={!selectedFiles.length}
        >
          Download
        </button>
      </div>
      <AMExportResult
        fileList={modalFiles}
        isShowed={isShow}
        fileSizes={Object.entries(fileSizes)}
        onClose={onCloseModal}
        onDownload={onDownload}
      />
    </div>
  );
};

ModelResult.propTypes = {
  modelName: PropTypes.string,
};

export default memo(ModelResult);
