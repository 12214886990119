import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { sanitize } from 'dompurify';

const InnerHTML = ({ data, tag: Component, ...rest }) => (
  <Component {...rest} dangerouslySetInnerHTML={{ __html: sanitize(data) }} />
);

InnerHTML.propTypes = {
  data: PropTypes.string.isRequired,
  tag: PropTypes.string,
};

InnerHTML.defaultProps = {
  tag: 'div',
};

export default memo(InnerHTML);
