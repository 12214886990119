import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'dataset';
export const PROPS = {
  DATASETS: 'datasets',
  DATASETS_POPUP: 'datasets_popup',
};
const initialState = {
  suggestedKeyword: {
    data: [],
    status: 'idle',
    error: null,
  },
  suggestedKeywordModularDatasets: {
    data: [],
    status: 'idle',
    error: null,
  },
  dataset: {
    data: {},
    status: 'idle',
    error: null,
  },
  [PROPS.DATASETS]: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  [PROPS.DATASETS_POPUP]: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  myDatasetsRecently: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  myDatasetsEntitlement: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  sampleHederDataset: {
    data: [],
    status: 'idle',
    error: null,
  },
  myDatasetsEntitlementParam: null,
  datasetMyWorkspace: {
    data: {},
    status: 'loading',
    error: null,
  },
  datasetExportValuesByColumn: {
    data: {},
    status: 'idle',
    error: null,
  },
  datasetExportColumns: {
    data: [],
    status: 'idle',
    error: null,
  },
};

const datasetSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getSuggestedKeywordRequest(state) {
      state.suggestedKeyword.status = 'loading';
    },
    getSuggestedKeywordSuccess(state, action) {
      state.suggestedKeyword.data = action.payload;
      state.suggestedKeyword.status = 'succeeded';
    },
    getSuggestedKeywordFailure(state, action) {
      state.suggestedKeyword.status = 'failed';
      state.suggestedKeyword.data = [];
      state.suggestedKeyword.error = action.payload;
    },
    getSuggestedKeywordModularDatasetsRequest(state) {
      state.suggestedKeywordModularDatasets.status = 'loading';
    },
    getSuggestedKeywordModularDatasetsSuccess(state, action) {
      state.suggestedKeywordModularDatasets.data = action.payload;
      state.suggestedKeywordModularDatasets.status = 'succeeded';
    },
    getSuggestedKeywordModularDatasetsFailure(state, action) {
      state.suggestedKeywordModularDatasets.status = 'failed';
      state.suggestedKeywordModularDatasets.data = [];
      state.suggestedKeywordModularDatasets.error = action.payload;
    },

    getDatasetRequest(state) {
      state.dataset.status = 'loading';
    },
    getDatasetSuccess(state, action) {
      state.dataset.data = action.payload;
      state.dataset.status = 'succeeded';
    },
    getDatasetFailure(state, action) {
      state.dataset.status = 'failed';
      state.dataset.data = {};
      state.datasets.error = action.payload;
    },
    favouriteDataset(state) {
      state.dataset.data.isWorkspace = !state.dataset.data.isWorkspace;
    },
    favouriteDatasetList(state, action) {
      const index = state.datasets.data.items.findIndex(
        item => item.id === action.payload.id,
      );

      if (index !== -1) {
        state.datasets.data.items[index].isWorkspace =
          !state.datasets.data.items[index].isWorkspace;
      }
    },
    getDatasetsRequest(state, action) {
      if (action.payload.hasLoading) {
        state[action.payload.prop].status = 'loading';
      }
      state[action.payload.prop].error = null;
    },
    getDatasetsSuccess(state, action) {
      const { prop, isLoadMore, data } = action.payload;

      state[prop].status = 'succeeded';
      state[prop].data = {
        ...data,
        items:
          isLoadMore && data.currentPage > 1
            ? [...state[prop].data.items, ...data.items]
            : data.items,
      };
    },
    getDatasetsFailure(state, action) {
      const { prop, error } = action.payload;

      state[prop].error = error;
      state[prop].status = 'failed';
    },
    updateDatasets(state, action) {
      const { prop, data } = action.payload;

      state[prop].data.items = data;
    },
    getMyDatasetsRecentlyRequest(state) {
      state.myDatasetsRecently.status = 'loading';
      state.myDatasetsRecently.error = null;
    },
    getMyDatasetsRecentlySuccess(state, action) {
      state.myDatasetsRecently.status = 'succeeded';
      state.myDatasetsRecently.data = action.payload;
    },
    getMyDatasetsRecentlyFailure(state, action) {
      state.myDatasetsRecently.status = 'failed';
      state.myDatasetsRecently.error = action.payload;
    },
    favouriteMyDatasetRecently(state, action) {
      const index = state.myDatasetsRecently.data.items.findIndex(
        item => item.id === action.payload.id,
      );
      if (index !== -1) {
        state.myDatasetsRecently.data.items[index].isFavorite =
          !state.myDatasetsRecently.data.items[index].isFavorite;
      }
    },
    getMyDatasetsEntitlementRequest(state, action) {
      state.myDatasetsEntitlement.status = 'loading';
      state.myDatasetsEntitlement.error = null;
      state.myDatasetsEntitlementParam = action.payload;
    },
    getMyDatasetsEntitlementSuccess(state, action) {
      state.myDatasetsEntitlement.status = 'succeeded';
      state.myDatasetsEntitlement.data = action.payload;
    },
    getMyDatasetsEntitlementFailure(state, action) {
      state.myDatasetsEntitlement.status = 'failed';
      state.myDatasetsEntitlement.error = action.payload;
    },
    getSampleHederRequest(state) {
      state.sampleHederDataset.status = 'loading';
      state.sampleHederDataset.error = null;
    },
    getSampleHederSuccess(state, action) {
      state.sampleHederDataset.status = 'succeeded';
      state.sampleHederDataset.data = action.payload;
    },
    getSampleHederFailure(state, action) {
      state.sampleHederDataset.status = 'failed';
      state.sampleHederDataset.error = action.payload;
    },
    putDatasetMyWorkspaceRequest(state) {
      state.datasetMyWorkspace.status = 'loading';
    },
    putDatasetMyWorkspaceSuccess(state, action) {
      state.datasetMyWorkspace.data = action.payload;
      state.datasetMyWorkspace.status = 'succeeded';
    },
    putDatasetMyWorkspaceFailure(state, action) {
      state.datasetMyWorkspace = {
        status: 'failed',
        data: {},
        error: action.payload,
      };
    },
    getDatasetExportValuesByColumnRequest(state) {
      state.datasetExportValuesByColumn.status = 'loading';
    },
    getDatasetExportValuesByColumnSuccess(state, action) {
      state.datasetExportValuesByColumn.data = action.payload;
      state.datasetExportValuesByColumn.status = 'succeeded';
    },
    getDatasetExportValuesByColumnFailure(state, action) {
      state.datasetExportValuesByColumn.status = 'failed';
      state.datasetExportValuesByColumn.data = [];
      state.datasetExportValuesByColumn.error = action.payload;
    },
    postDatasetExportColumnsRequest(state) {
      state.datasetExportColumns.status = 'loading';
    },
    postDatasetExportColumnsSuccess(state, action) {
      state.datasetExportColumns.data = action.payload;
      state.datasetExportColumns.status = 'succeeded';
    },
    postDatasetExportColumnsFailure(state, action) {
      state.datasetExportColumns = {
        status: 'failed',
        data: [],
        error: action.payload,
      };
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = datasetSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getSuggestedKeywordRequest,
  getSuggestedKeywordSuccess,
  getSuggestedKeywordFailure,
  getSuggestedKeywordModularDatasetsRequest,
  getSuggestedKeywordModularDatasetsSuccess,
  getSuggestedKeywordModularDatasetsFailure,
  getDatasetRequest,
  getDatasetSuccess,
  getDatasetFailure,
  favouriteDataset,
  favouriteDatasetList,
  getDatasetsRequest,
  getDatasetsSuccess,
  getDatasetsFailure,
  updateDatasets,
  getMyDatasetsRecentlyRequest,
  getMyDatasetsRecentlySuccess,
  getMyDatasetsRecentlyFailure,
  favouriteMyDatasetRecently,
  getMyDatasetsEntitlementRequest,
  getMyDatasetsEntitlementSuccess,
  getMyDatasetsEntitlementFailure,
  getSampleHederRequest,
  getSampleHederSuccess,
  getSampleHederFailure,
  putDatasetMyWorkspaceRequest,
  putDatasetMyWorkspaceSuccess,
  putDatasetMyWorkspaceFailure,
  getDatasetExportValuesByColumnRequest,
  getDatasetExportValuesByColumnSuccess,
  getDatasetExportValuesByColumnFailure,
  postDatasetExportColumnsRequest,
  postDatasetExportColumnsSuccess,
  postDatasetExportColumnsFailure,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const suggestedKeywordSelector = createSelector(
  rootSelector,
  ({ suggestedKeyword }) => suggestedKeyword,
);
export const suggestedKeywordModularDatasetsSelector = createSelector(
  rootSelector,
  ({ suggestedKeywordModularDatasets }) => suggestedKeywordModularDatasets,
);
export const datasetSelector = createSelector(
  rootSelector,
  ({ dataset }) => dataset,
);
export const datasetsSelectorByProp = prop =>
  createSelector(rootSelector, state => state[prop]);
export const myDatasetsRecentlySelector = createSelector(
  rootSelector,
  state => state.myDatasetsRecently,
);
export const myDatasetsEntitlementSelector = createSelector(
  rootSelector,
  state => state.myDatasetsEntitlement,
);
export const myDatasetsEntitlementParamSelector = createSelector(
  rootSelector,
  state => state.myDatasetsEntitlementParam,
);
export const sampleHederSelector = createSelector(
  rootSelector,
  state => state.sampleHederDataset,
);
export const datasetExportValuesByColumnSelector = createSelector(
  rootSelector,
  state => state.datasetExportValuesByColumn,
);
export const datasetExportColumnsSelector = createSelector(
  rootSelector,
  state => state.datasetExportColumns,
);
