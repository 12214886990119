import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { ReactComponent as CloseIcon } from '../../../assets/icons/common/close.svg';

import './styles.scss';

const AMExportResult = ({
  isShowed,
  fileSizes,
  onDownload,
  onClose,
  fileList,
}) => {
  const [fileType, setFileType] = useState();
  useEffect(() => {
    if (fileSizes.length > 0) {
      setFileType(fileSizes[0][0]);
    }
  }, [fileSizes]);

  const handleDownload = () => {
    onDownload(fileList, fileType);
  };

  return (
    <Modal className="am-export-modal" show={isShowed} centered>
      <div className="am-export-modal__header">
        <p className="am-export-modal__title">ANALYTICS MARKETPLACE</p>
        <button
          type="button"
          className="am-export-modal__close"
          onClick={onClose}
        >
          <CloseIcon />
        </button>
      </div>
      <p className="am-export-modal__name">Define Result Download Parameters</p>
      <div className="am-export-modal__body">
        <p className="am-export-modal__choose">Choose File</p>
        <div className="am-export-modal__content">
          {fileSizes.map(([type, fileSize]) => (
            <div key={type} className="am-export-modal__type">
              <label htmlFor={type}>
                Local {type.toUpperCase()} file{' '}
                <p>{`(${Math.round((fileSize / 1024) * 100) / 100} KB)`}</p>
                <input
                  type="radio"
                  name="file-type"
                  id={type}
                  checked={fileType === type}
                  onChange={() => setFileType(type)}
                />
                <span />
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="am-export-modal__footer">
        <button
          type="button"
          className="am-export-modal__cancel"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="am-export-modal__download"
          onClick={handleDownload}
        >
          Download
        </button>
      </div>
    </Modal>
  );
};

AMExportResult.propTypes = {
  fileSizes: PropTypes.array,
  isShowed: PropTypes.bool,
  fileList: PropTypes.array,
  onClose: PropTypes.func,
  onDownload: PropTypes.func,
};

AMExportResult.defaultProps = {
  isShowed: false,
  fileSizes: [],
  fileList: [],
  onDownload() {},
  onClose() {},
};

export default memo(AMExportResult);
