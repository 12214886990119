import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Status = ({
  className,
  background,
  color,
  size,
  cursor,
  bill,
  text,
  tag,
  activatedTag,
  onClick,
}) => {
  const styleClasses = ['edh-status', className];

  switch (size) {
    case 'sm':
      styleClasses.push('edh-status--sm');
      break;
    case 'lg':
      styleClasses.push('edh-status--lg');
      break;
    default:
      break;
  }

  if (tag) {
    styleClasses.push('edh-status--tag');
  }

  return (
    <button
      type="button"
      className={`${styleClasses.join(' ')}`}
      style={{
        background: `#${background}`,
        color: `#${color}`,
        fontWeight: `${activatedTag && 'bold'}`,
        borderRadius: bill ? '11px' : '2px',
        border: 'none',
        cursor: cursor || 'default',
      }}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

Status.propTypes = {
  bill: PropTypes.bool,
  tag: PropTypes.bool,
  activatedTag: PropTypes.bool,
  className: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  cursor: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  onClick: PropTypes.func,
};

Status.defaultProps = {
  bill: false,
  tag: false,
  activatedTag: false,
  className: '',
  background: '#EDF1F8',
  color: '#465463',
  size: 'sm',
  cursor: 'default',
  text: '',
  onClick() {},
};

export default memo(Status);
