import React, {
  memo,
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import BButton from 'react-bootstrap/Button';
import BBadge from 'react-bootstrap/Badge';
import BOverlay from 'react-bootstrap/Overlay';
import BCard from 'react-bootstrap/Card';
import BPopover from 'react-bootstrap/Popover';
import { BsXLg } from 'react-icons/bs';

import { NOTIFICATION } from 'constants/breakcrumb';
import { ID_TOKEN } from 'constants/storageKeys';
import { USER_HUB_PATH } from 'constants/paths';

import settings from 'settings';
import { getStorage } from 'services/storage';

import NoDataNotificationImg from 'assets/images/common/empty-notification.jpg';

import useNotifications from 'hooks/useNotifications';
import useSignalR from 'hooks/useSignalR';
import useDevice from 'hooks/useDevice';

import LoadingBalls from '../../core/LoadingBalls';
import MessageNoData from '../../core/MessageNoData';

import Tooltip from '../Tooltip';
import NotificationItem from '../NotificationItem';

import './styles.scss';

const NotificationModal = ({ icon }) => {
  const [toggleOpen, setToggleOpen] = useState(false);

  const popoverRef = useRef();

  const history = useHistory();

  const {
    notificationsPopup = {},
    newNotificationsQuantity,
    onGetNotificationsPopup,
    onMarkAllReadPopup,
    onDeleteNotificationPopup,
    onSaveTimeOpenClosePopup,
    onGetNewNotificationQuantity,
    onChangeIsShowPopup,
  } = useNotifications();
  const { isMobileDevice } = useDevice();

  const { data = {}, status } = notificationsPopup;
  const { items = [], unread } = data;

  const isLoading = useMemo(() => status === 'loading', [status]);

  const onClosePopup = useCallback(() => {
    setToggleOpen(false);
    onSaveTimeOpenClosePopup();
  }, []);

  useEffect(() => {
    onGetNewNotificationQuantity();
  }, []);

  useEffect(() => {
    if (toggleOpen) {
      onGetNotificationsPopup({ pageIndex: 1, pageSize: 5 });
    }
    onChangeIsShowPopup(toggleOpen);
  }, [toggleOpen]);

  useSignalR(
    {
      url: `${settings.ROOT_BASE_URL}${USER_HUB_PATH}`,
      options: {
        accessTokenFactory: () => getStorage(ID_TOKEN),
      },
      event: {
        methodName: 'NotifyNotification',
        action: onGetNewNotificationQuantity,
      },
    },
    [],
  );

  return (
    <div id="edh-notification">
      <Tooltip content="Notification" disabled={isMobileDevice}>
        <BButton
          className="edh-notification__btn"
          ref={popoverRef}
          onClick={() => {
            setToggleOpen(true);
            onSaveTimeOpenClosePopup();
          }}
        >
          <img src={icon} alt="Button notification" />
          {newNotificationsQuantity?.data?.count > 0 &&
            !toggleOpen &&
            window.location.pathname !== NOTIFICATION.id && (
              <BBadge
                pill
                style={{
                  position: 'absolute',
                  right: '2px',
                  backgroundColor: '#9B151D',
                }}
              >
                {newNotificationsQuantity.data.count > 9
                  ? '9+'
                  : newNotificationsQuantity.data.count}
              </BBadge>
            )}
        </BButton>
      </Tooltip>
      <BOverlay
        target={popoverRef.current}
        show={toggleOpen}
        flip
        rootClose
        rootCloseEvent="click"
        placement="bottom-end"
        onHide={onClosePopup}
      >
        <BPopover id="edh-notification__menus">
          <LoadingBalls isLoading={isLoading} />
          <BCard>
            <BCard.Header>
              <span id="edh-notification__title">Notifications</span>
              {unread ? (
                <span id="edh-notification__number-unread">
                  {unread} unread
                </span>
              ) : null}
              <BButton disabled={!unread} onClick={onMarkAllReadPopup}>
                Mark All as Read
              </BButton>
            </BCard.Header>
            <BCard.Body>
              {items.map(item => (
                <NotificationItem
                  key={item.id}
                  data={item}
                  action={
                    <BButton onClick={() => onDeleteNotificationPopup(item.id)}>
                      <BsXLg size={14} color="#8D8D8D" />
                    </BButton>
                  }
                  onClickItem={onClosePopup}
                />
              ))}
              {!isLoading && items.length === 0 && (
                <MessageNoData
                  img={NoDataNotificationImg}
                  title="No notification."
                  description={
                    <span>
                      Nothing to ring the bell yet.
                      <br />
                      Notifications about your activity will show up here.
                    </span>
                  }
                />
              )}
            </BCard.Body>
            {items.length > 0 && (
              <BCard.Footer>
                <BButton
                  onClick={() => {
                    history.push(NOTIFICATION.id);
                    onClosePopup();
                  }}
                >
                  View all notification
                </BButton>
              </BCard.Footer>
            )}
          </BCard>
        </BPopover>
      </BOverlay>
    </div>
  );
};

NotificationModal.propTypes = {
  icon: PropTypes.any,
};

export default memo(NotificationModal);
