/* eslint-disable react/no-this-in-sfc */
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import Mention from 'quill-mention';
import { Picker } from 'emoji-mart';
import _ from 'lodash';
import 'quill-paste-smart';

import useDevice from 'hooks/useDevice';

import { ReactComponent as MentionIcon } from 'assets/icons/common/mention.svg';
import { ReactComponent as PlaneIcon } from 'assets/icons/common/paper-plane.svg';
import { ReactComponent as EmojiIcon } from 'assets/icons/common/emoji.svg';

import useToggle from 'hooks/useToggle';
import useClickOutside from 'hooks/useClickOutside';
import useUser from 'hooks/useUser';

import AMCannedResponseDropdown from 'components/core/AMCannedResponseDropdown';
import { listAcceptMaxLength } from 'constants/dataRequestForm';

import { gettingNameInitials, utf8ToBase64 } from 'services/string';
import getImageFromEmail from 'services/image/getImageFromEmail';
import { graphConfig } from '../../../msalConfig';

import 'react-quill/dist/quill.snow.css';
import 'emoji-mart/css/emoji-mart.css';
import './styles.scss';

const getAvatar = ({ avatar, fullName }) => {
  if (avatar) {
    return (
      <img
        src={`${avatar}`}
        alt={fullName}
        style={{ width: '24px', height: '24px', borderRadius: '50%' }}
      />
    );
  }
  return (
    <div
      style={{
        '--bg': 'var(--container-color)',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#00a19c',
        color: 'var(--bg)',
        flexShrink: 0,
      }}
    >
      <p>{gettingNameInitials(fullName)}</p>
    </div>
  );
};

const handleHighlight = (title, searchWord) => {
  if (title.toLowerCase().includes(searchWord.toLowerCase())) {
    const index = title.toLowerCase().indexOf(searchWord.toLowerCase());
    return (
      <>
        {title.substring(0, index)}
        <span style={{ fontWeight: 'bold' }}>
          {title.substring(index, index + searchWord.length)}
        </span>
        {title.substring(index + searchWord.length)}
      </>
    );
  }
};

const TextEditor = ({
  id,
  value,
  className,
  placeholder,
  isReadOnly,
  keepPasteSelection,
  isShowCannedResponseDropdown,
  isRootContainerEmojiPicker,
  onSend,
  onChange,
  onFocus,
  onBlur,
  maxLength,
  widthCommentBox,
}) => {
  Quill.register('modules/mentions', Mention);
  Quill.register('modules/counter', (quill, options) => {
    if (maxLength !== -1) {
      const container = document.getElementById(options?.container);
      quill.on('text-change', () => {
        const textLength = quill.getLength();
        container.innerText = `${textLength - 1}/1500 characters`;
      });
    }
  });

  const { searchUser, onSearchUserRequest, isAMAdmin } = useUser();
  const [isShowEmojiPicker, changeShowEmojiPicker] = useToggle();
  const [isDisabled, setIsDisabled] = useState(false);
  const [editorIndex, setEditorIndex] = useState(0);
  const [rangeSelection, setRangeSelection] = useState({
    index: 0,
    length: 0,
  });

  const [users, setUsers] = useState({ isLoading: false, data: [] });
  const searchRef = useRef();
  const editorRef = useRef();
  const emojiRef = useRef();
  const buttonEmojiRef = useRef();
  const { isMobileDevice } = useDevice();

  const onGetUser = _.debounce(keyword => {
    if (searchRef.current === keyword) return;

    setUsers({ ...users, isLoading: true });
    searchRef.current = keyword;
    onSearchUserRequest({ keyword: utf8ToBase64(keyword), type: 'Mention' });
  }, 200);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#toolbar${id}`,
        handlers: {
          insertMention() {
            const position = this?.quill?.getSelection()?.index;
            this.quill.insertText(position, '@');
            this.quill.setSelection(position + 1);
          },
        },
      },
      clipboard: {
        allowed: {
          tags: ['span', 'p'],
          attributes: [],
        },
        keepSelection: keepPasteSelection,
        substituteBlockElements: true,
        magicPasteLinks: true,
      },
      keyboard: {
        bindings: {
          handleEnter: {
            key: 13,
            handler() {
              return !(
                maxLength !== -1 &&
                editorRef.current.editor.getLength() - 1 >= maxLength
              );
            },
          },
          'header enter': {
            key: 13,
            handler() {
              return !(
                maxLength !== -1 &&
                editorRef.current.editor.getLength() - 1 >= maxLength
              );
            },
          },
        },
      },
      mention: {
        allowedChars: /^[A-Za-z0-9\sÅÄÖåäö~`!$&*^()_+={},|/:;"'?-]*$/,
        mentionDenotationChars: ['@'],
        defaultMenuOrientation: 'top',
        fixMentionsToQuill: true,
        spaceAfterInsert: false,
        source(searchTerm, renderList) {
          if (searchTerm && searchTerm.trim()) {
            onGetUser(searchTerm.trim());
            if (!users.isLoading && searchUser.status !== 'loading') {
              renderList(users.data, searchTerm.trim());
            }
          } else {
            renderList();
          }
        },
        renderLoading: () =>
          renderToStaticMarkup(
            <div className="edh-loading-balls-mention--backdrop">
              <div className="edh-loading-balls-mention__balls-container">
                <p className="edh-loading-balls-mention__text">Loading</p>
                <div className="edh-loading-balls-mention__ball edh-loading-balls-mention__ball--style-one" />
                <div className="edh-loading-balls-mention__ball edh-loading-balls-mention__ball--style-two" />
                <div className="edh-loading-balls-mention__ball edh-loading-ball-mention__ball--style-three" />
              </div>
            </div>,
          ),
        renderItem(item, searchTerm) {
          const avatar = getAvatar(item);
          const fullName = handleHighlight(item.fullName, searchTerm);
          return renderToStaticMarkup(
            <>
              {avatar}
              <div style={{ marginLeft: '12px' }}>
                <div
                  className={`ql-mention-user__name${
                    isMobileDevice ? ' mr' : ''
                  }`}
                >
                  {fullName || item.fullName}
                </div>
                <div
                  style={{
                    width: '100%',
                    fontSize: '11px',
                    fontWeight: 500,
                    color: '#9F9EAE',
                  }}
                >
                  {item.department || ''}
                </div>
              </div>
            </>,
          );
        },
      },
      counter: {
        container: `edh-comment-input__max-length-${id}`,
      },
    }),
    [users, searchUser.status, id, keepPasteSelection],
  );

  const checkCharacterCount = useCallback(event => {
    if (
      maxLength !== -1 &&
      editorRef.current.editor.getLength() - 1 >= maxLength &&
      !listAcceptMaxLength.includes(event.code)
    ) {
      event.preventDefault();
    }

    const { index } = editorRef.current.editor.getSelection();
    setEditorIndex(index);
    if (
      maxLength !== -1 &&
      editorRef.current.editor.getLength() - 1 >= maxLength
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, []);

  const onChangeSelection = useCallback(range => {
    if (range) {
      setRangeSelection({ ...range });
    }
  }, []);

  const onInsertIcon = useCallback(
    emoji => {
      const stringLength = editorRef.current.editor.getLength() - 1;
      if (rangeSelection.length) {
        editorRef.current.editor.deleteText(
          rangeSelection.index,
          rangeSelection.length,
        );
      }

      if (maxLength !== -1) {
        if (stringLength >= maxLength) {
          editorRef.current.editor.deleteText(
            maxLength,
            editorRef.current.editor.getLength() - 1,
          );
        } else if (stringLength + emoji.native.length <= maxLength) {
          editorRef.current.editor.insertText(
            rangeSelection.index,
            emoji.native,
          );
        }
      } else {
        editorRef.current.editor.insertText(rangeSelection.index, emoji.native);
      }

      setRangeSelection({
        index: rangeSelection.index,
        length: 0,
      });
      changeShowEmojiPicker();
    },
    [editorRef, rangeSelection],
  );

  useClickOutside(emojiRef, e => {
    if (!buttonEmojiRef.current.contains(e.target)) {
      changeShowEmojiPicker();
    }
  });

  const handleChange = useCallback(html => {
    if (maxLength !== -1) {
      editorRef.current.editor.on('text-change', () => {
        if (editorRef.current.editor.getLength() - 1 >= maxLength) {
          setIsDisabled(true);
          editorRef.current.editor.deleteText(
            maxLength,
            editorRef.current.editor.getLength() - 1,
          );
        } else {
          setIsDisabled(false);
        }
      });
    }

    onChange(html);
  }, []);

  const handleOnSelect = useCallback(
    optionValue => {
      const { editor } = editorRef.current;
      const { index, length } = rangeSelection;
      const editorLength = editor.getLength() - 1;
      const availableSpace = maxLength - editorLength;

      // Delete the selected text if any
      if (length) {
        editor.deleteText(index, length);
      }

      // Check if the editor is full
      if (editorLength >= maxLength) {
        // Delete any extra text
        editor.deleteText(maxLength, editorLength);
        return;
      }

      // Insert the option value or a part of it if there is not enough space
      const insertValue =
        availableSpace <= optionValue.length
          ? optionValue.slice(0, availableSpace)
          : optionValue;
      editor.insertText(index, insertValue);

      // Reset the range selection
      setRangeSelection({
        index,
        length: 0,
      });
    },
    [editorRef, rangeSelection],
  );

  useEffect(() => {
    const newData = searchUser.data.map(item => ({
      ...item,
      value: item.fullName,
    }));

    let mounted = true;
    if (JSON.stringify(newData) !== JSON.stringify(users)) {
      const data = newData.map(async item => {
        const src = graphConfig.getGraphUserPhotoEndpoint(item.email);
        item.avatar = await getImageFromEmail(src);

        return item;
      });

      Promise.all(data).then(newUsers => {
        if (mounted) {
          setUsers({ isLoading: false, data: newUsers });
          editorRef.current.editor.setSelection(editorIndex + 1);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [searchUser.data]);

  const emojiOffsetTop =
    window.scrollY + buttonEmojiRef?.current?.getBoundingClientRect().top - 330;

  const emojiOffsetRight =
    window.outerWidth -
    buttonEmojiRef?.current?.getBoundingClientRect().x -
    buttonEmojiRef?.current?.getBoundingClientRect().width;
  const isShowEmojiInPortal = isRootContainerEmojiPicker && isMobileDevice;

  return (
    <div
      className={`edh-text-editor ${className}`}
      id={id}
      onDrop={e => e.preventDefault()}
    >
      {isShowEmojiPicker &&
        isShowEmojiInPortal &&
        createPortal(
          <>
            <div className="edh-text-editor__emoji--bg" />
            <div
              className="edh-text-editor__emoji"
              ref={emojiRef}
              style={{
                top: emojiOffsetTop,
                right: emojiOffsetRight,
              }}
            >
              <Picker
                native
                emojiSize={16}
                showPreview={false}
                showSkinTones={false}
                onSelect={onInsertIcon}
              />
            </div>
          </>,
          document.body,
        )}
      {isShowEmojiPicker && !isShowEmojiInPortal && (
        <div className="edh-text-editor__emoji" ref={emojiRef}>
          <Picker
            native
            emojiSize={20}
            showPreview={false}
            showSkinTones={false}
            onSelect={onInsertIcon}
          />
        </div>
      )}
      <ReactQuill
        id={`quill-${id}`}
        theme="snow"
        value={value}
        readOnly={isReadOnly}
        onChange={handleChange}
        modules={modules}
        placeholder={placeholder}
        ref={editorRef}
        onChangeSelection={onChangeSelection}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={checkCharacterCount}
      />{' '}
      <div id={`toolbar${id}`} className="edh-text-editor__actions">
        {isShowCannedResponseDropdown && isAMAdmin && (
          <AMCannedResponseDropdown
            disabled={isDisabled}
            onSelect={handleOnSelect}
            widthCommentBox={widthCommentBox}
          />
        )}
        <button
          type="button"
          className="edh-text-editor__action ql-insertMention"
          disabled={isDisabled}
        >
          <MentionIcon />
        </button>
        <button
          type="button"
          className="edh-text-editor__action"
          onClick={changeShowEmojiPicker}
          ref={buttonEmojiRef}
          disabled={isDisabled}
        >
          <EmojiIcon />
        </button>
        <button
          type="button"
          disabled={!value.replace(/<[^>]+>/g, '').trim()}
          className="edh-text-editor__action primary"
          onClick={onSend}
        >
          <PlaneIcon />
        </button>
      </div>
      {maxLength !== -1 && (
        <span
          id={`edh-comment-input__max-length-${id}`}
          className="edh-comment-input__max-length"
        />
      )}
    </div>
  );
};

TextEditor.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  widthCommentBox: PropTypes.number,
  isReadOnly: PropTypes.bool,
  keepPasteSelection: PropTypes.bool,
  isShowCannedResponseDropdown: PropTypes.bool,
  isRootContainerEmojiPicker: PropTypes.bool,
  onSend: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

TextEditor.defaultProps = {
  id: new Date().getTime() * Math.floor(Math.random() * 6 + 1),
  className: '',
  isReadOnly: false,
  keepPasteSelection: true,
  isShowCannedResponseDropdown: false,
  isRootContainerEmojiPicker: false,
  isShowCountCharacters: false,
  maxLength: -1,
  widthCommentBox: 0,
  placeholder: 'Write here...',
  onFocus() {},
  onBlur() {},
};

export default memo(TextEditor);
