import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { DOWNLOADED_COUNT, WATCHED_COUNT } from 'constants/getStarted';

export const ROOT_STATE_NAME = 'dataSample';

const initialState = {
  dataSample: {
    data: {},
    status: 'idle',
    error: null,
  },
  suggestion: {
    data: {},
    status: 'idle',
    error: null,
  },
  acquiringSteps: {
    data: {},
    status: 'idle',
    error: null,
  },
  suggestedKeywordDataSample: {
    data: [],
    status: 'idle',
    error: null,
  },
};

const dataSampleSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getDataSampleRequest(state) {
      state.dataSample.status = 'loading';
    },
    getDataSampleSuccess(state, action) {
      state.dataSample.data = action.payload;
      state.dataSample.status = 'succeeded';
    },
    getDataSampleFailure(state, action) {
      state.dataSample.status = 'failed';
      state.dataSample.data = {};
      state.dataSample.error = action.payload;
    },
    getDataSampleSuggestionRequest(state) {
      state.suggestion.status = 'loading';
    },
    getDataSampleSuggestionSuccess(state, action) {
      state.suggestion.data = action.payload;
      state.suggestion.status = 'succeeded';
    },
    getDataSampleSuggestionFailure(state, action) {
      state.suggestion.status = 'failed';
      state.suggestion.data = {};
      state.suggestion.error = action.payload;
    },
    getDataSampleAcquiringStepsRequest(state) {
      state.acquiringSteps.status = 'loading';
    },
    getDataSampleAcquiringStepsSuccess(state, action) {
      state.acquiringSteps.data = action.payload;
      state.acquiringSteps.status = 'succeeded';
    },
    getDataSampleAcquiringStepsFailure(state, action) {
      state.acquiringSteps.status = 'failed';
      state.acquiringSteps.data = {};
      state.acquiringSteps.error = action.payload;
    },
    postDataSampleSuggestionRequest(state) {
      state.suggestedKeywordDataSample.status = 'loading';
    },
    postDataSampleSuggestionSuccess(state, action) {
      state.suggestedKeywordDataSample.data = action.payload;
      state.suggestedKeywordDataSample.status = 'succeeded';
    },
    postDataSampleSuggestionFailure(state, action) {
      state.suggestedKeywordDataSample.status = 'failed';
      state.suggestedKeywordDataSample.data = [];
      state.suggestedKeywordDataSample.error = action.payload;
    },
    increaseDataSampleCountById(state, action) {
      const dataSample = state?.dataSample?.data;
      const { type, id } = action.payload;

      if (dataSample?.items && dataSample?.items.length > 0) {
        dataSample.items.forEach(item => {
          if (item.id === id) {
            if (type === WATCHED_COUNT) {
              item.watchedCount += 1;
            }

            if (type === DOWNLOADED_COUNT) {
              item.downloadedCount += 1;
            }
          }
        });
      }
    },
  },
});

const { actions, reducer } = dataSampleSlice;

export default reducer;

export const {
  getDataSampleRequest,
  getDataSampleSuccess,
  getDataSampleFailure,
  getDataSampleSuggestionRequest,
  getDataSampleSuggestionSuccess,
  getDataSampleSuggestionFailure,
  getDataSampleAcquiringStepsRequest,
  getDataSampleAcquiringStepsSuccess,
  getDataSampleAcquiringStepsFailure,
  postDataSampleSuggestionRequest,
  postDataSampleSuggestionSuccess,
  postDataSampleSuggestionFailure,
  increaseDataSampleCountById,
} = actions;

export const rootSelector = state => state[ROOT_STATE_NAME] || {};

export const dataSampleSelector = createSelector(
  rootSelector,
  ({ dataSample }) => dataSample,
);

export const dataSampleSuggestionSelector = createSelector(
  rootSelector,
  ({ suggestion }) => suggestion,
);

export const dataSampleAcquiringStepsSelector = createSelector(
  rootSelector,
  ({ acquiringSteps }) => acquiringSteps,
);

export const suggestedKeywordDataSampleSelector = createSelector(
  rootSelector,
  ({ suggestedKeywordDataSample }) => suggestedKeywordDataSample,
);
