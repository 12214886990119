import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'services/uid';
import { BsStar, BsStarFill } from 'react-icons/bs';
import Tooltip from '../Tooltip';

import './styles.scss';

const defaultWidthSvg = 16;

const RatingStar = ({
  ratingScore,
  disabled,
  starNumber,
  onRating,
  starSize,
  isTooltip,
  ...props
}) => {
  const [rating, setRating] = useState(ratingScore);
  const [hover, setHover] = useState(0);
  useEffect(() => {
    if (!disabled) {
      setHover(ratingScore);
    }
    setRating(ratingScore);
  }, [ratingScore]);

  const handleWidth = item => {
    const score = disabled ? rating : hover;
    if (score >= item) {
      return '100%';
    }
    if (item - score < 1) {
      return `${Math.round((score - item + 1) * 100)}%`;
    }
    return '0%';
  };

  const renderStar = index => {
    const width = handleWidth(index);
    const widthViewBox =
      (parseInt(handleWidth(index), 10) * defaultWidthSvg) / 100;

    return (
      <>
        <BsStarFill
          className="edh-ratingstar__starcontainer"
          viewBox={`0 0 ${widthViewBox} ${defaultWidthSvg}`}
          style={{ width }}
          color="#F1C21B"
          size={starSize}
        />
        <BsStar
          className="edh-ratingstar__starbg"
          color="#C4C3D1"
          size={starSize}
        />
      </>
    );
  };

  const onClickStar = index => {
    setRating(index);
    onRating({
      index,
      callback: () => onRating(index),
    });
  };
  return (
    <Tooltip
      id={uid()}
      disabled={!isTooltip}
      content={(disabled ? rating : hover).toString()}
      placement="top"
    >
      <div className="edh-ratingstar" {...props} style={{ height: starSize }}>
        <div className="edh-ratingstar__body">
          {[...Array(starNumber)].map((_star, index) => {
            const newIndex = index + 1;
            return (
              <button
                type="button"
                key={`stars-${newIndex.toString()}`}
                style={{
                  cursor: disabled ? 'default' : 'pointer',
                  width: starSize,
                }}
                className="edh-ratingstar__starbox"
                onClick={() => (disabled ? null : onClickStar(newIndex))}
                onMouseEnter={() => (disabled ? null : setHover(newIndex))}
                onMouseLeave={() => (disabled ? null : setHover(rating))}
              >
                {renderStar(newIndex)}
              </button>
            );
          })}
        </div>
      </div>
    </Tooltip>
  );
};

RatingStar.propTypes = {
  disabled: PropTypes.bool,
  isTooltip: PropTypes.bool,
  ratingScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  starSize: PropTypes.number,
  starNumber: PropTypes.number,
  onRating: PropTypes.func,
};
RatingStar.defaultProps = {
  ratingScore: 0,
  starSize: 24,
  starNumber: 5,
  disabled: false,
  isTooltip: false,
  onRating() {},
};
export default memo(RatingStar);
