import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import BPagination from 'react-bootstrap/Pagination';

import useDevice from 'hooks/useDevice';
import { onScrollToTop } from 'services/scroll';

import { PAGE_SIZE_OPTIONS } from 'constants/pageSize';

import DropdownBtn from '../../core/DropdownBtn';

import './styles.scss';

const Pagination = ({
  isScrollToTop,
  totalPages,
  totalCount,
  pageIndex,
  pageSize,
  pageSizeOptions,
  pageOptions,
  page,
  canNextPage,
  canPreviousPage,
  isShowPageSize,
  isShowTotalCount,
  isShowGotoPage,
  onGotoPage,
  onNextPage,
  onPreviousPage,
  onSetPageSize,
  isInSightScoopPage,
  isDQPage,
}) => {
  const [newPageIndex, setNewPageIndex] = useState('');
  const [error, setError] = useState(false);
  const { isMobileDevice } = useDevice();
  const pageSizeOptional = isInSightScoopPage ? 3 : 10;

  const onChangeNewPageIndex = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setNewPageIndex(e.target.value);
    }
  };

  if (isDQPage && totalCount <= pageSizeOptions[0]?.accessor) {
    return null;
  }

  if (
    totalCount <=
      (isMobileDevice
        ? pageSizeOptional
        : pageSizeOptions[0]?.accessor || 10) &&
    !isDQPage
  ) {
    return null;
  }

  const getArrShowPages = (current, total) => {
    const numDisplayItems = 5;
    const numSideItems = 2;

    const pageArray = Array(total)
      .fill('p')
      .map((ele, idx) => idx + 1);

    if (current <= numSideItems) {
      return pageArray.slice(0, numDisplayItems);
    }

    if (current >= total - numSideItems) {
      const start = total - numDisplayItems;
      const leftIdx = start > 0 ? start : 0;
      return pageArray.slice(leftIdx, total);
    }

    const leftIdx = current - (numSideItems + 1);
    const rightIdx = current + numSideItems;

    return pageArray.slice(leftIdx, rightIdx);
  };

  const arrShowPages = getArrShowPages(
    pageIndex + 1,
    pageOptions.length || totalPages,
  );

  const handleOnNextPage = () => {
    isScrollToTop && onScrollToTop();
    onNextPage();
  };

  const handleOnPreviousPage = () => {
    isScrollToTop && onScrollToTop();
    onPreviousPage();
  };

  return (
    <div className="edh-pagination">
      <div className="edh-pagination__left">
        {isShowPageSize && (
          <DropdownBtn
            title={`${pageSize} per page`}
            menu={pageSizeOptions}
            onSelect={onSetPageSize}
            disabled={totalCount <= 0}
          />
        )}
        {isShowTotalCount && (
          <span
            style={{
              fontSize: 14,
              fontWeight: 'normal',
              color: '#787587',
              marginLeft: 8,
              width: '100%',
            }}
          >
            {`${pageIndex * pageSize + 1} - ${
              pageIndex * pageSize + page.length
            } of ${totalCount} items`}
          </span>
        )}
      </div>
      <div className="edh-pagination__right">
        <BPagination>
          <BPagination.Prev
            disabled={!canPreviousPage}
            onClick={handleOnPreviousPage}
          />

          {arrShowPages.map(item => (
            <BPagination.Item
              key={item}
              active={item === pageIndex + 1}
              onClick={() => {
                if (item !== pageIndex + 1) {
                  isScrollToTop && onScrollToTop();
                  onGotoPage(item - 1);
                }
              }}
            >
              {item}
            </BPagination.Item>
          ))}

          <BPagination.Next
            disabled={!canNextPage}
            onClick={handleOnNextPage}
          />
        </BPagination>

        {isShowGotoPage && (
          <div className="edh-pagination__go-page">
            <p>Go to</p>
            <form
              onSubmit={e => {
                e.preventDefault();
                if (
                  newPageIndex !== '' &&
                  newPageIndex <= totalPages &&
                  newPageIndex > 0
                ) {
                  isScrollToTop && onScrollToTop();
                  setError(false);
                  onGotoPage(+newPageIndex - 1);
                  setNewPageIndex('');
                } else setError(true);
              }}
            >
              <input
                value={newPageIndex}
                onChange={onChangeNewPageIndex}
                className={error ? 'error' : ''}
                onBlur={() => {
                  setNewPageIndex('');
                  setError(false);
                }}
              />
            </form>
            <p>Page</p>
          </div>
        )}
      </div>
    </div>
  );
};

Pagination.propTypes = {
  isScrollToTop: PropTypes.bool,
  totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageSizeOptions: PropTypes.array,
  pageOptions: PropTypes.array,
  page: PropTypes.array,
  canNextPage: PropTypes.bool,
  canPreviousPage: PropTypes.bool,
  isShowPageSize: PropTypes.bool,
  isShowTotalCount: PropTypes.bool,
  isShowGotoPage: PropTypes.bool,
  isInSightScoopPage: PropTypes.bool,
  isDQPage: PropTypes.bool,
  onGotoPage: PropTypes.func,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  onSetPageSize: PropTypes.func,
};

Pagination.defaultProps = {
  isScrollToTop: true,
  totalPages: 0,
  totalCount: 0,
  pageIndex: 0,
  pageSize: 0,
  pageSizeOptions: PAGE_SIZE_OPTIONS,
  pageOptions: [],
  page: [],
  canNextPage: false,
  canPreviousPage: false,
  isShowPageSize: true,
  isShowTotalCount: true,
  isShowGotoPage: true,
  isInSightScoopPage: false,
  isDQPage: false,
  onGotoPage() {},
  onNextPage() {},
  onPreviousPage() {},
  onSetPageSize() {},
};

export default memo(Pagination);
