import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import classnames from 'services/classnames';

import blankImage from 'assets/images/common/empty.png';

import './styles.scss';

const Image = forwardRef(
  ({ className, src: srcLazy, alt, defaultImg, ...rest }, ref) => {
    const imageRef = useRef();

    const [src, setSrc] = useState();

    const handleError = () => {
      if (src) setSrc(defaultImg);
    };

    useEffect(() => {
      if (ref) {
        ref.current = imageRef.current;
      }
    }, [ref]);

    useEffect(() => {
      let observer;
      const handleIntersectionObserver = ([entry]) => {
        if (entry.isIntersecting) {
          const imgElement = document.createElement('img');
          imgElement.addEventListener('load', () => {
            setSrc(srcLazy);
          });
          imgElement.src = srcLazy;
          observer?.disconnect();
        }
      };

      observer = new IntersectionObserver(handleIntersectionObserver);
      observer.observe(imageRef.current);

      return () => {
        observer?.disconnect();
      };
    }, [srcLazy, imageRef]);

    return (
      <img
        {...rest}
        className={classnames(className, {
          'edh-image--loaded': src,
        })}
        src={src}
        alt={alt}
        onError={handleError}
        ref={imageRef}
      />
    );
  },
);

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  defaultImg: PropTypes.string,
};

Image.defaultProps = {
  defaultImg: blankImage,
};

export default memo(Image);
