import { useEffect } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useDispatch, useSelector } from 'react-redux';

import {
  addConnection,
  removeConnection,
  connectionsSelector,
} from '../slices/signalr';

const useSignalR = (
  {
    isGlobal = true,
    url,
    options = {},
    event = { methodName: '', action() {} },
  },
  dependencies,
) => {
  const dispatch = useDispatch();
  const connections = useSelector(connectionsSelector);

  let connection = connections[url];

  if (!connections[url]) {
    connection = new HubConnectionBuilder()
      .withUrl(url, options)
      .withAutomaticReconnect()
      .build();

    dispatch(addConnection(connection));
  }

  const connect = async newConnection => {
    if (newConnection.state === 'Disconnected') {
      await newConnection.start();
    }

    if (!Array.isArray(event)) {
      newConnection.off(event.methodName);
      newConnection.on(event.methodName, event.action);
    } else {
      event.forEach(e => {
        newConnection.off(e.methodName);
        newConnection.on(e.methodName, e.action);
      });
    }
  };

  useEffect(() => {
    connect(connection);

    return () => {
      if (!isGlobal) {
        connection.stop();
        dispatch(removeConnection(connection));
      }
    };
  }, dependencies);
};

export default useSignalR;
