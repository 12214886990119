import { analyticsMarketplaceAPI } from 'api';
import AMPurgeModal from 'components/layout/AMPurgeModal';
import React, { useState, useEffect } from 'react';
import { userTrackingType } from 'constants/analyticsmarketplace';

const usePurgeConfirm = ({ data, onRunModel }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [dataModel, setDataModel] = useState(null);

  const { getUserTracking, updateUserTracking } = analyticsMarketplaceAPI;

  const [trackingPurgeConfirm, setTrackingPurgeConfirm] = useState(false);

  useEffect(() => {
    getUserTracking({
      AnalyticDocumentId: data?.id,
      trackingType: userTrackingType.purgeConfirm,
    }).then(res => setTrackingPurgeConfirm(res.hasTrackingHistory));
  }, [data]);

  const onCheckNotifyPurgeConfirm = () => {
    updateUserTracking({
      AnalyticDocumentId: data?.id,
      trackingType: userTrackingType.purgeConfirm,
    });
  };

  const onCheckPurgeInformation = (...arg) => {
    if (trackingPurgeConfirm) {
      onRunModel(...arg);
    } else {
      setShowConfirmModal(true);
      setDataModel(arg);
    }
  };

  const onRunModalConfirm = (isPurgeConfirm, isRunModel) => {
    if (isPurgeConfirm) {
      onCheckNotifyPurgeConfirm();
    }
    if (isRunModel) {
      onRunModel(...dataModel);
      setDataModel(null);
    }
    setShowConfirmModal(prevState => !prevState);
  };

  const renderModal = () => (
    <AMPurgeModal
      isShow={showConfirmModal}
      pageTitle="Confirmation"
      modalTitle="Data Purge Confirmation"
      message="The document and personal information processed will not be stored by our system. All document processed and data will be purge immediately once output is generated."
      onClose={onRunModalConfirm}
    />
  );
  return { onCheckPurgeInformation, renderModal };
};

export default usePurgeConfirm;
