import React from 'react';

export const splitModelInput = str =>
  str
    .replaceAll(/Note:.*/g, '')
    .split(/-(?=(?:[^']*'[^']*')*[^']*$)/g)
    .filter(item => item.trim());

export const renderModelInput = input => {
  const modelInput = input ? input.split(/\s*[0-9]\s*\.\s*/g) : '';
  return (
    <div className="am-preview-subtab__local-files__text">
      <span className="requirement">{modelInput[0]}</span>
      <ol>
        {modelInput &&
          modelInput.slice(1).map(inp => {
            const [li, ...ul] = splitModelInput(inp);
            const note = inp.match(/Note:.*/g);
            return (
              <React.Fragment key={li}>
                <li>
                  <span className="order-item">{li}</span>
                  {ul.length > 0 && (
                    <ul>
                      {ul.map(bi => (
                        <li key={bi} className="un-order-item">
                          {bi}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
                {note && <div className="order-item">{note}</div>}
              </React.Fragment>
            );
          })}
      </ol>
    </div>
  );
};
