import { useCallback, useEffect, useState } from 'react';

const useQueryAPI = ({
  mutationFn,
  queryFn,
  queryParams,
  defaultData,
  onError,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState(defaultData);

  const handleMutation = useCallback(
    async ({ props, onError: onExecuteError, onSuccess: onExecuteSuccess }) => {
      setLoading(true);
      try {
        const queryData = await mutationFn(props);

        setData(queryData);
        onSuccess?.(queryData);
        onExecuteSuccess?.(queryData);
      } catch (queryError) {
        setError(queryError);
        onError?.(queryError);
        onExecuteError?.(queryError);
      } finally {
        setLoading(false);
      }
    },
    [mutationFn, onSuccess, onError, setData, setError, setLoading],
  );

  const handleQuery = useCallback(async () => {
    if (!queryFn) return;

    setLoading(true);
    try {
      const queryData = await queryFn(queryParams);

      setData(queryData);
      onSuccess?.(queryData);
    } catch (queryError) {
      setError(queryError);
      onError?.(queryError);
    } finally {
      setLoading(false);
    }
  }, [queryFn, queryParams]);

  useEffect(() => {
    handleQuery();
  }, [handleQuery]);

  return {
    mutation: handleMutation,
    loading,
    data,
    error,
  };
};

export default useQueryAPI;
