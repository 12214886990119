import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { FiClock } from 'react-icons/fi';

import { formatLocaleDateString } from '../../../services/date';

import Tooltip from '../../layout/Tooltip';

import './styles.scss';

const TimeStamp = ({ time, text, style, momentFormat, isDQ }) => (
  <div className="edh-timestamp" style={style}>
    {!isDQ ? (
      time && (
        <>
          <Tooltip placement="bottom" content="Last Updated in EDH Portal">
            <div className="edh-timestamp__icon">
              <FiClock style={{ marginRight: 2 }} />
            </div>
          </Tooltip>
          {`${text || ''} ${
            momentFormat
              ? moment(time).format(momentFormat)
              : formatLocaleDateString(time)
          }`}
        </>
      )
    ) : (
      <>
        <div className="edh-timestamp__icon">
          <FiClock style={{ marginRight: 2 }} />
        </div>
        {`${text || ''}`}
      </>
    )}
  </div>
);

TimeStamp.propTypes = {
  time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  text: PropTypes.string,
  momentFormat: PropTypes.string,
  style: PropTypes.object,
  isDQ: PropTypes.bool,
};

TimeStamp.defaultProps = {
  time: '',
  text: '',
  momentFormat: '',
  style: {},
  isDQ: false,
};

export default memo(TimeStamp);
