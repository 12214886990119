import React, { memo } from 'react';
import { PropTypes } from 'prop-types';

import RenderSample from './RenderSample';
import { getPreviewConfig } from './modelPreviewConfig';

const ModelPreview = ({ data }) => {
  const { title, sampleModelInput, sampleModelOutput } = data || {};
  const {
    renderSampleInput = sampleInput => (
      <RenderSample
        title="Sample Input"
        sample={sampleInput}
        multi={sampleInput?.length > 1}
      />
    ),
    renderSampleOutput = sampleOutput => (
      <RenderSample
        title="Sample Output"
        sample={sampleOutput}
        multi={sampleOutput?.length > 1}
      />
    ),
  } = getPreviewConfig(title);
  return (
    <>
      {renderSampleInput(sampleModelInput)}
      {renderSampleOutput(sampleModelOutput)}
    </>
  );
};

ModelPreview.propTypes = {
  data: PropTypes.any,
};
export default memo(ModelPreview);
