import React, { memo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { PropTypes } from 'prop-types';

import LoadingBalls from 'components/core/LoadingBalls';

import { ANALYTICS_MARKETPLACE_OFFLINE_SAMPLE_DOWNLOAD } from 'constants/paths';
import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';
import useToasts from 'hooks/useToasts';
import { offlineModelDownload } from 'services/download';
import settings from 'settings';

const { BASE_URL } = settings;

const OfflineTryModel = ({ data }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  const { onAddToastsMessage, onRemoveToastsMessage } = useToasts();
  const { updateAIModelBehavior } = useAnalyticsMarketplace();

  const callback = isSuccess => {
    if (isSuccess) {
      updateAIModelBehavior({
        analyticDocumentId: data.id,
        type: 1,
      });
    }
    const toastId = Date.now();
    setDownloadLoading(false);

    onAddToastsMessage({
      id: toastId,
      variant: isSuccess ? 'success' : 'danger',
      title: `Download is${isSuccess ? '' : ' Not'} Successful`,
      description: isSuccess ? 'This model is download successfully.' : '',
      buttonText: 'Okay',
      onClick() {
        onRemoveToastsMessage({ id: toastId });
      },
    });
  };

  const handleDownloadModel = () => {
    setDownloadLoading(true);

    offlineModelDownload({
      apiPath: `${BASE_URL}${ANALYTICS_MARKETPLACE_OFFLINE_SAMPLE_DOWNLOAD}`,
      fileName: `${data?.title}.zip`,
      payload: {
        modelName: data?.title,
      },
      callback,
    });
  };

  return (
    <>
      <p className="title">Download Model into Local Devices</p>
      <div>
        {downloadLoading ? <LoadingBalls isLoading /> : null}
        <Button
          variant="custom-primary"
          type="submit"
          onClick={handleDownloadModel}
        >
          Download Model
        </Button>
      </div>
    </>
  );
};

OfflineTryModel.propTypes = {
  data: PropTypes.any,
};

export default memo(OfflineTryModel);
