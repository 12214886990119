import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'analyticsMarketplace';
const initialState = {
  saveOptions: {
    data: {
      InteractiveModels: '',
      GenericModels: '',
      CitizenAnalyticsModels: '',
    },
    error: null,
  },
  savedFilter: {
    data: [],
    error: null,
  },
  filterOptions: {
    data: [],
    status: 'idle',
    error: null,
  },
  listAIModelOnline: {
    data: [],
    searchParams: { sort: -1, keywords: '', pageIndex: 1, pageSize: 6 },
    status: 'idle',
    error: null,
  },
  listAIModelOffline: {
    data: [],
    searchParams: { sort: -1, keywords: '', pageIndex: 1, pageSize: 6 },
    status: 'idle',
    error: null,
  },
  listAIModelGeneric: {
    data: [],
    searchParams: { sort: -1, keywords: '', pageIndex: 1, pageSize: 6 },
    status: 'idle',
    error: null,
  },
  aiModel: { data: {}, status: 'idle', error: null },
  behaviorModel: {},
  listUserReview: {
    data: [],
    status: 'idle',
    error: null,
  },
  getMyUserReview: {
    data: {},
    status: 'idle',
    error: null,
  },
  updateMyUserReview: {
    status: 'idle',
    error: null,
  },
  deleteMyUserReview: {
    status: 'idle',
    error: null,
  },
  postUserReview: {
    data: [],
    submitType: '',
    status: 'idle',
    error: null,
  },
  listUserReport: {
    data: [],
    status: 'idle',
    error: null,
  },
  listVersionHistory: {
    data: [],
    status: 'idle',
    error: null,
  },
  runModel: {
    result: {},
    status: 'idle',
    error: null,
  },
  isFirstAccess: false,
  downloadOfflineModel: {
    status: 'idle',
    error: '',
  },
  landingPageAM: {
    data: {},
    status: 'loading',
    error: null,
  },
  actionUserReview: {
    data: {},
    status: 'loading',
    error: null,
  },
  addToWorkspace: {
    loading: false,
    selectedId: [],
    isSuccess: false,
    error: null,
  },
  workspaceAM: {
    data: { items: [] },
    status: 'idle',
    error: null,
  },
  getPresetQuestions: {
    data: [],
    status: 'idle',
    error: null,
  },
  runCognitiveModel: {
    result: {},
    status: 'idle',
    error: null,
  },
  saveCognitiveModel: {
    result: {},
    status: 'idle',
    error: null,
  },
};

const analyticsMarketplaceSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getFilterOptionsRequest(state) {
      state.filterOptions.status = 'loading';
    },
    getFilterOptionsSuccess(state, action) {
      state.filterOptions.data = action.payload;
      state.filterOptions.status = 'succeeded';
    },
    getFilterOptionsFailure(state, action) {
      state.filterOptions.status = 'failed';
      state.filterOptions.data = [];
      state.filterOptions.error = action.payload;
    },
    getListAIModelRequest(state, action) {
      const { modelTypes, isGetMore } = action.payload;
      if (modelTypes.includes(0)) {
        state.listAIModelOnline.status = isGetMore ? 'loading-more' : 'loading';
        state.listAIModelOnline.searchParams = action.payload;
      }
      if (modelTypes.includes(1)) {
        state.listAIModelOffline.status = 'loading';
        state.listAIModelOffline.searchParams = action.payload;
      }
      if (modelTypes.includes(2)) {
        state.listAIModelGeneric.status = isGetMore
          ? 'loading-more'
          : 'loading';
        state.listAIModelGeneric.searchParams = action.payload;
      }
    },
    getListAIModelSuccess(state, action) {
      const { modelTypes, items, isGetMore } = action.payload;
      if (modelTypes.includes(0)) {
        state.listAIModelOnline.status = 'succeeded';
        if (isGetMore) {
          state.listAIModelOnline.data = {
            ...action.payload,
            items: [
              ...state.listAIModelOnline.data.items,
              ...items.filter(i => i.modelType === 'InteractiveModels'),
            ],
          };
        } else {
          state.listAIModelOnline.data = {
            ...action.payload,
            items: items.filter(i => i.modelType === 'InteractiveModels'),
          };
        }
      }
      if (modelTypes.includes(1)) {
        state.listAIModelOffline.status = 'succeeded';
        state.listAIModelOffline.data = {
          ...action.payload,
          items: items.filter(i => i.modelType === 'CitizenAnalyticsModels'),
        };
      }
      if (modelTypes.includes(2)) {
        state.listAIModelGeneric.status = 'succeeded';
        if (isGetMore) {
          state.listAIModelGeneric.data = {
            ...action.payload,
            items: [
              ...state.listAIModelGeneric.data.items,
              ...items.filter(i => {
                if (i.modelType === 'GenericModels') {
                  i.domain = [];
                  return true;
                }
                return false;
              }),
            ],
          };
        } else {
          state.listAIModelGeneric.data = {
            ...action.payload,
            items: items.filter(i => {
              if (i.modelType === 'GenericModels') {
                i.domain = [];
                return true;
              }
              return false;
            }),
          };
        }
      }
    },
    getListAIModelFailure(state, action) {
      const { modelTypes } = action.payload;
      if (modelTypes.includes(0)) {
        state.listAIModelOnline.status = 'failed';
        state.listAIModelOnline.data = null;
        state.listAIModelOnline.error = action.payload.message;
      }
      if (modelTypes.includes(1)) {
        state.listAIModelOffline.status = 'failed';
        state.listAIModelOffline.data = null;
        state.listAIModelOffline.error = action.payload.message;
      }
      if (modelTypes.includes(2)) {
        state.listAIModelGeneric.status = 'failed';
        state.listAIModelGeneric.data = null;
        state.listAIModelGeneric.error = action.payload.message;
      }
    },
    getAIModelRequest(state) {
      state.aiModel.status = 'loading';
    },
    getAIModelSuccess(state, action) {
      if (action.payload.modelType === 'GenericModels') {
        action.payload.domain = [];
      }
      state.aiModel.data = action.payload;
      state.aiModel.status = 'succeeded';
    },
    getAIModelFailure(state, action) {
      state.aiModel.status = 'failed';
      state.aiModel.data = null;
      state.aiModel.error = action.payload;
    },
    updateBehaviorAIModel(state, action) {
      const { id, usedCount, viewedCount } = action.payload;
      state.behaviorModel = {
        ...state.behaviorModel,
        [id]: { usedCount, viewedCount },
      };
    },
    setAiModel(state, action) {
      if (action.payload.modelType === 'GenericModels') {
        action.payload.domain = [];
      }
      state.aiModel.data = action.payload;
    },
    setSaveOptions(state, action) {
      state.saveOptions.data = { ...state.saveOptions.data, ...action.payload };
      state.saveOptions.status = 'succeeded';
    },
    setSavedFilter(state, action) {
      state.savedFilter.data = action.payload;
      state.savedFilter.status = 'succeeded';
    },
    setSearchKeyword(state, action) {
      const { modelTypes, keyword } = action.payload;
      if (modelTypes.includes(0)) {
        state.listAIModelOnline.searchParams.keywords = keyword;
      }
      if (modelTypes.includes(1)) {
        state.listAIModelOffline.searchParams.keywords = keyword;
      }
      if (modelTypes.includes(2)) {
        state.listAIModelGeneric.searchParams.keywords = keyword;
      }
    },
    getListUserReviewRequest(state, action) {
      state.listUserReview.status = action.payload.status || 'loading';
    },
    getListUserReviewSuccess(state, action) {
      state.listUserReview.data = action.payload;
      state.listUserReview.status = 'succeeded';
    },
    getListUserReviewFailure(state, action) {
      state.listUserReview.status = 'failed';
      state.listUserReview.data = null;
      state.listUserReview.error = action.payload;
    },
    getMyUserReviewRequest(state) {
      state.getMyUserReview.status = 'loading';
    },
    getMyUserReviewSuccess(state, action) {
      state.getMyUserReview.data = action.payload;
      state.getMyUserReview.status = 'succeeded';
    },
    getMyUserReviewFailure(state, action) {
      state.getMyUserReview.status = 'failed';
      state.getMyUserReview.data = {};
      state.getMyUserReview.error = action.payload;
    },
    updateMyUserReviewRequest(state) {
      state.updateMyUserReview.status = 'loading';
    },
    updateMyUserReviewSuccess(state) {
      state.updateMyUserReview.status = 'succeeded';
    },
    updateMyUserReviewFailure(state, action) {
      state.updateMyUserReview.status = 'failed';
      state.updateMyUserReview.error = action.payload;
    },
    deleteMyUserReviewRequest(state) {
      state.deleteMyUserReview.status = 'loading';
    },
    deleteMyUserReviewSuccess(state) {
      state.deleteMyUserReview.status = 'succeeded';
    },
    deleteMyUserReviewFailure(state, action) {
      state.deleteMyUserReview.status = 'failed';
      state.deleteMyUserReview.data = [];
      state.deleteMyUserReview.error = action.payload;
    },
    postUserReviewRequest(state, action) {
      state.postUserReview.status = 'loading';
      state.postUserReview.submitType = action.payload.submitType;
      state.postUserReview.data = [];
    },
    postUserReviewSuccess(state, action) {
      state.postUserReview.data = action.payload;
      state.postUserReview.status = 'succeeded';
    },
    postUserReviewFailure(state, action) {
      state.postUserReview.status = 'failed';
      state.postUserReview.data = [];
      state.postUserReview.error = action.payload;
    },
    postUserReviewClean(state) {
      state.postUserReview = initialState.postUserReview;
    },
    getListUserReportRequest(state, action) {
      // use fetching not loadingBalls
      state.listUserReport.status = action.payload.status || 'loading';
    },
    getListUserReportSuccess(state, action) {
      state.listUserReport.data = action.payload;
      state.listUserReport.status = 'succeeded';
    },
    getListUserReportFailure(state, action) {
      state.listUserReport.status = 'failed';
      state.listUserReport.data = null;
      state.listUserReport.error = action.payload;
    },

    getVersionHistoryRequest(state) {
      state.listVersionHistory.status = 'loading';
    },
    getVersionHistorySuccess(state, action) {
      state.listVersionHistory.data = action.payload;
      state.listVersionHistory.status = 'succeeded';
    },
    getVersionHistoryFailure(state, action) {
      state.listVersionHistory.status = 'failed';
      state.listVersionHistory.data = null;
      state.listVersionHistory.error = action.payload;
    },
    getFirstAccessRequest() {},
    getFirstAccessSuccess(state, action) {
      state.isFirstAccess = action.payload;
    },
    getFirstAccessFailure(state) {
      state.isFirstAccess = false;
    },
    runModelRequest(state) {
      state.runModel.status = 'loading';
      state.runModel.error = null;
    },
    runModelSuccess(state, action) {
      const { modelName, ...rest } = action.payload;
      state.runModel.result = { ...state.runModel.result, [modelName]: rest };
      state.runModel.status = 'succeeded';
    },
    runModelFailure(state, action) {
      state.runModel.status = 'failed';
      state.runModel.error = action.payload;
    },
    runSampleDataRequest(state) {
      state.runModel.status = 'loading';
      state.runModel.error = null;
    },
    runSampleDataSuccess(state, action) {
      const { modelName, ...rest } = action.payload;
      state.runModel.result = { ...state.runModel.result, [modelName]: rest };
      state.runModel.status = 'succeeded';
    },
    runSampleDataFailure(state, action) {
      state.runModel.status = 'failed';
      state.runModel.result = null;
      state.runModel.error = action.payload;
    },
    downloadOfflineModelRequest(state) {
      state.downloadOfflineModel.status = 'loading';
      state.downloadOfflineModel.error = '';
    },
    downloadOfflineModelSuccess(state) {
      state.downloadOfflineModel.status = 'succeeded';
      state.downloadOfflineModel.error = '';
    },
    downloadOfflineModelFailure(state, action) {
      state.downloadOfflineModel.status = 'failed';
      state.downloadOfflineModel.error = action.payload;
    },
    getTotalAIModelRequest(state) {
      state.landingPageAM.status = 'loading';
    },
    getTotalAIModelSuccess(state, action) {
      state.landingPageAM.data = action.payload;
      state.landingPageAM.status = 'succeeded';
    },
    getTotalAIModelFailure(state, action) {
      state.landingPageAM.status = 'failed';
      state.landingPageAM.error = action.payload;
    },
    doActionUserReviewRequest(state) {
      state.actionUserReview.status = 'loading';
    },
    doActionUserReviewSuccess(state, action) {
      state.actionUserReview.data = action.payload;
      state.actionUserReview.status = 'succeeded';
    },
    doActionUserReviewFailure(state, action) {
      state.actionUserReview.status = 'failed';
      state.actionUserReview.error = action.payload;
    },
    addToWorkspaceRequest(state, action) {
      state.addToWorkspace.isSuccess = false;
      state.addToWorkspace.error = null;
      state.addToWorkspace.loading = true;
      state.addToWorkspace.selectedId = action.payload.isRemoveFromWorkspace
        ? []
        : action.payload.id;
    },
    addToWorkspaceSuccess(state) {
      state.addToWorkspace.loading = false;
      state.addToWorkspace.isSuccess = true;
      state.addToWorkspace.error = null;
    },
    addToWorkspaceFailure(state) {
      state.addToWorkspace.loading = false;
      state.addToWorkspace.isSuccess = false;
      state.addToWorkspace.error = 'Failed';
    },
    getWorkspaceAMRequest(state) {
      state.workspaceAM.status = 'loading';
    },
    getWorkspaceAMSuccess(state, action) {
      state.workspaceAM.data = action.payload;
      state.workspaceAM.status = 'succeeded';
    },
    getWorkspaceAMFailure(state, action) {
      state.workspaceAM.status = 'failed';
      state.workspaceAM.data = [];
      state.workspaceAM.error = action.payload;
    },
    getPresetQuestionsRequest(state) {
      state.getPresetQuestions.status = 'loading';
    },
    getPresetQuestionsSuccess(state, action) {
      state.getPresetQuestions.data = action.payload;
      state.getPresetQuestions.status = 'succeeded';
    },
    getPresetQuestionsFailure(state, action) {
      state.getPresetQuestions.status = 'failed';
      state.getPresetQuestions.data = [];
      state.getPresetQuestions.error = action.payload;
    },
    runCognitiveModelRequest(state) {
      state.runCognitiveModel.status = 'loading';
      state.runCognitiveModel.error = null;
    },
    runCognitiveModelSuccess(state, action) {
      const { modelName, status, aiModelId, ...rest } = action.payload;
      state.runCognitiveModel.result = {
        ...state.runCognitiveModel.result,
        [modelName]: { aiModelId, ...rest },
      };
      if (aiModelId) {
        state.runCognitiveModel.status = status;
      }
    },
    runCognitiveModelFailure(state, action) {
      state.runCognitiveModel.status = 'failed';
      state.runCognitiveModel.error = action.payload;
    },
    saveCognitiveModelRequest(state) {
      state.saveCognitiveModel.status = 'loading';
      state.saveCognitiveModel.error = null;
    },
    saveCognitiveModelSuccess(state, action) {
      const { modelName, status, ...rest } = action.payload;
      state.saveCognitiveModel.result = {
        ...state.saveCognitiveModel.result,
        [modelName]: rest,
      };
      state.saveCognitiveModel.status = status;
    },
    saveCognitiveModelFailure(state, action) {
      state.saveCognitiveModel.status = 'failed';
      state.saveCognitiveModel.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = analyticsMarketplaceSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getFilterOptionsRequest,
  getFilterOptionsSuccess,
  getFilterOptionsFailure,
  getListAIModelRequest,
  getListAIModelSuccess,
  getListAIModelFailure,
  getAIModelRequest,
  getAIModelSuccess,
  getAIModelFailure,
  updateBehaviorAIModel,
  setAiModel,
  setSaveOptions,
  setSearchKeyword,
  getListUserReviewRequest,
  getListUserReviewSuccess,
  getListUserReviewFailure,
  getMyUserReviewRequest,
  getMyUserReviewSuccess,
  getMyUserReviewFailure,
  updateMyUserReviewRequest,
  updateMyUserReviewSuccess,
  updateMyUserReviewFailure,
  deleteMyUserReviewRequest,
  deleteMyUserReviewSuccess,
  deleteMyUserReviewFailure,
  postUserReviewRequest,
  postUserReviewSuccess,
  postUserReviewFailure,
  getListUserReportRequest,
  getListUserReportSuccess,
  getListUserReportFailure,
  postUserReviewClean,
  getVersionHistoryRequest,
  getVersionHistorySuccess,
  getVersionHistoryFailure,
  updateAIModelBehaviorRequest,
  runModelRequest,
  runModelSuccess,
  runModelFailure,
  runSampleDataRequest,
  runSampleDataSuccess,
  runSampleDataFailure,
  getFirstAccessFailure,
  getFirstAccessRequest,
  getFirstAccessSuccess,
  downloadOfflineModelRequest,
  downloadOfflineModelSuccess,
  downloadOfflineModelFailure,
  getTotalAIModelRequest,
  getTotalAIModelSuccess,
  getTotalAIModelFailure,
  doActionUserReviewRequest,
  doActionUserReviewSuccess,
  doActionUserReviewFailure,
  setSavedFilter,
  addToWorkspaceRequest,
  addToWorkspaceSuccess,
  addToWorkspaceFailure,
  getWorkspaceAMRequest,
  getWorkspaceAMSuccess,
  getWorkspaceAMFailure,
  getPresetQuestionsRequest,
  getPresetQuestionsSuccess,
  getPresetQuestionsFailure,
  runCognitiveModelRequest,
  runCognitiveModelSuccess,
  runCognitiveModelFailure,
  saveCognitiveModelRequest,
  saveCognitiveModelSuccess,
  saveCognitiveModelFailure,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};

export const filterOptionsSelector = createSelector(
  rootSelector,
  ({ filterOptions }) => filterOptions,
);

export const saveOptionsSelector = createSelector(
  rootSelector,
  ({ saveOptions }) => saveOptions,
);
export const savedFilterSelector = createSelector(
  rootSelector,
  ({ savedFilter }) => savedFilter,
);

export const listAIModelOnlineSelector = createSelector(
  rootSelector,
  ({ listAIModelOnline }) => listAIModelOnline,
);

export const listAIModelOfflineSelector = createSelector(
  rootSelector,
  ({ listAIModelOffline }) => listAIModelOffline,
);
export const listAIModelGenericSelector = createSelector(
  rootSelector,
  ({ listAIModelGeneric }) => listAIModelGeneric,
);

export const aiModelSelector = createSelector(
  rootSelector,
  ({ aiModel }) => aiModel,
);
export const getBehaviorModels = createSelector(
  rootSelector,
  ({ behaviorModel }) => behaviorModel,
);

export const listUserReviewSelector = createSelector(
  rootSelector,
  ({ listUserReview }) => listUserReview,
);

export const listUserReportSelector = createSelector(
  rootSelector,
  ({ listUserReport }) => listUserReport,
);

export const getMyUserReviewSelector = createSelector(
  rootSelector,
  ({ getMyUserReview }) => getMyUserReview,
);

export const postUserReviewSelector = createSelector(
  rootSelector,
  ({ postUserReview }) => postUserReview,
);

export const listVersionHistorySelector = createSelector(
  rootSelector,
  ({ listVersionHistory }) => listVersionHistory,
);

export const runModelSelector = createSelector(
  rootSelector,
  ({ runModel }) => runModel,
);

export const isFirstAccessSelector = createSelector(
  rootSelector,
  ({ isFirstAccess }) => isFirstAccess,
);

export const getTotalAIModelSelector = createSelector(
  rootSelector,
  ({ landingPageAM }) => landingPageAM,
);

export const getAIModelByNameSelector = createSelector(
  [rootSelector, (_, modelName) => modelName],
  (state, modelName) => state.runModel.result[modelName],
);

export const addToWorkspaceSelector = createSelector(
  rootSelector,
  ({ addToWorkspace }) => addToWorkspace,
);

export const workspaceAMSelector = createSelector(
  rootSelector,
  ({ workspaceAM }) => workspaceAM,
);

export const getPresetQuestionsSelector = createSelector(
  rootSelector,
  ({ getPresetQuestions }) => getPresetQuestions,
);

export const runCognitiveModelSelector = createSelector(
  rootSelector,
  ({ runCognitiveModel }) => runCognitiveModel,
);

export const saveCognitiveModelSelector = createSelector(
  rootSelector,
  ({ saveCognitiveModel }) => saveCognitiveModel,
);
