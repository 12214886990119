import React, { memo, useEffect, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { isEmpty, some } from 'lodash';
import { useForm } from 'react-hook-form';

import { ReactComponent as PlayRoundIcon } from 'assets/icons/common/play-round.svg';

import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';
import useToasts from 'hooks/useToasts';
import useDevice from 'hooks/useDevice';
import useQuery from 'hooks/useQuery';
import useAPI from 'hooks/useAPI';

import Expire from 'components/core/Expire';
import LoadingBalls from 'components/core/LoadingBalls';
import AMSelect from 'components/core/AMSelect';
import AMPurgeModal from 'components/layout/AMPurgeModal';
import Tooltip from 'components/layout/Tooltip';

import { analyticsMarketplaceAPI } from 'api';

import { userTrackingType } from 'constants/analyticsmarketplace';
import { ANALYTICS_MARKETPLACE_RUN_MODEL_BY_TEXT } from 'constants/paths';
import { getModelConfig, uploadFileMessages } from '../../constants';

import SampleDataInput from './SampleDataInput';
import SampleDataLocal from './SampleDataLocal';
import SampleDataPreview from './SampleDataPreview';
import SampleDataTemplateLocal from './SampleDataTemplateLocal';
import SampleDataTextBox from './SampleDataTextBox';
import SampleDataTemplate from './SampleDataTemplate';

import './styles.scss';

const renderSampleData = {
  preview: props => (
    <React.Fragment key={props.key}>
      <SampleDataPreview {...props} />
    </React.Fragment>
  ),
  template: props => (
    <React.Fragment key={props.key}>
      <SampleDataTemplate {...props} />
    </React.Fragment>
  ),
  input: props => (
    <React.Fragment key={props.key}>
      <SampleDataInput {...props} />
    </React.Fragment>
  ),
  text: props => (
    <React.Fragment key={props.key}>
      <SampleDataTextBox {...props} />
    </React.Fragment>
  ),
};

const TryModel = ({ data }) => {
  const [previewFiles, setPreviewFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [selectedSample, setSelectedSample] = useState({ key: '' });
  const [standardSamples, setStandardSamples] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedSampleList, setSelectedSampleList] = useState([]);
  const [sampleInput, setSampleInput] = useState('');

  const {
    aiModel,
    runModel,
    runSampleData,
    selectRunModel,
    updateAIModelBehavior,
  } = useAnalyticsMarketplace();
  const { query, onAddToQuery } = useQuery();

  const { onAddToastsMessage, onRemoveToastsMessage } = useToasts();
  const { isMobileDevice } = useDevice();
  const { data: dataTrySampleInput, onCallAPI } = useAPI();

  const { subModels, sampleParameters } = aiModel?.data || [];
  const { status } = selectRunModel;
  const {
    sampleTryModel,
    renderCustomTryModel,
    multiSample,
    limitUpload,
    runModelTooltip,
    showTooltip,
    requiredFiles,
    resultInput,
    sampleDataList,
    templates,
    formInput,
    typeFileUpload,
  } = getModelConfig(data?.title);

  const { getUserTracking, updateUserTracking } = analyticsMarketplaceAPI;

  const deviceType = isMobileDevice ? 2 : 1;

  const formSampleParameters = useMemo(() => {
    if (subModels?.length > 0) {
      const { sampleParameters: currentSampleParameters } =
        subModels.find(model => model.id === query.subModel) || subModels[0];

      return {
        parameters: currentSampleParameters
          ? JSON.stringify(currentSampleParameters)
          : undefined,
      };
    }

    return {
      parameters: sampleParameters
        ? JSON.stringify(sampleParameters)
        : undefined,
    };
  }, [sampleParameters, query.subModel, subModels]);

  const defaultFormValue = useMemo(
    () =>
      formInput
        ?.map(input => input?.name)
        ?.reduce((a, v) => ({ ...a, [v]: '' }), {}),
    [formInput],
  );
  const form = useForm({
    defaultValues: defaultFormValue,
  });

  const { watch } = form;
  const formValues = watch();
  const isEmptyForm = useMemo(
    () => !Object.values(formValues).some(x => x),
    [formValues],
  );

  const selectSubModelOption = useMemo(
    () =>
      subModels?.map(item => ({
        label: item.title?.split(' - ')[1],
        value: item.id,
      })) || [],
    [subModels],
  );

  useEffect(() => {
    if (data?.subModels?.length < 1 || data?.subModels === null) {
      const returnArr = data.sampleFiles.map((item, index) => ({
        key: item.fileName,
        file: {
          url: item.fileUrl,
          type: item.contentType,
        },
        modalName: !isEmpty(data) ? `${data.title} File ${index + 1}` : '',
      }));
      setStandardSamples(returnArr);
      setSelectedSample({ key: '' });
      setPreviewFiles([]);
      setSelectedSampleList([]);
    }
  }, [data]);

  const handleSetUploadMessage = isSucceed => {
    setUploadMessage({
      isSucceed,
      message: isSucceed ? '' : uploadFileMessages.fail,
    });
  };

  const onRunModel = () => {
    if (
      previewFiles.length ||
      selectedSample.key ||
      selectedSampleList.length
    ) {
      const callback = isSuccess => {
        const toastId = Date.now();

        onAddToastsMessage({
          id: toastId,
          variant: isSuccess ? 'success' : 'danger',
          title: `Model result ${
            isSuccess ? 'in progress to' : 'can not'
          } be generated`,
          description: isSuccess ? (
            <p>
              Your model result <strong>“{data.title}”</strong> still in
              progress.
            </p>
          ) : (
            <p>
              Please try run your model <strong>“{data.title}”</strong> again.
            </p>
          ),
          buttonText: 'Okay',
          onClick: () => onRemoveToastsMessage({ id: toastId }),
        });
      };
      updateAIModelBehavior({
        analyticDocumentId: data?.id,
        type: 1,
      });
      if (selectedSampleList.length) {
        runSampleData({
          modelName: data?.title,
          fileNames: selectedSampleList.map(file => file.key),
          analyticDocumentId: data?.id,
          deviceType,
          ...formSampleParameters,
          callback,
        });
      } else if (selectedSample.key) {
        runSampleData({
          modelName: data?.title,
          fileNames: [selectedSample.key],
          analyticDocumentId: data?.id,
          deviceType,
          ...formSampleParameters,
          callback,
        });
      } else {
        const formParameters = watch();
        runModel({
          modelName: data?.title,
          files: [...previewFiles],
          analyticDocumentId: data?.id,
          deviceType,
          parameters: isEmptyForm ? '' : JSON.stringify(formParameters),
          callback,
        });
      }
    }
  };

  const handleRunModelInput = text => {
    const { id } = data;

    getUserTracking({
      AnalyticDocumentId: id,
      trackingType: userTrackingType.purgeConfirm,
    }).then(res => {
      if (res.hasTrackingHistory) {
        onCallAPI({
          method: 'post',
          url: ANALYTICS_MARKETPLACE_RUN_MODEL_BY_TEXT,
          data: { id, text },
        });
      } else {
        setSampleInput(text);
        setShowConfirmModal(true);
      }
    });
  };

  const onCheckPurgeInformation = () => {
    getUserTracking({
      AnalyticDocumentId: data?.id,
      trackingType: userTrackingType.purgeConfirm,
    }).then(res => {
      if (res.hasTrackingHistory) {
        onRunModel();
      } else {
        setShowConfirmModal(true);
      }
    });
  };

  const onCheckNotifyPurgeConfirm = () => {
    updateUserTracking({
      AnalyticDocumentId: data?.id,
      trackingType: userTrackingType.purgeConfirm,
    });
  };

  const handleStoppedRunModel = () => {
    if (sampleTryModel === 'template') {
      const requiredPreviewFiles = previewFiles.filter(item => !item.optional);
      return (
        (requiredPreviewFiles.length < requiredFiles || isEmptyForm) &&
        !selectedSample.key &&
        selectedSampleList.length < requiredFiles
      );
    }
    return (
      previewFiles.length < requiredFiles &&
      !selectedSample.key &&
      selectedSampleList.length < requiredFiles
    );
  };

  const onRunModalConfirm = (isPurgeConfirm, isRunModel) => {
    if (isPurgeConfirm) {
      onCheckNotifyPurgeConfirm();
    }
    if (isRunModel) {
      if (sampleInput) {
        onCallAPI({
          method: 'post',
          url: ANALYTICS_MARKETPLACE_RUN_MODEL_BY_TEXT,
          data: { id: data.id, text: sampleInput },
        });
      } else {
        onRunModel();
      }
    }
    setShowConfirmModal(prevState => !prevState);
    setSampleInput('');
  };

  const onSelectSubModel = selectedModel => {
    if (selectedModel.value !== query?.subModel) {
      onAddToQuery({ ...query, subModel: selectedModel.value }, []);
      updateAIModelBehavior({
        analyticDocumentId: selectedModel.value,
        type: 0,
      });
    }
  };

  const onSetSelectedSample = selectSample => {
    if (multiSample) {
      if (some(selectedSampleList, ['key', selectSample.key])) {
        setSelectedSampleList(prevState =>
          prevState.filter(sample => sample.key !== selectSample.key),
        );
      } else {
        setSelectedSampleList(prevState => [
          ...new Map(
            [...prevState, selectSample].map(item => [item.key, item]),
          ).values(),
        ]);
      }
    } else {
      setSelectedSample(selectSample);
    }
  };

  const renderSampleDataGroup = () => {
    const disableSamplePreview = !!previewFiles.length || !isEmptyForm;
    return sampleDataList.map((preview, index) =>
      renderSampleData[preview.comp]({
        ...preview,
        key: index.toString(),
        disabled: disableSamplePreview,
        data: standardSamples,
        selectedSample: multiSample ? selectedSampleList : selectedSample,
        setSelectedSample: onSetSelectedSample,
        multiSample,
        content: sampleParameters,
        id: data.id,
        templates,
        resultInput,
        contentType: typeFileUpload,
        dataTrySampleInput,
        onRunModel: handleRunModelInput,
      }),
    );
  };

  const renderSampleDataLocal = type => {
    const disableUpload =
      previewFiles.length >= limitUpload ||
      selectedSample.key.length > 0 ||
      selectedSampleList.length > 0;
    const disableField =
      selectedSample.key.length > 0 || selectedSampleList.length > 0;
    if (type === 'template') {
      return (
        <SampleDataTemplateLocal
          form={form}
          data={data}
          templates={templates}
          previewFiles={previewFiles}
          setPreviewFiles={setPreviewFiles}
          setMessageUpload={handleSetUploadMessage}
          disableUpload={disableUpload}
          disableField={disableField}
        />
      );
    }
    return (
      <SampleDataLocal
        data={data}
        previewFiles={previewFiles}
        setPreviewFiles={setPreviewFiles}
        setMessageUpload={handleSetUploadMessage}
        disableUpload={disableUpload}
      />
    );
  };

  if (sampleTryModel === 'custom') {
    return renderCustomTryModel({ data });
  }

  return (
    <>
      <LoadingBalls isLoading={status === 'loading'} />
      <div
        className={`am-preview-subtab__model-panel ${
          sampleTryModel === 'template' && 'template-layout'
        }`}
      >
        <div className="am-preview-subtab__model-panel-sub">
          <div className="am-preview-subtab__model-title">
            Try model with sample data
          </div>
          <div className="am-preview-subtab__model-content">
            {renderSampleDataGroup()}
          </div>
        </div>
        <div className="am-preview-subtab__model-panel-sub">
          <div className="am-preview-subtab__model-title">
            Try model with your local files
          </div>
          {renderSampleDataLocal(sampleTryModel)}
        </div>
      </div>
      <div className="am-preview-subtab__local-files__footer">
        {uploadMessage && !uploadMessage.isSucceed ? (
          <div className="am-preview-subtab__local-files__footer__message-container">
            <Expire unmountAction={() => setUploadMessage(null)} delay={10000}>
              <p
                className={`am-preview-subtab__local-files__footer__message${
                  uploadMessage.isSucceed ? ' success' : ' error'
                }`}
              >
                {uploadMessage.message}
              </p>
            </Expire>
          </div>
        ) : null}
        {subModels && subModels.length > 0 && (
          <div className="am-preview-subtab__select-submodel">
            <AMSelect
              value={
                subModels.find(model => model.id === query?.subModel)?.id ||
                selectSubModelOption[0]?.value
              }
              options={selectSubModelOption}
              onSelect={onSelectSubModel}
            />
          </div>
        )}
        <Tooltip
          content={runModelTooltip}
          placement="top"
          id={`edh-try-btn-${Date.now()}`}
          disabled={showTooltip ? !handleStoppedRunModel() : true}
        >
          <div
            className="am-preview-subtab__local-files__footer__run-btn-wrapper"
            style={{
              cursor: handleStoppedRunModel() ? 'not-allowed' : 'pointer',
            }}
          >
            <button
              type="button"
              disabled={handleStoppedRunModel()}
              className="am-preview-subtab__local-files__footer__run-btn"
              onClick={onCheckPurgeInformation}
            >
              <PlayRoundIcon width={16} height={16} viewBox="0 0 20 20" />
              <p>Run Model</p>
            </button>
          </div>
        </Tooltip>
      </div>
      <AMPurgeModal
        isShow={showConfirmModal}
        pageTitle="Confirmation"
        modalTitle="Data Purge Confirmation"
        message="The document and personal information processed will not be stored by our system. All document processed and data will be purge immediately once output is generated."
        onClose={onRunModalConfirm}
      />
    </>
  );
};

TryModel.propTypes = {
  data: PropTypes.any,
};

export default memo(TryModel);
