import { useEffect, useRef, useState } from 'react';
import useClickOutside from './useClickOutside';
import useDevice from './useDevice';

const useHover = () => {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const { isMobileDevice } = useDevice();

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useClickOutside(ref, () => {
    if (isMobileDevice) {
      handleMouseOut();
    }
  });

  useEffect(() => {
    const node = ref.current;
    if (node) {
      if (isMobileDevice) {
        node.addEventListener('touchstart', handleMouseOver);
        return () => {
          node.removeEventListener('touchstart', handleMouseOver);
        };
      }

      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref.current, isMobileDevice]);
  return [ref, value, setValue];
};

export default useHover;
