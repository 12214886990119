import React, { Fragment, memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from 'assets/icons/common/close.svg';

import Button from 'components/core/Button';
import PopupGroup from '../PopupGroup';

import '../styles.scss';

const customRender = (value, props, customFunction, HTMLTag = 'h3') => {
  const element = <HTMLTag {...props}>{value}</HTMLTag>;

  if (customFunction) {
    return customFunction({
      value,
      props,
      element,
    });
  }

  return element;
};

const generateElementWrapper = onSubmit => {
  if (!onSubmit) {
    return {
      Component: Fragment,
      props: {},
    };
  }

  return {
    Component: 'form',
    props: { onSubmit },
  };
};

const PopupWrapper = ({
  children,
  className,
  category,
  title,
  actions,
  actionsFooter,
  onHide,
  onFormSubmit,
  renderCategory,
  renderTitle,
}) => {
  const classes = ['edh-popup'];

  if (className) {
    classes.push(className);
  }

  const categoryElement = customRender(
    category,
    {
      className: 'edh-popup__category',
    },
    renderCategory,
    'h3',
  );

  const titleElement = customRender(
    title,
    {
      className: 'edh-popup__title',
    },
    renderTitle,
    'h2',
  );

  const renderActions = useMemo(() => {
    if (!actions.length) {
      return null;
    }

    return (
      <PopupGroup>
        <div className="edh-popup__btn-group">
          {actions.map(({ id, children: actionElement, ...rest }) => (
            <Button key={id} {...rest}>
              {actionElement}
            </Button>
          ))}
        </div>
      </PopupGroup>
    );
  }, [actions]);

  const renderActionsFooter = useMemo(() => {
    if (!actionsFooter.length) {
      return null;
    }

    return (
      <PopupGroup>
        <div className="edh-popup__btn-group edh-popup__btn-group--full footer">
          {actionsFooter.map(({ id, children: actionElement, ...rest }) => (
            <Button key={id} {...rest}>
              {actionElement}
            </Button>
          ))}
        </div>
      </PopupGroup>
    );
  }, [actionsFooter]);

  const elementWrapper = generateElementWrapper(onFormSubmit);

  return (
    <div className={classes.join(' ')}>
      <header className="edh-popup__header">
        <PopupGroup>
          {categoryElement}
          <button
            className="edh-popup__close-btn"
            type="button"
            onClick={onHide}
            aria-label="Close button"
          >
            <CloseIcon />
          </button>
        </PopupGroup>
        <PopupGroup>
          {titleElement}
          {renderActions}
        </PopupGroup>
      </header>
      <elementWrapper.Component {...elementWrapper.props}>
        {children}
        {renderActionsFooter}
      </elementWrapper.Component>
    </div>
  );
};

PopupWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  category: PropTypes.string.isRequired,
  title: PropTypes.string,
  actions: PropTypes.array,
  actionsFooter: PropTypes.array,
  onHide: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func,
  renderCategory: PropTypes.func,
  renderTitle: PropTypes.func,
};

PopupWrapper.defaultProps = {
  title: '',
  actions: [],
  actionsFooter: [],
};

export default memo(PopupWrapper);
