import { useCallback } from 'react';

import useUser from './useUser';

const useTakeEventGA = () => {
  const { userInfo } = useUser();

  const { adId, department } = userInfo.data;

  const handleSendUserLogin = useCallback(() => {
    window.dataLayer.push({
      event: 'Login',
      uid: adId,
    });
  }, [adId]);

  const handleSendPageAccess = useCallback(
    ({ pageTitle, pageLocation, pagePath }) => {
      window.dataLayer.push({
        event: 'page_view',
        page_title: pageTitle,
        page_location: pageLocation,
        page_path: pagePath,
        uid: adId,
      });
    },
    [adId],
  );

  const handleSendTrackingSpendTime = useCallback(
    ({ page, time, pagePath }) => {
      window.dataLayer.push({
        event: 'spend_time',
        page_title: page,
        value: `${adId} - ${time}`,
        page_path: pagePath,
      });
    },
    [adId],
  );

  const handleSendAddNewDatasetRequest = useCallback(
    name => {
      window.dataLayer.push({
        event: 'add_new_dataset_request',
        name,
        uid: adId,
      });
    },
    [adId],
  );

  const handleSendOpenDataset = useCallback(
    title => {
      window.dataLayer.push({
        event: 'open_dataset',
        uid: adId,
        name: title,
      });
    },
    [adId],
  );

  const handleSendCreateEntitlement = useCallback(
    name => {
      window.dataLayer.push({
        event: 'create_entitlement',
        uid: adId,
        name,
      });
    },
    [adId],
  );

  const handleSendExportDataset = useCallback(
    name => {
      window.dataLayer.push({
        event: 'export_dataset',
        uid: adId,
        name,
      });
    },
    [adId],
  );

  const handleSendPowerBIViewCount = useCallback(
    name => {
      window.dataLayer.push({
        event: 'power-bi_view_count',
        name,
        uid: adId,
      });
    },
    [adId],
  );

  const handleSendUCViewCount = useCallback(
    name => {
      window.dataLayer.push({
        event: 'use-case_view_count',
        name,
        uid: adId,
      });
    },
    [adId],
  );

  const handleSendAccessJAI = useCallback(() => {
    window.dataLayer.push({
      event: 'J.AI_FROM_DATA_PLUS',
      value: `${adId}-${department}`,
    });
  }, [adId, department]);

  return {
    onSendUserLogin: handleSendUserLogin,
    onSendAddNewDatasetRequest: handleSendAddNewDatasetRequest,
    onSendOpenDataset: handleSendOpenDataset,
    onSendCreateEntitlement: handleSendCreateEntitlement,
    onSendExportDataset: handleSendExportDataset,
    onSendPowerBIViewCount: handleSendPowerBIViewCount,
    onSendUCViewCount: handleSendUCViewCount,
    onSendPageAccess: handleSendPageAccess,
    onSendTrackingSpendTime: handleSendTrackingSpendTime,
    onSendAccessJAI: handleSendAccessJAI,
  };
};

export default useTakeEventGA;
