import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import useWatermarkImage from 'features/AM/hooks/useWatermarkImage';

const ImageComponent = ({ loading, src = '', errorMessage = '', caption }) => {
  const {
    isDownload = false,
    downloadImage = () => null,
    renderImage,
  } = useWatermarkImage({
    prefix: 'sample-data',
    src: loading || errorMessage ? '' : src,
    loading,
    caption,
  });
  return (
    <>
      {renderImage()}
      {errorMessage && (
        <div className="sample-data__error-message">{errorMessage}</div>
      )}
      {!errorMessage && isDownload && (
        <Button
          className="sample-data__action-button download-button"
          variant="custom-primary"
          onClick={() => downloadImage('download')}
        >
          Download
        </Button>
      )}
    </>
  );
};

ImageComponent.propTypes = {
  errorMessage: PropTypes.string,
  caption: PropTypes.string,
  loading: PropTypes.any,
  src: PropTypes.string,
};

export default ImageComponent;
