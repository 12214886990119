import apm from '@elastic/apm-rum';

const HOSTNAME = 'localhost';
const SERVICE_NAME = 'DataPlus FE';
const SERVER_URL = 'https://prd.apm.petronas.com:443';
const { ENV = 'Development', BASE_URL } = process.env;

const initialize = () => {
  apm({
    serviceName: SERVICE_NAME,
    serverUrl: SERVER_URL,
    environment: ENV,
    distributedTracingOrigins: [BASE_URL?.slice(0, -1)],
    propagateTracestate: true,
    active: window.location.hostname !== HOSTNAME,
  });
};

const elastic = {
  initialize,
};

export default elastic;
