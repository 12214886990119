import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';

import { SUBTAB_ID } from '../constants';

import './styles.scss';

const AMTabs = ({ tabs, onClick, defaultTab, detail }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const { selectRunModel } = useAnalyticsMarketplace();
  const { result } = selectRunModel;

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    if (result && result.files && result.files.length) {
      setActiveTab(SUBTAB_ID.PREVIEW_AND_TRY);
    }
  }, [result]);

  const onClickTab = useCallback(
    (event, tab) => {
      event.preventDefault();
      setActiveTab(tab.id);
      const element = document.getElementById(tab.id);
      element.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      if (typeof onClick === 'function') {
        onClick(tab);
      }
    },
    [onClick],
  );

  const renderTabContent = () => {
    const tabData = tabs.find(tab => tab.id === activeTab);
    return <tabData.component detail={detail} />;
  };

  return (
    <div className="am-nav-tabs">
      <div className="am-nav-tabs__item">
        {tabs.map(tab => (
          <div
            id={tab.id}
            key={tab.id}
            className={['am-nav-tabs__name', tab.id === activeTab && 'active']
              .filter(Boolean)
              .join(' ')}
            title={tab.name}
            role="button"
            tabIndex={-1}
            onClick={event => onClickTab(event, tab)}
          >
            {tab.name}
          </div>
        ))}
      </div>
      <div className="am-nav-tabs__content">{renderTabContent()}</div>
    </div>
  );
};

AMTabs.propTypes = {
  detail: PropTypes.any,
  tabs: PropTypes.array,
  onClick: PropTypes.func,
  defaultTab: PropTypes.string,
};

export default memo(AMTabs);
