import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { ID_TOKEN } from '../../../constants/storageKeys';

import { gettingNameInitials } from '../../../services/string';
import { arrayBufferToBase64 } from '../../../services/file';
import { getStorage, setStorage } from '../../../services/storage';
import { validateURL } from '../../../services/uri';

import './styles.scss';

const Avatar = ({
  icon,
  name,
  email,
  src,
  shape,
  size,
  className,
  style,
  children,
}) => {
  const [imgSource, setImgSource] = useState('');
  const shapeStyledClass = {
    circle: 'edh-avatar--circle',
    square: 'edh-avatar--square',
  };
  const sizeStyledClass = {
    default: 'edh-avatar--default',
    large: 'edh-avatar--lg',
    medium: 'edh-avatar--md',
    sm: 'edh-avatar--sm',
    xl: 'edh-avatar--xl',
  };
  const styledClass = [
    'edh-avatar',
    shapeStyledClass[shape],
    sizeStyledClass[size],
    className,
  ];

  useEffect(() => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    if (email) {
      const avatarStorage = getStorage('avatar')?.[email];
      if (avatarStorage !== undefined) {
        if (avatarStorage) {
          setImgSource(`data:image/jpeg;base64,${avatarStorage}`);
        }
      }
    }

    if (src) {
      let dataAvatar;
      if (validateURL(src)) {
        const loadImgSource = async () => {
          try {
            const data = await axios(src, {
              cancelToken: source.token,
              method: 'GET',
              headers: {
                Authorization: `Bearer ${getStorage(ID_TOKEN) || ''}`,
              },
              responseType: 'arraybuffer',
            }).then(res => arrayBufferToBase64(res?.data));

            if (data && !unmounted) {
              setImgSource(`data:image/jpeg;base64,${data}`);
              dataAvatar = data;
            }
          } catch (error) {
            if (!unmounted) {
              setImgSource('');
            }
          }
        };

        loadImgSource();
      } else {
        setImgSource(`data:image/jpeg;base64,${src}`);
        dataAvatar = src;
      }

      setStorage({
        key: 'avatar',
        val: {
          ...getStorage('avatar'),
          [email]: dataAvatar || null,
        },
      });
    }

    return () => {
      unmounted = true;
      setImgSource('');
      source.cancel('Cancelling in cleanup');
    };
  }, [src, email]);

  let avatarDisplay = (
    <span
      className="edh-avatar--string"
      style={{
        transform: 'scale(1) translateX(-50%)',
      }}
    >
      {children}
    </span>
  );

  if (icon) {
    avatarDisplay = (
      <span>
        <img alt="Avatar Icon" src={icon} />
      </span>
    );
    styledClass.push('edh-avatar--icon');
  } else if (imgSource) {
    avatarDisplay = <img alt="User Avatar" src={imgSource} />;
    styledClass.push('edh-avatar--image');
  } else if (name) {
    avatarDisplay = <span>{gettingNameInitials(name)}</span>;
    styledClass.push('edh-avatar--string');
  }

  return (
    <span className={styledClass.join(' ')} style={style}>
      {avatarDisplay}
    </span>
  );
};

Avatar.propTypes = {
  icon: PropTypes.any,
  style: PropTypes.any,
  children: PropTypes.any,
  src: PropTypes.string,
  shape: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
};

Avatar.defaultProps = {
  icon: null,
  src: '',
  shape: 'circle',
  size: 'default',
  className: '',
  style: {},
  children: '',
  name: '',
  email: '',
};

export default memo(Avatar);
