import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connectionsSelector } from 'slices/signalr';

import { useIdleTimer } from 'react-idle-timer';

const IdleTimer = () => {
  const connections = useSelector(connectionsSelector);

  const onIdle = () => {
    Object.values(connections).forEach(item => item.stop());
  };

  const onActive = () => {
    history.go(0);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: 900_000,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      getRemainingTime();
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return Fragment;
};

export default IdleTimer;
