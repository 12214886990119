import { call, put, takeEvery } from 'redux-saga/effects';

import { putData } from '../services/api';

import { FAVOURITE_DOMAIN } from '../constants/paths';

import {
  putFavouriteDomainRequest,
  putFavouriteDomainSuccess,
  putFavouriteDomainFailure,
} from '../slices/favourite';

export function* putFavouriteDomainAction({ payload }) {
  const { callback, ...formData } = payload;
  try {
    const {
      data: { data },
    } = yield call(putData, {
      url: FAVOURITE_DOMAIN,
      data: formData,
    });

    yield put(putFavouriteDomainSuccess(data));

    if (callback) {
      callback();
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(putFavouriteDomainFailure(message));
  }
}

export default function* favouriteSaga() {
  yield takeEvery(putFavouriteDomainRequest().type, putFavouriteDomainAction);
}
