import { ADD_FEEDBACK, SURVEY } from 'constants/paths';
import { postData } from 'services/api';
import delay from 'services/api/delay';

export const sendFeedback = (category, description) => {
  const data = {
    category,
    description,
  };
  const url = ADD_FEEDBACK;

  return postData({ url, data });
};

export const sendFeedbackSurvey = async data => {
  const url = SURVEY;

  const response = await delay(postData({ url, data }), 1000);
  return response;
};
