export const TOAST_MESSAGES = {
  addNewRequest: {
    title: 'New Dataset Request has been submitted',
    description:
      'Your data request form has successfully been submitted for approval. Please wait while it is being reviewed.',
  },
  entitlementRequest: {
    title: 'Entitlement Request has been submitted',
    description:
      'Your entitlement request has successfully been submitted. Please wait while it is being reviewed.',
  },
  changeCurrentRequest: {
    title: 'Change Current Dataset has been submitted',
    description:
      'Your dataset request has successfully been submitted for approval. Please wait while it is being reviewed.',
  },
  extensionEntitlementRequest: {
    title: 'Dataset Entitlement Extension Request has been submitted',
    description:
      'Your data request form has successfully been submitted for approval. Please wait while it is being reviewed.',
  },
};

export const CONFIRM_MESSAGES = {
  addNewRequest: {
    submit: {
      title: 'Submit Add New Dataset Request',
      description:
        'You are about to submit Add New Dataset request form. Are you sure you want to continue?',
    },
    cancel: {
      title: 'Cancel Add New Dataset Request',
      description:
        'You are about to cancel submitted Add New Dataset request. Please note that this action will be permanent and all data will be removed. Are you sure you want to continue?',
    },
  },
  entitlementRequest: {
    submit: {
      title: 'Submit Dataset Entitlement Request',
      description:
        'You are about to submit Dataset Entitlement request form. Are you sure you want to continue?',
    },
    cancel: {
      title: 'Cancel Dataset Entitlement Request',
      description:
        'You are about to cancel submitted Dataset Entitlement request. Please note that this action will be permanent and all data will be removed. Are you sure you want to continue?',
    },
  },
  changeCurrentRequest: {
    submit: {
      title: 'Submit Change Current Dataset Request',
      description:
        'You are about to submit Change Current Dataset request form. Are you sure you want to continue?',
    },
    cancel: {
      title: 'Cancel Change Current Dataset Request',
      description:
        'You are about to cancel submitted Change Current Dataset request. Please note that this action will be permanent and all data will be removed. Are you sure you want to continue?',
    },
  },
  extensionEntitlementRequest: {
    submit: {
      title: 'Submit Dataset Entitlement Extension Request',
      description:
        'You are about to submit Dataset Entitlement Extension request form. Are you sure you want to continue?',
    },
    cancel: {
      title: 'Cancel Dataset Entitlement Extension Request',
      description:
        'You are about to cancel submitted Dataset Entitlement Extension request. Please note that this action will be permanent and all data will be removed. Are you sure you want to continue?',
    },
  },
  dtcDataset: {
    edit: {
      title: 'Request has been submitted',
      description: [
        'Your request to update new details on the dataset has been successfully submitted.',
        'Please wait while it is being reviewed.',
      ],
    },
  },
  requestAccess: {
    submit: {
      title: 'Successfully Submitted!',
      description:
        'Your request has successfully submitted. Our team will contact you later. Thanks!',
    },
  },
};

export const CANCELLED_REQUEST_MESSAGE = {
  category: 'My Requested Data',
  title: 'Cancelled Request',
  description:
    'You are about to cancel an action item. Please note that this action will be permanent and all data will be removed. Are you sure you want to continue?',
};

export const DELETE_NEWSFEED_MESSAGE = {
  category: 'NEWSFEED',
  title: 'Delete Newsfeed',
  description:
    'You are about to delete this newsfeed. Please note that this action will be permanent and all data will be removed. Are you sure you want to continue?',
};

export const NEWSFEED = {
  CREATE: {
    title: 'Your newsfeed has been created!',
    description:
      'This newsfeed has been created and save as draft. You can publish this newsfeed by clicking the details of newsfeed!',
  },
  PUBLISH: {
    title: 'Your newsfeed has been published!',
    description: 'Congratulations, this newsfeed is now visible to all users.',
  },
};
