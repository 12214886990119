import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import './styles.scss';
import classnames from 'services/classnames';

const AMSelect = ({
  Icon,
  value,
  defaultLabel,
  align,
  variant,
  options,
  disabled,
  onSelect,
}) => {
  const [selectItem, setSelectItem] = useState({});

  const handleOnSelect = optionValue => {
    const option = options.find(item => item.value === optionValue);
    setSelectItem(option);
    onSelect(option);
  };

  useEffect(() => {
    if (value || value === null) {
      const option = options.find(item => item.value === value);
      setSelectItem(
        option || {
          value,
          label: value,
        },
      );
    }
  }, [value]);

  return (
    <Dropdown
      className="edh-am-dropdown-button"
      onSelect={handleOnSelect}
      align={align}
    >
      <Dropdown.Toggle variant={variant} align={align} disabled={disabled}>
        <div className="edh-am-dropdown-button__title-group">
          {Icon && <Icon className="edh-am-dropdown-button__icon" />}
          <span
            className={classnames({
              'edh-am-dropdown-button__placeholder': !selectItem.label,
            })}
          >
            {selectItem.label || defaultLabel}
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map(item => (
          <Dropdown.Item
            key={item.value}
            className={[
              selectItem.value === item.value ? 'selected-item' : '',
              item.className,
            ].join(' ')}
            eventKey={item.value}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

AMSelect.propTypes = {
  Icon: PropTypes.node,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  defaultLabel: PropTypes.string,
  align: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func,
};

AMSelect.defaultProps = {
  value: '',
  defaultLabel: '',
  align: '',
  variant: '',
  disabled: false,
  options: [],
  onSelect() {},
};

export default memo(AMSelect);
