import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { convertObjectToParams } from '../services/uri';

const useQuery = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const query = useMemo(() => {
    const data = {};
    for (const [key, value] of new URLSearchParams(search)) {
      data[key] = value;
    }

    return data;
  }, [search]);

  const onAddToQuery = useCallback(
    (queryObj, inValidValueExpect = []) => {
      history.replace({
        search: convertObjectToParams(
          { ...query, ...(queryObj || {}) },
          inValidValueExpect,
        ),
      });
    },
    [query],
  );

  const onRemoveQuery = useCallback(
    key => {
      if (!key) return false;
      const list = !Array.isArray(key) ? [key] : key;
      list.forEach(item => {
        delete query[item];
      });

      history.replace({
        search: convertObjectToParams(query),
      });
    },
    [query],
  );

  return { query, pathname, onAddToQuery, onRemoveQuery };
};

export default useQuery;
