import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import classnames from 'services/classnames';

import './styles.scss';

const Button = ({
  children,
  loading,
  disabled,
  className,
  type,
  color,
  variant,
  href,
  style,
  onClick,
  ...rest
}) => {
  let Comp = 'button';

  if (href) {
    Comp = 'a';
  }

  const props = {
    disabled: loading || disabled,
    type: Comp === 'button' ? type : null,
    href,
    style,
    onClick,
    ...rest,
  };

  return (
    <Comp
      {...props}
      className={classnames('edh-btn', color, variant, className, {
        disabled,
        loading,
      })}
    >
      {loading ? <AiOutlineLoading3Quarters /> : children}
    </Comp>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  color: PropTypes.oneOf(['primary', 'secondary', 'danger']),
  variant: PropTypes.oneOf(['default', 'contained', 'outlined']),
  style: PropTypes.object,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  type: 'button',
  color: 'primary',
  variant: 'default',
  style: {},
  onClick() {},
};

export default memo(Button);
