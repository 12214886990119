import { createSelector, createSlice } from '@reduxjs/toolkit';

export const ROOT_STATE_NAME = 'tour';
const initialState = {
  landingPageRunTour: false,
};

const tourSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    changeLandingPageRunTour(state, action) {
      state.landingPageRunTour = action.payload;
    },
  },
});

export const { changeLandingPageRunTour } = tourSlice.actions;
export default tourSlice.reducer;

const rootSelector = state => state[ROOT_STATE_NAME];
export const landingPageRunTourSelector = createSelector(
  rootSelector,
  state => state.landingPageRunTour,
);
