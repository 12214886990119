import PropTypes from 'prop-types';
import React, { Fragment, memo, useCallback, useMemo } from 'react';

import { isEmpty } from 'lodash';
import { splitModelInput } from 'services/am-utils';
import './styles.scss';

const AMOverviewTab = ({ detail }) => {
  const modelInput = useMemo(
    () =>
      detail?.modelInput
        ? detail.modelInput.replaceAll(/\s\s+/g, ' ').split(/ [0-9]\. /g)
        : '',
    [detail],
  );

  const modelOutput = useMemo(
    () => (detail?.modelOutput ? detail.modelOutput.split(/[0-9]\. /g) : ''),
    [detail],
  );

  const overviewData = useMemo(
    () => [
      { title: 'Model Description', data: detail?.description },
      {
        title: 'Model Input',
        data: modelInput[0],
        nested: modelInput.slice(1),
      },
      {
        title: 'Model Output',
        data: modelOutput[0],
        nested: modelOutput.slice(1),
      },
      { title: 'Model Value', data: detail?.modelValue },
      { title: 'Model Labelling', data: detail?.tags?.text?.join(', ') },
      { title: 'Model Language (Source Code)', data: detail?.modelLanguages },
      { title: 'Model Domain', data: detail?.domain?.text?.join(', ') },
    ],
    [detail, modelInput, modelOutput],
  );

  const nestedDescription = useCallback(
    data =>
      data.map((input, i) => {
        const breakInput = splitModelInput(input);
        const noteInput = input.match(/Note:.*/g);

        return (
          <Fragment key={breakInput[0]}>
            <p key={breakInput[0]} className="am-tab-overview__model__desc">
              {`\xa0${i + 1}. ${breakInput[0]}`}
            </p>
            {breakInput.slice(1).map(bi => (
              <p key={bi} className="am-tab-overview__model__desc--child">
                - {bi}
              </p>
            ))}
            {noteInput?.length && (
              <>
                <p className="am-tab-overview__model__desc--child">
                  {noteInput[0].split(/- /g)[0]}
                </p>
                {noteInput[0].includes('- ') &&
                  noteInput[0]
                    .split(/- /g)
                    .slice(1)
                    .map(item => (
                      <p className="am-tab-overview__model__desc--child">
                        - {item}
                      </p>
                    ))}
              </>
            )}
          </Fragment>
        );
      }),
    [],
  );

  return (
    <div className="am-tab-overview">
      <div className="am-tab-overview__model">
        {overviewData.map(({ title, data, nested = [] }) => {
          if (isEmpty(data)) {
            return null;
          }
          return (
            <div key={title} className="am-tab-overview__model__row">
              <p className="am-tab-overview__model__title">{title}</p>
              <p className="am-tab-overview__model__desc">{data}</p>
              {nested.length ? nestedDescription(nested) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

AMOverviewTab.propTypes = { detail: PropTypes.object };

export default memo(AMOverviewTab);
