import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';

import useDataset from 'hooks/useDataset';
import { DOWNLOAD_CATEGORY } from 'constants/exportCategory';
import Download from '../../../assets/icons/common/download.svg';

import { SUCCESSFUL } from '../../../constants/status';

import TimeStamp from '../../core/TimeStamp';
import DownloadStatus from '../../core/DownloadStatus';
import Tooltip from '../Tooltip';

import { exportDownload } from '../../../services/download';

import './styles.scss';

const ExportHistoryItem = ({ file }) => {
  const { onIncreaseDownloadAllDataOption } = useDataset();

  const onExportData = exportDownload();

  const idTooltip = `export-${file.id}-${Date.now()}`;
  return (
    <div className="edu-history-export-item">
      <div className="edu-history-export-item__status">
        <DownloadStatus status={file.status} />
        {file?.status?.toUpperCase() === SUCCESSFUL && (
          <Tooltip content="Click to download file to local PC">
            <Button
              onClick={() =>
                onExportData({
                  item: file,
                  callback:
                    file.category === DOWNLOAD_CATEGORY.dataset
                      ? () => onIncreaseDownloadAllDataOption(file.id)
                      : false,
                })
              }
            >
              <img src={Download} alt="Icon download" />
            </Button>
          </Tooltip>
        )}
      </div>
      <div className="edu-history-export-item__name">
        <Tooltip content={file.fileName} id={idTooltip}>
          <p id={idTooltip}>{file.fileName}</p>
        </Tooltip>
      </div>
      <div className="edu-history-export-item__date">
        <div className="edu-history-export-item__type">{file.fileType}</div>
        <TimeStamp time={file.createdAt} />
        {file.completedAt && <TimeStamp time={file.completedAt} />}
      </div>
    </div>
  );
};

ExportHistoryItem.propTypes = {
  file: PropTypes.object,
};

export default memo(ExportHistoryItem);
