import _ from 'lodash';

export const convertParamsToObject = queryString => {
  if (!queryString) {
    return {};
  }

  const arr = queryString.slice(1).split(/&|=/);
  const params = {};

  for (let i = 0; i < arr.length; i += 2) {
    const key = arr[i];
    const value = arr[i + 1];
    params[key] = value;
  }

  return params;
};

export const convertObjectToParams = (obj, inValidValueExpect = ['']) => {
  if (_.isEmpty(obj)) {
    return '';
  }

  const params = [];

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      value.forEach(item => {
        const keyValue = Object.keys(item)[0];
        params.push(`${keyValue}=${item[keyValue]}`);
      });
    } else if (
      !['', undefined, 'undefined', null, 'null']
        .filter(item => !inValidValueExpect.includes(item))
        .includes(value)
    ) {
      params.push(`${key}=${value}`);
    }
  }

  return `?${params.join('&')}`;
};

export const validateURL = link => {
  if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
    return true;
  }
  return false;
};

export const removeParamsInSearch = (search, list) => {
  const params = new URLSearchParams(search);
  list.forEach(item => params.delete(item));
  return params;
};
