import { useState, useEffect, useRef } from 'react';

const useCountDown = ({ count = 3, timeout = 1000, dependencies }) => {
  const [countNumber, setCountNumber] = useState();
  const countRef = useRef();

  useEffect(() => {
    setCountNumber(count);
  }, [dependencies]);

  useEffect(() => {
    countRef.current = setInterval(() => {
      setCountNumber(prevState => prevState - 1);
    }, timeout);

    return () => {
      clearInterval(countRef.current);
    };
  }, [dependencies]);

  useEffect(() => {
    if (countNumber === 0) {
      clearTimeout(countRef.current);
    }
  }, [countNumber]);

  return countNumber;
};

export default useCountDown;
