import { useState } from 'react';
import { useDidMount } from 'hooks/useDidMount';
import { useDidUpdate } from 'hooks/useDidUpdate';

const useCommentData = ({ data = [], mentionId = '5' }) => {
  const [listData, setListData] = useState([]);
  const [hasNewer, setHasNewer] = useState(false);
  const [hasOlder, setHasOlder] = useState(false);
  const [currentComment, setCurrentComment] = useState({ from: 0, to: 1 });
  const total = data.length;

  const updateStateData = ({ from, to }) => {
    setCurrentComment({ from, to });
    setListData(data.slice(from, to));
    setHasNewer(from > 0);
    setHasOlder(to < total);
  };

  function getCommentByMentionId(array, id) {
    const getNodes = (result, object) => {
      if (object.id === id) {
        result.push({ ...object });
        return result;
      }
      if (Array.isArray(object.children)) {
        const children = object.children.reduce(getNodes, []);
        if (children.length) result.push({ ...object });
      }
      return result;
    };
    return array.reduce(getNodes, []);
  }

  const onUpdateData = () => {
    const { to } = currentComment;
    if (data[0]?.children.length === 0 && data[1]?.children.length === 0) {
      updateStateData({ from: 0, to: Math.max(2, to) });
    } else updateStateData({ from: 0, to: Math.max(1, to) });
  };

  const getDataMention = () => {
    const mentionData = getCommentByMentionId(data, mentionId);
    const mentionIndex = data.findIndex(item => item.id === mentionData[0]?.id);
    return { mentionData, mentionIndex };
  };

  useDidMount(() => {
    const { mentionData, mentionIndex } = getDataMention();
    if (mentionId && mentionIndex !== -1) {
      const older =
        data[mentionIndex].children > mentionData[0].children ||
        mentionIndex < total - 1;
      const newer = mentionIndex > 0;
      setHasNewer(newer);
      setHasOlder(older);
      setCurrentComment({ from: mentionIndex, to: mentionIndex + 1 });
      setListData(mentionData);
    } else {
      onUpdateData();
    }
  });

  useDidUpdate(() => {
    if (mentionId) {
      const { mentionIndex } = getDataMention();
      const { from, to } = currentComment;
      updateStateData({
        from: Math.min(from, mentionIndex),
        to: Math.max(to, mentionIndex + 1),
      });
    } else {
      onUpdateData();
    }
  }, [data]);

  const onChangeStatus = state => {
    const { from, to } = currentComment;
    if (state === 'newer') {
      updateStateData({ from: from - 2 > 0 ? from - 2 : 0, to });
    }
    if (state === 'older') {
      updateStateData({ from, to: to + 2 < total ? to + 2 : total });
    }
  };

  const onAddNewComment = comment => {
    if (!comment.parentId) {
      setHasNewer(false);
      setCurrentComment({
        from: 0,
        to: currentComment.to + 1,
      });
      setListData([comment, ...data.slice(0, currentComment.to)]);
    } else {
      const newList = [...listData].map(item => {
        if (item.id !== comment.parentId) {
          return item;
        }
        return { ...item, children: [comment, ...item.children] };
      });
      setListData(newList);
    }
  };

  const onDeleteComment = id => {
    if (listData.findIndex(item => item.id) > -1) {
      setListData([...listData].filter(item => item.id !== id));
      setCurrentComment({
        from: currentComment.from,
        to: currentComment.to - 1,
      });
    } else {
      setListData(
        [...listData].map(item => ({
          ...item,
          children: item.children.filter(child => child.id !== id),
        })),
      );
    }
  };

  return {
    listData,
    hasOlder,
    hasNewer,
    onChangeStatus,
    onAddNewComment,
    onDeleteComment,
  };
};

export default useCommentData;
