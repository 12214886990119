import {
  call,
  put,
  delay,
  takeLatest,
  takeEvery,
  select,
} from 'redux-saga/effects';

import { getData, postData, deleteData, putData } from 'services/api';

import { CATEGORY_TYPE, TAG_TYPE } from 'constants/newsfeedCategory';
import { NEWSFEED as NEWSFEED_PATH } from '../constants/breakcrumb';
import {
  GET_NEWSFEED_API_PATH,
  GET_NEWSFEED_MANAGE_API_PATH,
  GET_NEW_NEWSFEED_QUANTITY_API_PATH,
  GET_TAG_OR_CATEGORY_NEWSFEED,
  NEWSFEED,
  GET_HIGHLIGHT_NEWSFEED,
} from '../constants/paths';

import {
  getNewsfeedRequest,
  getNewsfeedSuccess,
  getNewsfeedFailure,
  getHighLightNewsfeedRequest,
  getHighLightNewsfeedSuccess,
  getHighLightNewsfeedFailure,
  getNewsfeedManageRequest,
  getNewsfeedManageSuccess,
  getNewsfeedManageFailure,
  deleteNewsfeedManageRequest,
  deleteNewsfeedManageSuccess,
  deleteNewsfeedManageFailure,
  getNewNewsfeedQuantityRequest,
  getNewNewsfeedQuantitySuccess,
  getNewNewsfeedQuantityFailure,
  newsfeedSelector,
  getTagsRequest,
  getTagsFailure,
  getTagsSuccess,
  getCategoriesRequest,
  getCategoriesFailure,
  getCategoriesSuccess,
  createNewsFeedRequest,
  createNewsFeedFailure,
  createNewsFeedSuccess,
  getNewsDetailRequest,
  getNewsDetailFailure,
  getNewsDetailSuccess,
  publishNewsRequest,
  publishNewsFailure,
  publishNewsSuccess,
  updateNewsRequest,
  updateNewsSuccess,
  updateNewsFailure,
  getSectionNewsfeedRequest,
  getSectionNewsfeedSuccess,
  getSectionNewsfeedFailure,
} from '../slices/newsfeed';

export function* getNewsfeed({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_NEWSFEED_API_PATH,
      params: payload,
    });

    yield delay(800);
    yield put(getNewsfeedSuccess({ data, types: payload.types }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getNewsfeedFailure(message));
  }
}

export function* getNewsfeedManage({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: GET_NEWSFEED_MANAGE_API_PATH,
      data: payload,
    });

    yield delay(800);
    yield put(getNewsfeedManageSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getNewsfeedManageFailure(message));
  }
}

export function* deleteNewsfeedManage({ payload }) {
  const { id, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(deleteData, {
      url: `${GET_NEWSFEED_API_PATH}/${id}`,
    });

    yield put(deleteNewsfeedManageSuccess(data));

    callback?.();
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(deleteNewsfeedManageFailure(message));
  }
}

export function* getTags() {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_TAG_OR_CATEGORY_NEWSFEED,
      params: { type: TAG_TYPE },
    });

    const newData = data.map(item => ({
      label: item,
      value: item,
    }));

    yield put(getTagsSuccess(newData));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getTagsFailure(message));
  }
}

export function* getCategories() {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_TAG_OR_CATEGORY_NEWSFEED,
      params: { type: CATEGORY_TYPE },
    });

    const newData = data.map(item => ({
      label: item,
      value: item,
    }));

    yield put(getCategoriesSuccess(newData));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getCategoriesFailure(message));
  }
}

export function* getHighLightNews() {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_HIGHLIGHT_NEWSFEED,
    });

    yield put(getHighLightNewsfeedSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getHighLightNewsfeedFailure(message));
  }
}

export function* createNewsFeed({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: NEWSFEED,
      data: payload.data,
      contentType: 'multipart/form-data',
    });

    yield delay(800);

    payload.callback?.();
    yield put(createNewsFeedSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(createNewsFeedFailure(message));
  }
}

export function* getNewNewsfeedQuantity({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_NEW_NEWSFEED_QUANTITY_API_PATH,
      params: payload,
    });

    yield put(getNewNewsfeedQuantitySuccess(data));

    if (window.location.pathname === NEWSFEED_PATH.id) {
      const newsfeedParams = yield select(newsfeedSelector);
      yield call(getNewsfeed, {
        payload: {
          pageSize: newsfeedParams.data.pageSize || 12,
          pageIndex: newsfeedParams.data.currentPage || 1,
          types: newsfeedParams.data.types || 0,
          IsPublished: true,
        },
      });
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getNewNewsfeedQuantityFailure(message));
  }
}

export function* getNewsDetail(action) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: `${NEWSFEED}/${action.payload.id}`,
    });

    yield delay(800);
    yield put(getNewsDetailSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getNewsDetailFailure(message));
  }
}

export function* publishNews(action) {
  const { id, callback } = action.payload;
  try {
    const {
      data: { data },
    } = yield call(putData, {
      url: `${NEWSFEED}/${id}/publish`,
    });

    yield delay(800);
    callback?.();
    yield put(publishNewsSuccess(data));
  } catch (error) {
    if (error?.response?.status === 409) {
      callback?.(true);
    }
    const { message = 'Something went wrong!' } = error;
    yield put(publishNewsFailure(message));
  }
}

export function* updateNews(action) {
  const { id, formData, callback } = action.payload;

  try {
    const {
      data: { data },
    } = yield call(putData, {
      url: `${NEWSFEED}/${id}`,
      data: formData,
      contentType: 'multipart/form-data',
    });

    yield delay(800);
    callback?.();
    yield put(updateNewsSuccess(data));
  } catch (error) {
    if (error?.response?.status === 409) {
      callback?.(true);
    }
    const { message = 'Something went wrong!' } = error;
    yield put(updateNewsFailure(message));
  }
}

export function* getSectionNewsfeed(action) {
  const { categoryKey, callback = () => {}, ...params } = action.payload;

  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_NEWSFEED_API_PATH,
      params,
    });

    yield delay(800);
    callback();
    yield put(getSectionNewsfeedSuccess({ ...data, categoryKey }));
  } catch (error) {
    callback();
    const { message = 'Something went wrong!' } = error;
    yield put(getSectionNewsfeedFailure(message));
  }
}

export default function* newsfeedSaga() {
  yield takeLatest(getNewsfeedRequest().type, getNewsfeed);
  yield takeLatest(getNewsfeedManageRequest().type, getNewsfeedManage);
  yield takeLatest(deleteNewsfeedManageRequest().type, deleteNewsfeedManage);
  yield takeLatest(getTagsRequest().type, getTags);
  yield takeLatest(getCategoriesRequest().type, getCategories);
  yield takeEvery(createNewsFeedRequest().type, createNewsFeed);
  yield takeLatest(
    getNewNewsfeedQuantityRequest().type,
    getNewNewsfeedQuantity,
  );
  yield takeLatest(getNewsDetailRequest().type, getNewsDetail);
  yield takeEvery(publishNewsRequest().type, publishNews);
  yield takeEvery(updateNewsRequest().type, updateNews);
  yield takeEvery(getHighLightNewsfeedRequest().type, getHighLightNews);
  yield takeEvery(getSectionNewsfeedRequest().type, getSectionNewsfeed);
}
