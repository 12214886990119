import React, { useEffect, useState } from 'react';
import SampleOutputArrow from 'assets/icons/common/sampleOutputArrow';

const useSamplePagination = () => {
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [displayedPageNumber, setDisplayedPageNumber] = useState(1);
  const onClickPrevious = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  const onClickNext = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const onChangePageNumber = event => {
    const value = parseInt(event?.target?.value, 10) || '';
    if (typeof value === 'number' && value > totalPages) {
      setPageNumber(totalPages);
    } else if (typeof value === 'number' && value < 1) {
      setPageNumber(1);
    } else {
      setPageNumber(value);
    }
  };

  useEffect(() => {
    if (typeof pageNumber === 'number' && pageNumber !== displayedPageNumber) {
      setDisplayedPageNumber(pageNumber);
    }
  }, [pageNumber]);

  const renderPagination = () => {
    if (totalPages && totalPages > 1) {
      return (
        <div className="output-pagination">
          <button
            type="button"
            className="output-pagination__previous"
            onClick={onClickPrevious}
            disabled={pageNumber === 1}
          >
            <SampleOutputArrow
              color={pageNumber === 1 ? '#3F3C4C' : '#ffffff'}
            />
          </button>
          <input
            className="output-pagination__input"
            type="number"
            value={pageNumber}
            min={1}
            max={totalPages || 1}
            onChange={onChangePageNumber}
            onBlur={event =>
              !event?.target?.value && setPageNumber(displayedPageNumber)
            }
          />
          <p className="output-pagination__page">of {totalPages}</p>
          <button
            type="button"
            className="output-pagination__next"
            onClick={onClickNext}
            disabled={totalPages === pageNumber}
          >
            <SampleOutputArrow
              color={pageNumber === totalPages ? '#3F3C4C' : '#ffffff'}
            />
          </button>
        </div>
      );
    }

    return null;
  };

  return {
    totalPages,
    setTotalPages,
    pageNumber: displayedPageNumber,
    setPageNumber,
    renderPagination,
  };
};

export default useSamplePagination;
