import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import BButton from 'react-bootstrap/Button';

import { MY_WORKSPACE } from 'constants/breakcrumb';

import EmptyHistoryImg from 'assets/images/common/empty-history.jpg';

import useClickOutside from 'hooks/useClickOutside';
import useExportHistory from 'hooks/useExportHistory';
import useDevice from 'hooks/useDevice';

import ExportHistoryItem from '../ExportHistoryItem';
import Tooltip from '../Tooltip';
import MessageNoData from '../../core/MessageNoData';
import LoadingBalls from '../../core/LoadingBalls';

import './styles.scss';

const ExportHistory = ({ icon }) => {
  const [toggleExportHistory, setToggleExportHistory] = useState(false);

  const ref = useRef(null);

  const history = useHistory();
  const { exportHistoryPopup: exportHistory, onGetExportHistoryPopup } =
    useExportHistory();
  const { isMobileDevice } = useDevice();

  useEffect(() => {
    if (toggleExportHistory) {
      onGetExportHistoryPopup({
        params: { pageIndex: 1, pageSize: 5 },
        hasLoading: true,
      });
    }
  }, [toggleExportHistory]);

  useClickOutside(ref, () => {
    setToggleExportHistory(false);
  });

  const viewAllBtnElem = (
    <div className="edh-export-history__action">
      <BButton
        onClick={() => {
          if (history.location.pathname === MY_WORKSPACE.id) {
            history.replace(`${MY_WORKSPACE.id}?isGoToDatasetTab=true`);
          } else {
            history.push(MY_WORKSPACE.id);
          }
          setToggleExportHistory(false);
        }}
      >
        View all (History)
      </BButton>
    </div>
  );

  return (
    <div className="edh-export-history" ref={ref}>
      <Tooltip content="Downloaded History" disabled={isMobileDevice}>
        <BButton
          className="edh-export-history__btn"
          onClick={() => setToggleExportHistory(!toggleExportHistory)}
        >
          <img src={icon} alt="Button history export" />
        </BButton>
      </Tooltip>
      {toggleExportHistory && (
        <div className="edh-export-history__popover">
          <LoadingBalls isLoading={exportHistory.status === 'loading'} />
          <div className="edh-export-history__title">
            <h6>Downloaded History</h6>
          </div>
          {exportHistory.data.items && exportHistory.data.items.length > 0 ? (
            <>
              <div className="edh-export-history__items">
                {exportHistory.data.items.map((item, idx) => {
                  if (idx < 5) {
                    return <ExportHistoryItem key={item.id} file={item} />;
                  }

                  return;
                })}
              </div>
              {viewAllBtnElem}
            </>
          ) : (
            <>
              <MessageNoData
                img={EmptyHistoryImg}
                title="No export history."
                description="Your export history will be appear here."
                isLoading={exportHistory.status === 'loading'}
              />
              {viewAllBtnElem}
            </>
          )}
        </div>
      )}
    </div>
  );
};

ExportHistory.propTypes = {
  icon: PropTypes.any,
};

export default memo(ExportHistory);
