import React, { memo } from 'react';
import { PropTypes } from 'prop-types';

import classnames from 'services/classnames';
import { BANNER_CONTENT } from 'constants/banner';

import Vector from 'assets/images/vector.svg';

import './styles.scss';

const modules = {
  'Data Marketplace': {
    id: 'data-marketplace',
    title: 'Data Marketplace',
    color: `274.54deg, #00A19C 2.28%, #763F98 90.36%`,
  },
  'Analytics Marketplace': {
    id: 'analytics-marketplace',
    title: 'Analytics Marketplace',
    color: `35.36deg, #763F98 9.96%, #20419A 100%`,
  },
};

const Banner = ({ pageTitle, description, logoTitle }) => {
  const bgColorLP = `#EDF1F8`;

  const module = modules[pageTitle];

  return (
    <div
      className={`edh-banner edh-banner-${module?.id || 'edh'} `}
      style={{
        background: module ? `linear-gradient(${module.color})` : bgColorLP,
      }}
    >
      {!logoTitle && (
        <img className="edh-banner__vector" src={Vector} alt="EDH banner" />
      )}
      <div className="edh-banner-info">
        {pageTitle ? (
          <h1 className="edh-banner-info__page-title">{pageTitle}</h1>
        ) : (
          <img className="edh-banner-info__logo" src={logoTitle} alt="logo" />
        )}

        <h2
          style={{ color: `${logoTitle ? '#2D2B39' : '#ffffff'}` }}
          className={classnames('edh-banner-info__description', {
            dm: pageTitle === BANNER_CONTENT.DATA_MARKET_PLACE.pageTitle,
          })}
        >
          {description}
        </h2>
      </div>
    </div>
  );
};

Banner.propTypes = {
  pageTitle: PropTypes.string,
  description: PropTypes.string,
  logoTitle: PropTypes.any,
};

Banner.defaultProps = {
  pageTitle: '',
  description: '',
};

export default memo(Banner);
