import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import { ReactComponent as DropdownIcon } from 'assets/icons/common/angle-down.svg';

import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';

import './styles.scss';

const AMCannedResponseDropdown = ({ disabled, widthCommentBox, onSelect }) => {
  const { selectPresetQuestions } = useAnalyticsMarketplace();

  const handleOnSelect = optionValue => {
    onSelect(optionValue);
  };

  return (
    <Dropdown className="edh-am-cr-dropdown-button" onSelect={handleOnSelect}>
      <Dropdown.Toggle disabled={disabled}>
        <DropdownIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: widthCommentBox }}>
        {selectPresetQuestions?.data?.map(item => (
          <div key={item?.id}>
            <Dropdown.Header className="edh-am-cr-dropdown-button__header">
              {item?.value?.trim()}:
            </Dropdown.Header>
            <div className="edh-am-cr-dropdown-button__items">
              {item?.data?.map(subItem => (
                <Dropdown.Item
                  className="edh-am-cr-dropdown-button__item"
                  key={subItem?.id}
                  eventKey={subItem?.value?.trim()}
                >
                  {subItem?.value?.trim()}
                </Dropdown.Item>
              ))}
            </div>
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

AMCannedResponseDropdown.propTypes = {
  widthCommentBox: PropTypes.number,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
};

AMCannedResponseDropdown.defaultProps = {
  widthCommentBox: 0,
  disabled: false,
  onSelect() {},
};

export default memo(AMCannedResponseDropdown);
