import React, { memo, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import { graphConfig } from 'msalConfig';

import {
  MANAGEMENT_DASHBOARD,
  DATA_MARKETPLACE,
  EXTERNAL_DATASETS_PAGE,
  INTERNAL_DATASETS_PAGE,
  MY_WORKSPACE,
} from 'constants/breakcrumb';

import useClickOutside from 'hooks/useClickOutside';
import useAuth from 'hooks/useAuth';

import Avatar from 'components/core/Avatar';
import Tooltip from 'components/layout/Tooltip';

import './styles.scss';

const LoggedUser = ({ userInfo }) => {
  const [toggleUserInfo, setToggleUserInfo] = useState(false);

  const avatarRef = useRef(null);

  const history = useHistory();
  const { onLogout } = useAuth();
  const location = useLocation();
  const pathNameArr = location?.pathname?.split('/') || [];
  const isDMPage = pathNameArr[1] === DATA_MARKETPLACE.id.substring(1);
  const isDMDatasetsPage = [
    INTERNAL_DATASETS_PAGE.id,
    EXTERNAL_DATASETS_PAGE.id,
  ].includes(location?.pathname);

  const { fullName, email } = userInfo?.data;

  const userPhotoUrl = useMemo(
    () => (email ? graphConfig.getGraphUserPhotoEndpoint(email) : ''),
    [email],
  );

  useClickOutside(avatarRef, () => {
    setToggleUserInfo(false);
  });

  const onMyEntitledDataClick = () => {
    history.push(MANAGEMENT_DASHBOARD.id);
    setToggleUserInfo(!toggleUserInfo);
  };

  const onMyWorkSpaceClick = () => {
    history.push(`${MY_WORKSPACE.id}?isGoToDatasetTab=true`);
    setToggleUserInfo(!toggleUserInfo);
  };

  const onClickLogout = () => {
    onLogout();
    setToggleUserInfo(!toggleUserInfo);
  };

  const isAuthenticationSuccess = useMemo(
    () => userInfo?.status === 'succeeded',
    [userInfo],
  );

  return (
    <div className="edh-logged-user" ref={avatarRef}>
      <Button
        className="edh-logged-user__btn"
        style={{
          cursor: `${isAuthenticationSuccess ? 'pointer' : 'not-allowed'}`,
        }}
        onClick={() => setToggleUserInfo(!toggleUserInfo)}
        disabled={!isAuthenticationSuccess}
      >
        <Avatar
          name={fullName}
          src={userPhotoUrl}
          email={email}
          className={
            isDMPage && !isDMDatasetsPage ? 'edh-logged-user__avatar' : null
          }
        />
      </Button>
      {toggleUserInfo && isAuthenticationSuccess ? (
        <div className="edh-logged-user__popover">
          <div className="edh-logged-user__info">
            <div className="edh-logged-user__info-gitavatar">
              <Avatar
                name={fullName}
                src={userPhotoUrl}
                size="xl"
                email={email}
              />
            </div>
            <div className="edh-logged-user__info-profile">
              <h6 className="edh-logged-user__info-profile-name">{fullName}</h6>
              <p className="edh-logged-user__info-profile-email">{email}</p>
              <div className="edh-logged-user__btn-group">
                <Tooltip content="Coming soon">
                  <Button className="edh-logged-user__view-btn">
                    View Profile
                  </Button>
                </Tooltip>
                <Button variant="custom-primary" onClick={onClickLogout}>
                  Logout
                </Button>
              </div>
            </div>
          </div>
          <div className="edh-logged-user__actions">
            <Button onClick={onMyEntitledDataClick}>MY ENTITLED DATA</Button>
            <Button onClick={onMyWorkSpaceClick}>MY WORKSPACE</Button>
          </div>
        </div>
      ) : (
        <p className="edh-logged-user__info-profile-display-none-email">
          {email}
        </p>
      )}
    </div>
  );
};

LoggedUser.propTypes = {
  userInfo: PropTypes.object,
};

LoggedUser.defaultProps = {
  userInfo: {},
};

export default memo(LoggedUser);
