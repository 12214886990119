import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const EllipseCellTable = ({ children, column, row }) => {
  const id = `cell-${column.id}-${row.id}`;
  const classNameCell = 'edh-ellipse-cell-table';

  return (
    <div
      id={id}
      className={classNameCell}
      role="button"
      tabIndex={-1}
      style={{ cursor: 'default' }}
      onClick={() => {
        const ele = document.getElementById(id);
        ele.classList.toggle(classNameCell);
      }}
    >
      {children}
    </div>
  );
};

EllipseCellTable.propTypes = {
  children: PropTypes.any,
  column: PropTypes.object,
  row: PropTypes.object,
};

export default memo(EllipseCellTable);
