import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

import { LIMIT_CHARACTERS } from 'constants/dataRequestForm';

import SearchBarWithSuggestion from 'components/layout/SearchBarWithSuggestion';
import DropdownBtn from 'components/core/DropdownBtn';
import ReactSelectCustom from '../../layout/ReactSelectCustom';
import Field from '../Field';

const Select = ({
  control,
  id,
  className,
  label,
  name,
  placeholder,
  keySubmit,
  menuListTitle,
  menuPlacement,
  isShowIconInfo,
  disabled,
  closeMenuOnSelect,
  isSearchable,
  limitText,
  limitLength,
  maxLength,
  isDropdownBtn,
  isMulti,
  isMultiSelectionExceedLimit,
  isLoading,
  isClearable,
  isSelectDataset,
  isSearchField,
  disableSearchEmpty,
  options,
  rules,
  error,
  components,
  onSearchData,
  onClearError,
  onChangeSelectedMulti,
  onCustomDropdownSearchField,
}) => {
  const updatedRules = useMemo(() => {
    const validate = {};

    if (rules?.required === true || rules?.required?.value === true) {
      validate.required = val =>
        !_.isEmpty(val) || rules?.required?.message || `${label} is required.`;
    }
    if (rules?.min) {
      validate.min = val =>
        val.length >= rules.min || `Value not less than ${rules?.min}.`;
    }
    if (rules?.max) {
      validate.max = val =>
        val.length <= rules.max || `Value not greater than ${rules?.max}.`;
    }

    return { validate };
  }, [rules]);

  const handleOnClearError = useCallback(() => {
    onClearError(name);
  }, [name]);

  const handleOnSearchData = useCallback(
    keyword => {
      if (!keyword && isSearchField) {
        return;
      }
      onSearchData(keyword, name);
    },
    [name],
  );

  return (
    <Field
      rules={rules}
      id={id}
      className={className}
      placeholder={placeholder}
      label={label}
      isShowIconInfo={isShowIconInfo}
      disabled={disabled}
      error={error}
    >
      <Controller
        name={name}
        control={control}
        rules={updatedRules}
        render={({ field: { value, onChange } }) => {
          if (isSearchField) {
            return (
              <SearchBarWithSuggestion
                isLoading={isLoading}
                placeholder={placeholder}
                keySubmit={keySubmit}
                suggestionData={options}
                isSubmitForm={false}
                dropdownHeaderTitle={menuListTitle}
                onSearch={keyword => {
                  if (keyword === '') {
                    onChange(keyword);
                  }
                  handleOnSearchData(keyword);
                }}
                onSubmit={onChange}
                onClickKeyWordSuggestion={onChange}
                onCustomItemDropdown={onCustomDropdownSearchField}
                onClearKeyword={onChange}
              />
            );
          }
          if (isDropdownBtn) {
            return (
              <DropdownBtn
                isHeaderTitleDropdown={false}
                title={value?.title}
                menu={options}
                onItemSelect={onChange}
                onClearKeyword={onChange}
              />
            );
          }
          return (
            <ReactSelectCustom
              placeholder={placeholder}
              menuListTitle={menuListTitle}
              menuPlacement={menuPlacement}
              isClearable={isClearable}
              value={value}
              options={options}
              closeMenuOnSelect={closeMenuOnSelect}
              isDisabled={disabled}
              isSearchable={isSearchable}
              isSelectDataset={isSelectDataset}
              isMulti={isMulti}
              isMultiSelectionExceedLimit={isMultiSelectionExceedLimit}
              limitText={limitText}
              limitLength={limitLength}
              maxLength={maxLength}
              isLoading={isLoading}
              disableSearchEmpty={disableSearchEmpty}
              components={components}
              onSearchData={handleOnSearchData}
              onEnter={!isMulti ? onChange : () => {}}
              onChangeSelectedItems={
                !isMulti ? onChange : onChangeSelectedMulti
              }
              onClearError={handleOnClearError}
            />
          );
        }}
      />
    </Field>
  );
};

Select.propTypes = {
  control: PropTypes.any.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  menuListTitle: PropTypes.string,
  menuPlacement: PropTypes.string,
  limitText: PropTypes.string,
  keySubmit: PropTypes.string,
  limitLength: PropTypes.number,
  maxLength: PropTypes.number,
  isShowIconInfo: PropTypes.bool,
  disabled: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  isDropdownBtn: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isMultiSelectionExceedLimit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSelectDataset: PropTypes.bool,
  isSearchField: PropTypes.bool,
  disableSearchEmpty: PropTypes.bool,
  options: PropTypes.array,
  rules: PropTypes.object,
  error: PropTypes.object,
  components: PropTypes.object,
  onSearchData: PropTypes.func,
  onClearError: PropTypes.func,
  onChangeSelectedMulti: PropTypes.func,
  onCustomDropdownSearchField: PropTypes.func,
};

Select.defaultProps = {
  id: '',
  className: '',
  label: '',
  name: '',
  placeholder: '',
  menuListTitle: '',
  limitText: '',
  keySubmit: '',
  limitLength: 0,
  maxLength: LIMIT_CHARACTERS.input,
  menuPlacement: 'auto',
  isShowIconInfo: false,
  disabled: false,
  closeMenuOnSelect: true,
  isDropdownBtn: false,
  isSearchable: false,
  isMulti: true,
  isLoading: false,
  isClearable: true,
  isSelectDataset: false,
  isSearchField: false,
  disableSearchEmpty: true,
  options: [],
  rules: {},
  error: {},
  components: {},
  onSearchData() {},
  onClearError() {},
  onChangeSelectedMulti() {},
};

export default memo(Select);
