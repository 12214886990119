import React, { memo } from 'react';
import PropTypes from 'prop-types';

import BOverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BTooltip from 'react-bootstrap/Tooltip';

import useEllipsis from 'hooks/useEllipsis';

import './styles.scss';
import useDevice from 'hooks/useDevice';

const Tooltip = ({
  children,
  id,
  placement,
  content,
  bsPrefix,
  trigger,
  show,
  disabled,
}) => {
  const { isEllipsis } = useEllipsis(id);
  const { isMobileDevice } = useDevice();

  if (disabled) return children;

  if (!isEllipsis) return children;
  return (
    <BOverlayTrigger
      placement={placement}
      show={show}
      trigger={isMobileDevice ? ['click', 'click'] : trigger}
      rootClose={isMobileDevice}
      overlay={
        <BTooltip
          id={`edh-tooltip-${placement}`}
          bsPrefix={`${bsPrefix} tooltip`}
        >
          {content}
        </BTooltip>
      }
    >
      {children}
    </BOverlayTrigger>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string,
  placement: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.number,
  ]),
  bsPrefix: PropTypes.string,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  show: PropTypes.bool,
  disabled: PropTypes.bool,
};

Tooltip.defaultProps = {
  id: '',
  placement: 'bottom',
  content: '',
  trigger: ['hover', 'hover'],
  disabled: false,
};

export default memo(Tooltip);
