/* eslint-disable no-console */
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { GoInfo } from 'react-icons/go';

import {
  CONFLICT,
  UNAUTHORIZED,
  LOCKED,
  GATEWAY_TIMEOUT,
  BAD_REQUEST,
  SERVER_ERROR,
} from 'constants/errorCodes';
import {
  ANALYTICS_MARKETPLACE_RUN_MODEL_PATH,
  ANALYTICS_MARKETPLACE_RUN_SAMPLE_DATA_PATH,
  ANALYTICS_MARKETPLACE_RUN_COGNITIVE_DIGITALIZATION_MODEL_PATH,
  EXPORT_DATASET,
  GET_NUMBER_DATA_EXPORT,
  PREVIEW_DYNAMIC_FILTER_DOWNLOAD_API_PATH,
  ANALYTICS_MARKETPLACE_RUN_MODEL_BY_TEXT,
  ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_GET_MODEL_TYPE,
} from 'constants/paths';
import { DQ_BAD_DATA_EXPORT } from 'features/DQ/constants/paths';

import { uid } from 'services/uid';

import { loginRequest } from 'msalConfig';

import useAlerts from './useAlerts';

const ExcludeError = [GATEWAY_TIMEOUT, BAD_REQUEST, SERVER_ERROR];

const apiExcludeError = {
  [ANALYTICS_MARKETPLACE_RUN_MODEL_PATH]: ExcludeError,
  [ANALYTICS_MARKETPLACE_RUN_SAMPLE_DATA_PATH]: ExcludeError,
  [ANALYTICS_MARKETPLACE_RUN_COGNITIVE_DIGITALIZATION_MODEL_PATH]: ExcludeError,
  [ANALYTICS_MARKETPLACE_RUN_MODEL_BY_TEXT]: ExcludeError,
  [ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_GET_MODEL_TYPE]: ExcludeError,
  [PREVIEW_DYNAMIC_FILTER_DOWNLOAD_API_PATH]: ExcludeError,
  [GET_NUMBER_DATA_EXPORT]: ExcludeError,
  [EXPORT_DATASET]: ExcludeError,
  [DQ_BAD_DATA_EXPORT]: ExcludeError,
};

const MESSAGES = {
  [ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_GET_MODEL_TYPE]: {
    ALL: 'Document Type is failed to be detected, please try again.',
  },
  ALL: 'Data is failed to load, please try again',
};

const useAxiosInterceptor = (axiosInstance, msalInstance, history) => {
  const [error, setError] = useState({});
  const { onAddAlertMessage } = useAlerts();

  const excludeErrorHandler = useMemo(() => {
    const addAlertMessage = message => {
      onAddAlertMessage({
        id: uid(),
        variant: 'warning',
        isAutoHide: false,
        className: 'edh-alerts-highest',
        content: (
          <p className="edh-export-data-modal__alert-content">
            <GoInfo size={18} color="#3F3C4C" style={{ marginRight: '10px' }} />
            <span>{message}</span>
          </p>
        ),
      });
    };

    const result = {
      ALL() {
        addAlertMessage(MESSAGES.ALL);
      },
    };

    return {
      [PREVIEW_DYNAMIC_FILTER_DOWNLOAD_API_PATH]: result,
      [GET_NUMBER_DATA_EXPORT]: result,
      [DQ_BAD_DATA_EXPORT]: result,
      [ANALYTICS_MARKETPLACE_RUN_MODEL_PATH]: result,
      [ANALYTICS_MARKETPLACE_RUN_SAMPLE_DATA_PATH]: result,
      [ANALYTICS_MARKETPLACE_RUN_COGNITIVE_DIGITALIZATION_MODEL_PATH]: result,
      [ANALYTICS_MARKETPLACE_RUN_MODEL_BY_TEXT]: result,
      [ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_GET_MODEL_TYPE]: {
        ALL() {
          addAlertMessage(
            MESSAGES[
              ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_GET_MODEL_TYPE
            ].ALL,
          );
        },
      },
    };
  }, [onAddAlertMessage]);

  useEffect(() => {
    // Set axiosInstance interceptors
    const requestInterceptor = axiosInstance.interceptors.request.use(
      req => req,
      err => Promise.reject(err),
    );

    const responseInterceptor = axiosInstance.interceptors.response.use(
      res => res,
      err => {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('err.response.data', err?.response?.data);
        console.log('err.response.status', err?.response?.status);
        console.log('err.response.headers', err?.response?.headers);

        // Cancel request
        if (axios.isCancel(err)) {
          return;
        }

        const { status } = err?.response || {};
        if (status === UNAUTHORIZED) {
          msalInstance.loginRedirect(loginRequest);
        } else if (status === LOCKED) {
          history.push({
            pathname: '/forbidden',
            state: {
              status,
              message: err?.response?.data?.data?.[0]?.message,
            },
          });
        } else {
          (() => {
            if (status === CONFLICT) return;

            const url = err?.response?.config?.url;

            const errorFound = apiExcludeError[url];

            if (errorFound?.length === 0 || errorFound?.includes?.(status)) {
              const itemError = excludeErrorHandler[url];

              itemError?.ALL?.(err);
              itemError?.[status]?.(err);
              return;
            }

            history.push({
              pathname: '/error',
              state: {
                status,
                message: err?.response?.data?.data?.[0]?.message,
              },
            });
            return;
          })();
        }

        setError(err?.response || {});

        return Promise.reject(err);
      },
    );
    return () => {
      // Remove handlers, so Garbage Collector will get rid of if WrappedComponent will be removed
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [axiosInstance, msalInstance]);

  return { error, onSetError: setError };
};

export default useAxiosInterceptor;
