import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Video from 'components/core/Video';

import './styles.scss';

const VideoSection = ({ videos }) => (
  <div className="edh-user-guide-videos">
    <Row>
      {videos.map(video => (
        <Col key={video.id} lg={6} md={6} sm={12}>
          <Video
            src={video.src}
            thumbnail={video.thumbnail}
            title={video.title}
          />
        </Col>
      ))}
    </Row>
  </div>
);

VideoSection.propTypes = {
  videos: PropTypes.array.isRequired,
};

export default memo(VideoSection);
