import React, { memo } from 'react';
import * as PropTypes from 'prop-types';

const SampleDataTextBox = ({ content, label, type, name }) => (
  <div className="sample-data__field">
    <label className="sample-data__label" htmlFor={name}>
      {label}
    </label>
    <div
      className={`sample-data__field--input ${type}`}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content[name] }}
    />
  </div>
);

SampleDataTextBox.propTypes = {
  content: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
};
export default memo(SampleDataTextBox);
