import {
  AM_DETAIL_INFORMATION,
  AM_THEME_OVERVIEW,
  ANALYTICS_MARKETPLACE,
} from './breakcrumb';

export const INTERACTIVE_MODELS_ENUM = 0;
export const CITIZEN_ANALYTICS_MODELS_ENUM = 1;
export const GENERIC_MODELS_ENUM = 2;

export const INTERACTIVE_MODELS_KEY = 'InteractiveModels';
export const CITIZEN_ANALYTICS_MODELS_KEY = 'CitizenAnalyticsModels';
export const GENERIC_MODELS_KEY = 'GenericModels';

export const INTERACTIVE_MODELS_HREF = 'interactive-models';
export const GENERIC_MODELS_HREF = 'generic-models';

export const AM_MODEL_TYPE = {
  [INTERACTIVE_MODELS_KEY]: {
    key: INTERACTIVE_MODELS_ENUM,
    href: `/${INTERACTIVE_MODELS_HREF}`,
    type: 'online',
    name: 'Interactive Models',
    modelType: [INTERACTIVE_MODELS_KEY],
    description: 'Users are able to test these ML Models using their own data.',
  },
  [GENERIC_MODELS_KEY]: {
    key: GENERIC_MODELS_ENUM,
    href: `/${GENERIC_MODELS_HREF}`,
    type: 'generic',
    name: 'Generic Models',
    modelType: GENERIC_MODELS_KEY,
    description:
      'A curated selection of ML models for generic use cases. Brought to you by Data Science.',
  },
};

export const AM_DOMAIN_LIST = [
  AM_DETAIL_INFORMATION.id,
  ANALYTICS_MARKETPLACE.id,
  `${ANALYTICS_MARKETPLACE.id}${AM_MODEL_TYPE[INTERACTIVE_MODELS_KEY].href}`,
  `${ANALYTICS_MARKETPLACE.id}${AM_MODEL_TYPE[GENERIC_MODELS_KEY].href}`,
];

export const typeMap = {
  csv: 'csv',
  json: 'json',
  pdf: 'pdf',
  png: 'png',
  jpg: 'jpg',
  xlsx: 'xlsx',
  docx: 'docx',
};

export const userTrackingType = {
  view: 0,
  download: 1,
  purgeConfirm: 2,
  userGuide: 3,
};

export const AM_THEME_OVERVIEW_ROUTE = `${AM_THEME_OVERVIEW.id}(.*)-theme`;
