import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';

import { ReactComponent as PlayRoundIcon } from 'assets/icons/common/play-round.svg';

import LoadingBalls from 'components/core/LoadingBalls';

import './styles.scss';

const SampleDataInput = ({ resultInput = {}, dataTrySampleInput, onRunModel }) => {
  const {
    maxLength = 1000,
    rows = 3,
    renderResultInput = () => {},
  } = resultInput;

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm({ defaultValues: { sample: '' } });

  const submitHandler = sampleData => {
    onRunModel(sampleData.sample);
  };

  return (
    <>
      <LoadingBalls
        isLoading={dataTrySampleInput && dataTrySampleInput.status === 'loading'}
      />
      <form
        className="sample-data__form"
        onSubmit={handleSubmit(submitHandler)}
      >
        <textarea
          className="sample-data__text"
          rows={rows}
          placeholder="Insert Text"
          maxLength={maxLength}
          {...register('sample', {
            required: true,
          })}
        />
        <Button
          variant="custom-primary"
          type="submit"
          disabled={!isDirty || errors?.sample}
        >
          <PlayRoundIcon width={16} height={16} viewBox="0 0 20 20" />
          <p>Run Model</p>
        </Button>
        {renderResultInput(dataTrySampleInput)}
      </form>
    </>
  );
};

SampleDataInput.propTypes = {
  resultInput: PropTypes.shape({
    maxLength: PropTypes.number,
    rows: PropTypes.number,
    renderResultInput: PropTypes.func,
  }),
  dataTrySampleInput: PropTypes.any,
  onRunModel: PropTypes.func,
};

export default memo(SampleDataInput);
