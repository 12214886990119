import { lazy } from 'react';

import { BANNER_CONTENT } from 'constants/banner';
import { AM_THEME_OVERVIEW_ROUTE } from 'constants/analyticsmarketplace';

import BannerFooterLayout from 'layouts/BannerFooterLayout';

import {
  DATA_MARKETPLACE,
  EBG,
  NEWSFEED,
  NEWSFEED_MANAGE,
  NEWSFEED_PUBLISH,
  NEWSFEED_CATEGORY,
  NOTIFICATION,
  HOME,
  LANDING_PAGE_SEARCH,
  ANALYTICS_MARKETPLACE,
  ANALYTICS_MARKETPLACE_DETAIL,
  PAP,
  UPCOMING_SERVICE,
  MY_WORKSPACE,
  MANAGEMENT_DASHBOARD,
  DQ_DATA_QUALITY,
  DQ_DOMAIN_DETAIL,
  USER_MANAGEMENT,
  GET_STARTED,
  AM_MODEL_OVERVIEW,
} from './constants/breakcrumb';

const routes = [
  {
    path: HOME.id,
    component: lazy(() => import('./pages/LandingPage')),
    exact: true,
    layout: BannerFooterLayout,
    pageConfig: BANNER_CONTENT.LANDING_PAGE,
  },
  {
    path: '/auth',
    component: lazy(() => import('./pages/AuthPage')),
    exact: true,
  },
  {
    path: '/auth/callback',
    component: lazy(() => import('./pages/CallbackAuthPage')),
    exact: true,
  },
  {
    path: DATA_MARKETPLACE.id,
    component: lazy(() => import('./pages/DataMarketplaceRouterPage')),
    exact: false,
  },
  {
    path: LANDING_PAGE_SEARCH.id,
    component: lazy(() => import('./pages/LadingPageSearch')),
    exact: false,
    layout: BannerFooterLayout,
    pageConfig: BANNER_CONTENT.LANDING_PAGE,
  },
  {
    path: EBG.id,
    component: lazy(() => import('./pages/EbgPage')),
    exact: false,
  },
  {
    path: NOTIFICATION.id,
    component: lazy(() => import('./pages/NotificationPage')),
    exact: false,
  },
  {
    path: NEWSFEED.id,
    component: lazy(() => import('./pages/NewsfeedLandingPage')),
    exact: true,
    requiredAuthen: true,
  },
  {
    path: NEWSFEED_MANAGE.id,
    component: lazy(() => import('./pages/ManageNewsfeedPage')),
    exact: true,
  },
  {
    path: NEWSFEED_PUBLISH.id,
    component: lazy(() => import('./pages/NewsFeedPublishPage')),
    exact: true,
  },
  {
    path: NEWSFEED_CATEGORY.id,
    component: lazy(() => import('./pages/NewsfeedCategoryPage')),
    exact: false,
  },
  {
    path: ANALYTICS_MARKETPLACE_DETAIL.id,
    component: lazy(() => import('./pages/AMDetailedInformationPage')),
    exact: false,
  },
  {
    path: `${AM_MODEL_OVERVIEW.id}(.*-models)`,
    component: lazy(() => import('./pages/AMOverviewPage')),
    exact: true,
    layout: BannerFooterLayout,
    pageConfig: BANNER_CONTENT.AM_OVERVIEW,
  },
  {
    path: AM_THEME_OVERVIEW_ROUTE,
    component: lazy(() => import('./pages/AMThemePage')),
    exact: true,
  },
  {
    path: ANALYTICS_MARKETPLACE.id,
    component: lazy(() => import('./pages/AMLandingPage')),
    exact: false,
    layout: BannerFooterLayout,
    pageConfig: BANNER_CONTENT.AM,
  },
  {
    path: DQ_DOMAIN_DETAIL.id,
    component: lazy(() => import('./pages/DQDomainDetailPage')),
    exact: false,
  },
  {
    path: DQ_DATA_QUALITY.id,
    component: lazy(() => import('./pages/DQDataQualityPage')),
    exact: false,
  },
  {
    path: PAP.id,
    component: lazy(() => import('./pages/ComingSoonPage')),
    exact: false,
  },
  {
    path: UPCOMING_SERVICE.id,
    component: lazy(() => import('./pages/ComingSoonPage')),
    exact: false,
  },
  {
    path: MY_WORKSPACE.id,
    component: lazy(() => import('./pages/MyWorkspacePage')),
    exact: false,
  },
  {
    path: MANAGEMENT_DASHBOARD.id,
    component: lazy(() => import('./pages/DatasetManagementPage')),
    exact: false,
  },
  {
    path: USER_MANAGEMENT.id,
    component: lazy(() => import('./pages/UserManagementPage')),
    exact: false,
  },
  {
    path: GET_STARTED.id,
    component: lazy(() => import('./pages/GetStartedPage')),
    exact: false,
  },
  {
    path: '/error',
    component: lazy(() => import('./pages/ErrorPage')),
    exact: false,
  },
  {
    path: '/forbidden',
    component: lazy(() => import('./pages/ForBiddenPage')),
    exact: false,
  },
  {
    path: '*',
    component: lazy(() => import('./pages/PageNotFound')),
    exact: false,
  },
];

export default routes;
