import { call, put, takeLatest, delay, takeEvery } from 'redux-saga/effects';

import { getData, postData } from 'services/api';

import {
  USER_SEARCH_USER_MANAGEMENT,
  USER_MANAGEMENT_ROLE_REQUESTS,
  USER_ASSIGN_MODULAR_ROLE,
  USER_MANAGEMENT_ROLE_ASSIGN_CARD,
  USER_LIST_SUBMIT_TEMPLATE,
  USER_MANAGEMENT_CREATE_REQUEST,
  USER_MANAGEMENT_TDC_REQUEST,
  USER_MANAGEMENT_SUBMISSION_ID,
  USER_MANAGEMENT_ASSIGN_EDITOR,
  DATA_QUALITY_MANAGEMENT_DQ_REQUEST,
  GET_DATA_QUALITY_MANAGEMENT_ROLE_ASSIGN_CARD,
  GET_DATA_QUALITY_MANAGEMENT_REQUIRED_REVIEWERS,
  POST_DATA_QUALITY_MANAGEMENT_SUBMITTED_IDS,
  POST_DATA_QUALITY_MANAGEMENT_UPDATE_STATUS_REQUEST,
  POST_DATA_QUALITY_MANAGEMENT_ASSIGN_REQUEST,
} from 'constants/paths';

import {
  getListUserRequest,
  getListUserSuccess,
  getListUserFailure,
  searchUserRequest,
  searchUserSuccess,
  searchUserFailure,
  postAssignModularRoleUserRequest,
  postAssignModularRoleUserSuccess,
  postAssignModularRoleUserFailure,
  getRoleAssignCardRequest,
  getRoleAssignCardSuccess,
  getRoleAssignCardFailure,
  updateStatusUserRequest,
  updateStatusUserSuccess,
  updateStatusUserFailure,
  updateStatusRequestDQMRequest,
  updateStatusRequestDQMSuccess,
  updateStatusRequestDQMFailure,
  getListSubmittedTemplateRequest,
  getListSubmittedTemplateSuccess,
  getListSubmittedTemplateFailure,
  getTDCManagementRequest,
  getTDCManagementSuccess,
  getTDCManagementFailure,
  getSubmissionIdRequest,
  getSubmissionIdSuccess,
  getSubmissionIdFailure,
  assignEditorRequest,
  assignEditorSuccess,
  assignEditorFailure,
  getDQManagementSuccess,
  getDQManagementFailure,
  getDQManagementRequest,
  getDQMRoleAssignCardSuccess,
  getDQMRoleAssignCardFailure,
  getDQMRoleAssignCardRequest,
  getDQManagementRequiredReviewersSuccess,
  getDQManagementRequiredReviewersFailure,
  getDQManagementRequiredReviewersRequest,
  getDQMSubmissionIdSuccess,
  getDQMSubmissionIdFailure,
  getDQMSubmissionIdRequest,
  postAssignRequestDQMRequest,
  postAssignRequestDQMSuccess,
  postAssignRequestDQMFailure,
} from 'slices/userManagement';

export function* getListUser({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: USER_MANAGEMENT_ROLE_REQUESTS,
      params: { ...payload, userId: payload?.userId || '' },
      data: payload,
    });

    yield delay(800);
    yield put(getListUserSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getListUserFailure(message));
  }
}

export function* searchUser({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: USER_SEARCH_USER_MANAGEMENT,
      data: payload,
    });

    yield delay(800);
    yield put(searchUserSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(searchUserFailure(message));
  }
}

export function* postAssignModularRoleUser({ payload }) {
  const { params, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: USER_MANAGEMENT_CREATE_REQUEST,
      data: params,
    });

    yield delay(800);
    yield put(postAssignModularRoleUserSuccess(data));
    callback?.(true);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(postAssignModularRoleUserFailure(message));
  }
}

export function* getRoleAssignCard({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: USER_MANAGEMENT_ROLE_ASSIGN_CARD,
      params: payload,
    });

    yield delay(800);
    yield put(getRoleAssignCardSuccess({ id: payload?.id, data }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(
      getRoleAssignCardFailure({
        id: payload?.id,
        data: message,
      }),
    );
  }
}

export function* getDQMRoleAssignCard({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_DATA_QUALITY_MANAGEMENT_ROLE_ASSIGN_CARD,
      params: payload,
    });

    yield delay(800);
    yield put(getDQMRoleAssignCardSuccess({ id: payload?.id, data }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(
      getDQMRoleAssignCardFailure({
        id: payload?.id,
        data: message,
      }),
    );
  }
}

export function* updateStatusUser({ payload }) {
  const { param, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: USER_ASSIGN_MODULAR_ROLE,
      data: param,
    });

    yield delay(800);
    yield put(
      updateStatusUserSuccess({
        ...data,
        id: param?.id,
      }),
    );
    callback?.(true);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(updateStatusUserFailure(message));
  }
}

export function* updateStatusRequestDQM({ payload }) {
  const { param, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: POST_DATA_QUALITY_MANAGEMENT_UPDATE_STATUS_REQUEST,
      data: param,
    });

    yield delay(800);
    yield put(
      updateStatusRequestDQMSuccess({
        ...data,
        id: param?.id,
      }),
    );
    callback?.(true);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(updateStatusRequestDQMFailure(message));
  }
}

export function* getTDCManagement({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: `${USER_MANAGEMENT_TDC_REQUEST}`,
      data: payload,
    });

    yield delay(800);
    yield put(getTDCManagementSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getTDCManagementFailure(message));
  }
}

export function* getDQManagement({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: DATA_QUALITY_MANAGEMENT_DQ_REQUEST,
      params: payload,
    });

    yield delay(800);
    yield put(getDQManagementSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getDQManagementFailure(message));
  }
}

export function* getDQManagementRequiredReviewers() {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_DATA_QUALITY_MANAGEMENT_REQUIRED_REVIEWERS,
    });

    yield delay(800);
    yield put(getDQManagementRequiredReviewersSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getDQManagementRequiredReviewersFailure(message));
  }
}

export function* getListSubmittedTemplate() {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: USER_LIST_SUBMIT_TEMPLATE,
      data: [],
    });

    yield delay(800);
    yield put(getListSubmittedTemplateSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getListSubmittedTemplateFailure(message));
  }
}

export function* getSubmissionId({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: `${USER_MANAGEMENT_SUBMISSION_ID}`,
      data: payload,
    });

    yield delay(800);
    yield put(getSubmissionIdSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getSubmissionIdFailure(message));
  }
}

export function* getDQMSubmissionId({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: POST_DATA_QUALITY_MANAGEMENT_SUBMITTED_IDS,
      data: payload,
    });

    yield delay(800);
    yield put(getDQMSubmissionIdSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getDQMSubmissionIdFailure(message));
  }
}

export function* assignEditor({ payload }) {
  const { params, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: `${USER_MANAGEMENT_ASSIGN_EDITOR}`,
      data: params,
    });

    yield delay(800);
    yield put(assignEditorSuccess(data));
    callback?.(true);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(assignEditorFailure(message));
  }
}

export function* postAssignRequestDQM({ payload }) {
  const { params, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: POST_DATA_QUALITY_MANAGEMENT_ASSIGN_REQUEST,
      data: params,
    });

    yield delay(800);
    yield put(postAssignRequestDQMSuccess(data));
    callback?.(true);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(postAssignRequestDQMFailure(message));
  }
}

export default function* userManagementSaga() {
  yield takeLatest(getListUserRequest().type, getListUser);
  yield takeEvery(updateStatusUserRequest().type, updateStatusUser);
  yield takeEvery(updateStatusRequestDQMRequest().type, updateStatusRequestDQM);
  yield takeEvery(searchUserRequest().type, searchUser);
  yield takeEvery(
    postAssignModularRoleUserRequest().type,
    postAssignModularRoleUser,
  );
  yield takeEvery(getRoleAssignCardRequest().type, getRoleAssignCard);
  yield takeEvery(getDQMRoleAssignCardRequest().type, getDQMRoleAssignCard);
  yield takeEvery(
    getListSubmittedTemplateRequest().type,
    getListSubmittedTemplate,
  );
  yield takeEvery(getTDCManagementRequest().type, getTDCManagement);
  yield takeEvery(getDQManagementRequest().type, getDQManagement);
  yield takeEvery(
    getDQManagementRequiredReviewersRequest().type,
    getDQManagementRequiredReviewers,
  );
  yield takeEvery(getSubmissionIdRequest().type, getSubmissionId);
  yield takeEvery(getDQMSubmissionIdRequest().type, getDQMSubmissionId);
  yield takeEvery(assignEditorRequest().type, assignEditor);
  yield takeEvery(postAssignRequestDQMRequest().type, postAssignRequestDQM);
}
