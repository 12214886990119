import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button, Col, Row } from 'react-bootstrap';

import AngleDown from 'assets/icons/common/angle-down.svg';

import {
  SELECT_FIELD_OPTION,
  FILTER_VALUE,
} from 'pages/AMDetailedInformationPage/constants';

import Input from 'components/fields/Input';
import AMSelectField from 'components/fields/AMSelectField';
import RatingField from 'components/fields/Rating';
import LoadingBalls from 'components/core/LoadingBalls';
import ConfirmModal from 'components/layout/ConfirmModal';

import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';
import useQuery from 'hooks/useQuery';

import './styles.scss';

const ReportForm = ({ onSubmitForm }) => {
  const [showForm, setShowForm] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { query } = useQuery();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: query.title || '',
      typeOfForm: query.typeOfForm || '',
      description: '',
      position: null,
      ratingScore: '',
    },
  });

  const isReviewForm =
    watch('typeOfForm', false) === FILTER_VALUE.USER_REVIEW.toString();

  const {
    selectUserReviewPost: { status },
  } = useAnalyticsMarketplace();

  useEffect(() => {
    if (status === 'succeeded' && submitted) {
      setShowConfirm(true);
      setShowForm(false);
      setSubmitted(!submitted);
      reset();
    }
  }, [status]);

  const rules = {
    title: {
      required: true,
      maxLength: 1500,
    },
    position: {
      required: true,
    },
    typeOfForm: {
      required: {
        value: true,
        message: 'Type of Inquiry is required.',
      },
    },
    description: { required: true, maxLength: 3000 },
    ratingScore: {
      required: {
        value: true,
        message: 'Your Rating is required.',
      },
    },
  };

  const renderFormContent = show => {
    if (!show) {
      return null;
    }
    return (
      <>
        <div className="report-form__body">
          <Row style={{ '--bs-gutter-x': '1rem' }}>
            <Col lg={6}>
              <Input
                id="edh-user-report-form-title"
                label="Subject"
                name="title"
                placeholder="Insert Subject"
                rules={rules.title}
                error={errors.title}
                control={control}
                maxLength={1500}
              />
            </Col>
            <Col lg={6}>
              <AMSelectField
                name="typeOfForm"
                label="Type of Inquiry"
                placeholder="Select..."
                options={SELECT_FIELD_OPTION}
                rules={rules.typeOfForm}
                error={errors.typeOfForm}
                className={!watch('typeOfForm', false) ? 'default' : ''}
                control={control}
              />
            </Col>
            {isReviewForm ? (
              <>
                <Col lg={6}>
                  <Input
                    name="position"
                    label="Position"
                    control={control}
                    maxLength={1500}
                    placeholder="Insert Position"
                    rules={rules.position}
                    error={errors.position}
                  />
                </Col>
                <Col lg={6}>
                  <RatingField
                    label="Your Rating"
                    name="ratingScore"
                    starSize={12.5}
                    control={control}
                    rules={rules.ratingScore}
                    error={errors.ratingScore}
                  />
                </Col>
              </>
            ) : null}
          </Row>
          <Input
            type="textarea"
            name="description"
            control={control}
            label={isReviewForm ? 'Your Review' : 'Details'}
            placeholder={`Write your ${
              isReviewForm ? 'review' : 'details'
            } here...`}
            isTrimSpace={false}
            limit={3000}
            className="edh-input-description"
            rules={rules.description}
            error={errors.description}
          />
        </div>
        <div className="report-form__footer">
          <p className="report-form__details-note">
            <span>*</span>Please note that feedback will be sent to Data+
            administrator.
          </p>
          <Button
            className="item__button"
            variant="custom-primary"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </>
    );
  };

  const onSubmit = useCallback(
    data => {
      if (typeof onSubmitForm === 'function') {
        const submitData = isReviewForm
          ? data
          : { ...data, position: null, ratingScore: null };
        onSubmitForm(submitData);
        setSubmitted(true);
      }
    },
    [onSubmitForm, isReviewForm],
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={['report-form', !showForm && 'hide'].filter(Boolean).join(' ')}
    >
      <div className="report-form__header">
        <h3>Submit your Inquiry</h3>
        <div role="button" tabIndex="0" onClick={() => setShowForm(!showForm)}>
          <img
            className="report-form__header-icon"
            src={AngleDown}
            alt="angle-down"
          />
        </div>
      </div>
      {status === 'loading' && <LoadingBalls isLoading />}
      <ConfirmModal
        show={showConfirm}
        title="Submit Successfully"
        message="Thank you for your response. Your input is highly appreciated and we will get back to you soonest."
        category="CONFIRMATION"
        yesText="OK"
        isCancel={false}
        onYesClick={() => setShowConfirm(false)}
        onHide={() => setShowConfirm(false)}
        onCancelClick={() => setShowConfirm(false)}
      />
      {renderFormContent(showForm)}
    </form>
  );
};

ReportForm.propTypes = {
  onSubmitForm: PropTypes.func,
};

export default ReportForm;
