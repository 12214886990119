import { call, put, delay, takeEvery } from 'redux-saga/effects';

import { getData } from '../services/api';

import {
  OVERALL_DATA_OVERVIEW_API_PATH,
  DONUT_CHART_API_PATH,
  GET_MOST_DOWNLOADED_API_PATH,
} from '../constants/paths';

import {
  getDataQualityScoreRequest,
  getDataQualityScoreSuccess,
  getDataQualityScoreFailure,
  getTotalDataRequest,
  getTotalDataSuccess,
  getTotalDataFailure,
  getMostDownloadedDataRequest,
  getMostDownloadedDataSuccess,
  getMostDownloadedDataFailure,
} from '../slices/overviews';

export function* getDataQualityScore({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: OVERALL_DATA_OVERVIEW_API_PATH,
      params: payload,
    });

    yield delay(800);
    yield put(getDataQualityScoreSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getDataQualityScoreFailure(message));
  }
}

export function* getTotalData({ payload }) {
  const { prop, ...params } = payload;

  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: DONUT_CHART_API_PATH,
      params,
    });
    yield delay(800);
    yield put(getTotalDataSuccess({ prop, data }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getTotalDataFailure({ prop, message }));
  }
}

export function* getMostDownloadedData({ payload }) {
  const { prop, ...params } = payload;

  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_MOST_DOWNLOADED_API_PATH,
      params,
    });
    yield delay(800);
    yield put(getMostDownloadedDataSuccess({ prop, data }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getMostDownloadedDataFailure({ prop, message }));
  }
}

export default function* overviewsSaga() {
  yield takeEvery(getDataQualityScoreRequest().type, getDataQualityScore);
  yield takeEvery(getTotalDataRequest().type, getTotalData);
  yield takeEvery(getMostDownloadedDataRequest().type, getMostDownloadedData);
}
