import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import TableCellTextEditor from 'components/layout/TableCellTextEditor';

const TableCells = ({
  isEditAble,
  cellProps,
  updatedDataRows,
  setUpdatedDataRows,
}) => {
  const { column, row, value } = cellProps;

  const originalRow = row.original;
  const columnId = column.id;

  return useMemo(
    () => (
      <TableCellTextEditor
        disabled={!isEditAble}
        customNullValue=""
        cellProps={cellProps}
        onUpdateDataEdit={(updatedRow, updatedColumn, updatedValue) => {
          const index = updatedDataRows.findIndex(e => e.id === originalRow.id);

          if (index !== -1) {
            const newUpdatedDataRows = [...updatedDataRows];

            newUpdatedDataRows[index] = {
              ...newUpdatedDataRows[index],
              [columnId]: updatedValue.toString(),
            };

            setUpdatedDataRows(newUpdatedDataRows);
          } else {
            setUpdatedDataRows(prev => [
              ...prev,
              { ...originalRow, [columnId]: updatedValue.toString() },
            ]);
          }
        }}
      />
    ),
    [value, originalRow, columnId, updatedDataRows, setUpdatedDataRows],
  );
};

TableCells.propTypes = {
  isEditAble: PropTypes.bool,
  cellProps: PropTypes.object,
  updatedDataRows: PropTypes.array,
  setUpdatedDataRows: PropTypes.func,
};

export default memo(TableCells);
