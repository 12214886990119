import React, {
  memo,
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select, { createFilter } from 'react-select';
import { BsSearch, BsCaretDownFill, BsXLg } from 'react-icons/bs';

import classnames from 'services/classnames';

import { LIMIT_CHARACTERS } from 'constants/dataRequestForm';

import { trimSpace } from 'services/string';

import RSDropdownIndicator from 'components/core/RSDropdownIndicator';
import RSMenuList from 'components/core/RSMenuList';
import Tooltip from '../Tooltip';

import './styles.scss';

const ENTER_KEY = 13;

const ReactSelectCustom = ({
  value,
  placeholder,
  maxLength,
  menuListTitle,
  menuPlacement,
  dropdownIndicatorElement,
  dropdownIndicatorElementType,
  isOpenMenuOnFocus,
  isGetDataWhenOpenHandler,
  isClearable,
  isDisabled,
  isLoading,
  isSearchable,
  isFiltered,
  isMulti,
  isMultiSelectionExceedLimit,
  limitText,
  limitLength,
  isSelectDataset,
  disableSearchEmpty,
  closeMenuOnSelect,
  components,
  options,
  filterOption,
  onEnter,
  onSearchData,
  onChangeSelectedItems,
  onClearOptionsData,
  onClearError,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputSearched, setInputSearched] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(true);
  const [isClear, setIsClear] = useState();
  const selectRef = useRef();
  const isPasteRef = useRef();

  let isEnterKey = false;
  const filterConfig = useMemo(
    () => ({
      ignoreCase: true,
      ignoreAccents: true,
      matchFrom: 'any',
      stringify: option => `${option.label} ${option.value}`,
      trim: true,
    }),
    [],
  );

  const handleSearchData = useCallback(
    searchKeyword => {
      if (
        isMulti &&
        !isMultiSelectionExceedLimit &&
        value?.length >= limitLength
      )
        return;

      onSearchData(searchKeyword);
    },
    [onSearchData, limitLength, value, isMulti],
  );

  const setInputChangeHandler = useCallback(
    _.debounce(inputChange => {
      handleSearchData(inputChange);
    }, 250),
    [isFiltered, handleSearchData],
  );

  const onSearchKeyWord = () => {
    const valueInput = trimSpace(selectRef?.current?.inputRef?.value);
    if (valueInput) {
      onEnter({
        label: valueInput,
        value: valueInput,
      });
      setInputSearched(valueInput);
    }

    setInputValue('');
    selectRef?.current?.blur();
  };

  const onFocus = () => {
    setIsFocus(true);

    if (isOpenMenuOnFocus) {
      selectRef?.current?.focus();
      setIsShowMenu(true);
    }
  };

  const componentsCustom = useMemo(
    () => ({
      IndicatorSeparator: null,
      MenuList: props =>
        isShowMenu && (
          <RSMenuList {...props} title={menuListTitle} isLoading={isLoading} />
        ),
      DropdownIndicator: prop => {
        const onClear = () => {
          prop.clearValue();
          setIsClear(true);
          onClearError();
          if (!disableSearchEmpty) {
            setInputChangeHandler('');
          }
        };

        return (
          !isDisabled && (
            <RSDropdownIndicator
              {...prop}
              dropdownIndicatorElement={
                dropdownIndicatorElement ||
                (isSearchable ? (
                  <>
                    {isClearable && (inputValue || value) && (
                      <Tooltip content="Clear">
                        <div
                          role="button"
                          tabIndex={-1}
                          {...prop.innerProps}
                          style={{ cursor: 'pointer', marginRight: '8px' }}
                          onTouchStart={onClear}
                          onMouseDown={onClear}
                        >
                          <BsXLg size={12} />
                        </div>
                      </Tooltip>
                    )}
                    <Tooltip content="Search">
                      <div>
                        <BsSearch
                          fill="#8D8D8D"
                          onTouchStart={onSearchKeyWord}
                          onClick={onSearchKeyWord}
                          size={16}
                        />
                      </div>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip content="Open dropdown">
                    <div>
                      <BsCaretDownFill size={16} />
                    </div>
                  </Tooltip>
                ))
              }
              dropdownIndicatorElementType={dropdownIndicatorElementType}
              onFocus={onFocus}
            />
          )
        );
      },
      NoOptionsMessage: () => (
        <div className="edh-select__no-options">
          {!isLoading && 'No options'}
        </div>
      ),
      ...components,
    }),
    [
      isLoading,
      isSearchable,
      isShowMenu,
      inputValue,
      isClear,
      value,
      components,
      menuListTitle,
      dropdownIndicatorElement,
      dropdownIndicatorElementType,
    ],
  );

  useEffect(() => {
    const target = document.querySelector('div.edh-select__input-container');
    if (target) {
      target.addEventListener('paste', () => {
        isPasteRef.current = true;
      });
    }
  }, []);

  const inputChangeHandler = useCallback(
    (inputChange, { action }) => {
      const newInputValue =
        inputChange.length <= maxLength
          ? inputChange
          : inputChange.substr(0, maxLength);

      if (
        action === 'input-change' &&
        (inputValue.length < maxLength ||
          newInputValue.length < maxLength ||
          (inputValue.length === maxLength && isPasteRef.current))
      ) {
        setInputValue(newInputValue);
        setInputChangeHandler(newInputValue);
        isPasteRef.current = false;
      }
    },
    [setInputValue, handleSearchData, inputValue, disableSearchEmpty],
  );

  const menuOpenHandler = useCallback(() => {
    if (!isClear) {
      if (value && isGetDataWhenOpenHandler) {
        if (isMulti) {
          setInputChangeHandler(inputValue);
        } else {
          setInputChangeHandler(value.label);
        }
      } else if (!isSearchable || isFiltered || isFocus) {
        handleSearchData(inputValue);
      }
    }

    if (!disableSearchEmpty) {
      setInputChangeHandler(inputValue || value?.label);
    }
  }, [
    handleSearchData,
    onChangeSelectedItems,
    value,
    inputValue,
    isFiltered,
    isGetDataWhenOpenHandler,
    isClear,
    disableSearchEmpty,
    isFocus,
  ]);

  const menuCloseHandler = useCallback(() => {
    if (!isMulti && !isSearchable && _.isEmpty(value)) {
      setInputValue('');
    }

    onClearOptionsData();
  }, [isMulti, isSearchable, onClearOptionsData, onChangeSelectedItems]);

  const onChange = useCallback(
    itemSelected => {
      const checkEnter = isEnterKey && onEnter;

      if (isMulti) {
        setInputValue('');
        onChangeSelectedItems(itemSelected);
      }
      if (!isMulti) {
        if (checkEnter) {
          isEnterKey = false;
          return;
        }
        if (!checkEnter) {
          if (itemSelected?.isAdded) {
            setInputSearched(itemSelected?.value);
          } else {
            setInputSearched('');
          }
          onChangeSelectedItems(itemSelected);
          setInputValue('');
        }
      }
      setIsClear();
    },
    [selectRef, isEnterKey, onChangeSelectedItems],
  );

  const onKeyDown = useCallback(
    ({ keyCode }) => {
      isEnterKey = keyCode === ENTER_KEY;

      if (isEnterKey && onEnter) {
        onSearchKeyWord();
      }
    },
    [selectRef, isEnterKey, onChangeSelectedItems],
  );

  const optionArray = useMemo(() => {
    if (
      (isSearchable &&
        !value &&
        !inputValue &&
        !isMulti &&
        disableSearchEmpty) ||
      (isSelectDataset && !inputSearched && !inputValue && isSearchable)
    ) {
      setIsShowMenu(false);
      return [];
    }

    setIsShowMenu(true);
    return options;
  }, [inputValue, options, value]);

  const onBlur = () => {
    setIsFocus(false);
  };

  useEffect(() => {
    if (value !== undefined) {
      setInputSearched(value?.label || value?.value);
      onClearError();
    }
  }, [value]);

  return (
    <>
      <Select
        ref={selectRef}
        className="edh-select"
        classNamePrefix={classnames(
          {
            'edh-select--hide-menu':
              !isShowMenu ||
              (isMulti &&
                !isMultiSelectionExceedLimit &&
                value?.length >= limitLength),
          },
          'edh-select',
        )}
        menuPlacement={menuPlacement}
        placeholder={placeholder}
        isDisabled={isDisabled}
        openMenuOnFocus={isOpenMenuOnFocus}
        isSearchable={
          isMulti
            ? isSearchable &&
              (isMultiSelectionExceedLimit || value?.length || 0) < limitLength
            : isSearchable
        }
        isMulti={isMulti}
        isLoading={isMulti && isLoading && isSelectDataset}
        closeMenuOnSelect={closeMenuOnSelect}
        isOptionDisabled={optionArr => {
          if (optionArr.isDisabled) {
            return optionArr.isDisabled;
          }
          return (
            isMulti &&
            !isMultiSelectionExceedLimit &&
            value?.length >= limitLength
          );
        }}
        value={value}
        inputValue={inputValue}
        maxMenuHeight={200}
        filterOption={(!isMulti && filterOption) || createFilter(filterConfig)}
        components={componentsCustom}
        options={optionArray}
        onInputChange={inputChangeHandler}
        onChange={onChange}
        onMenuOpen={menuOpenHandler}
        onMenuClose={menuCloseHandler}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      {isSearchable && !isDisabled && !limitText ? (
        <span className="edh-select--max-length">
          {inputValue?.length || inputSearched?.length || 0}/{maxLength}{' '}
          characters
        </span>
      ) : (
        limitText && (
          <span className="edh-select--up-to-numbers-datasets">
            {value?.length || 0} / {limitLength} {limitText}
          </span>
        )
      )}
    </>
  );
};

ReactSelectCustom.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  menuListTitle: PropTypes.string,
  menuPlacement: PropTypes.string,
  limitText: PropTypes.string,
  limitLength: PropTypes.number,
  maxLength: PropTypes.number,
  dropdownIndicatorElement: PropTypes.element,
  dropdownIndicatorElementType: PropTypes.elementType,
  isGetDataWhenOpenHandler: PropTypes.bool,
  isOpenMenuOnFocus: PropTypes.bool,
  isClearable: PropTypes.bool,
  isFiltered: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isMultiSelectionExceedLimit: PropTypes.bool,
  isSelectDataset: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  disableSearchEmpty: PropTypes.bool,
  components: PropTypes.object,
  options: PropTypes.array,
  filterOption: PropTypes.func,
  onEnter: PropTypes.func,
  onSearchData: PropTypes.func,
  onChangeSelectedItems: PropTypes.func,
  onClearOptionsData: PropTypes.func,
  onClearError: PropTypes.func,
};

ReactSelectCustom.defaultProps = {
  placeholder: 'start your search...',
  menuListTitle: 'SUGGESTED KEYWORDS',
  menuPlacement: 'auto',
  limitText: '',
  limitLength: 0,
  maxLength: LIMIT_CHARACTERS.input,
  dropdownIndicatorElement: null,
  dropdownIndicatorElementType: null,
  isGetDataWhenOpenHandler: true,
  isOpenMenuOnFocus: false,
  isClearable: false,
  isFiltered: false,
  isDisabled: false,
  isLoading: false,
  isSearchable: true,
  isMulti: true,
  isMultiSelectionExceedLimit: false,
  isSelectDataset: false,
  closeMenuOnSelect: true,
  disableSearchEmpty: true,
  components: {},
  options: [],
  filterOption: options => options,
  onSearchData() {},
  onChangeSelectedItems() {},
  onClearOptionsData() {},
  onClearError() {},
  onEnter() {},
};

export default memo(ReactSelectCustom);
