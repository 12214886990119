export const BAD_DATA = 'bad-data';
export const DATA_QUALITY = 'data-quality';

export const DQ_RULES_CATEGORY = 'DQ RULES';
export const DQ_RULES_SECTION_TITLE = 'Total Number of DQ Rules';
export const DQ_RULES_SECTION_BUTTON_LABEL = 'Go to DQ Rules';
export const DQ_RULES_POPUP_TITLE = 'Data Quality (DQ) Rules Library';
export const DQ_RULES_DOWNLOAD_TOOLTIP_CONTENT = 'Download as CSV/Excel';
export const DQ_RULES_REQUEST_NEW_EXTENDED_VERSION =
  'Request New Extend Version';
export const DQ_RULES_VIEW_OTHER_CHILD_RULES = "View Other Child Rules"; 
export const DQ_RULES_MY_RULES_LIBRARY = 'My Rules Library';
export const DQ_RULES_CREATE_AND_UPLOAD = 'Create/Upload DQ Rules';
export const DQ_RULES_MY_RULES_LIBRARY_NOT_FOUND_MESSAGE_TITLE =
  'No result found';
export const DQ_RULES_MY_RULES_LIBRARY_NOT_FOUND_MESSAGE_DESC = `Please try another search or you can proceed to request new rules by clicking the button.`;
export const DQ_RULES_MY_RULES_LIBRARY_NO_DQ_RULES_MESSAGE_TITLE =
  'No DQ Rules';
export const DQ_RULES_MY_RULES_LIBRARY_NO_DQ_RULES_MESSAGE_DESC =
  'Your DQ library is empty. Start to add the DQ Rules to your library.';
