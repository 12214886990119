import React from 'react';
import PropTypes from 'prop-types';
import Banner from 'components/layout/Banner';
import Footer from 'components/layout/Footer';

const BannerFooterLayout = ({ children, pageConfig }) => {
  const { pageTitle, description, logoTitle } = pageConfig;

  return (
    <>
      <Banner
        pageTitle={pageTitle}
        description={description}
        logoTitle={logoTitle}
      />
      {children}
      <Footer />
    </>
  );
};

BannerFooterLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageConfig: PropTypes.object.isRequired,
};

export default BannerFooterLayout;
