import React, {
  memo,
  useEffect,
  useState,
  useMemo,
  useRef,
  useLayoutEffect,
} from 'react';
import { debounce } from 'lodash';
import * as PropTypes from 'prop-types';
import { Overlay, Popover } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import Status from 'components/core/Status';
import useClickOutside from 'hooks/useClickOutside';
import useDevice from 'hooks/useDevice';
import { useDidMount } from 'hooks/useDidMount';
import useHover from 'hooks/useHover';

import './styles.scss';

const AMTagLists = ({
  id,
  classification,
  themes,
  activatedTag,
  isClickTag,
  isHighlightText,
  isHidden,
  onClick,
  searchKeyword = [],
}) => {
  const [offsetMap, setOffsetMap] = useState([]);
  const [numberShowItem, setNumberShowItem] = useState(1);
  const [idElement, setIdElement] = useState(`tag-list-${id}`);
  const [hoverAMRef, isHovered] = useHover();
  const [popoverAMRef, isHoveredPopover] = useHover();
  const [isShow, setShow] = useState(false);

  const containerRef = useRef();
  const { isMobileDevice } = useDevice();

  useClickOutside(hoverAMRef, () => {
    setShow(false);
  });

  useEffect(() => {
    setIdElement(`tag-list-${id}`);
  }, [id]);

  const tags = useMemo(() => {
    const tagsArr = [];
    if (classification && typeof classification?.text !== 'string') {
      classification?.text?.forEach(element => {
        tagsArr.push({
          text: element,
          background: classification.background,
          color: classification.color,
          type: 'ModelClassification',
        });
      });
    }
    if (themes && typeof themes?.text !== 'string') {
      themes?.text?.forEach(elem => {
        tagsArr.push({
          text: elem,
          background: themes.background,
          color: themes.color,
          type: 'ModelTheme',
        });
      });
    }
    return tagsArr;
  }, [classification, themes]);

  const getOffsetMap = () => {
    const tagElm = document.getElementById(idElement);
    const leftMap = [];
    const { length } = tags;
    if (!tagElm) {
      return;
    }
    tagElm.childNodes.forEach((item, index) => {
      const element = document.createElement('div');
      element.className = 'edh-status';
      element.innerText = `+${length - index - 1}`;
      element.style.position = 'absolute';
      element.style.left = `${item.offsetLeft + item.clientWidth + 4}px`;
      tagElm.parentNode.appendChild(element);
      leftMap.push(element.offsetLeft + element.clientWidth);
      element.remove();
    });
    tagElm.remove();
    setOffsetMap(leftMap);
  };

  const getNumberShowItem = offsetWidth => {
    let show = tags.length;
    for (let i = 0; i < offsetMap.length; i++) {
      if (offsetMap[i] > offsetWidth) {
        show = i;
        break;
      }
    }
    setNumberShowItem(show);
  };

  const numberHiddenItem = tags.length - numberShowItem;

  useDidMount(() => {
    getOffsetMap();
  });

  useLayoutEffect(() => {
    const ref = containerRef.current;
    const observer = new ResizeObserver(
      debounce(item => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(item) || !item.length) {
            return;
          }
          const { offsetWidth } = item[0].target;
          if (isHidden) {
            getNumberShowItem(offsetWidth);
          } else {
            setNumberShowItem(tags?.length || 0);
          }
        });
      }, 250),
    );
    if (ref && ref.children.length > 0) {
      observer.observe(ref);
    }
    return () => {
      observer.disconnect();
    };
  }, [offsetMap, tags]);

  const onClickTag = ({ type, text }) => {
    if (!isClickTag) return null;
    onClick({ type, text });
  };

  const renderTagEle = list =>
    list.map(({ text, background, color, type }) => (
      <Status
        key={`${text}-${activatedTag}`}
        cursor={isClickTag ? 'pointer' : 'default'}
        background={activatedTag === text ? 'FEDC92' : background}
        color={color}
        text={
          <span className="edh-am-tags__text">
            {isHighlightText ? (
              <Highlighter
                highlightClassName="item__title-highlighter"
                searchWords={searchKeyword}
                autoEscape
                textToHighlight={text}
              />
            ) : (
              <span>{text}</span>
            )}
          </span>
        }
        tag
        activatedTag={activatedTag === text}
        onClick={() => onClickTag({ type, text })}
      />
    ));

  const renderHiddenElement = () => {
    if (numberHiddenItem === 0) {
      return null;
    }
    return (
      <div
        ref={hoverAMRef}
        className="edh-am-tags__number"
        role="button"
        tabIndex={0}
        onClick={() => {
          setShow(!isShow);
        }}
      >
        <Status text={`+${numberHiddenItem}`} tag />
        <Overlay
          show={isMobileDevice ? isShow : isHovered || isHoveredPopover}
          target={hoverAMRef}
        >
          <Popover className="edh-am-tags__number-tooltip">
            <div
              ref={popoverAMRef}
              className="edh-am-tags__number-tooltip-body"
            >
              {renderTagEle([...tags.slice(numberShowItem)])}
            </div>
          </Popover>
        </Overlay>
      </div>
    );
  };

  return (
    <div ref={containerRef} className="edh-am-tags">
      <div className="edh-am-tags__list temp-list" id={idElement}>
        {tags && renderTagEle(tags)}
      </div>
      <div className="edh-am-tags__list">
        {renderTagEle(tags.slice(0, numberShowItem))}
      </div>
      {renderHiddenElement()}
    </div>
  );
};

AMTagLists.propTypes = {
  isClickTag: PropTypes.bool,
  isHighlightText: PropTypes.bool,
  isHidden: PropTypes.bool,
  id: PropTypes.string,
  activatedTag: PropTypes.string,
  classification: PropTypes.object,
  themes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  searchKeyword: PropTypes.array,
  onClick: PropTypes.func,
};

AMTagLists.defaultProps = {
  isClickTag: true,
  isHighlightText: false,
  isHidden: false,
  id: 'am-tags-ele',
  activatedTag: '',
  classification: {},
  themes: {},
  onClick() {},
};

export default memo(AMTagLists);
