import Modal from 'components/layout/Modal';
import React from 'react';

const withModal = (PassedComponent, modalProps) => {
  const WrapperComponent = props => (
    <Modal {...modalProps} {...props}>
      <PassedComponent {...props} />
    </Modal>
  );

  return WrapperComponent;
};

export default withModal;
