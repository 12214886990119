import EDHLogoDark from 'assets/icons/logo/edh-logo-dark.svg';

export const BANNER_CONTENT = {
  LANDING_PAGE: {
    logoTitle: EDHLogoDark,
    description:
      'Data+ is the enterprise portal to discover and analyse data & content for bright insights, driving business results',
  },
  DATA_MARKET_PLACE: {
    pageTitle: 'Data Marketplace',
    description:
      'The Data Marketplace is where data citizens can explore and retrieve datasets that are available in Data+. Each dataset is accompanied by detailed metadata information, sample data and data quality health score for reference. A dataset is a collection of multiple data attributes that have relationships and can be used for analytics to provide insights.',
  },
  AM: {
    pageTitle: 'Analytics Marketplace',
    description:
      'Revolutionise your business needs with customisable AI models and streamlined automation in the Analytics Marketplace',
  },
  AM_OVERVIEW: {
    pageTitle: 'Analytics Marketplace',
    description:
      'Revolutionise your business needs with customisable AI models and streamlined automation in the Analytics Marketplace',
  },
};
