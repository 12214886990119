/* eslint-disable react/no-danger */
import React, { memo, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { some, isEmpty } from 'lodash';
import * as PropTypes from 'prop-types';
import { Document, Page } from 'components/core/ReactPdf';

import SampleZoomIn from 'assets/icons/common/sample-data-zoom-in.svg';
import SampleZoomOut from 'assets/icons/common/sample-data-zoom-out.svg';
import CheckIcon from 'assets/icons/common/check-long.svg';
import { ReactComponent as DownloadCSVIcon } from 'assets/icons/common/download-csv.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/common/close.svg';

import useDevice from 'hooks/useDevice';

import { convertCsvToJson } from 'services/string';
import { formatFileSize } from 'services/file';

import KeywordSkillSetTemplateModal from 'features/AM/components/layout/KeywordSkillSetTemplateModal';
import useSamplePagination from 'features/AM/hooks/useSamplePagination';
import AMImageZoom from 'components/core/AMImageZoom';
import LoadingBalls from 'components/core/LoadingBalls';
import Expire from 'components/core/Expire';

import { typeFileMap } from '../../../constants';

const ModalContainer = ({
  showSample,
  onCloseModal,
  sizeModal,
  isMobileDevice,
  onSetScale,
  renderPagination,
  children,
}) => (
  <Modal
    className="sample-data__modal"
    show={showSample?.key}
    centered
    backdrop="static"
  >
    <div className="sample-data__modal__header">
      <p className="sample-data__modal__header__title">
        {showSample?.modalName}
      </p>
      <CloseIcon
        className="sample-data__modal__header__close"
        onClick={onCloseModal}
      />
    </div>
    <div className="sample-data__modal__body">
      <div className="sample-data__modal__content">
        <div
          className="sample-data__modal__preview"
          style={{ width: sizeModal.width, height: sizeModal.height }}
        >
          <AMImageZoom
            scaleOffset={1}
            step={0.5}
            range={[0.5, 6]}
            canvasMode={showSample?.file?.type === 'pdf' && !isMobileDevice}
            setScale={onSetScale}
          >
            {children}
          </AMImageZoom>
        </div>
        {renderPagination()}
        <button
          type="button"
          className="sample-data__zoom-out"
          onClick={onCloseModal}
        >
          <img alt="Zoom-in" src={SampleZoomOut} />
        </button>
      </div>
    </div>
  </Modal>
);

const CardContainer = ({
  sample,
  index,
  checkSelected,
  setShowSample,
  data,
  setScale,
  children,
}) => (
  <div className="sample-data__card">
    <label htmlFor={sample.key}>
      {children}
      <div id="blur" />
    </label>
    {checkSelected(sample.key) ? (
      <Expire delay={10000}>
        <img className="sample-data__success" alt="" src={CheckIcon} />
      </Expire>
    ) : null}
    <button
      type="button"
      className="sample-data__zoom-in"
      onClick={() => {
        setShowSample({ ...data[index], index });
        setScale(1);
      }}
    >
      <img alt="Zoom-in" src={SampleZoomIn} />
    </button>
  </div>
);

const SampleDataPreview = ({
  data,
  label,
  disabled,
  selectedSample,
  setSelectedSample,
  multiSample,
  contentType,
}) => {
  const defaultWidth = 640;

  const [showSample, setShowSample] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState({});
  const [fileSize, setFileSize] = useState([]);
  const { pageNumber, setPageNumber, setTotalPages, renderPagination } =
    useSamplePagination();

  const { isMobileDevice } = useDevice();
  const [sizeModal, setSizeModal] = useState({
    width: defaultWidth,
    height: 300,
  });
  const [sizePDF, setSizePDF] = useState({
    width: 0,
    height: 0,
  });

  const [scale, setScale] = useState(1);

  const { csv } = typeFileMap;
  const fetchSampleData = async (sampleData, callback) => {
    const res = await fetch(sampleData);
    callback(res);
  };

  const checkSelected = key =>
    multiSample
      ? some(selectedSample, ['key', key])
      : selectedSample.key === key;

  const onSelectSample = key => {
    setSelectedSample({
      key: selectedSample.key !== key ? key : '',
    });
  };

  const onCloseModal = () => {
    setShowSample(null);
    setPageNumber(1);
  };

  const onSetScale = value => {
    if (!isMobileDevice) {
      setScale(value);
    }
  };

  useEffect(() => {
    const { width, height } = sizePDF;

    if (width && height) {
      if (isMobileDevice) {
        setSizeModal({
          width: document.body.offsetWidth - 40,
          height: Math.min(height, window.screen.height - 150),
        });
      } else {
        setSizeModal({
          width,
          height: height * 1.1,
        });
      }
    }
  }, [isMobileDevice, sizePDF]);

  const renderSampleDataPdf = {
    renderCard: (sample, sampleIndex) => (
      <CardContainer
        sample={sample}
        index={sampleIndex}
        checkSelected={checkSelected}
        setShowSample={setShowSample}
        data={data}
        setScale={setScale}
      >
        <Document file={sample.file}>
          <Page
            onLoadSuccess={page => {
              const {
                viewBox: [, , width, height],
              } = page.getViewport();
              setSizePDF({ width, height });
            }}
            pageNumber={1}
            renderAnnotationLayer={false}
            width={124}
          />
        </Document>
      </CardContainer>
    ),
    renderModal: () => (
      <ModalContainer
        showSample={showSample}
        onCloseModal={onCloseModal}
        sizeModal={sizeModal}
        isMobileDevice={isMobileDevice}
        onSetScale={onSetScale}
        renderPagination={renderPagination}
      >
        <Document
          file={{
            url: showSample?.file?.url,
          }}
          loading={<LoadingBalls isLoading />}
          onLoadSuccess={({ numPages }) => {
            setTotalPages(numPages);
          }}
        >
          <Page
            loading={<LoadingBalls isLoading />}
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            scale={scale}
            renderMode={isMobileDevice ? 'svg' : 'canvas'}
          />
        </Document>
      </ModalContainer>
    ),
  };

  const renderSampleDataImg = {
    renderCard: (sample, sampleIndex) => (
      <CardContainer
        sample={sample}
        index={sampleIndex}
        checkSelected={checkSelected}
        setShowSample={setShowSample}
        data={data}
        setScale={setScale}
      >
        <img
          className="sample-data__preview-image"
          draggable
          src={sample.file.url}
          alt="Sample input"
        />
      </CardContainer>
    ),
    renderModal: sample => (
      <ModalContainer
        showSample={showSample}
        onCloseModal={onCloseModal}
        sizeModal={sizeModal}
        isMobileDevice={isMobileDevice}
        onSetScale={onSetScale}
        renderPagination={renderPagination}
      >
        <img
          className="sample-data__preview-image"
          onLoad={e => {
            const { clientHeight, clientWidth } = e.target;
            if (isMobileDevice) {
              const width = document.body.offsetWidth - 102;
              setSizeModal({
                width,
                height: (width * clientHeight) / clientWidth,
              });
            } else {
              setSizeModal({
                width: Math.max(clientWidth, defaultWidth),
                height: clientHeight * 1.1,
              });
            }
          }}
          style={{ height: sizeModal.height }}
          draggable
          src={sample.file.url}
          alt="Sample input"
        />
      </ModalContainer>
    ),
  };
  const renderSampleDataCSV = {
    renderCard(sample, sampleIndex) {
      if (!Object.hasOwn(tableData, sample.key)) {
        fetchSampleData(sample.file.url, async res => {
          const dataResponse = await res.arrayBuffer();
          const dec = new TextDecoder('windows-1251');
          const textResponse = dec.decode(dataResponse);
          const jsonData = convertCsvToJson(textResponse);
          const alphabet = [...Array(jsonData.headers.length).keys()].map(i =>
            String.fromCharCode(i + 97).toUpperCase(),
          );
          const formatFileToTable = JSON.parse(
            JSON.stringify(
              {
                columns: [
                  { accessor: 'action', Header: '' },
                  { accessor: 'headerIndex', Header: '' },
                  ...alphabet.map((item, index) => ({
                    accessor: jsonData.headers[index].key || item,
                    Header: item,
                  })),
                ],
                rows: [
                  {
                    ...Object.keys(Object.assign({}, ...jsonData.data)).reduce(
                      // eslint-disable-next-line no-return-assign,no-sequences
                      (acc, curr) => ((acc[curr] = curr), acc),
                      {},
                    ),
                    action: 'Action',
                    headerIndex: '1',
                  },
                  ...jsonData.data.map(item => item),
                ],
              },
              null,
              4,
            ),
          );
          await setFileSize(prev => [...prev, dataResponse.byteLength]);
          await setTableData(prev => ({
            ...prev,
            [sample.key]: formatFileToTable,
          }));
        });
      }
      return (
        <div
          className={`sample-data__template ${
            checkSelected(sample.key) && 'active'
          } ${disabled ? 'disabled' : ''}`}
          role="presentation"
          onClick={() => {
            if (!disabled) {
              onSelectSample(sample.key);
            }
          }}
        >
          <DownloadCSVIcon className="file-icon" />
          <div className="file-container">
            <div className="file-info">
              <span
                className="file-name"
                onClick={e => {
                  if (!disabled) {
                    e.stopPropagation();
                    setShowTable(true);
                    setShowSample({ ...data[sampleIndex], sampleIndex });
                  }
                }}
                role="presentation"
              >
                {sample.key}
              </span>
              <span className="file-size">
                ({formatFileSize(fileSize[sampleIndex])})
              </span>
            </div>
            {checkSelected(sample.key) ? (
              <Expire delay={10000}>
                <img className="sample-data__success" alt="" src={CheckIcon} />
              </Expire>
            ) : null}
          </div>
        </div>
      );
    },
    renderModal(sample) {
      const modalWidth = window.innerWidth - 32;
      return (
        !isEmpty(tableData[sample.key]) && (
          <KeywordSkillSetTemplateModal
            data={tableData[sample.key]}
            isShowModal={showTable}
            isEditAble={false}
            onClose={() => {
              setShowSample({});
              setShowTable(false);
            }}
            title={sample.key}
            customWidth={
              (modalWidth - 100) / (tableData[sample.key].columns.length - 2)
            }
          />
        )
      );
    },
  };
  const renderSampleDataPreview = {
    pdf: renderSampleDataPdf,
    jpg: renderSampleDataImg,
    png: renderSampleDataImg,
    csv: renderSampleDataCSV,
  };
  return (
    <div className={`sample-data ${disabled ? 'disabled' : ''}`}>
      <p className="sample-data__title">{label}</p>
      <div
        className={`sample-data__group ${
          contentType.includes(csv) ? 'full-width' : ''
        }`}
      >
        {data.map((sample, sampleIndex) => (
          <React.Fragment key={sample.key}>
            <input
              type="checkbox"
              id={sample.key}
              name="select-sample"
              checked={checkSelected(sample.key)}
              onChange={() => onSelectSample(sample.key)}
              disabled={disabled}
            />
            {renderSampleDataPreview[sample.file.type]?.renderCard(
              sample,
              sampleIndex,
            )}
          </React.Fragment>
        ))}
      </div>
      {(showSample?.key || showTable) &&
        renderSampleDataPreview[showSample?.file?.type]?.renderModal(
          showSample,
        )}
    </div>
  );
};

SampleDataPreview.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      file: PropTypes.object,
    }),
  ),
  label: PropTypes.string,
  contentType: PropTypes.array,
  disabled: PropTypes.bool,
  multiSample: PropTypes.bool,
  selectedSample: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({ key: PropTypes.string }),
  ]),
  setSelectedSample: PropTypes.func,
};

ModalContainer.propTypes = {
  sizeModal: PropTypes.object,
  showSample: PropTypes.object,
  isMobileDevice: PropTypes.bool,
  onSetScale: PropTypes.func,
  renderPagination: PropTypes.func,
  onCloseModal: PropTypes.func,
  children: PropTypes.node,
};
CardContainer.propTypes = {
  index: PropTypes.number,
  sample: PropTypes.object,
  data: PropTypes.array,
  checkSelected: PropTypes.func,
  setShowSample: PropTypes.func,
  setScale: PropTypes.func,
  children: PropTypes.node,
};
export default memo(SampleDataPreview);
