import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  changeLandingPageRunTour,
  landingPageRunTourSelector,
} from 'slices/tourSlice';

const useTour = () => {
  const landingPageRunTour = useSelector(landingPageRunTourSelector);
  const dispatch = useDispatch();

  const handleLandingPageShowTour = useCallback(() => {
    dispatch(changeLandingPageRunTour(true));
  }, [changeLandingPageRunTour]);

  const handleLandingPageHideTour = useCallback(() => {
    dispatch(changeLandingPageRunTour(false));
  }, [changeLandingPageRunTour]);

  return {
    landingPageRunTour,
    onLandingPageShowTour: handleLandingPageShowTour,
    onLandingPageHideTour: handleLandingPageHideTour,
  };
};

export default useTour;
