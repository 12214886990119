import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import RatingStar from 'components/layout/RatingStar';
import Field from '../Field';

import './styles.scss';

const RatingField = ({
  control,
  id,
  className,
  label,
  name,
  rules,
  error,
  starSize,
  isTooltip,
}) => (
  <Field
    rules={rules}
    id={id}
    className={className}
    label={label}
    error={error}
  >
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <RatingStar
          ratingScore={value}
          starSize={starSize}
          onRating={({ index }) => onChange(index)}
          isTooltip={isTooltip}
        />
      )}
    />
  </Field>
);

RatingField.propTypes = {
  control: PropTypes.any.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  starSize: PropTypes.number,
  isTooltip: PropTypes.bool,
  rules: PropTypes.object,
  error: PropTypes.object,
};

RatingField.defaultProps = {
  id: '',
  className: 'rating-field',
  label: '',
  name: '',
  starSize: 16,
  rules: {},
  error: {},
};

export default memo(RatingField);
