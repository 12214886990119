import { useEffect, useState } from 'react';

import useWindowSize from './useWindowSize';

const useEllipsis = id => {
  const [isEllipsis, setIsEllipsis] = useState(true);
  const size = useWindowSize();

  useEffect(() => {
    const elm = document.getElementById(id);
    if (elm)
      setIsEllipsis(
        elm.scrollHeight > elm.offsetHeight ||
          elm.scrollWidth > elm.offsetWidth,
      );
  }, [id, size]);

  return { isEllipsis };
};

export default useEllipsis;
