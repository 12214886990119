import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'notifications';
export const PROPS_NOTIFICATIONS = {
  NOTIFICATIONS: 'notifications',
  NOTIFICATIONS_POPUP: 'notificationsPopup',
};

const initialState = {
  [PROPS_NOTIFICATIONS.NOTIFICATIONS]: {
    data: {
      unread: 0,
      items: [],
    },
    status: 'loading',
    error: null,
  },
  [PROPS_NOTIFICATIONS.NOTIFICATIONS_POPUP]: {
    data: {
      unread: 0,
      items: [],
    },
    status: 'loading',
    error: null,
  },
  nonDevelopedNotification: {
    data: {},
    isShow: false,
  },
  timeOpenClosePopup: {
    data: {},
    status: 'succeeded',
    error: null,
  },
  newNotificationsQuantity: {
    data: {},
    status: 'loading',
    error: null,
  },
  isShowPopup: false,
};

const notificationsSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getNotificationsRequest(state, action) {
      state[action.payload.prop].status = 'loading';
    },
    getNotificationsSuccess(state, action) {
      const { prop, data } = action.payload;
      state[prop].data = data;
      state[prop].status = 'succeeded';
    },
    getNotificationsFailure(state, action) {
      const { prop, message } = action.payload;
      state[prop].status = 'failed';
      state[prop].data = {};
      state[prop].error = message;
    },
    setNonDevelopedNotification(state, action) {
      state.nonDevelopedNotification.data = action.payload.data;
      state.nonDevelopedNotification.isShow = true;
    },
    setUndisplayNonDevelopedNotification(state) {
      state.nonDevelopedNotification.isShow = false;
    },
    markAllReadRequest(state, action) {
      state[action.payload.prop].status = 'loading';
    },
    markAllReadSuccess(state, action) {
      state[action.payload].status = 'succeeded';
    },
    markAllReadFailure(state, action) {
      const { prop, message } = action.payload;
      state[prop].status = 'failed';
      state[prop].error = message;
    },
    markReadRequest(state, action) {
      state[action.payload.prop].status = 'loading';
    },
    markReadSuccess(state, action) {
      state[action.payload].status = 'succeeded';
    },
    markReadFailure(state, action) {
      const { prop, message } = action.payload;
      state[prop].status = 'failed';
      state[prop].error = message;
    },
    deleteNotificationRequest(state, action) {
      state[action.payload.prop].status = 'loading';
    },
    deleteNotificationSuccess(state, action) {
      state[action.payload].status = 'succeeded';
    },
    deleteNotificationFailure(state, action) {
      const { prop, message } = action.payload;
      state[prop].status = 'failed';
      state[prop].error = message;
    },
    saveTimeOpenClosePopupRequest(state) {
      state.timeOpenClosePopup.status = 'loading';
      state.newNotificationsQuantity.data = {
        count: 0,
      };
    },
    saveTimeOpenClosePopupSuccess(state) {
      state.timeOpenClosePopup.status = 'succeeded';
    },
    saveTimeOpenClosePopupFailure(state, action) {
      state.timeOpenClosePopup.status = 'failed';
      state.timeOpenClosePopup.error = action.payload;
    },
    getNewNotificationQuantityRequest(state) {
      state.newNotificationsQuantity.status = 'loading';
    },
    getNewNotificationQuantitySuccess(state, action) {
      state.newNotificationsQuantity.data = action.payload;
      state.newNotificationsQuantity.status = 'succeeded';
    },
    getNewNotificationQuantityFailure(state, action) {
      state.newNotificationsQuantity.status = 'failed';
      state.newNotificationsQuantity.data = {};
      state.newNotificationsQuantity.error = action.payload;
    },
    changeIsShowPopup(state, action) {
      state.isShowPopup = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = notificationsSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getNotificationsRequest,
  getNotificationsSuccess,
  getNotificationsFailure,
  setNonDevelopedNotification,
  setUndisplayNonDevelopedNotification,
  removeNotification,
  markAllReadRequest,
  markAllReadSuccess,
  markAllReadFailure,
  markReadRequest,
  markReadSuccess,
  markReadFailure,
  deleteNotificationRequest,
  deleteNotificationSuccess,
  deleteNotificationFailure,
  saveTimeOpenClosePopupRequest,
  saveTimeOpenClosePopupSuccess,
  saveTimeOpenClosePopupFailure,
  getNewNotificationQuantityRequest,
  getNewNotificationQuantitySuccess,
  getNewNotificationQuantityFailure,
  changeIsShowPopup,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const notificationsSelector = createSelector(
  rootSelector,
  ({ notifications }) => notifications,
);
export const notificationsPopupSelector = createSelector(
  rootSelector,
  ({ notificationsPopup }) => notificationsPopup,
);
export const nonDevelopedNotificationSelector = createSelector(
  rootSelector,
  ({ nonDevelopedNotification }) => nonDevelopedNotification,
);
export const timeOpenClosePopupSelector = createSelector(
  rootSelector,
  ({ timeOpenClosePopup }) => timeOpenClosePopup,
);
export const newNotificationsQuantitySelector = createSelector(
  rootSelector,
  ({ newNotificationsQuantity }) => newNotificationsQuantity,
);
export const notificationsAllSelector = createSelector(
  rootSelector,
  ({ isShowPopup, notifications, notificationsPopup }) => ({
    isShowPopup,
    notifications,
    notificationsPopup,
  }),
);
