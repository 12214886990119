import moment from 'moment';

import { DATETIME_LOCAL_MS } from 'constants/dateTime';

export const convertDuration = time => {
  let timeRender = '';
  let seconds = '';

  const timeFormat = time;
  const arr = timeFormat.split(':');

  const arrDate = arr[0].split('.');
  if (arrDate.length === 2) {
    seconds = Math.floor(
      arrDate[0] * 86400 + arrDate[1] * 3600 + arr[1] * 60 + +arr[2],
    );
  } else {
    seconds = Math.floor(arr[0] * 3600 + arr[1] * 60 + +arr[2]);
  }

  const day = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const second = Math.floor(seconds % 60);

  switch (true) {
    case seconds < 60 && seconds > 0:
      timeRender = `${second} Seconds`;
      break;
    case seconds >= 60 && seconds < 3600:
      timeRender = `${minutes}m${second === 0 ? '' : ` ${second}s`}`;
      break;
    case seconds >= 3600 && seconds < 86400:
      timeRender = `${hours}h${minutes === 0 ? '' : ` ${minutes}m`}${
        second === 0 ? '' : ` ${second}s`
      }`;
      break;
    case seconds >= 86400:
      timeRender = `${day}d${hours === 0 ? '' : ` ${hours}h`}${
        minutes === 0 ? '' : ` ${minutes}m`
      }${second === 0 ? '' : ` ${second}s`}`;
      break;

    default:
      break;
  }

  return timeRender;
};

export const formatLocaleDateString = time => {
  const date = new Date(time);
  const newDate = date
    .toLocaleDateString('en-JM', {
      day: 'numeric',
      year: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
    })
    .replace(',', ' at')
    .replace('am', 'AM')
    .replace('pm', 'PM');

  return newDate;
};

export const formatDate = (date, styleFormat = DATETIME_LOCAL_MS) => {
  if (!date) {
    return '';
  }
  return moment(date).format(styleFormat);
};

export const dayCount = (startDate, endDate) => {
  const start = new Date(startDate); // clone
  const end = new Date(endDate); // clone
  let newDayCount = 0;
  while (end > start) {
    newDayCount += 1;
    start.setDate(start.getDate() + 1);
  }

  return newDayCount;
};

export const isOverTime = (
  startDate,
  endDate,
  duration = 24 * 60 * 60 * 1000,
) => new Date(endDate).getTime() - new Date(startDate).getTime() <= duration;

export const subYears = (date, amount) =>
  Date.parse(moment(date).subtract(amount, 'years').format());

export const generateDateFormat = (
  dateValue,
  format = 'YYYY-MM-DD HH:mm:ss',
) => {
  const date = new Date(dateValue);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  return moment(date).format(format);
};

const getLastDayOfMonth = (year, month, monthsToAddOrSubtract) => {
  let newMonth = month + (monthsToAddOrSubtract % 12);
  let newYear = year + Math.floor((month + monthsToAddOrSubtract) / 12);

  if (newMonth < 0) {
    newMonth += 12;
    newYear -= 1;
  }

  const lastDay = new Date(newYear, newMonth + 1, 0).getDate();
  const newDate = new Date(newYear, newMonth, lastDay);

  return newDate;
};

export const addOrSubtractDate = (unit, amount, date) => {
  const currentDate = date ? new Date(date) : new Date();

  if (unit === 'days') {
    currentDate.setDate(currentDate.getDate() + amount);
  } else if (unit === 'months') {
    const lastDayOfMonth = getLastDayOfMonth(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      amount,
    );

    if (currentDate.getDate() > lastDayOfMonth.getDate()) {
      return lastDayOfMonth;
    }
    currentDate.setMonth(currentDate.getMonth() + amount);
  } else if (unit === 'years') {
    currentDate.setFullYear(currentDate.getFullYear() + amount);
  }

  return currentDate;
};
