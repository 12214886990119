import { combineReducers } from 'redux';

import apisObserverReducer, {
  ROOT_STATE_NAME as API_OBSERVER,
} from 'features/DQ/slices/apisObserverSlice';
import userReducer, { ROOT_STATE_NAME as USER } from 'slices/user';
import historyExportReducer, {
  ROOT_STATE_NAME as HISTORY_EXPORT,
} from 'slices/exportHistory';
import domainReducer, { ROOT_STATE_NAME as DOMAIN } from 'slices/domain';
import overviewsReducer, {
  ROOT_STATE_NAME as OVERVIEWS,
} from 'slices/overviews';
import toastsReducer, { ROOT_STATE_NAME as TOASTS } from 'slices/toasts';
import datasetReducer, { ROOT_STATE_NAME as DATASET } from 'slices/dataset';
import dataMarketplaceReducer, {
  ROOT_STATE_NAME as DATAMARKETPLACE,
} from 'slices/dataMarketplace';
import myRequestReduce, {
  ROOT_STATE_NAME as MYREQUEST,
} from 'slices/myRequest';
import favouriteReduce, {
  ROOT_STATE_NAME as FAVOURITE,
} from 'slices/favourite';
import notificationsReduce, {
  ROOT_STATE_NAME as NOTIFICATIONS,
} from 'slices/notifications';
import alertsReducer, { ROOT_STATE_NAME as ALERTS } from 'slices/alerts';
import commentsReducer, { ROOT_STATE_NAME as COMMENTS } from 'slices/comments';
import newsfeedReducer, { ROOT_STATE_NAME as NEWSFEED } from 'slices/newsfeed';
import analyticsMarketplaceReducer, {
  ROOT_STATE_NAME as ANALYTICS_MARKETPLACE,
} from 'slices/analyticsMarketplace';
import signalrReducer, { ROOT_STATE_NAME as SIGNALR } from 'slices/signalr';
import datasetManagement, {
  ROOT_STATE_NAME as DATASET_MANAGEMENT,
} from 'slices/datasetManagement';
import userManagement, {
  ROOT_STATE_NAME as USER_MANAGEMENT,
} from 'slices/userManagement';
import modalReducer, { ROOT_STATE_NAME as MODAL } from 'slices/modals';
import searchLandingPageReducer, {
  ROOT_STATE_NAME as SEARCH_LANDING_PAGE,
} from 'slices/searchLandingPage';
import dataQualityReducer, {
  ROOT_STATE_NAME as DATA_QUALITY,
} from 'slices/dataQuality';
import dataSampleReducer, {
  ROOT_STATE_NAME as DATA_SAMPLE,
} from 'slices/dataSample';
import tourSlice, { ROOT_STATE_NAME as TOUR } from 'slices/tourSlice';
import datasetFootprintReducer, {
  ROOT_STATE_NAME as DATASET_FOOTPRINT,
} from 'slices/datasetFootprint';

export default function createRootReducer() {
  const rootReducer = combineReducers({
    [USER]: userReducer,
    [HISTORY_EXPORT]: historyExportReducer,
    [DOMAIN]: domainReducer,
    [OVERVIEWS]: overviewsReducer,
    [TOASTS]: toastsReducer,
    [DATASET]: datasetReducer,
    [DATAMARKETPLACE]: dataMarketplaceReducer,
    [MYREQUEST]: myRequestReduce,
    [NOTIFICATIONS]: notificationsReduce,
    [FAVOURITE]: favouriteReduce,
    [ALERTS]: alertsReducer,
    [COMMENTS]: commentsReducer,
    [NEWSFEED]: newsfeedReducer,
    [ANALYTICS_MARKETPLACE]: analyticsMarketplaceReducer,
    [SIGNALR]: signalrReducer,
    [DATASET_MANAGEMENT]: datasetManagement,
    [USER_MANAGEMENT]: userManagement,
    [MODAL]: modalReducer,
    [SEARCH_LANDING_PAGE]: searchLandingPageReducer,
    [DATA_QUALITY]: dataQualityReducer,
    [DATA_SAMPLE]: dataSampleReducer,
    [API_OBSERVER]: apisObserverReducer,
    [TOUR]: tourSlice,
    [DATASET_FOOTPRINT]: datasetFootprintReducer,
  });

  return rootReducer;
}
