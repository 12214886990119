import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ANALYTICS_MARKETPLACE_RUN_MODEL_BY_TEXT } from 'constants/paths';
import useAPI from 'hooks/useAPI';
import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';

import usePurgeConfirm from 'features/AM/hooks/usePurgeConfirm';
import ImageComponent from './ImageComponent';
import PromptComponent from './PromptComponent';

import './styles.scss';

const TextToImageModel = ({ data }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [modelDescription, setModelDescription] = useState('');

  const { data: dataTryModel, onCallAPI } = useAPI();
  const { updateAIModelBehavior } = useAnalyticsMarketplace();

  const { status, data: responseData } = dataTryModel || {};
  const isLoading = status && status === 'loading';

  const onSubmit = ({ description }) => {
    setErrorMessage('');
    updateAIModelBehavior({
      analyticDocumentId: data?.id,
      type: 1,
    });
    onCallAPI({
      method: 'post',
      url: ANALYTICS_MARKETPLACE_RUN_MODEL_BY_TEXT,
      data: { id: data.id, text: description },
    }).then(({ isSuccess }) => {
      if (!isSuccess) {
        setErrorMessage(
          'Model result can not be generated. Please retry to run the model again.',
        );
      }
    });
  };

  const { renderModal, onCheckPurgeInformation } = usePurgeConfirm({
    data,
    onRunModel: onSubmit,
  });

  return (
    <div className="model-content__text-to-image">
      <PromptComponent
        onSubmit={onCheckPurgeInformation}
        modelInput={data.modelInput}
        onSetModelDescription={setModelDescription}
      />
      <ImageComponent
        loading={isLoading}
        src={responseData?.fileUrl}
        errorMessage={errorMessage}
        caption={modelDescription}
      />
      {renderModal()}
    </div>
  );
};

TextToImageModel.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    modelInput: PropTypes.any,
  }),
};

export default TextToImageModel;
