import {
  DATA_QUALITY_MANAGEMENT,
  DATA_QUALITY_MANAGEMENT_UPDATE_STATUS,
} from 'constants/paths';
import { deleteData, getData, patchData, postData } from 'services/api';

export const deleteDataset = id => {
  const url = `${DATA_QUALITY_MANAGEMENT}/${id}`;

  return deleteData({ url });
};

export const duplicateDataset = id => {
  const url = `${DATA_QUALITY_MANAGEMENT}/${id}/duplicate-template`;

  return postData({ url });
};

export const getDetailDataset = async id => {
  const url = `/v1/data-quality-management/${id}/dq-submission`;
  const response = await getData({ url });
  return response.data.data;
};

export const updateStatus = data => {
  const url = DATA_QUALITY_MANAGEMENT_UPDATE_STATUS;

  return patchData({ url, data });
};
