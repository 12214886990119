import React, { memo, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { ReactComponent as PlayIcon } from '../../../assets/icons/common/play.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/common/close52.svg';

import Tooltip from '../../layout/Tooltip';

import './styles.scss';

const Video = ({ src, thumbnail, title }) => {
  const videoRef = useRef();
  const divRef = useRef();
  const [isShowThumbnail, setIsShowThumbnail] = useState(true);

  const onPlay = () => {
    setIsShowThumbnail(false);

    videoRef.current.play();
    divRef.current.requestFullscreen();
  };

  const onPause = () => {
    videoRef.current.pause();
  };

  const onMinimize = () => {
    setIsShowThumbnail(true);
    onPause();
    videoRef.current.currentTime = 0;
  };

  const onClose = () => {
    if (!document.fullscreenElement) return;

    document.exitFullscreen().then(() => {
      onMinimize();
    });
  };

  const onVideoClick = () => {
    if (!videoRef.current) return;

    if (videoRef.current.paused) {
      videoRef.current.play();
      return;
    }
    videoRef.current.pause();
  };

  useEffect(() => {
    const eventNames = [
      'fullscreenchange',
      'webkitfullscreenchange',
      'mozfullscreenchange',
      'msfullscreenchange',
    ];

    const onFullscreenChange = () => {
      if (!document.fullscreenElement) {
        onMinimize();
      }
    };

    eventNames.forEach(name => {
      document.addEventListener(name, onFullscreenChange);
    });

    return () => {
      eventNames.forEach(name => {
        document.removeEventListener(name, onFullscreenChange);
      });
    };
  }, []);

  return (
    <div className="edh-video">
      <div className="edh-video__container" ref={divRef}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          hidden={isShowThumbnail}
          ref={videoRef}
          src={src}
          controls
          controlsList="nofullscreen"
          onClick={onVideoClick}
          disablePictureInPicture
        />
        {isShowThumbnail ? (
          <div className="edh-video__thumbnail">
            <img src={thumbnail} alt={title} />
            <Button onClick={onPlay}>
              <PlayIcon />
            </Button>
          </div>
        ) : (
          <div className="edh-video__play-header">
            <h4>{title}</h4>

            <Button onClick={onClose}>
              <CloseIcon />
            </Button>
          </div>
        )}
      </div>
      <Tooltip content={title} id={title.replace(' ', '')}>
        <h4 className="edh-video__title" id={title.replace(' ', '')}>
          {title}
        </h4>
      </Tooltip>
    </div>
  );
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(Video);
