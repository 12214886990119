import { uid } from '../services/uid';

export const CATEGORY_FEEDBACK = [
  {
    id: uid(),
    label: 'General',
    value: 'General',
  },
  {
    id: uid(),
    label: 'My Workspace',
    value: 'My Workspace',
  },
  {
    id: uid(),
    label: 'Data Marketplace',
    value: 'Data Marketplace',
  },
  {
    id: uid(),
    label: 'Analytics Marketplace',
    value: 'Analytics Marketplace',
  },
  {
    id: uid(),
    label: 'Enterprise Business Glossary',
    value: 'Enterprise Business Glossary',
  },
  {
    id: uid(),
    label: 'Management Dashboard',
    value: 'Management Dashboard',
  },
  {
    id: uid(),
    label: 'Data Quality',
    value: 'Data Quality',
  },
  {
    id: uid(),
    label: 'Insights Scoop',
    value: 'Insights Scoop',
  },
  {
    id: uid(),
    label: 'Newsfeed',
    value: 'Newsfeed',
  },
  {
    id: uid(),
    label: 'J.AI Search',
    value: 'J.AI Search',
  },
  {
    id: uid(),
    label: 'J.AI Chat',
    value: 'J.AI Chat',
  },
];
