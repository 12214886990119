import { call, put, delay, takeEvery } from 'redux-saga/effects';

import { getData, postData } from '../services/api';

import {
  DOMAIN_API_PATH,
  EBG_API_PATH,
  ALL_DOMAIN_API_PATH,
} from '../constants/paths';

import {
  getEbgRequest,
  getEbgSuccess,
  getEbgFailure,
  getDomainRequest,
  getDomainSuccess,
  getDomainFailure,
  getTotalDomainsRequest,
  getTotalDomainsSuccess,
  getTotalDomainsFailure,
} from '../slices/domain';

export function* getDomain({ payload }) {
  const { prop, params, isPagin } = payload;

  try {
    const {
      data: { data },
    } = yield call(getData, { url: DOMAIN_API_PATH, params });

    yield put(getDomainSuccess({ prop, data, isPagin }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(
      getDomainFailure({
        prop,
        error: message,
      }),
    );
  }
}

export function* getTotalDomains({ payload }) {
  const { prop, params } = payload;
  try {
    const {
      data: { data },
    } = yield call(getData, { url: ALL_DOMAIN_API_PATH, params });
    yield put(getTotalDomainsSuccess({ prop, data }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(
      getTotalDomainsFailure({
        prop,
        error: message,
      }),
    );
  }
}

export function* getEbg({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, { url: EBG_API_PATH, data: payload });

    yield delay(800);
    yield put(getEbgSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getEbgFailure(message));
  }
}

export default function* domainSaga() {
  yield takeEvery(getDomainRequest().type, getDomain);
  yield takeEvery(getTotalDomainsRequest().type, getTotalDomains);
  yield takeEvery(getEbgRequest().type, getEbg);
}
