import React, { useEffect, useState } from 'react';
import XLSX from 'xlsx';
import { isEmpty, mapKeys, keys } from 'lodash';
import { PropTypes } from 'prop-types';

import useDevice from 'hooks/useDevice';

import { Document, Page } from 'components/core/ReactPdf';
import AMImageZoom from 'components/core/AMImageZoom';
import AMJSONPreview from 'components/core/AMJSONPreview';

import { convertCsvToJson } from 'services/string';
import { typeMap } from 'constants/analyticsmarketplace';
import classnames from 'services/classnames';

import useSamplePagination from 'features/AM/hooks/useSamplePagination';
import ReactTableCustom from '../ReactTableCustom';

import './styles.scss';

const PreviewSample = ({ className, type, sampleData }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(1);
  const { pageNumber, setTotalPages, renderPagination, setPageNumber } =
    useSamplePagination();

  const { isMobileDevice } = useDevice();
  const fetchSampleData = async callback => {
    setLoading(true);
    const res = await fetch(sampleData);
    callback(res);
    setLoading(false);
  };

  useEffect(() => {
    setPageNumber(1);
    if ([typeMap.png, typeMap.jpg].includes(type)) {
      setData(sampleData);
      setLoading(false);
      setScale(1);
      if (Array.isArray(sampleData)) {
        setTotalPages(sampleData.length);
      }
    }
    if ([typeMap.json].includes(type)) {
      fetchSampleData(async res => {
        const dataResponse = await res.json();
        setData(dataResponse);
      });
    }
    if ([typeMap.csv].includes(type)) {
      fetchSampleData(async res => {
        const dataResponse = await res.arrayBuffer();
        const dec = new TextDecoder('windows-1251');
        const textResponse = dec.decode(dataResponse);
        setData([textResponse]);
      });
    }
    if (!data && [typeMap.xlsx].includes(type)) {
      fetchSampleData(async res => {
        const arrayBuffer = await res.arrayBuffer();
        const workbook = await XLSX.read(arrayBuffer);
        if (workbook?.SheetNames?.length) {
          const allSheet = workbook.SheetNames.map(sName =>
            XLSX.utils.sheet_to_json(workbook.Sheets[sName], {}),
          );
          setTotalPages(allSheet.length);
          setData(allSheet);
        } else {
          setData(null);
          setTotalPages(0);
        }
      });
    }
    if (type === typeMap.pdf) {
      fetchSampleData(async res => {
        const arrayBuffer = await res.arrayBuffer();
        const sample = new Uint8Array(arrayBuffer);
        setData(sample);
        setScale(1);
      });
    }
    return () => {
      setLoading(false);
    };
  }, [type, sampleData]);

  if (loading || !data) {
    return <div className={[className]} />;
  }

  if (type === typeMap.json) {
    return (
      <div className={[className, `preview-${typeMap.json}`].join(' ')}>
        <AMJSONPreview src={data} />
      </div>
    );
  }

  if ([typeMap.png, typeMap.jpg].includes(type)) {
    return (
      <div className={[className, 'preview-img'].join(' ')}>
        <AMImageZoom scaleOffset={1} step={0.5} range={[0.5, 6]}>
          <img draggable src={data[pageNumber - 1]} alt="Sample input" />
        </AMImageZoom>
        {Array.isArray(sampleData) && renderPagination()}
      </div>
    );
  }

  if (type === typeMap.pdf) {
    const onDocumentLoadSuccess = ({ numPages }) => {
      setTotalPages(numPages);
    };

    const onSetScale = value => {
      if (!isMobileDevice) {
        setScale(value);
      }
    };
    return (
      <div className={classnames(className, `preview-${typeMap.pdf}`)}>
        <AMImageZoom
          scaleOffset={1}
          step={0.5}
          range={[0.5, 6]}
          canvasMode={!isMobileDevice}
          setScale={onSetScale}
        >
          <Document file={{ data }} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              height={571}
              scale={isMobileDevice ? 1 : scale}
              renderMode="canvas"
            />
          </Document>
        </AMImageZoom>
        {renderPagination()}
      </div>
    );
  }

  if ([typeMap.xlsx].includes(type)) {
    const mappingData =
      Array.isArray(data) && !isEmpty(data) ? data[pageNumber - 1] : [];
    const headers = keys(mappingData[0]).map(header => ({
      key: header.replace(/\s/g, '').toLowerCase() || `_${header}`,
      header,
      length: header?.length || 0,
    }));
    const jsonData = mappingData.map(item => ({
      ...mapKeys(
        item,
        (value, key) => key?.replace(/\s/g, '').toLowerCase() || `_${key}`,
      ),
    }));
    const columns = headers.map(item => {
      const contentMaxLength = Math.max(
        ...jsonData.map(content => content[item?.key].length),
      );
      return {
        Header: item?.header,
        accessor: item?.key,
        disableSortBy: true,
        minWidth: 20 + (item?.length <= 25 ? item.length * 10 : 250),
        width:
          20 +
          (contentMaxLength <= 25
            ? contentMaxLength * 10
            : Math.max(250, contentMaxLength)),
        maxWidth:
          20 +
          (contentMaxLength <= 25
            ? contentMaxLength * 10
            : Math.max(250, contentMaxLength)),
      };
    });
    const keepFormatData = jsonData.map((item, index) => {
      const tempObject = { ...item };
      Object.keys(tempObject).forEach(key => {
        tempObject[key] = (
          <div style={{ whiteSpace: 'break-spaces' }}>{tempObject[key]}</div>
        );
      });
      return { ...tempObject, id: Date.now() + index };
    });
    return (
      <div className={[className, 'preview-table'].join(' ')}>
        <ReactTableCustom
          id="am-preview-sample-table"
          columns={columns}
          data={keepFormatData}
          hasStickyHeader
          hasPagination={false}
          messageTitleNoData="No Sample Data"
        />
        {renderPagination()}
      </div>
    );
  }
  if ([typeMap.csv].includes(type)) {
    const { headers, data: jsonData } = convertCsvToJson(
      Array.isArray(data) && !isEmpty(data) ? data[pageNumber - 1] : [],
    );
    const columns = headers.map(item => ({
      Header: item?.header,
      accessor: item?.key,
      disableSortBy: true,
      minWidth: 20 + (item?.length <= 25 ? item.length * 10 : 250),
      width: 20 + (item?.length <= 25 ? item.length * 10 : 250),
    }));
    return (
      <div className={[className, 'preview-table'].join(' ')}>
        <ReactTableCustom
          id="am-preview-sample-table"
          columns={columns}
          data={jsonData}
          hasStickyHeader
          hasPagination={false}
          messageTitleNoData="No Sample Data"
        />
        {renderPagination()}
      </div>
    );
  }
  return <div className={[className]} />;
};

PreviewSample.propTypes = {
  type: PropTypes.string,
  sampleData: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
};

export default PreviewSample;
