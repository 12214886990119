import React from 'react';

import {
  TextToImageSampleInput,
  TextToImageSampleOutput,
} from './TextToImageSample';

const modelPreviewConfig = {
  'Text to Image Generator': {
    renderSampleInput(sampleData) {
      return <TextToImageSampleInput sampleData={sampleData} />;
    },
    renderSampleOutput(sampleData) {
      return <TextToImageSampleOutput sampleData={sampleData} />;
    },
  },
};

export const getPreviewConfig = title => {
  if (modelPreviewConfig[title]) {
    const { renderSampleInput, renderSampleOutput } = modelPreviewConfig[title];
    return { renderSampleInput, renderSampleOutput };
  }
  return {};
};
