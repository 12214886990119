import { createSelector, createSlice } from '@reduxjs/toolkit';

export const ROOT_STATE_NAME = 'comment';
const initialState = {
  comments: {
    status: 'idle',
    data: {
      items: [],
    },
    error: null,
  },
  newComment: {
    status: 'idle',
    data: {},
    error: null,
  },
};

const commentsSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getCommentsRequest(state) {
      state.comments.status = 'loading';
      state.comments.error = null;
    },
    getCommentsSuccess(state, action) {
      state.comments.status = 'succeed';
      state.comments.data = {
        ...action.payload.data,
        items: [...state.comments.data.items, ...action.payload.data.items],
      };
    },
    getCommentsFailure(state, action) {
      state.comments.status = 'failed';
      state.comments.error = action.payload.error;
    },
    clearComments(state) {
      state.comments.status = 'idle';
      state.comments.data = { items: [] };
    },
    pushComment(state, action) {
      state.comments.data.items = [
        action.payload,
        ...state.comments.data.items,
      ].sort((a, b) => new Date(a.createdAt) + new Date(b.createdAt));
    },
    addNewCommentRequest(state) {
      state.newComment.status = 'loading';
      state.newComment.error = null;
    },
    addNewCommentSuccess(state, action) {
      state.newComment.status = 'succeed';
      state.newComment.data = action.payload.data;
    },
    addNewCommentFailure(state, action) {
      state.newComment.status = 'failed';
      state.newComment.error = action.payload.error;
    },
  },
});

export const {
  getCommentsRequest,
  getCommentsSuccess,
  getCommentsFailure,
  clearComments,
  pushComment,
  addNewCommentRequest,
  addNewCommentSuccess,
  addNewCommentFailure,
} = commentsSlice.actions;
export default commentsSlice.reducer;

const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const commentsSelector = createSelector(
  rootSelector,
  state => state.comments,
);
export const newCommentSelector = createSelector(
  rootSelector,
  state => state.newComment,
);
