import { call, put, delay, takeLatest } from 'redux-saga/effects';

import { postData } from '../services/api';

import { ANALYTICS_MARKETPLACE_GET_LIST_AIMODEL_DETAIL } from '../constants/paths';

import {
  getWorkspaceAMRequest,
  getWorkspaceAMFailure,
  getWorkspaceAMSuccess,
} from '../slices/myWorkspace';

export function* getListAIModels({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: ANALYTICS_MARKETPLACE_GET_LIST_AIMODEL_DETAIL,
      data: payload,
    });
    yield delay(800);
    yield put(getWorkspaceAMSuccess({ ...data }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getWorkspaceAMFailure(message));
  }
}

export default function* myWorkspaceSaga() {
  yield takeLatest(getWorkspaceAMRequest().type, getListAIModels);
}
