import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { listAcceptMaxLength } from 'constants/dataRequestForm';

import CustomToolbar from './customToolbar';

import './styles.scss';

const modules = {
  toolbar: {
    container: '#toolbar',
  },
};

const formats = ['bold', 'italic', 'underline', 'strike', 'script', 'list'];

const DescriptionEditor = ({ disabled, value, maxLength, onChange }) => {
  const [charactersCounter, setCharactersCounter] = useState('?');

  const editorRef = useRef();

  const handleChange = html => {
    onChange(html);
  };

  useEffect(() => {
    setCharactersCounter(editorRef?.current?.editor?.getLength() - 1);
  }, [editorRef?.current?.editor?.getLength()]);

  const checkMaxLength = event => {
    if (editorRef?.current?.editor?.getLength() - 1 >= maxLength) {
      if (!listAcceptMaxLength.includes(event.code)) {
        event.preventDefault();
      }
    }
  };

  useEffect(() => {
    if (maxLength !== -1) {
      const editor = editorRef?.current?.editor;
      editor?.on('text-change', () => {
        if (editor?.getLength() - 1 >= maxLength) {
          editor?.deleteText(maxLength, editor?.getLength() - 1);
        }
      });
    }
  }, [maxLength]);

  return (
    <div className="edh-description_editor">
      <CustomToolbar />
      <ReactQuill
        ref={editorRef}
        value={value}
        onChange={handleChange}
        placeholder="Insert Description"
        modules={modules}
        formats={formats}
        onKeyDown={checkMaxLength}
      />
      {!disabled && (
        <p className="edh-input__max-length">
          {charactersCounter}/{maxLength} characters
        </p>
      )}
    </div>
  );
};

DescriptionEditor.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

DescriptionEditor.defaultProps = {
  disabled: false,
  value: '',
  maxLength: 0,
  onChange() {},
};

export default memo(DescriptionEditor);
