import { call, put, delay, takeLatest, takeEvery } from 'redux-saga/effects';

import { getData, postData, putData } from '../services/api';

import {
  DATASET_API_PATH,
  SAMPLE_HEADER_DATASET,
  DATA_MARKETPLACE_DETAIL,
  GET_DATASETS_API_PATH,
  MY_DATASETS_RECENTLY,
  MY_DATASETS_ENTITLEMENT,
  SUGGESTED_KEYWORD_API_PATH,
  SUGGESTED_KEYWORD_MODULAR_DATASETS_API_PATH,
  PUT_DATASETS_MY_WORKSPACE_PATH,
  GET_DATASET_EXPORT_VALUES_BY_COLUMN,
  POST_DATASET_EXPORT_COLUMNS,
} from '../constants/paths';

import {
  getSuggestedKeywordRequest,
  getSuggestedKeywordSuccess,
  getSuggestedKeywordFailure,
  getSuggestedKeywordModularDatasetsRequest,
  getSuggestedKeywordModularDatasetsSuccess,
  getSuggestedKeywordModularDatasetsFailure,
  getDatasetRequest,
  getDatasetSuccess,
  getDatasetFailure,
  getDatasetsRequest,
  getDatasetsFailure,
  getDatasetsSuccess,
  getMyDatasetsRecentlyRequest,
  getMyDatasetsRecentlyFailure,
  getMyDatasetsRecentlySuccess,
  getMyDatasetsEntitlementRequest,
  getMyDatasetsEntitlementFailure,
  getMyDatasetsEntitlementSuccess,
  getSampleHederRequest,
  getSampleHederSuccess,
  getSampleHederFailure,
  putDatasetMyWorkspaceRequest,
  putDatasetMyWorkspaceSuccess,
  putDatasetMyWorkspaceFailure,
  getDatasetExportValuesByColumnRequest,
  getDatasetExportValuesByColumnSuccess,
  getDatasetExportValuesByColumnFailure,
  postDatasetExportColumnsSuccess,
  postDatasetExportColumnsFailure,
  postDatasetExportColumnsRequest,
} from '../slices/dataset';

export function* getSuggestedKeyword({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: SUGGESTED_KEYWORD_API_PATH,
      params: payload,
    });

    yield delay(800);
    yield put(getSuggestedKeywordSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getSuggestedKeywordFailure(message));
  }
}
export function* getSuggestedKeywordModularDatasets({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: SUGGESTED_KEYWORD_MODULAR_DATASETS_API_PATH,
      params: payload,
    });

    yield delay(800);
    yield put(getSuggestedKeywordModularDatasetsSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getSuggestedKeywordModularDatasetsFailure(message));
  }
}

export function* getDataset({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: DATA_MARKETPLACE_DETAIL,
      params: payload,
    });

    yield put(getDatasetSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getDatasetFailure(message));
  }
}

export function* getDatasets({ payload }) {
  const { formData, prop, isLoadMore } = payload;
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: GET_DATASETS_API_PATH,
      data: formData,
    });

    yield delay(800);
    yield put(getDatasetsSuccess({ prop, data, isLoadMore }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getDatasetsFailure({ prop, error: message }));
  }
}

export function* getMyDatasetsRecently(action) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: MY_DATASETS_RECENTLY,
      params: action.payload,
    });

    yield delay(800);
    yield put(getMyDatasetsRecentlySuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getMyDatasetsRecentlyFailure(message));
  }
}

export function* getMyDatasetsEntitlement(action) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: MY_DATASETS_ENTITLEMENT,
      params: action.payload,
    });

    yield delay(800);
    yield put(getMyDatasetsEntitlementSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getMyDatasetsEntitlementFailure(message));
  }
}

export function* getSampleHeader({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: `${DATASET_API_PATH}/${payload}/${SAMPLE_HEADER_DATASET}`,
    });

    yield put(getSampleHederSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getSampleHederFailure(message));
  }
}

export function* getDatasetExportValuesByColumn({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_DATASET_EXPORT_VALUES_BY_COLUMN,
      params: payload,
    });

    yield put(getDatasetExportValuesByColumnSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getDatasetExportValuesByColumnFailure(message));
  }
}

export function* postDatasetExportColumns({ payload }) {
  const { formData, prop, isLoadMore } = payload;
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: POST_DATASET_EXPORT_COLUMNS,
      data: formData,
    });

    yield delay(800);
    yield put(postDatasetExportColumnsSuccess({ prop, data, isLoadMore }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(postDatasetExportColumnsFailure({ prop, error: message }));
  }
}

export function* putDatasetMyWorkspace({ payload }) {
  const { callback, ...formData } = payload;
  try {
    const {
      data: { data },
    } = yield call(putData, {
      url: PUT_DATASETS_MY_WORKSPACE_PATH,
      data: formData,
    });

    put(putDatasetMyWorkspaceSuccess(data));

    if (callback) {
      callback();
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    put(putDatasetMyWorkspaceFailure(message));
  }
}

export default function* datasetSaga() {
  yield takeLatest(getSuggestedKeywordRequest().type, getSuggestedKeyword);
  yield takeEvery(
    getSuggestedKeywordModularDatasetsRequest().type,
    getSuggestedKeywordModularDatasets,
  );
  yield takeLatest(getDatasetRequest().type, getDataset);
  yield takeLatest(getDatasetsRequest().type, getDatasets);
  yield takeLatest(getMyDatasetsRecentlyRequest().type, getMyDatasetsRecently);
  yield takeLatest(
    getMyDatasetsEntitlementRequest().type,
    getMyDatasetsEntitlement,
  );
  yield takeLatest(getSampleHederRequest().type, getSampleHeader);
  yield takeLatest(
    getDatasetExportValuesByColumnRequest().type,
    getDatasetExportValuesByColumn,
  );
  yield takeLatest(
    postDatasetExportColumnsRequest().type,
    postDatasetExportColumns,
  );
  yield takeEvery(putDatasetMyWorkspaceRequest().type, putDatasetMyWorkspace);
}
