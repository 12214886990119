import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'dataQuality';

const initialState = {
  showBadDataModal: false,
  workspaceDQ: {
    data: { items: [] },
    status: 'idle',
    error: null,
  },
  deleteWorkspaceItem: {
    loading: false,
    selectedId: [],
    isSuccess: false,
    error: null,
  },
};

const dataQualitySlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    setShowBadDataModal(state) {
      state.showBadDataModal = !state.showBadDataModal;
    },
    getWorkspaceDQRequest(state) {
      state.workspaceDQ.status = 'loading';
    },
    getWorkspaceDQSuccess(state, action) {
      state.workspaceDQ.data = action.payload;
      state.workspaceDQ.status = 'succeeded';
    },
    getWorkspaceDQFailure(state, action) {
      state.workspaceDQ.status = 'failed';
      state.workspaceDQ.data = [];
      state.workspaceDQ.error = action.payload;
    },
    deleteWorkspaceItemRequest(state, action) {
      state.deleteWorkspaceItem.isSuccess = false;
      state.deleteWorkspaceItem.error = null;
      state.deleteWorkspaceItem.loading = true;
      state.deleteWorkspaceItem.selectedId = action.payload
        .isRemoveFromWorkspace
        ? []
        : action.payload.id;
    },
    deleteWorkspaceItemSuccess(state) {
      state.deleteWorkspaceItem.loading = false;
      state.deleteWorkspaceItem.isSuccess = true;
      state.deleteWorkspaceItem.error = null;
    },
    deleteWorkspaceItemFailure(state) {
      state.deleteWorkspaceItem.loading = false;
      state.deleteWorkspaceItem.isSuccess = false;
      state.deleteWorkspaceItem.error = 'Failed';
    },
  },
});

const { actions, reducer } = dataQualitySlice;

export default reducer;

export const {
  setShowBadDataModal,
  getWorkspaceDQRequest,
  getWorkspaceDQSuccess,
  getWorkspaceDQFailure,
  deleteWorkspaceItemRequest,
  deleteWorkspaceItemSuccess,
  deleteWorkspaceItemFailure,
} = actions;

/** SELECTORS */
export const rootSelector = state => state[ROOT_STATE_NAME] || {};

export const showBadDataModalSelector = createSelector(
  rootSelector,
  ({ showBadDataModal }) => showBadDataModal,
);

export const workspaceDQSelector = createSelector(
  rootSelector,
  ({ workspaceDQ }) => workspaceDQ,
);

export const deleteWorkspaceItemSelector = createSelector(
  rootSelector,
  ({ deleteWorkspaceItem }) => deleteWorkspaceItem,
);
