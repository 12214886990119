export const HOME = {
  id: '/',
  label: 'Home',
  href: '/',
};

export const NOTIFICATION = {
  id: '/notification',
  label: 'Notification',
  href: '/notification',
};

export const LANDING_PAGE_SEARCH = {
  id: '/search',
  label: 'Search',
  href: '/search',
};

export const EBG = {
  id: '/ebg',
  label: 'Enterprise Business Glossary (EBG)',
  href: '/ebg',
};

export const DATA_MARKETPLACE = {
  id: '/data-marketplace',
  label: 'Data Marketplace',
  href: '/data-marketplace',
};

export const DATA_MARKETPLACE_SEARCH = {
  id: '/data-marketplace/search',
  label: 'Search',
  href: '/data-marketplace/search',
};

export const DATA_MARKETPLACE_INTERNAL_SOURCE = {
  id: '/data-marketplace/internal-source',
  label: 'Internal Source',
  href: '/data-marketplace/internal-source',
};

export const DATA_MARKETPLACE_EXTERNAL_SOURCE = {
  id: '/data-marketplace/external-source',
  label: 'External Source',
  href: '/data-marketplace/external-source',
};

export const INTERNAL_DATASETS_PAGE = {
  id: '/data-marketplace/internal-source/datasets',
  label: 'Datasets',
  subLabel: 'Internal Source',
  href: '/data-marketplace/internal-source/datasets',
};

export const EXTERNAL_DATASETS_PAGE = {
  id: '/data-marketplace/external-source/datasets',
  label: 'Datasets',
  subLabel: 'External Source',
  href: '/data-marketplace/external-source/datasets',
};

export const NEWSFEED = {
  id: '/newsfeed',
  label: 'Newsfeed',
  href: '/newsfeed',
};

export const NEWSFEED_CATEGORY = {
  id: '/newsfeed/:category',
  label: 'Newsfeed Category',
  href: '/newsfeed/:category',
};

export const NEWSFEED_MANAGE = {
  id: '/newsfeed/manage-newsfeed',
  label: 'Manage Newsfeed',
  href: '/newsfeed/manage-newsfeed',
};

export const NEWSFEED_PUBLISH = {
  id: '/newsfeed/manage-newsfeed/:id',
  label: 'Manage Newsfeed Publish',
  href: '/newsfeed/manage-newsfeed/:id',
};

export const AM_DETAIL_INFORMATION = {
  id: '/analytics-marketplace',
  label: 'Analytics Marketplace',
  href: '/analytics-marketplace',
};

export const ANALYTICS_MARKETPLACE = {
  id: '/analytics-marketplace',
  label: 'Analytics Marketplace',
  href: '/analytics-marketplace',
};

export const ANALYTICS_MARKETPLACE_DETAIL = {
  id: '/analytics-marketplace-detail',
  label: 'Analytics Marketplace',
  href: '/analytics-marketplace-detail',
};

export const AM_MODEL_OVERVIEW = {
  id: '/analytics-marketplace/:type',
  label: 'Analytics Marketplace',
  href: '/analytics-marketplace/:type',
};

export const AM_THEME_OVERVIEW = {
  id: '/analytics-marketplace/:theme',
  label: 'Analytics Marketplace',
  href: '/analytics-marketplace/:theme',
};

export const DQ_DATA_QUALITY = {
  id: '/data-quality',
  label: 'Data Quality',
  href: '/data-quality',
};

export const DQ_DOMAIN_DETAIL = {
  id: '/data-quality/domain=:domain',
  label: 'Data Quality Domain Detail',
  href: '/data-quality/domain:domain',
  url: '/data-quality/domain=',
};

export const PAP = {
  id: '/pap',
  label: 'Personalized Analytics Playground',
  href: '/pap',
};

export const UPCOMING_SERVICE = {
  id: '/upcoming-service',
  label: 'Upcoming EDH Service',
  href: '/upcoming-service',
};

export const MY_WORKSPACE = {
  id: '/my-workspace',
  label: 'My Workspace',
  href: '/my-workspace',
};

export const MANAGEMENT_DASHBOARD = {
  id: '/management-dashboard',
  label: 'Management Dashboard',
  href: '/management-dashboard',
};

export const USER_MANAGEMENT = {
  id: '/user-management',
  label: 'User Management',
  href: '/user-management',
};

export const VIEW_DOMAIN_EXTERNAL = {
  id: '/data-marketplace/external-source/view-domain',
  label: 'View Domain External',
  href: '/data-marketplace/external-source/view-domain',
};

export const GET_STARTED = {
  id: '/insights-scoop',
  label: 'Insights Scoop',
  href: '/insights-scoop',
};

export default {
  [HOME.id]: [HOME],
  [NOTIFICATION.id]: [HOME, NOTIFICATION],
  [LANDING_PAGE_SEARCH.id]: [HOME, LANDING_PAGE_SEARCH],
  [EBG.id]: [HOME, EBG],
  [DATA_MARKETPLACE.id]: [HOME, DATA_MARKETPLACE],
  [DATA_MARKETPLACE_INTERNAL_SOURCE.id]: [
    HOME,
    DATA_MARKETPLACE,
    DATA_MARKETPLACE_INTERNAL_SOURCE,
  ],
  [DATA_MARKETPLACE_EXTERNAL_SOURCE.id]: [
    HOME,
    DATA_MARKETPLACE,
    DATA_MARKETPLACE_EXTERNAL_SOURCE,
  ],
  [DATA_MARKETPLACE_SEARCH.id]: [
    HOME,
    DATA_MARKETPLACE,
    DATA_MARKETPLACE_SEARCH,
  ],
  [INTERNAL_DATASETS_PAGE.id]: [
    HOME,
    DATA_MARKETPLACE,
    DATA_MARKETPLACE_INTERNAL_SOURCE,
    INTERNAL_DATASETS_PAGE,
  ],
  [EXTERNAL_DATASETS_PAGE.id]: [
    HOME,
    DATA_MARKETPLACE,
    DATA_MARKETPLACE_EXTERNAL_SOURCE,
    EXTERNAL_DATASETS_PAGE,
  ],
  [NEWSFEED.id]: [HOME, NEWSFEED],
  [NEWSFEED_MANAGE.id]: [HOME, NEWSFEED, NEWSFEED_MANAGE],
  [NEWSFEED_PUBLISH.id]: [HOME, NEWSFEED, NEWSFEED_MANAGE, NEWSFEED_PUBLISH],
  [AM_DETAIL_INFORMATION.id]: [HOME, AM_DETAIL_INFORMATION],
  [ANALYTICS_MARKETPLACE.id]: [HOME, ANALYTICS_MARKETPLACE],
  [MY_WORKSPACE.id]: [HOME, MY_WORKSPACE],
  [MANAGEMENT_DASHBOARD.id]: [HOME, MANAGEMENT_DASHBOARD],
  [DQ_DATA_QUALITY.id]: [HOME, DQ_DATA_QUALITY],
  [DQ_DOMAIN_DETAIL.id]: [HOME, DQ_DATA_QUALITY, DQ_DOMAIN_DETAIL],
  [USER_MANAGEMENT.id]: [HOME, USER_MANAGEMENT],
  [VIEW_DOMAIN_EXTERNAL.id]: [
    HOME,
    DATA_MARKETPLACE,
    DATA_MARKETPLACE_EXTERNAL_SOURCE,
  ],
  [GET_STARTED.id]: [HOME, GET_STARTED],
};
