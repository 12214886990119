import React, { memo, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { useLocation, Link, NavLink } from 'react-router-dom';

import BBadge from 'react-bootstrap/Badge';

import classnames from 'services/classnames';

import useNews from 'hooks/useNews';
import useSignalR from 'hooks/useSignalR';
import useDevice from 'hooks/useDevice';
import useUser from 'hooks/useUser';
import useVA from 'hooks/useVA';
import useTakeEventGA from 'hooks/useTakeEventGA';

import { ReactComponent as LogoIcon } from 'assets/icons/logo/logo.svg';
import { ReactComponent as LogoMobileIcon } from 'assets/icons/logo/edh-logo-new.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/common/close.svg';
import { ReactComponent as JAIIcon } from 'assets/icons/sidebar/jai.svg';

import { GET_STARTED, NEWSFEED } from 'constants/breakcrumb';
import { USER_HUB_PATH } from 'constants/paths';
import { ID_TOKEN } from 'constants/storageKeys';

import settings from 'settings';
import { getStorage } from 'services/storage';

import { sidebarData } from 'constants/sidebar';

import Button from 'components/core/Button';

import './styles.scss';

const { ENIGMA_URL } = process.env;

const routeBadge = [NEWSFEED.id];

const isShowFlickerAnimation = () => {
  const limitDate = new Date('2023/04/16 00:00:00').getTime();

  return limitDate > Date.now();
};

const JAI_ROUTE = {
  label: 'Search',
  href: ENIGMA_URL,
};

const Sidebar = ({ show, onHideSidebar }) => {
  const location = useLocation();

  const { newNewsfeedQuantity, onGetNewNewsfeedQuantity } = useNews();
  const { roles, modularRoles } = useUser();
  const { isMobileDevice } = useDevice();
  const { isShowJAIVA } = useVA();
  const { onSendAccessJAI } = useTakeEventGA();

  const renderBadge = quantity => {
    if (quantity) {
      return (
        <BBadge
          style={{
            position: 'absolute',
            right: '0',
            top: '0',
            backgroundColor: '#feeabd',
            color: '#e56e18',
            fontStyle: 'normal',
            fontSize: '8px',
          }}
        >
          {quantity.data.count > 9 ? '9+' : quantity.data.count}
        </BBadge>
      );
    }
  };

  const handleSidebarMobileClick = () => {
    if (isMobileDevice) onHideSidebar();
  };

  const handleCheckRenderBadge = route => {
    if (route) {
      let badge = null;
      switch (true) {
        case route === NEWSFEED.id &&
          newNewsfeedQuantity.data.count > 0 &&
          route !== location.pathname:
          badge = renderBadge(newNewsfeedQuantity);
          break;
        default:
          break;
      }
      return badge;
    }
  };

  const sidebarList = sidebarData.filter(
    item =>
      !item.roles ||
      item.roles.some(
        role => roles?.includes(role) || modularRoles?.includes(role),
      ),
  );

  useEffect(() => {
    onGetNewNewsfeedQuantity();
  }, []);

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (!isMobileDevice || !show) return;

    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isMobileDevice, show]);

  useSignalR(
    {
      url: `${settings.ROOT_BASE_URL}${USER_HUB_PATH}`,
      options: {
        accessTokenFactory: () => getStorage(ID_TOKEN),
      },
      event: [
        {
          methodName: 'NotifyNewsfeed',
          action: onGetNewNewsfeedQuantity,
        },
      ],
    },
    [],
  );

  const isShowSidebar = isMobileDevice ? show : true;

  return (
    <>
      {isMobileDevice && (
        <div
          className={classnames('edh-sidebar-overlay', { show: isShowSidebar })}
          role="button"
          tabIndex={0}
          onClick={onHideSidebar}
        >
          {' '}
        </div>
      )}
      <div
        className={classnames('edh-sidebar', {
          show: isShowSidebar,
          animate: !isMobileDevice,
        })}
        // eslint-disable-next-line react/no-unknown-property
        animate-type="translate-left-to-right"
        // eslint-disable-next-line react/no-unknown-property
        animate-duration="0.5"
      >
        <div className="edh-sidebar-top">
          <Link
            to="/"
            className="edh-sidebar-top__logo"
            onClick={handleSidebarMobileClick}
          >
            {isMobileDevice ? <LogoMobileIcon /> : <LogoIcon />}
          </Link>
          {isMobileDevice && (
            <Button
              className="edh-sidebar-top__close-btn"
              onClick={onHideSidebar}
            >
              <CloseIcon />
            </Button>
          )}
        </div>
        <div className="edh-sidebar-nav">
          {isShowJAIVA && (
            <a
              href={JAI_ROUTE.href}
              className={classnames('edh-sidebar-nav__item', {
                active: window.location.href.startsWith(JAI_ROUTE.href),
              })}
              onClick={onSendAccessJAI}
            >
              <div className="edh-sidebar-nav__icon jai">
                <JAIIcon />
              </div>
              <div
                className={classnames('edh-sidebar-nav__label', {
                  hiding: isMobileDevice && !show,
                })}
              >
                {JAI_ROUTE.label}
              </div>
            </a>
          )}
          {sidebarList.map(
            ({ id, name, route, icon: Icon, disabled, matchers }) => {
              const isFlicker =
                GET_STARTED.id === route && isShowFlickerAnimation();

              return (
                <NavLink
                  key={id}
                  to={{
                    pathname: !disabled && route,
                    state: { prevPath: location.pathname },
                  }}
                  className={classnames('edh-sidebar-nav__item', {
                    disabled,
                  })}
                  isActive={() =>
                    route === location.pathname ||
                    matchers?.some(item =>
                      location.pathname.includes(item.split('=')[0]),
                    )
                  }
                  onClick={handleSidebarMobileClick}
                >
                  <div className="edh-sidebar-nav__icon">
                    <Icon
                      className={classnames({ 'flicker-text': isFlicker })}
                    />
                    {routeBadge.includes(route) &&
                      handleCheckRenderBadge(route)}
                  </div>
                  <div
                    className={classnames('edh-sidebar-nav__label', {
                      hiding: isMobileDevice && !show,
                      'flicker-text': isFlicker,
                    })}
                  >
                    {name}
                  </div>
                </NavLink>
              );
            },
          )}
        </div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  show: PropTypes.bool,
  onHideSidebar: PropTypes.func,
};

Sidebar.defaultProps = {
  isSideBarExpand: true,
};

export default memo(Sidebar);
