import { call, put, takeLatest, delay, select } from 'redux-saga/effects';

import { isEmpty } from 'lodash';

import { getData, postData, patchData } from '../services/api';

import {
  CATEGORY_TYPE,
  DATASET_MANAGEMENT_TAB_LIST,
} from '../constants/requestStatus';
import {
  MY_REQUEST_API_PATH,
  MY_REQUEST_REQUESTED_DATA_API_PATH,
  SAVE_TIME_ACCESS_TO_USER_MANAGEMENT_API_PATH,
  NEW_USER_MANAGEMENT_QUANTITY_API_PATH,
  REQUESTED_CATEGORIES,
  DATASET_MANAGEMENT,
  DATASET_MANAGEMENT_UPDATE_LIST_REQUEST,
} from '../constants/paths';

import {
  listRequestParamSelector,
  getListRequestRequest,
  getListRequestSuccess,
  getListRequestFailure,
  updateStatusRequest,
  updateStatusSuccess,
  updateStatusFailure,
  postRequestedDataRequest,
  postRequestedDataSuccess,
  postRequestedDataFailure,
  getRequestedDataRequest,
  getRequestedDataSuccess,
  getRequestedDataFailure,
  getRequestedDataIdRequest,
  getRequestedDataIdSuccess,
  getRequestedDataIdFailure,
  getNewUserManagementQuantityRequest,
  getNewUserManagementQuantitySuccess,
  getNewUserManagementQuantityFailure,
  saveTimeAccessToUserManagementRequest,
  saveTimeAccessToUserManagementSuccess,
  saveTimeAccessToUserManagementFailure,
  getCategoriesRequest,
  getCategoriesSuccess,
  getCategoriesFailure,
} from '../slices/myRequest';
import {
  listEntitlementParamSelector,
  getListEntitlementRequest,
  getListStatusBlockRequest,
} from '../slices/datasetManagement';

export function* getListRequest({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: `${DATASET_MANAGEMENT}/${payload.page}`,
      params: payload,
    });

    yield delay(800);
    yield put(getListRequestSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getListRequestFailure(message));
  }
}

export function* handleUpdateStatus({ payload }) {
  const { callback, pageTitle } = payload;
  const { id, rowVersion, status, remark, adjustedDuration } = payload.data;
  const param = {
    data: [
      {
        id,
        rowVersion,
      },
    ],
    status,
    remark,
    adjustedDuration,
  };
  try {
    const {
      data: { data },
    } = yield call(patchData, {
      url: DATASET_MANAGEMENT_UPDATE_LIST_REQUEST,
      data: param,
    });

    if (!data.data || isEmpty(data.data)) {
      callback?.(false);
      return;
    }

    if (pageTitle === DATASET_MANAGEMENT_TAB_LIST[3].label) {
      const listEntitlementParam = yield select(listEntitlementParamSelector);
      yield put(
        getListEntitlementRequest(
          listEntitlementParam || {
            pageIndex: 1,
            pageSize: 10,
          },
        ),
      );

      yield put(getListStatusBlockRequest());
    } else {
      const dataResponse = {
        id,
        status: data.status,
        ...data.data[id],
      };
      yield put(updateStatusSuccess(dataResponse));
    }

    callback?.(true);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(updateStatusFailure(message));
  }
}

export function* postRequestedData({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, { url: MY_REQUEST_API_PATH, data: payload.data });

    yield delay(800);
    yield put(postRequestedDataSuccess(data));
    payload.callback();
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(postRequestedDataFailure(message));
  }
}

export function* getRequestedData({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: MY_REQUEST_REQUESTED_DATA_API_PATH,
      params: payload,
    });

    yield delay(800);
    yield put(getRequestedDataSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getRequestedDataFailure(message));
  }
}

export function* getRequestedDataId({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: `${MY_REQUEST_API_PATH}/${payload.id}?requestType=${payload.requestType}`,
    });

    yield delay(800);
    yield put(getRequestedDataIdSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getRequestedDataIdFailure(message));
  }
}

export function* getNewUserManagementQuantity({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: NEW_USER_MANAGEMENT_QUANTITY_API_PATH,
    });

    yield delay(800);
    yield put(getNewUserManagementQuantitySuccess(data));

    if (payload?.isUserManagementPage) {
      const listRequestParam = yield select(listRequestParamSelector);
      yield call(getListRequest, {
        payload: listRequestParam || {
          pageIndex: 1,
          pageSize: 10,
          category:
            payload?.isDGAAdmin || payload?.isDataSteward
              ? CATEGORY_TYPE.DATASET_ENTITLEMENT_REQUEST.code
              : CATEGORY_TYPE.NEW_DATASET_REQUEST.code,
        },
      });
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getNewUserManagementQuantityFailure(message));
  }
}

export function* saveTimeAccessToUserManagement() {
  try {
    yield call(patchData, {
      url: SAVE_TIME_ACCESS_TO_USER_MANAGEMENT_API_PATH,
    });

    yield delay(800);
    yield put(saveTimeAccessToUserManagementSuccess());
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(saveTimeAccessToUserManagementFailure(message));
  }
}

export function* getCategories({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: REQUESTED_CATEGORIES,
      params: payload,
    });

    yield delay(800);
    yield put(getCategoriesSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getCategoriesFailure(message));
  }
}

export default function* myRequestSaga() {
  yield takeLatest(getListRequestRequest().type, getListRequest);
  yield takeLatest(updateStatusRequest().type, handleUpdateStatus);
  yield takeLatest(postRequestedDataRequest().type, postRequestedData);
  yield takeLatest(getRequestedDataRequest().type, getRequestedData);
  yield takeLatest(getCategoriesRequest().type, getCategories);
  yield takeLatest(getRequestedDataIdRequest().type, getRequestedDataId);
  yield takeLatest(
    getNewUserManagementQuantityRequest().type,
    getNewUserManagementQuantity,
  );
  yield takeLatest(
    saveTimeAccessToUserManagementRequest().type,
    saveTimeAccessToUserManagement,
  );
}
