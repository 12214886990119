const delay = async (promise, timeout) => {
  const delayPromise = new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });

  const [response] = await Promise.all([promise, delayPromise]);

  return response;
};

export default delay;
