import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';

import { loginRequest } from 'msalConfig';
import { removeStorage, setStorage } from 'services/storage';
import { ACCESS_TOKEN, ID_TOKEN } from 'constants/storageKeys';
import useUser from 'hooks/useUser';
import { LOADING_STATUS } from 'constants/apis';

const useAuth = () => {
  const { instance, accounts } = useMsal();
  const { userInfo, onGetUserInfo } = useUser();

  const isAuthenticated = userInfo.status === LOADING_STATUS.SUCCEEDED;

  const handleLogin = useCallback(async () => {
    try {
      let account;
      const tokenResponse = await instance.handleRedirectPromise();
      if (tokenResponse) {
        account = tokenResponse.account;
        instance.setActiveAccount(account);
      }

      const accountActivated = account || instance.getActiveAccount();
      const request = {
        ...loginRequest,
        accountActivated,
      };

      const response = await instance.acquireTokenSilent(request);

      setStorage({
        key: ACCESS_TOKEN,
        val: response?.accessToken,
      });
      setStorage({
        key: ID_TOKEN,
        val: response?.idToken,
      });
      onGetUserInfo();
    } catch (error) {
      removeStorage(ACCESS_TOKEN);
      removeStorage(ID_TOKEN);
      instance.loginRedirect();
    }
  }, [instance, onGetUserInfo]);

  const handleLogout = useCallback(() => {
    instance.logoutRedirect();
    setStorage({ key: ACCESS_TOKEN, val: '' });
    setStorage({ key: ID_TOKEN, val: '' });
  }, [instance]);

  return {
    isAuthenticated,
    accounts,
    msalInstance: instance,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };
};

export default useAuth;
