import React, { useEffect, useState, memo } from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as CloseIcon } from 'assets/icons/common/close24.svg';

import { getStorage, setStorage } from 'services/storage';

import { useDidMount } from 'hooks/useDidMount';

import Button from 'components/core/Button';

// eslint-disable-next-line import/no-unresolved,import/extensions,import/no-absolute-path
import { introductionPopUpContent } from '/src/pages/AMDetailedInformationPage/AMExploreTab/constants';

import './styles.scss';

const IntroductionPopup = () => {
  const [step, setStep] = useState(0);

  const [isHidden, setIsHidden] = useState(
    getStorage('am-template-introduction-hidden'),
  );

  const content = introductionPopUpContent;

  const { header, description, elementId } = content[step];

  const element = (id, index) =>
    index === content.length - 1
      ? document.querySelector(`#${id}`)
      : document.querySelector(`#${id}`).closest('div[role="cell"]');

  const highlightElement = (id, index) => {
    element(id, index).style.zIndex = 12;
    element(id, index).style.position = 'relative';
  };

  const blurElement = (id, index) => {
    element(id, index).style.zIndex = null;
    element(id, index).style.position = null;
  };

  const handleOnClose = () => {
    setStep(0);
    setIsHidden(true);
  };

  const handleOnFinish = () => {
    setStep(0);
    setStorage({ key: 'am-template-introduction-hidden', val: true });
    setIsHidden(true);
  };

  const handleSetStep = val => {
    if (step === content.length - 1 && val > step) {
      handleOnFinish();
      blurElement(elementId);
    } else {
      highlightElement(content[val]?.elementId, val);
      blurElement(elementId, step);
      setStep(val);
    }
  };

  useDidMount(() => {
    if (!isHidden) {
      const cellElement = document.getElementById(content[0].elementId);
      cellElement.scrollIntoView({
        behavior: 'instant',
        block: 'end',
        inline: 'center',
      });
      highlightElement(content[0].elementId, step);
    }
  });

  useEffect(() => {
    const tableElement = document.getElementsByClassName(
      'keyword-skillset-template-table',
    )[0];
    const handleWindowWheel = event => {
      if (!isHidden) {
        event.preventDefault();
      }
    };

    tableElement.addEventListener('wheel', handleWindowWheel, {
      passive: false,
    });
    tableElement.addEventListener('touchmove', handleWindowWheel, {
      passive: false,
    });

    return () => {
      tableElement.removeEventListener('wheel', handleWindowWheel);
      tableElement.removeEventListener('touchmove', handleWindowWheel);
    };
  }, [isHidden]);

  const renderPopupCard = () => (
    <div
      className={`introduction-popup__container ${
        step === content.length - 1 ? 'top' : 'bottom'
      }`}
      onWheel={e => e.preventDefault()}
    >
      <div className="arrow-top" />
      <div className="introduction-popup__header">
        <div className="header-label">{header}</div>
        <CloseIcon className="header-close-button" onClick={handleOnClose} />
      </div>
      <div className="introduction-popup__description">{description}</div>
      <div className="introduction-popup__footer">
        <Button
          variant="default"
          type="button"
          onClick={() =>
            step === 0 ? handleOnClose() : handleSetStep(step - 1)
          }
        >
          {step === 0 ? `Skip` : 'Back'}
        </Button>
        <Button
          variant="contained"
          type="button"
          onClick={() => handleSetStep(step + 1)}
        >
          {step + 1 < content.length ? `Next` : 'Got it'}
        </Button>
      </div>
    </div>
  );

  return (
    <div className={`introduction-popup ${isHidden ? '' : 'visible'}`}>
      {!isHidden && createPortal(renderPopupCard(), element(elementId, step))}
    </div>
  );
};

export default memo(IntroductionPopup);
