/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */

import { PDFDocument } from 'pdf-lib';
import XLSX from 'xlsx';
import axios from 'axios';
import { getStorage } from '../storage';
import { ID_TOKEN } from '../../constants/storageKeys';

const setInstance = () => {
  const token = getStorage(ID_TOKEN);

  const instance = axios.create({
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob',
  });

  return instance;
};
export const formatFileSize = (bytes, decimalPoint = 0) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1000;
  const dm = decimalPoint;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const arrayBufferToBase64 = buffer => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const base64ToArrayBuffer = base64 => {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

export const renameFile = (originalFile, newName) =>
  new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });

export const getPageCountInFile = (file, callback) => {
  const reader = new FileReader();

  reader.onload = async fileEvent => {
    const count = fileEvent.target.result.match(
      /\/Type[\s]*\/Page[^s]/g,
    ).length;

    callback(count);
  };

  reader.readAsBinaryString(file);
};

export const readFile = (file, readType = 'arraybuffer') =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    if (readType === 'text') {
      reader.readAsText(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });

export const getNumPages = async file => {
  const arrayBuffer = await readFile(file);

  const pdf = await PDFDocument.load(arrayBuffer);

  return pdf.getPages();
};

export const getAiModelResultFile = async ({ apiPath, fileName }) => {
  const instance = setInstance();
  return instance
    .post(apiPath, { id: fileName })
    .then(response => response.data);
};

export const excelToJson = async file => {
  const fileData = await file.arrayBuffer();
  const workbook = XLSX.read(fileData, {
    dateNF: 'M/DD/YYYY',
    cellDates: true,
  });
  if (workbook?.SheetNames?.length) {
    return workbook.SheetNames.map(sName => ({
      name: sName,
      value: XLSX.utils.sheet_to_json(workbook.Sheets[sName], {
        header: 1,
        raw: false,
        dateNF: 'M/DD/YYYY',
      }),
    }));
  }
};

export function calculateImageSize(list = []) {
  let sum = 0;
  list.forEach(base64String => {
    const base64WithoutPrefix = base64String.replace(
      /^data:image\/\w+;base64,/,
      '',
    );

    const bytes = Math.ceil((base64WithoutPrefix.length / 4) * 3);
    sum += bytes;
  });
  return sum;
}

export const convertAOAtoWorkBook = ({ array, tabName, exportOpts }) => {
  const newData = array.map(row => row);
  const workSheet = XLSX.utils.aoa_to_sheet(newData, exportOpts);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, tabName);
  return workBook;
};

export const embedImagesToPdf = async imageList => {
  const promises = await imageList.map(item =>
    fetch(item.imageSrc).then(res => res.blob()),
  );
  const pdfDoc = await PDFDocument.create();
  const response = await Promise.all(promises);

  for (const file of response) {
    const imageData = await file.arrayBuffer();
    const page = await pdfDoc.addPage();
    const pngImage = await pdfDoc.embedPng(imageData);
    const scale = Math.min(
      page.getWidth() / pngImage.width,
      page.getHeight() / pngImage.height,
      1,
    );
    const pngDims = pngImage.scale(scale);

    await page.drawImage(pngImage, {
      x: page.getWidth() / 2 - pngDims.width / 2,
      y: page.getHeight() / 2 - pngDims.height / 2,
      width: pngDims.width,
      height: pngDims.height,
    });
  }
  const pdfBytes = await pdfDoc.save();
  return new File([pdfBytes], 'output.pdf');
};
