import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'user';
const initialState = {
  isAuthenticated: false,
  userInfo: {
    data: {},
    status: 'loading', // or: 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  },
  searchUser: {
    data: [],
    status: 'idle',
    error: null,
  },
  viewPrivacy: {
    data: {},
    status: 'loading',
    error: null,
  },
};

const userSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    updateIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    },
    getUserInfoRequest(state) {
      state.userInfo.status = 'loading';
    },
    getUserInfoSuccess(state, action) {
      state.userInfo.data = action.payload;
      state.userInfo.status = 'succeeded';
    },
    getUserInfoFailure(state, action) {
      state.userInfo.status = 'failed';
      state.userInfo.data = {};
      state.userInfo.error = action.payload;
    },
    searchUserRequest(state) {
      state.searchUser.status = 'loading';
    },
    searchUserSuccess(state, action) {
      state.searchUser.data = action.payload;
      state.searchUser.status = 'succeeded';
    },
    searchUserFailure(state, action) {
      state.searchUser.status = 'failed';
      state.searchUser.data = {};
      state.searchUser.error = action.payload;
    },
    viewPrivacyRequest(state) {
      state.viewPrivacy.status = 'loading';
    },
    viewPrivacySuccess(state, action) {
      state.viewPrivacy.data = action.payload;
      state.viewPrivacy.status = 'succeeded';
    },
    viewPrivacyFailure(state, action) {
      state.viewPrivacy.status = 'failed';
      state.viewPrivacy.data = {};
      state.viewPrivacy.error = action.payload;
    },
    updateIsAccessModelRequestPage(state, action) {
      state.userInfo.data.isAccessModelRequestPage = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = userSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getUserInfoRequest,
  getUserInfoSuccess,
  getUserInfoFailure,
  searchUserRequest,
  searchUserSuccess,
  searchUserFailure,
  updateIsAuthenticated,
  viewPrivacyRequest,
  viewPrivacySuccess,
  viewPrivacyFailure,
  updateIsAccessModelRequestPage,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const isAuthenticatedSelector = createSelector(
  rootSelector,
  ({ isAuthenticated }) => isAuthenticated,
);
export const userInfoSelector = createSelector(
  rootSelector,
  ({ userInfo }) => userInfo,
);
export const searchUserSelector = createSelector(
  rootSelector,
  ({ searchUser }) => searchUser,
);
