import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import LoadingBalls from '../LoadingBalls';

const RSMenuList = props => {
  const isDisplayTitle = useMemo(() => {
    const { options, selectProps } = props || {};
    if (selectProps?.isMulti && selectProps?.value?.length >= options.length) {
      return false;
    }

    return true;
  }, [props?.options, props?.selectProps?.value, props?.selectProps?.isMulti]);

  return (
    <>
      {props.isLoading && <LoadingBalls isLoading />}
      <div
        style={{
          color: '#00A19C',
          padding: '1.5rem 1.5rem 0rem 1.5rem',
          fontSize: 11,
          fontWeight: 'bold',
          letterSpacing: '1px',
        }}
      >
        {isDisplayTitle && props.title}
      </div>
      <components.MenuList {...props}>
        <div
          style={{
            margin: '0rem 0.75rem',
            color: '#3F3C4C',
            fontSize: 15,
            letterSpacing: '1px',
            lineHeight: 1.6,
          }}
        >
          {props.children}
        </div>
      </components.MenuList>
    </>
  );
};

RSMenuList.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  selectProps: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
};

RSMenuList.defaultProps = {};

export default memo(RSMenuList);
