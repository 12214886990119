import React, { memo } from 'react';
import PropTypes from 'prop-types';
import BSpinner from 'react-bootstrap/Spinner';
import { CgSearch } from 'react-icons/cg';
import { BsX } from 'react-icons/bs';
import _ from 'lodash';
import { LIMIT_CHARACTERS } from 'constants/dataRequestForm';

import { trimSpace } from 'services/string';

import useDevice from 'hooks/useDevice';

import Tooltip from '../../layout/Tooltip';

import './styles.scss';

const SearchInput = ({
  hasCountCharacters,
  hasRemoveAllKeywords,
  isLoading,
  isClearReload,
  isShowIconSearchMR,
  isSubmitForm,
  placeholder,
  value,
  onSetValue,
  onFocus,
  onBlur,
  onSubmit,
  onClearKeyword,
}) => {
  const handleSubmit = event => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(trimSpace(value));
    }
  };
  const onClear = () => {
    onSetValue('', isClearReload);
    onClearKeyword('');
  };

  const { isMobileDevice } = useDevice();

  const onRenderSearchInput = () => (
    <div className="edh-search-input">
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        maxLength={LIMIT_CHARACTERS.input}
        onChange={({ target }) => onSetValue(target.value)}
        onFocus={({ target }) => {
          onFocus(target.value);
        }}
        onBlur={({ target }) => {
          onBlur(target.value);
        }}
      />
      <div className="edh-search-input__buttons">
        {isLoading ? (
          <div className="edh-search-input__icon-loading">
            <BSpinner animation="border" size="sm" />
          </div>
        ) : (
          <>
            {hasRemoveAllKeywords && !!value?.length && (
              <Tooltip content="Remove all keywords">
                <div className="edh-search-input__icon-clear">
                  <BsX color="#C4C3D1" size={24} onClick={onClear} />
                </div>
              </Tooltip>
            )}

            <Tooltip disabled={isMobileDevice} content="Search">
              <div
                className="edh-search-input__icon-search"
                style={{
                  display: `${
                    isMobileDevice && !_.isEmpty(value) && isShowIconSearchMR
                      ? 'none'
                      : 'block'
                  }`,
                }}
              >
                <CgSearch color="#C4C3D1" size={18} onClick={handleSubmit} />
              </div>
            </Tooltip>
          </>
        )}
      </div>
      {hasCountCharacters && (
        <span className="edh-search-input__count-characters">
          {`${value?.length || 0}/${LIMIT_CHARACTERS.input} characters`}
        </span>
      )}
    </div>
  );

  if (!isSubmitForm) return onRenderSearchInput();

  return <form onSubmit={handleSubmit}>{onRenderSearchInput()}</form>;
};

SearchInput.propTypes = {
  hasCountCharacters: PropTypes.bool,
  hasRemoveAllKeywords: PropTypes.bool,
  isLoading: PropTypes.bool,
  isClearReload: PropTypes.bool,
  isShowIconSearchMR: PropTypes.bool,
  isSubmitForm: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onSetValue: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSubmit: PropTypes.func,
  onClearKeyword: PropTypes.func,
};

SearchInput.defaultProps = {
  hasCountCharacters: true,
  hasRemoveAllKeywords: true,
  isLoading: false,
  isClearReload: true,
  isSubmitForm: true,
  isShowIconSearchMR: false,
  placeholder: 'start your search...',
  onSetValue() {},
  onFocus() {},
  onBlur() {},
  onSubmit() {},
  onClearKeyword() {},
};

export default memo(SearchInput);
