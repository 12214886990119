import React, { useEffect, useState } from 'react';

import useUser from 'hooks/useUser';
import useQuery from 'hooks/useQuery';
import useDevice from 'hooks/useDevice';

import PrivacyConsentPopup from 'layouts/PrivacyConsentPopup';
import UserGuidePopup from 'layouts/UserGuidePopup';
import useTour from 'hooks/useTour';

const FirstAccess = () => {
  const { userInfo } = useUser();
  const { query, onRemoveQuery } = useQuery();
  const { isMobileDevice } = useDevice();
  const { onLandingPageShowTour } = useTour();

  const [isShowPrivacyPopup, setIsShowPrivacyPopup] = useState(
    !userInfo.data.isAcceptedPrivacyConsentPopup,
  );
  const [isShowUserGuide, setIsShowUserGuide] = useState(false);

  const userGuideCountNumber =
    !userInfo.data.isAcceptedPrivacyConsentPopup && isShowUserGuide ? 3 : 0;

  const handleHidePrivacyPopup = () => {
    setIsShowPrivacyPopup(false);

    if (!isMobileDevice) {
      setIsShowUserGuide(true);
    }
  };

  const handleHideUserGuidePopup = () => {
    setIsShowUserGuide(false);

    onLandingPageShowTour();
  };

  useEffect(() => {
    if (query.isOpenHelpPopup) {
      onRemoveQuery('isOpenHelpPopup');

      if (isMobileDevice) return;

      setIsShowUserGuide(true);
    }
  }, [query.isOpenHelpPopup, isMobileDevice]);

  return (
    <>
      <PrivacyConsentPopup
        show={isShowPrivacyPopup}
        onHide={handleHidePrivacyPopup}
      />
      <UserGuidePopup
        show={!isMobileDevice && isShowUserGuide}
        countdown={userGuideCountNumber}
        onHide={handleHideUserGuidePopup}
      />
    </>
  );
};

export default FirstAccess;
