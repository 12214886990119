import React from 'react';

import { uid } from 'services/uid';

import { deleteModelResult } from 'api/analyticsMarketplaceAPI';

import useAnalyticsMarketplace from './useAnalyticsMarketplace';
import useToasts from './useToasts';
import useQueryAPI from './useQueryAPI';

const useAnalyticsNotice = () => {
  const { updateRunModelResult } = useAnalyticsMarketplace();
  const { onAddToastsMessage, onRemoveToastsMessage } = useToasts();
  const { mutation } = useQueryAPI({ mutationFn: deleteModelResult });

  const addNoticeRunModel = ({
    isSuccess,
    files,
    processingTime,
    aiModelName,
    aiModelId,
    executedAt,
    deviceType,
  }) => {
    if (isSuccess === undefined) return;

    const toastId = uid();

    mutation({ props: aiModelId });

    const onSuccess = () =>
      updateRunModelResult({
        aiModelId,
        modelName: aiModelName,
        files,
        processingTime,
        executedAt,
        deviceType,
        status: 'success',
      });
    const onFail = () =>
      updateRunModelResult({
        aiModelId,
        files,
        modelName: aiModelName,
        status: 'failed',
      });

    if (isSuccess) {
      onSuccess();
    } else {
      onFail();
    }

    onAddToastsMessage({
      id: toastId,
      variant: isSuccess ? 'success' : 'danger',
      title: `Model result ${
        isSuccess ? 'has been ready to download' : 'can not be generated'
      }`,
      description: (
        <p>
          {isSuccess ? (
            <>
              Your model result <strong>“{aiModelName}”</strong> has been ready
              to download.
            </>
          ) : (
            <>
              Please try run your model <strong>“{aiModelName}”</strong> again
            </>
          )}
        </p>
      ),
      buttonText: 'Okay',
      onClick() {
        if (isSuccess) {
          onSuccess();
        } else {
          onFail();
        }
        onRemoveToastsMessage({ id: toastId });
      },
    });
  };

  return { addNoticeRunModel };
};

export default useAnalyticsNotice;
