import axios from 'axios';
import { ID_TOKEN } from '../../constants/storageKeys';

import { arrayBufferToBase64 } from '../file';
import { getStorage } from '../storage';
import { validateURL } from '../uri';

const getImageFromEmail = async src => {
  const source = axios.CancelToken.source();
  if (!src) {
    return null;
  }

  if (!validateURL(src)) {
    return `data:image/jpeg;base64,${src}`;
  }

  try {
    const data = await axios(src, {
      cancelToken: source.token,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getStorage(ID_TOKEN) || ''}`,
      },
      responseType: 'arraybuffer',
    }).then(res => arrayBufferToBase64(res?.data));

    if (data) {
      return `data:image/jpeg;base64,${data}`;
    }
  } catch (error) {
    return null;
  }
};

export default getImageFromEmail;
