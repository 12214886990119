import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'datasetManagement';
const initialState = {
  listStatusBlock: {
    data: {},
    status: 'idle',
    error: null,
  },
  listEntitlement: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  listRequestDetail: {
    data: {},
    status: {},
    error: {},
  },
  listRequestUpdate: {
    data: {},
    status: 'idle',
    error: null,
  },
  listEntitlementParam: null,
  tdcManagementCategoriesTerms: {
    data: {},
    status: 'idle',
    error: null,
  },
  tdcSubmitForReview: {
    data: {},
    status: 'idle',
    error: null,
  },
  tdcSampleTemplate: {
    data: [],
    status: 'idle',
    error: null,
  },
  tdcUploadTemplate: {
    data: {},
    status: 'idle',
    error: null,
  },
  tdcViewDetailTemplate: {
    data: {},
    status: 'idle',
    error: null,
  },
  tdcUpdateTemplate: {
    data: {},
    status: 'idle',
    error: null,
  },
  tdcTransformExcelTemplate: {
    data: [],
    status: 'idle',
    error: null,
  },
  tdcTemplateConstraints: {
    data: {},
    status: 'idle',
    error: null,
  },
  tdcPathFinder: {
    data: {},
    status: 'idle',
    error: null,
  },
  dqRulesSampleTemplate: {
    data: [],
    status: 'idle',
    error: null,
  },
  dqRulesUploadTemplate: {
    data: {},
    status: 'idle',
    error: null,
  },
  modelRequest: {
    data: {},
    payload: {},
    status: 'idle',
    error: null,
  },
  dqRulesTemplateConstraints: {
    data: {},
    status: 'idle',
    error: null,
  },
  dqRulesTransformExcelTemplate: {
    data: [],
    status: 'idle',
    error: null,
  },
  dqRulesViewDetailTemplate: {
    data: {},
    status: 'idle',
    error: null,
  },
  dqRulesUpdateTemplate: {
    data: {},
    status: 'idle',
    error: null,
  },
};

const datasetManagementSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getListStatusBlockRequest(state) {
      state.listStatusBlock.status = 'loading';
    },
    getListStatusBlockSuccess(state, action) {
      state.listStatusBlock.data = action.payload;
      state.listStatusBlock.status = 'succeeded';
      state.listStatusBlock.error = null;
    },
    getListStatusBlockFailure(state, action) {
      state.listStatusBlock.status = 'failed';
      state.listStatusBlock.data = {};
      state.listStatusBlock.error = action.payload;
    },

    getListEntitlementRequest(state, action) {
      state.listEntitlement.status = 'loading';
      state.listEntitlementParam = action.payload;
    },
    getListEntitlementSuccess(state, action) {
      state.listEntitlement.data = action.payload;
      state.listEntitlement.status = 'succeeded';
      state.listEntitlement.error = null;
    },
    getListEntitlementFailure(state, action) {
      state.listEntitlement.status = 'failed';
      state.listEntitlement.data = {};
      state.listEntitlement.error = action.payload;
    },

    getListRequestDetailRequest(state, action) {
      state.listRequestDetail.status[action.payload.dataSetId] = 'loading';
    },
    getListRequestDetailSuccess(state, action) {
      const { data, id } = action.payload;
      state.listRequestDetail.data[id] = data;
      state.listRequestDetail.status[id] = 'succeeded';
      state.listRequestDetail.error[id] = null;
    },
    getListRequestDetailFailure(state, action) {
      const { message, id } = action.payload;
      state.listRequestDetail.status[id] = 'failed';
      state.listRequestDetail.data[id] = {};
      state.listRequestDetail.error[id] = message;
    },
    updateListRequestRequest(state) {
      state.listRequestUpdate.status = 'loading';
    },
    updateListRequestSuccess(state, action) {
      const { dataSetId } = action.payload;
      const { data = {}, status } = action.payload.data || {};
      state.listRequestDetail.data[dataSetId].items =
        state.listRequestDetail.data[dataSetId].items.map(item => {
          if (data[item.id]) {
            return {
              ...item,
              ...data[item.id],
              status,
            };
          }
          return item;
        });
      state.listRequestUpdate.status = 'succeeded';
      state.listRequestUpdate.error = null;
    },
    updateListRequestFailure(state, action) {
      state.listRequestUpdate.status = 'failed';
      state.listRequestUpdate.data = {};
      state.listRequestUpdate.error = action.payload;
    },
    postTdcManagementCategoriesTermsRequest(state) {
      state.tdcManagementCategoriesTerms.status = 'loading';
    },
    postTdcManagementCategoriesTermsSuccess(state, action) {
      state.tdcManagementCategoriesTerms.data = action.payload;
      state.tdcManagementCategoriesTerms.status = 'succeeded';
    },
    postTdcManagementCategoriesTermsFailure(state, action) {
      state.tdcManagementCategoriesTerms.status = 'failed';
      state.tdcManagementCategoriesTerms.data = [];
      state.tdcManagementCategoriesTerms.error = action.payload;
    },
    postTdcSubmitForReviewRequest(state) {
      state.tdcSubmitForReview.status = 'loading';
    },
    postTdcSubmitForReviewSuccess(state, action) {
      state.tdcSubmitForReview.data = action.payload;
      state.tdcSubmitForReview.status = 'succeeded';
    },
    postTdcSubmitForReviewFailure(state, action) {
      state.tdcSubmitForReview.status = 'failed';
      state.tdcSubmitForReview.data = [];
      state.tdcSubmitForReview.error = action.payload;
    },
    getTdcManagementSampleTemplateRequest(state) {
      state.tdcSampleTemplate.status = 'loading';
    },
    getTdcManagementSampleTemplateSuccess(state, action) {
      state.tdcSampleTemplate.data = action.payload;
      state.tdcSampleTemplate.status = 'succeeded';
    },
    getTdcManagementSampleTemplateFailure(state, action) {
      state.tdcSampleTemplate.status = 'failed';
      state.tdcSampleTemplate.data = [];
      state.tdcSampleTemplate.error = action.payload;
    },
    postTdcUploadTemplateRequest(state) {
      state.tdcUploadTemplate.status = 'loading';
    },
    postTdcUploadTemplateSuccess(state, action) {
      state.tdcUploadTemplate.data = action.payload;
      state.tdcUploadTemplate.status = 'succeeded';
    },
    postTdcUploadTemplateFailure(state, action) {
      state.tdcUploadTemplate.status = 'failed';
      state.tdcUploadTemplate.data = [];
      state.tdcUploadTemplate.error = action.payload;
    },
    getTdcViewDetailTemplateRequest(state) {
      state.tdcViewDetailTemplate.status = 'loading';
    },
    getTdcViewDetailTemplateSuccess(state, action) {
      state.tdcViewDetailTemplate.data = action.payload;
      state.tdcViewDetailTemplate.status = 'succeeded';
      state.tdcViewDetailTemplate.error = null;
    },
    getTdcViewDetailTemplateFailure(state, action) {
      state.tdcViewDetailTemplate.status = 'failed';
      state.tdcViewDetailTemplate.data = {};
      state.tdcViewDetailTemplate.error = action.payload;
    },
    putTdcUpdateTemplateRequest(state) {
      state.tdcUpdateTemplate.status = 'loading';
    },
    putTdcUpdateTemplateSuccess(state, action) {
      state.tdcUpdateTemplate.data = action.payload;
      state.tdcUpdateTemplate.status = 'succeeded';
      state.tdcUpdateTemplate.error = null;
    },
    putTdcUpdateTemplateFailure(state, action) {
      state.tdcUpdateTemplate.status = 'failed';
      state.tdcUpdateTemplate.data = {};
      state.tdcUpdateTemplate.error = action.payload;
    },
    postTdcTransformExcelTemplateRequest(state) {
      state.tdcTransformExcelTemplate.status = 'loading';
    },
    postTdcTransformExcelTemplateSuccess(state, action) {
      state.tdcTransformExcelTemplate.data = action.payload;
      state.tdcTransformExcelTemplate.status = 'succeeded';
      state.tdcTransformExcelTemplate.error = null;
    },
    postTdcTransformExcelTemplateFailure(state, action) {
      state.tdcTransformExcelTemplate.status = 'failed';
      state.tdcTransformExcelTemplate.data = [];
      state.tdcTransformExcelTemplate.error = action.payload;
    },
    getTdcTemplateConstraintsRequest(state) {
      state.tdcTemplateConstraints.status = 'loading';
    },
    getTdcTemplateConstraintsSuccess(state, action) {
      state.tdcTemplateConstraints.data = action.payload;
      state.tdcTemplateConstraints.status = 'succeeded';
      state.tdcTemplateConstraints.error = null;
    },
    getTdcTemplateConstraintsFailure(state, action) {
      state.tdcTemplateConstraints.status = 'failed';
      state.tdcTemplateConstraints.data = {};
      state.tdcTemplateConstraints.error = action.payload;
    },
    getTdcPathFinderRequest(state) {
      state.tdcPathFinder.status = 'loading';
    },
    getTdcPathFinderSuccess(state, action) {
      state.tdcPathFinder.data = action.payload;
      state.tdcPathFinder.status = 'succeeded';
      state.tdcPathFinder.error = null;
    },
    getTdcPathFinderFailure(state, action) {
      state.tdcPathFinder.status = 'failed';
      state.tdcPathFinder.data = {};
      state.tdcPathFinder.error = action.payload;
    },
    getDQRulesSampleTemplateRequest(state) {
      state.dqRulesSampleTemplate.status = 'loading';
    },
    getDQRulesSampleTemplateSuccess(state, action) {
      state.dqRulesSampleTemplate.data = action.payload;
      state.dqRulesSampleTemplate.status = 'succeeded';
    },
    getDQRulesSampleTemplateFailure(state, action) {
      state.dqRulesSampleTemplate.status = 'failed';
      state.dqRulesSampleTemplate.data = [];
      state.dqRulesSampleTemplate.error = action.payload;
    },
    postDQRulesUploadTemplateRequest(state) {
      state.dqRulesUploadTemplate.status = 'loading';
    },
    postDQRulesUploadTemplateSuccess(state, action) {
      state.dqRulesUploadTemplate.data = action.payload;
      state.dqRulesUploadTemplate.status = 'succeeded';
    },
    postDQRulesUploadTemplateFailure(state, action) {
      state.dqRulesUploadTemplate.status = 'failed';
      state.dqRulesUploadTemplate.data = [];
      state.dqRulesUploadTemplate.error = action.payload;
    },
    getDQRulesTemplateConstraintsRequest(state) {
      state.dqRulesTemplateConstraints.status = 'loading';
    },
    getDQRulesTemplateConstraintsSuccess(state, action) {
      state.dqRulesTemplateConstraints.data = action.payload;
      state.dqRulesTemplateConstraints.status = 'succeeded';
      state.dqRulesTemplateConstraints.error = null;
    },
    getDQRulesTemplateConstraintsFailure(state, action) {
      state.dqRulesTemplateConstraints.status = 'failed';
      state.dqRulesTemplateConstraints.data = {};
      state.dqRulesTemplateConstraints.error = action.payload;
    },
    postDQRulesTransformExcelTemplateRequest(state) {
      state.dqRulesTransformExcelTemplate.status = 'loading';
    },
    postDQRulesTransformExcelTemplateSuccess(state, action) {
      state.dqRulesTransformExcelTemplate.data = action.payload;
      state.dqRulesTransformExcelTemplate.status = 'succeeded';
      state.dqRulesTransformExcelTemplate.error = null;
    },
    postDQRulesTransformExcelTemplateFailure(state, action) {
      state.dqRulesTransformExcelTemplate.status = 'failed';
      state.dqRulesTransformExcelTemplate.data = [];
      state.dqRulesTransformExcelTemplate.error = action.payload;
    },
    getDQRulesViewDetailTemplateRequest(state) {
      state.dqRulesViewDetailTemplate.status = 'loading';
    },
    getModelRequestRequest(state, { payload }) {
      state.modelRequest.status = 'loading';
      state.modelRequest.payload = payload;
    },
    getModelRequestClear(state) {
      state.modelRequest.status = 'loading';
      state.modelRequest.data = {};
    },
    getModelRequestSuccess(state, action) {
      state.modelRequest.data = action.payload;
      state.modelRequest.status = 'succeeded';
    },
    getModelRequestFailure(state, action) {
      state.modelRequest.status = 'failed';
      state.modelRequest.data = {};
      state.modelRequest.error = action.payload;
    },
    getDQRulesViewDetailTemplateSuccess(state, action) {
      state.dqRulesViewDetailTemplate.data = action.payload;
      state.dqRulesViewDetailTemplate.status = 'succeeded';
      state.dqRulesViewDetailTemplate.error = null;
    },
    getDQRulesViewDetailTemplateFailure(state, action) {
      state.dqRulesViewDetailTemplate.status = 'failed';
      state.dqRulesViewDetailTemplate.data = {};
      state.dqRulesViewDetailTemplate.error = action.payload;
    },
    putDQRulesUpdateTemplateRequest(state) {
      state.dqRulesUpdateTemplate.status = 'loading';
    },
    putDQRulesUpdateTemplateSuccess(state, action) {
      state.dqRulesUpdateTemplate.data = action.payload;
      state.dqRulesUpdateTemplate.status = 'succeeded';
      state.dqRulesUpdateTemplate.error = null;
    },
    putDQRulesUpdateTemplateFailure(state, action) {
      state.dqRulesUpdateTemplate.status = 'failed';
      state.dqRulesUpdateTemplate.data = {};
      state.dqRulesUpdateTemplate.error = action.payload;
    },
  },
});

const { actions, reducer } = datasetManagementSlice;

export default reducer;

export const {
  getListStatusBlockRequest,
  getListStatusBlockSuccess,
  getListStatusBlockFailure,
  getListEntitlementRequest,
  getListEntitlementSuccess,
  getListEntitlementFailure,
  getListRequestDetailRequest,
  getListRequestDetailSuccess,
  getListRequestDetailFailure,
  updateListRequestRequest,
  updateListRequestSuccess,
  updateListRequestFailure,
  postTdcManagementCategoriesTermsRequest,
  postTdcManagementCategoriesTermsSuccess,
  postTdcManagementCategoriesTermsFailure,
  postTdcSubmitForReviewRequest,
  postTdcSubmitForReviewSuccess,
  postTdcSubmitForReviewFailure,
  getTdcManagementSampleTemplateRequest,
  getTdcManagementSampleTemplateSuccess,
  getTdcManagementSampleTemplateFailure,
  postTdcUploadTemplateRequest,
  postTdcUploadTemplateSuccess,
  postTdcUploadTemplateFailure,
  getTdcViewDetailTemplateRequest,
  getTdcViewDetailTemplateSuccess,
  getTdcViewDetailTemplateFailure,
  putTdcUpdateTemplateRequest,
  putTdcUpdateTemplateSuccess,
  putTdcUpdateTemplateFailure,
  postTdcTransformExcelTemplateRequest,
  postTdcTransformExcelTemplateSuccess,
  postTdcTransformExcelTemplateFailure,
  getTdcTemplateConstraintsRequest,
  getTdcTemplateConstraintsSuccess,
  getTdcTemplateConstraintsFailure,
  getTdcPathFinderRequest,
  getTdcPathFinderSuccess,
  getTdcPathFinderFailure,
  getDQRulesSampleTemplateRequest,
  getDQRulesSampleTemplateSuccess,
  getDQRulesSampleTemplateFailure,
  postDQRulesUploadTemplateRequest,
  postDQRulesUploadTemplateSuccess,
  postDQRulesUploadTemplateFailure,
  getDQRulesTemplateConstraintsRequest,
  getDQRulesTemplateConstraintsSuccess,
  getDQRulesTemplateConstraintsFailure,
  postDQRulesTransformExcelTemplateRequest,
  postDQRulesTransformExcelTemplateSuccess,
  postDQRulesTransformExcelTemplateFailure,
  getModelRequestRequest,
  getModelRequestClear,
  getModelRequestSuccess,
  getModelRequestFailure,
  getDQRulesViewDetailTemplateRequest,
  getDQRulesViewDetailTemplateSuccess,
  getDQRulesViewDetailTemplateFailure,
  putDQRulesUpdateTemplateRequest,
  putDQRulesUpdateTemplateSuccess,
  putDQRulesUpdateTemplateFailure,
} = actions;

export const rootSelector = state => state[ROOT_STATE_NAME] || {};

export const listStatusBlockSelector = createSelector(
  rootSelector,
  ({ listStatusBlock }) => listStatusBlock,
);

export const listEntitlementSelector = createSelector(
  rootSelector,
  ({ listEntitlement }) => listEntitlement,
);

export const listRequestDetailSelector = createSelector(
  rootSelector,
  ({ listRequestDetail }) => listRequestDetail,
);

export const listRequestUpdateSelector = createSelector(
  rootSelector,
  ({ listRequestUpdate }) => listRequestUpdate,
);

export const listEntitlementParamSelector = createSelector(
  rootSelector,
  ({ listEntitlementParam }) => listEntitlementParam,
);

export const tdcManagementCategoriesTermsSelector = createSelector(
  rootSelector,
  ({ tdcManagementCategoriesTerms }) => tdcManagementCategoriesTerms,
);

export const tdcManagementSubmitForReviewSelector = createSelector(
  rootSelector,
  ({ tdcSubmitForReview }) => tdcSubmitForReview,
);

export const tdcManagementSampleTemplateSelector = createSelector(
  rootSelector,
  ({ tdcSampleTemplate }) => tdcSampleTemplate,
);

export const tdcUploadTemplateSelector = createSelector(
  rootSelector,
  ({ tdcUploadTemplate }) => tdcUploadTemplate,
);

export const tdcViewDetailTemplateSelector = createSelector(
  rootSelector,
  ({ tdcViewDetailTemplate }) => tdcViewDetailTemplate,
);

export const tdcUpdateTemplateSelector = createSelector(
  rootSelector,
  ({ tdcUpdateTemplate }) => tdcUpdateTemplate,
);

export const tdcTransformExcelTemplateSelector = createSelector(
  rootSelector,
  ({ tdcTransformExcelTemplate }) => tdcTransformExcelTemplate,
);

export const tdcTemplateConstraintsSelector = createSelector(
  rootSelector,
  ({ tdcTemplateConstraints }) => tdcTemplateConstraints,
);

export const tdcPathFinderSelector = createSelector(
  rootSelector,
  ({ tdcPathFinder }) => tdcPathFinder,
);

export const dqRulesSampleTemplateSelector = createSelector(
  rootSelector,
  ({ dqRulesSampleTemplate }) => dqRulesSampleTemplate,
);

export const dqRulesUploadTemplateSelector = createSelector(
  rootSelector,
  ({ dqRulesUploadTemplate }) => dqRulesUploadTemplate,
);

export const dqRulesTemplateConstraintsSelector = createSelector(
  rootSelector,
  ({ dqRulesTemplateConstraints }) => dqRulesTemplateConstraints,
);

export const dqRulesTransformExcelTemplateSelector = createSelector(
  rootSelector,
  ({ dqRulesTransformExcelTemplate }) => dqRulesTransformExcelTemplate,
);
export const modelRequestSelector = createSelector(
  rootSelector,
  ({ modelRequest }) => modelRequest,
);
export const dqRulesViewDetailTemplateSelector = createSelector(
  rootSelector,
  ({ dqRulesViewDetailTemplate }) => dqRulesViewDetailTemplate,
);

export const dqRulesUpdateTemplateSelector = createSelector(
  rootSelector,
  ({ dqRulesUpdateTemplate }) => dqRulesUpdateTemplate,
);
