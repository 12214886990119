import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowDown } from 'react-icons/io';

import useToggle from 'hooks/useToggle';

import './styles.scss';

const Question = ({ type, questions }) => {
  const [isCollapse, toggleIsCollapse] = useToggle(true);

  const wrapperClass = (() => {
    const classes = ['edh-user-guide-question'];

    if (!isCollapse) {
      classes.push('expand');
    }

    return classes.join(' ');
  })();

  return (
    <div className={wrapperClass}>
      <h3
        className="edh-user-guide-question__category"
        onClick={toggleIsCollapse}
        aria-hidden
      >
        <IoIosArrowDown /> {type}
      </h3>
      <div className="edh-user-guide-question__list">
        {questions.length > 0 ? (
          questions.map(({ question, answer }, index) => {
            const numericalOrder = index + 1;

            return (
              <div
                key={numericalOrder}
                className="edh-user-guide-question__item"
              >
                <h4 className="edh-user-guide-question__item-question">
                  {numericalOrder}.{question}
                </h4>
                <div
                  className="edh-user-guide-question__item-answer"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: answer.toString() }}
                />
              </div>
            );
          })
        ) : (
          <p>Not yet available.</p>
        )}
      </div>
    </div>
  );
};

Question.propTypes = {
  type: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
};

export default memo(Question);
