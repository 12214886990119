import { useEffect, useRef } from 'react';

function useEffectOnceWhen(callback = () => {}, when = true) {
  const hasRunOnceRef = useRef(false);
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  });
  useEffect(() => {
    if (when && !hasRunOnceRef.current) {
      callbackRef.current();
      hasRunOnceRef.current = true;
    }
  }, [when]);
}

export { useEffectOnceWhen };
