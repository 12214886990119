import React, { memo, useState } from 'react';
import * as PropTypes from 'prop-types';

import { ReactComponent as DownloadCSVIcon } from 'assets/icons/common/download-csv.svg';

import KeywordSkillSetTemplateModal from 'features/AM/components/layout/KeywordSkillSetTemplateModal';

const SampleDataTemplate = ({ templates, label, disabled, name, size }) => {
  const [showTemplate, setShowTemplate] = useState(false);
  const onSelectTemplate = () => {
    if (!disabled) {
      setShowTemplate(true);
    }
  };
  const onCloseTemplateModal = () => {
    setShowTemplate(false);
  };
  return (
    <div className="sample-data__field skill-set">
      <p className="sample-data__label">{label}</p>
      {templates.map(item => (
        <React.Fragment key={item.name}>
          <div
            className={`sample-data__template ${disabled ? 'disabled' : ''}`}
            role="presentation"
            onClick={onSelectTemplate}
          >
            <DownloadCSVIcon className="file-icon" />
            <div className="file-info">
              <span className="file-name">{name}</span>
              <span className="file-size">{size}</span>
            </div>
          </div>
          <KeywordSkillSetTemplateModal
            data={item?.file}
            isShowModal={showTemplate}
            isEditAble={false}
            onClose={onCloseTemplateModal}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

SampleDataTemplate.propTypes = {
  templates: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};
export default memo(SampleDataTemplate);
