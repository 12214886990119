import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'favourite';
const initialState = {
  favouriteDomainAction: {
    data: {},
    status: 'loading',
    error: null,
  },
};

const favouriteSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    updateDataset(state, action) {
      state.datasetFavourite.data.items =
        state.datasetFavourite.data.items.filter(
          item => item.id !== action.payload.id,
        );
    },
    updateDatasetDetail(state, action) {
      const index = state.datasetDetail.data.items.findIndex(
        item => item.id === action.payload.id,
      );
      if (index !== -1) {
        state.datasetDetail.data.items[index].isFavorite =
          !state.datasetDetail.data.items[index].isFavorite;
      }
    },
    clearDatasetDetailFavouriteRequest(state) {
      state.datasetFavourite.data = {
        items: [],
      };
    },
    putFavouriteDomainRequest(state) {
      state.favouriteDomainAction.status = 'loading';
    },
    putFavouriteDomainSuccess(state, action) {
      state.favouriteDomainAction.data = action.payload;
      state.favouriteDomainAction.status = 'succeeded';
    },
    putFavouriteDomainFailure(state, action) {
      state.favouriteDomainAction = {
        status: 'failed',
        data: {},
        error: action.payload,
      };
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = favouriteSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name

export const {
  updateDatasetDetail,
  clearDatasetDetailFavouriteRequest,
  putFavouriteDomainRequest,
  putFavouriteDomainSuccess,
  putFavouriteDomainFailure,
  updateDataset,
} = actions;

export const rootSelector = state => state[ROOT_STATE_NAME] || {};

export const favouriteDomainActionSelector = createSelector(
  rootSelector,
  ({ favouriteDomainAction }) => favouriteDomainAction,
);

export const favouriteDatasetActionSelector = createSelector(
  rootSelector,
  ({ favouriteDatasetAction }) => favouriteDatasetAction,
);
