import React from 'react';
import PropTypes from 'prop-types';

import '../styles.scss';

const PopupGroup = ({ children, className, ...rest }) => {
  const classes = ['edh-popup-group'];

  if (className) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')} {...rest}>
      {children}
    </div>
  );
};

PopupGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default PopupGroup;
