import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { ReactComponent as HelpIcon } from 'assets/icons/common/help.svg';

import classnames from 'services/classnames';

import useDevice from 'hooks/useDevice';

import Tooltip from 'components/layout/Tooltip';
import UserGuidePopup from 'layouts/UserGuidePopup';

import './styles.scss';

const UserGuide = ({ isDarkIcon }) => {
  const [isShowUserGuide, setIsShowUserGuide] = useState(false);

  const { isMobileDevice } = useDevice();

  const handleShowUserGuide = () => {
    setIsShowUserGuide(true);
  };

  const handleHideUserGuide = () => {
    setIsShowUserGuide(false);
  };

  return (
    <div className="edh-user-guide">
      <Tooltip content="Help & Contacts" disabled={isMobileDevice}>
        <Button
          className={classnames('edh-user-guide__help', {
            dark: isDarkIcon,
          })}
          onClick={handleShowUserGuide}
        >
          <HelpIcon />
        </Button>
      </Tooltip>

      <UserGuidePopup show={isShowUserGuide} onHide={handleHideUserGuide} />
    </div>
  );
};

UserGuide.propTypes = {
  isDarkIcon: PropTypes.bool,
};

export default memo(UserGuide);
