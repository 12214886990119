import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useWatermarkImage from 'features/AM/hooks/useWatermarkImage';
import { TEXT_UNDER_WATERMARK } from '../../../constants';

import './styles.scss';

const TextToImageSampleInput = ({ sampleData = [] }) => {
  useEffect(() => {
    const elm = document.querySelector(
      '.am-preview-subtab__model-body.preview-model',
    );
    elm.classList.add('preview-model-text-to-image');
  }, []);

  if (sampleData.length === 0) {
    return null;
  }

  return (
    <div className="am-preview-subtab__model-panel">
      <div className="am-preview-subtab__model-header">
        <div className="am-preview-subtab__model-title">Sample Input</div>
      </div>
      <div className="sample-data__form-container">
        <div className="sample-data__label">Prompt</div>
        <div className="sample-data__text">{sampleData[0].description}</div>
      </div>
    </div>
  );
};

const TextToImageSampleOutput = ({ sampleData = [] }) => {
  const { renderImage } = useWatermarkImage({
    prefix: 'sample-data',
    src: sampleData[0].fileUrl,
    caption: `${TEXT_UNDER_WATERMARK}: "${sampleData[0].description}"`,
  });

  return (
    <div className="am-preview-subtab__model-panel">
      <div className="am-preview-subtab__model-header">
        <div className="am-preview-subtab__model-title">Sample Output</div>
      </div>
      {renderImage()}
    </div>
  );
};

TextToImageSampleOutput.propTypes = {
  sampleData: PropTypes.array,
};

TextToImageSampleInput.propTypes = {
  sampleData: PropTypes.any,
};

export { TextToImageSampleInput, TextToImageSampleOutput };
