import useMediaQuery from './useMediaQuery';

const DEVICE = {
  mobile: 1023,
  inch14: 1365,
};

const useDevice = () => {
  const isMobileDevice = useMediaQuery(`(max-width: ${DEVICE.mobile}px)`);
  const is13InchDevice = useMediaQuery(
    `(min-width: ${DEVICE.mobile + 1}px) and (max-width: ${DEVICE.inch14}px)`,
  );

  return {
    isMobileDevice,
    is13InchDevice,
  };
};

export default useDevice;
