import { uid } from '../services/uid';

export const ENTITLEMENT_DURATION = [
  {
    id: uid(),
    label: '1 Month',
    value: '1 Month',
  },
  {
    id: uid(),
    label: '6 Months',
    value: '6 Months',
  },
  {
    id: uid(),
    label: '1 Year',
    value: '1 Year',
  },
  {
    id: uid(),
    label: 'Permanent',
    value: 'Permanent',
  },
];

export const REQUEST_FORM_DROPDOWN = [
  {
    title: 'Select Action',
    accessor: 'Select Action',
  },
  {
    title: 'Add New Dataset',
    accessor: 'New Dataset Request',
  },
  {
    title: 'Dataset Entitlement Request',
    accessor: 'Dataset Entitlement Request',
  },
  {
    title: 'Change Current Dataset Request',
    accessor: 'Change Current Dataset Request',
  },
];
export const REQUEST_FORM_DROPDOWN_DATASET_DETAIL = [
  {
    title: 'Select Action',
    accessor: 'Select Action',
  },
  {
    title: 'Dataset Entitlement Request',
    accessor: 'Dataset Entitlement Request',
  },
  {
    title: 'Change Current Dataset Request',
    accessor: 'Change Current Dataset Request',
  },
];

export const REQUEST_TYPES = {
  addNewRequest: 'Add New Dataset Request',
  entitlementRequest: 'Dataset Entitlement Request',
  changeCurrentRequest: 'Change Current Dataset Request',
  extensionEntitlementRequest: 'Dataset Entitlement Extension Request',
};

export const LIMIT_CHARACTERS = {
  titleNewsFeed: 3000,
  descriptionNewsFeed: 18000,
  input: 300,
  textarea: 1500,
};

export const listAcceptMaxLength = [
  'Backspace',
  'Delete',
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
];

export const PLACEHOLDER = {
  selectDataset: 'Select Dataset',
};

export const MENU_TITLE_LIST = {
  datasetAssignment: 'DATASET ASSIGNMENT',
};
