export const DQ_DATA_QUALITY = 'v1/data-quality';
export const DQ_MY_WORKSPACE = 'v1/my-workspace';
export const DQ_DATA_QUALITY_MANAGEMENT = 'v1/data-quality-management';

export const DQ_DATA_QUALITY_OVERALL_SCORE = `${DQ_DATA_QUALITY}/overall-score`;
export const DQ_DATA_QUALITY_BUSINESS_DOMAIN = `${DQ_DATA_QUALITY}/business-domains`;
export const DQ_DATA_QUALITY_ANNOUNCEMENTS = `${DQ_DATA_QUALITY}/announcements`;
export const DQ_GRAPH_CHART = `${DQ_DATA_QUALITY}/overall-score-chart`;
export const DQ_SUBMISSION = `${DQ_DATA_QUALITY_MANAGEMENT}/dq-submission`;

export const DQ_REQUEST_DATA = 'v1/requested-data';
export const DQ_BAD_DATA_ENTITLEMENT_REQUEST =
  'v1/management-dashboard/bad-data-entitlement-request';
export const DQ_BAD_DATA_ACCESS_MANAGEMENT_UPDATE_STATUS =
  'v1/management-dashboard/update-status';

export const DQ_BAD_DATA = `${DQ_DATA_QUALITY}/bad-data`;
export const DQ_BAD_DATA_DOWNLOAD = `${DQ_BAD_DATA}/download`;
export const DQ_BAD_DATA_EXPORT = `${DQ_BAD_DATA}/export`;
export const DQ_BAD_DATA_GET_VIEW_NAME_INFO = `${DQ_BAD_DATA}/viewname-information`;

export const DATA_QUALITY_EXPORT_HISTORY = `${DQ_MY_WORKSPACE}/data-quality-history`;
export const DATA_QUALITY_DELETE_EXPORT_HISTORY_ITEM = `${DQ_MY_WORKSPACE}/delete-export-data-quality`;

export const DQ_RULES_LIBRARY = `${DQ_DATA_QUALITY}/rules-library`;
export const DQ_RULES_TOTAL_NUMBER = `${DQ_RULES_LIBRARY}/total-number`;
export const DQ_RULES_LIBRARY_FILTER_OPTIONS = `${DQ_RULES_LIBRARY}/filter-options`;

export const DQ_RULES_MY_RULES = `${DQ_DATA_QUALITY}/my-rules`;
export const DQ_RULES_TOTAL_NUMBER_MY_RULES = `${DQ_RULES_MY_RULES}/total-number`;
export const DQ_RULES_MY_RULES_FILTER_OPTIONS = `${DQ_RULES_MY_RULES}/filter-options`;

export const DQ_SUBMISSION_MODIFIED_VIEW = `${DQ_SUBMISSION}/modified-view`;
export const DQ_SUBMISSION_FILTER_OPTIONS = `${DQ_SUBMISSION}/filter-options`;
export const DQ_SUBMISSION_BLOCKS = `${DQ_SUBMISSION}/blocks`;
export const DQ_SUBMISSION_TEMP_EDIT_PATCH = `${DQ_SUBMISSION}/temp-edit`;
export const DQ_SUBMISSION_TEMP_EDIT_CANCEL = `${DQ_SUBMISSION}/#id/cancel-temp-edit`;
export const DQ_SUBMISSION_TEMP_EDIT_SAVE = `${DQ_SUBMISSION}/#id/save-temp-edit`;
