import React, { forwardRef, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import BButton from 'react-bootstrap/Button';
import { BsXLg } from 'react-icons/bs';

import './styles.scss';

const AlertItem = forwardRef(
  ({ isAutoHide, variant, duration, content, className, onClose }, ref) => {
    useEffect(() => {
      let alertTimeout;

      if (isAutoHide) {
        alertTimeout = setTimeout(() => {
          onClose();
        }, duration || 3000);
      }

      return () => {
        if (alertTimeout) {
          clearTimeout(alertTimeout);
        }
      };
    }, []);

    return (
      <div className={['edh-alert', className, variant].join(' ')} ref={ref}>
        {content}
        <BButton onClick={onClose} style={{ lineHeight: '12px' }}>
          <BsXLg size={12} />
        </BButton>
      </div>
    );
  },
);

AlertItem.propTypes = {
  isAutoHide: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  duration: PropTypes.number,
  className: PropTypes.string,
  content: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
};

AlertItem.defaultProps = {
  isAutoHide: false,
};

export default memo(AlertItem);
