import React from 'react';
import { withTransaction } from '@elastic/apm-rum-react';

import { TRANSACTION_NAME } from 'constants/elasticSearch';

import { Toasts } from 'layouts';
import ErrorForbiddenMessage from 'components/layout/ErrorForbiddenMessage';

const ForBiddenPage = () => {
  const message = 'Access Denied!';
  const description =
    "It seems like you don’t have permission to see this page. If you're experiencing difficulties accessing a Data+ page or have concerns about your authorization, here's you can drop your concern/issue.";

  return (
    <>
      <ErrorForbiddenMessage message={message} description={description} />
      <Toasts />
    </>
  );
};

export default withTransaction('/forbidden', TRANSACTION_NAME)(ForBiddenPage);
