import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addToastsMessage,
  removeToastsMessage,
  toastsMessagesSelector,
} from '../slices/toasts';

const useToasts = () => {
  const toastsMessages = useSelector(toastsMessagesSelector);

  const dispatch = useDispatch();

  const onAddToastsMessage = useCallback(data => {
    const { isAutoHide, duration } = data;
    dispatch(addToastsMessage(data));
    if (isAutoHide && duration >= 1000) {
      setTimeout(() => {
        dispatch(removeToastsMessage(data));
      }, duration);
    }
  }, []);

  const onRemoveToastsMessage = useCallback(data => {
    dispatch(removeToastsMessage(data));
  }, []);

  return {
    toastsMessages,
    onAddToastsMessage,
    onRemoveToastsMessage,
  };
};

export default useToasts;
