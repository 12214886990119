import { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';

import usePrevious from './usePrevious';

const moveToFirst = (arr, idx) => arr.splice(0, 0, arr.splice(idx, 1)[0]);

const useSortOption = (sortBy, setSortBy, enableSortOption) => {
  const [sortOption, setSortOption] = useState([sortBy[0]]);

  const prevSort = usePrevious(sortBy);
  const tick = useRef(0);

  useEffect(() => {
    const difference = differenceWith(sortBy, prevSort, isEqual);
    if (!isEmpty(difference) && enableSortOption && tick.current % 2 === 1) {
      setSortOption(difference);
      const differenceId = difference[0].id;
      let sortIdx = 0;

      const reset = sortBy.map((item, idx) => {
        const newItem = { ...item };

        if (newItem.id !== differenceId) {
          newItem.desc = false;
        } else {
          sortIdx = idx;
        }

        return newItem;
      });
      moveToFirst(reset, sortIdx);

      setSortBy(reset);
    }
    tick.current += 1;
  }, [sortBy]);

  return enableSortOption ? sortOption : sortBy;
};

export default useSortOption;
