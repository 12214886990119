import { createSlice } from '@reduxjs/toolkit';
import {
  DATA_KNOWLEDGE_SHARING_KEY,
  EVENTS_AWARD_KEY,
  LATEST_NEWS_KEY,
  REALITY_SERIES_KEY,
} from 'constants/newsfeedCategory';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'newsfeed';
const initialState = {
  news: {
    data: [],
    status: 'loading', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
  newsManage: {
    data: [],
    status: 'loading', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
  tags: {
    data: [],
    status: 'idle',
    error: null,
  },
  categories: {
    data: [],
    status: 'idle',
    error: null,
  },
  newNewsFeed: {
    data: {},
    status: 'idle',
    error: null,
  },
  newNewsfeedQuantity: {
    data: {},
    status: 'loading', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
  newsDetail: {
    data: {},
    status: 'idle',
    error: null,
  },
  publishNews: {
    data: {},
    status: 'idle',
    error: null,
  },
  updateNews: {
    data: {},
    status: 'idle',
    error: null,
  },
  highLightNews: {
    data: [],
    status: 'loading', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
  sectionNewsfeed: {
    data: {},
    status: 'loading', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
  [LATEST_NEWS_KEY]: {
    data: {},
    status: 'loading', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
  [REALITY_SERIES_KEY]: {
    data: {},
    status: 'loading', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
  [DATA_KNOWLEDGE_SHARING_KEY]: {
    data: {},
    status: 'loading', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
  [EVENTS_AWARD_KEY]: {
    data: {},
    status: 'loading', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
};

const newsfeedSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getNewsfeedRequest(state) {
      state.news.status = 'loading';
    },
    getNewsfeedSuccess(state, action) {
      state.news.data = { ...action.payload.data, types: action.payload.types };
      state.newNewsfeedQuantity.data = { count: 0 };
      state.news.status = 'succeeded';
    },
    getNewsfeedFailure(state, action) {
      state.news.status = 'failed';
      state.news.data = [];
      state.news.error = action.payload;
    },
    getHighLightNewsfeedRequest(state) {
      state.highLightNews.status = 'loading';
    },
    getHighLightNewsfeedSuccess(state, action) {
      state.highLightNews.data = action.payload;
      state.highLightNews.status = 'succeeded';
    },
    getHighLightNewsfeedFailure(state, action) {
      state.highLightNews.status = 'failed';
      state.highLightNews.data = [];
      state.highLightNews.error = action.payload;
    },
    getNewsfeedManageRequest(state) {
      state.newsManage.status = 'loading';
    },
    getNewsfeedManageSuccess(state, action) {
      state.newsManage.data = action.payload;
      state.newsManage.status = 'succeeded';
    },
    getNewsfeedManageFailure(state, action) {
      state.newsManage.status = 'failed';
      state.newsManage.data = [];
      state.newsManage.error = action.payload;
    },
    deleteNewsfeedManageRequest(state) {
      state.newsManage.status = 'loading';
    },
    deleteNewsfeedManageSuccess(state) {
      state.newsManage.status = 'succeeded';
    },
    deleteNewsfeedManageFailure(state, action) {
      state.newsManage.status = 'failed';
      state.newsManage.data = [];
      state.newsManage.error = action.payload;
    },
    getTagsRequest(state) {
      state.tags.status = 'loading';
      state.tags.error = null;
    },
    getTagsSuccess(state, action) {
      state.tags.status = 'succeeded';
      state.tags.data = action.payload;
    },
    getTagsFailure(state, action) {
      state.tags.status = 'failed';
      state.tags.error = action.payload;
    },
    getCategoriesRequest(state) {
      state.categories.status = 'loading';
      state.categories.error = null;
    },
    getCategoriesSuccess(state, action) {
      state.categories.status = 'succeeded';
      state.categories.data = action.payload;
    },
    getCategoriesFailure(state, action) {
      state.categories.status = 'failed';
      state.categories.error = action.payload;
    },
    createNewsFeedRequest(state) {
      state.newNewsFeed.status = 'loading';
      state.newNewsFeed.error = null;
    },
    createNewsFeedSuccess(state, action) {
      state.newNewsFeed.status = 'succeeded';
      state.newNewsFeed.data = action.payload;
    },
    createNewsFeedFailure(state, action) {
      state.newNewsFeed.status = 'failed';
      state.newNewsFeed.error = action.payload;
    },
    getNewNewsfeedQuantityRequest(state) {
      state.newNewsfeedQuantity.status = 'loading';
    },
    getNewNewsfeedQuantitySuccess(state, action) {
      state.newNewsfeedQuantity.data = action.payload;
      state.newNewsfeedQuantity.status = 'succeeded';
    },
    getNewNewsfeedQuantityFailure(state, action) {
      state.newNewsfeedQuantity.status = 'failed';
      state.newNewsfeedQuantity.data = {};
      state.newNewsfeedQuantity.error = action.payload;
    },
    getNewsDetailRequest(state) {
      state.newsDetail.status = 'loading';
      state.newsDetail.error = null;
    },
    getNewsDetailSuccess(state, action) {
      state.newsDetail.data = action.payload;
      state.newsDetail.status = 'succeeded';
    },
    getNewsDetailFailure(state, action) {
      state.newsDetail.error = action.payload;
      state.newsDetail.status = 'failed';
    },
    getSectionNewsfeedRequest(state, action) {
      const { categoryKey } = action.payload;
      state[categoryKey].status = 'loading';
    },
    getSectionNewsfeedSuccess(state, action) {
      const { categoryKey, ...rest } = action.payload;
      state[categoryKey].data = rest;
      state.newNewsfeedQuantity.data = { count: 0 };
      state[categoryKey].status = 'succeeded';
    },
    getSectionNewsfeedFailure(state, action) {
      const { categoryKey } = action.payload;
      state[categoryKey].status = 'failed';
      state[categoryKey].data = [];
      state[categoryKey].error = action.payload;
    },
    updateNewsDetail(state, action) {
      state.newsDetail.data = { ...state.newsDetail.data, ...action.payload };
    },
    publishNewsRequest(state) {
      state.publishNews.error = null;
      state.publishNews.status = 'loading';
    },
    publishNewsSuccess(state, action) {
      state.publishNews.data = action.payload;
      state.publishNews.status = 'succeeded';
    },
    publishNewsFailure(state, action) {
      state.publishNews.error = action.payload;
      state.publishNews.status = 'failed';
    },
    updateNewsRequest(state) {
      state.updateNews.error = null;
      state.updateNews.status = 'loading';
    },
    updateNewsSuccess(state, action) {
      state.updateNews.data = action.payload;
      state.updateNews.status = 'succeeded';
    },
    updateNewsFailure(state, action) {
      state.updateNews.error = action.payload;
      state.updateNews.status = 'failed';
    },
    clearIsNewArticle(state) {
      state.news.data = {
        ...state.news.data,
        items: state.news.data.items?.map(x => {
          if (x.isNew) {
            x.isNew = false;
          }
          return x;
        }),
      };
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = newsfeedSlice;
// Export the reducer, either as a Overview or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getNewsfeedRequest,
  getNewsfeedSuccess,
  getNewsfeedFailure,
  getHighLightNewsfeedRequest,
  getHighLightNewsfeedSuccess,
  getHighLightNewsfeedFailure,
  getNewsfeedManageRequest,
  getNewsfeedManageSuccess,
  getNewsfeedManageFailure,
  deleteNewsfeedManageRequest,
  deleteNewsfeedManageSuccess,
  deleteNewsfeedManageFailure,
  getNewNewsfeedQuantityRequest,
  getNewNewsfeedQuantitySuccess,
  getNewNewsfeedQuantityFailure,
  getTagsRequest,
  getTagsSuccess,
  getTagsFailure,
  getCategoriesRequest,
  getCategoriesSuccess,
  getCategoriesFailure,
  createNewsFeedRequest,
  createNewsFeedSuccess,
  createNewsFeedFailure,
  getNewsDetailRequest,
  getNewsDetailSuccess,
  getNewsDetailFailure,
  getSectionNewsfeedRequest,
  getSectionNewsfeedSuccess,
  getSectionNewsfeedFailure,
  updateNewsDetail,
  publishNewsRequest,
  publishNewsSuccess,
  publishNewsFailure,
  updateNewsRequest,
  updateNewsSuccess,
  updateNewsFailure,
  clearIsNewArticle,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const newsfeedSelector = createSelector(
  rootSelector,
  ({ news }) => news,
);

export const newsfeedManageSelector = createSelector(
  rootSelector,
  ({ newsManage }) => newsManage,
);
export const tagsSelector = createSelector(rootSelector, state => state.tags);
export const categoriesSelector = createSelector(
  rootSelector,
  state => state.categories,
);
export const newNewsFeedSelector = createSelector(
  rootSelector,
  state => state.newNewsFeed,
);
export const getNewNewsfeedSelector = createSelector(
  rootSelector,
  ({ newNewsfeedQuantity }) => newNewsfeedQuantity,
);
export const newsDetailSelector = createSelector(
  rootSelector,
  state => state.newsDetail,
);
export const publishNewsSelector = createSelector(
  rootSelector,
  state => state.publishNews,
);
export const updateNewsSelector = createSelector(
  rootSelector,
  state => state.updateNews,
);
export const highLightNewsSelector = createSelector(
  rootSelector,
  state => state.highLightNews,
);
export const sectionNewsfeedSelector = createSelector(
  [rootSelector, (_, type) => type],
  (state, type) => state[type],
);
