import React from 'react';

const DEFAULT_COLUMN_WIDTH = 150;

export const onGetDefaultColumn = () => ({
  minWidth: 150,
  width: DEFAULT_COLUMN_WIDTH,
  maxWidth: 10000,
  flex: 1,
});

export const onUpdateRowStyle = (currentStyle, hasSticky) => {
  if (hasSticky) {
    return currentStyle;
  }

  return {
    ...currentStyle,
    width: '100%',
  };
};

export const onGenerateColumData = ({
  hasColor,
  header,
  accessor,
  ...props
}) => ({
  Header: header,
  accessor,
  ...props,
  Cell: cellProps => {
    const { value, color } = cellProps?.value;
    const updatedValue = value === null ? 'N/A' : value;

    return hasColor ? (
      <div
        style={{
          display: 'flex',
          gap: 4,
          alignItems: 'center',
          fontSize: 13,
          fontWeight: 600,
          color: '#2D2B39',
        }}
      >
        <div
          className={`pvda-traffic-color pvda-traffic-color__${
            color || 'gray'
          }`}
        />
        <span>{updatedValue}</span>
      </div>
    ) : (
      updatedValue
    );
  },
});

export const onUpdateHeaderGroups = headerGroups => {
  if (headerGroups && headerGroups.length <= 1) {
    return headerGroups;
  }

  const clonedHeaderGroups = [...headerGroups];
  if (clonedHeaderGroups[0] && clonedHeaderGroups[0]?.headers) {
    // Check and swap parents header-cell to sub header-cell
    clonedHeaderGroups[0].headers = clonedHeaderGroups[0]?.headers.map(
      parentsHeader => {
        let swappedCell;
        if (parentsHeader?.isSwappedCells) {
          swappedCell = { ...parentsHeader, flex: 1 };
          let updatedParentsHeader = {};

          clonedHeaderGroups[1].headers = clonedHeaderGroups[1].headers.map(
            subHeader => {
              if (
                parentsHeader?.headers &&
                parentsHeader?.headers[0]?.id === subHeader?.id
              ) {
                updatedParentsHeader = { ...subHeader };

                return swappedCell;
              }

              return subHeader;
            },
          );

          return updatedParentsHeader;
        }

        return parentsHeader;
      },
    );
  }

  return clonedHeaderGroups;
};
