import { uid } from 'services/uid';

import { ReactComponent as ConnectIcon } from 'assets/icons/sidebar/connect.svg';
import { ReactComponent as AnalysisIcon } from 'assets/icons/sidebar/analysis.svg';
import { ReactComponent as PageIcon } from 'assets/icons/sidebar/page.svg';
import { ReactComponent as MyRequestIcon } from 'assets/icons/sidebar/my-request.svg';
import { ReactComponent as NewsfeedIcon } from 'assets/icons/sidebar/newsfeed.svg';
import { ReactComponent as DataQualityIcon } from 'assets/icons/sidebar/dataQuality.svg';
import { ReactComponent as MyWorkspaceIcon } from 'assets/icons/sidebar/my-workspace.svg';
import { ReactComponent as UserManagementIcon } from 'assets/icons/sidebar/user-management.svg';
import { ReactComponent as GetStartedIcon } from 'assets/icons/sidebar/get-started.svg';

import {
  NEWSFEED,
  MY_WORKSPACE,
  DATA_MARKETPLACE,
  DATA_MARKETPLACE_INTERNAL_SOURCE,
  DATA_MARKETPLACE_EXTERNAL_SOURCE,
  DATA_MARKETPLACE_SEARCH,
  INTERNAL_DATASETS_PAGE,
  EXTERNAL_DATASETS_PAGE,
  EBG,
  ANALYTICS_MARKETPLACE,
  NEWSFEED_MANAGE,
  MANAGEMENT_DASHBOARD,
  DQ_DATA_QUALITY,
  USER_MANAGEMENT,
  ANALYTICS_MARKETPLACE_DETAIL,
  DQ_DOMAIN_DETAIL,
  GET_STARTED,
  NEWSFEED_PUBLISH,
} from './breakcrumb';

import { AM_MODEL_TYPE } from './analyticsmarketplace';
import {
  DATA_KNOWLEDGE_SHARING_KEY,
  EVENTS_AWARD_KEY,
  REALITY_SERIES_KEY,
} from './newsfeedCategory';

export const sidebarData = [
  {
    id: uid(),
    name: NEWSFEED.label,
    icon: NewsfeedIcon,
    route: NEWSFEED.id,
    matchers: [
      NEWSFEED_MANAGE.id,
      NEWSFEED_PUBLISH.id,
      `${NEWSFEED.id}/${REALITY_SERIES_KEY}`,
      `${NEWSFEED.id}/${DATA_KNOWLEDGE_SHARING_KEY}`,
      `${NEWSFEED.id}/${EVENTS_AWARD_KEY}`,
    ],
  },
  {
    id: uid(),
    name: 'Insights Scoop',
    icon: GetStartedIcon,
    route: GET_STARTED.id,
    matchers: [GET_STARTED.id],
  },
  {
    id: uid(),
    name: DATA_MARKETPLACE.label,
    icon: ConnectIcon,
    route: DATA_MARKETPLACE.id,
    matchers: [
      DATA_MARKETPLACE_INTERNAL_SOURCE.id,
      DATA_MARKETPLACE_EXTERNAL_SOURCE.id,
      DATA_MARKETPLACE_SEARCH.id,
      INTERNAL_DATASETS_PAGE.id,
      EXTERNAL_DATASETS_PAGE.id,
    ],
  },
  {
    id: uid(),
    name: ANALYTICS_MARKETPLACE.label,
    icon: AnalysisIcon,
    route: ANALYTICS_MARKETPLACE.id,
    matchers: [
      ANALYTICS_MARKETPLACE_DETAIL.id,
      ...Object.values(AM_MODEL_TYPE).map(item => item.href),
    ],
  },
  {
    id: uid(),
    name: DQ_DATA_QUALITY.label,
    icon: DataQualityIcon,
    route: DQ_DATA_QUALITY.id,
    matchers: [DQ_DOMAIN_DETAIL.id],
  },
  {
    id: uid(),
    name: 'Enterprise Business Glossary',
    icon: PageIcon,
    route: EBG.id,
  },
  {
    id: uid(),
    name: MY_WORKSPACE.label,
    icon: MyWorkspaceIcon,
    route: MY_WORKSPACE.id,
  },
  {
    id: uid(),
    name: MANAGEMENT_DASHBOARD.label,
    icon: MyRequestIcon,
    route: MANAGEMENT_DASHBOARD.id,
  },
  {
    id: uid(),
    name: USER_MANAGEMENT.label,
    icon: UserManagementIcon,
    route: USER_MANAGEMENT.id,
    roles: ['Admin', 'Editor'],
  },
];
