/* eslint-disable camelcase */
import React from 'react';
import { readFile } from 'services/file';
import { oneLineCsvToArray } from 'services/string';
import AMDataAvailability from './AMDataAvailability';
import AMTechnicalDetails from './AMTechnicalDetails';
import AMTryAndPreview from './AMTryAndPreview';

// eslint-disable-next-line import/no-unresolved,import/no-absolute-path
import keywordSkillSetTemplateData from '/__mocks__/data/keywordSkillSetTemplateData.json';
import TextToImageModel from './AMTryAndPreview/TryModel/CustomTryModel/TextToImageModel';

export const SUBTAB_ID = {
  PREVIEW_AND_TRY: '0',
  DATA_AVAILABILITY_IN_MARKETPLACE: '1',
  TECHNICAL_DETAILS: '2',
};

export const tabExploreComponent = [
  {
    id: SUBTAB_ID.PREVIEW_AND_TRY,
    name: 'Preview and Try',
    component: AMTryAndPreview,
  },
  {
    id: SUBTAB_ID.DATA_AVAILABILITY_IN_MARKETPLACE,
    name: 'Data Availability in Marketplace',
    component: AMDataAvailability,
  },
  {
    id: SUBTAB_ID.TECHNICAL_DETAILS,
    name: 'Technical Details',
    component: AMTechnicalDetails,
  },
];

export const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/;

export const TechnicalTableColumn = [
  {
    Header: '#',
    accessor: 'index',
    disableSortBy: true,
    width: 60,
    maxWidth: 60,
    minWidth: 60,
  },
  {
    Header: 'ML Model Output ',
    accessor: 'name',
    disableSortBy: true,
    Cell: cellProps =>
      cellProps?.value.charAt(0).toUpperCase() + cellProps?.value.slice(1),
  },
];

export const fileSizeOnSystemOS =
  navigator.userAgent.indexOf('Windows') !== -1 ? 1024 : 1000;

export const typeFileMap = {
  pdf: 'application/pdf',
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  csv: 'text/csv',
  txt: 'text/plain',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const defaultModelConfig = {
  maxPage: 10,
  typeFileUpload: [typeFileMap.pdf],
  limitUpload: 5,
  limitSize: 25,
  validate() {},
  sampleTryModel: 'preview',
  directDownload: false,
  multiSample: false,
  showTooltip: false,
  runModelTooltip: '',
  requiredFiles: 1,
  sampleDataList: [
    { comp: 'preview', label: 'Select one of the samples below:' },
  ],
  templates: [],
  formInput: [],
};

const LIST_UPLOAD_CUSTOM = [
  typeFileMap.pdf,
  typeFileMap.png,
  typeFileMap.jpg,
  typeFileMap.jpeg,
];

const LIST_UPLOAD_DOC = [typeFileMap.pdf, typeFileMap.docx];

export const modelConfig = {
  'Visual Inspection Report': {
    maxPage: 1,
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Table Extractor': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'K1 Customs Form Extractor': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Invoice Extractor': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Receipt Extractor': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Safety Data Sheet - Pictogram Extraction': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
    directDownload: true,
  },
  'Safety Data Sheet - Text Extraction': { typeFileUpload: LIST_UPLOAD_CUSTOM },
  'Pressure Safety Relief Valve - Calculation Sheet': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Pressure Safety Relief Valve - Data Sheet': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Pressure Safety Relief Valve - Inspection Report': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Pressure Safety Relief Valve - Maintenance Record': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  Passport: {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Employment Pass': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  MyKad: {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Flange Inspection Report': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'OGSP Card': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'E-Vetting': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Centrifugal Pump Engineering Datasheet Extractor': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Instrumented Protective Function Report': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Non Conformance Report': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Safety Data Sheet - Validation': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Valve Passing Detection Inspection (VPAD) Report': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Bill of Lading Report': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Pipe Prover Calibration Certificate Report': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Proving Report': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'NIOSH Documents - OGSP Card': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'NIOSH Documents - Kad Perakuan Latihan': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'P&ID Extractor - PCMTBE': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'P&ID Extractor - PMA, MRCSB, OEMS, PGB': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
    maxPage: 1,
  },
  'PMMS Maintenance Checksheet': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Ultrasonic Testing Wall Thickness Measurement - IVA': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Ultrasonic Testing Wall Thickness Measurement - PRPC': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'PDF Merger': {
    limitUpload: 10,
    requiredFiles: 2,
    multiSample: true,
    directDownload: true,
    showTooltip: true,
    runModelTooltip: 'Please select/upload at least 2 files',
    sampleDataList: [
      { comp: 'preview', label: 'Select at least 2 samples below:' },
    ],
  },
  'CV Parser': {
    typeFileUpload: LIST_UPLOAD_DOC,
    multiSample: true,
    showTooltip: true,
    sampleTryModel: 'template',
    runModelTooltip: 'Please complete all mandatory fields to proceed.',
    sampleDataList: [
      {
        comp: 'text',
        label: 'Job Title',
        type: 'input',
        name: 'job_title',
      },
      {
        comp: 'text',
        label: 'Job Description',
        type: 'textarea',
        name: 'job-description',
      },
      {
        comp: 'preview',
        label: 'Select minimum one from the samples below:',
      },
      {
        comp: 'template',
        label: 'Keywords/Skillset',
        name: 'Keyword_samplefile.csv',
        size: '112KB',
      },
    ],
    renderFormRequirement() {
      return (
        <>
          <p className="am-preview-subtab__local-files__text requirement">
            Please refer to Try Model section for detail requirements.
          </p>
          <p className="am-preview-subtab__local-files__text requirement">
            Note: The document and Personal Information processed will not be
            stored by our system. All document processed and data will be purged
            immediately once output is generated.
          </p>
        </>
      );
    },
    renderUploadLabel() {
      return (
        <div className="am-preview-subtab__local-upload-requirement">
          <span className="edh-field__label edh-field__label-required upload-label">
            Upload Resume and Keyword/Skillset
          </span>
          <p className="edh-field__sub-label">
            Minimum 1 resume required.
            <br />
            Keyword/Skillset is optional. AI generates generic recommendation if
            no keywords provided.
          </p>
        </div>
      );
    },
    formInput: [
      {
        label: 'Job Title',
        type: 'input',
        name: 'job_title',
        placeholder: 'Insert Job Title',
        rules: {
          required: true,
          maxLength: 250,
        },
      },
      {
        label: 'Job Description',
        type: 'textarea',
        name: 'job-description',
        placeholder: 'Insert Job Description',
        rules: {
          required: true,
          maxLength: 500,
        },
      },
    ],
    templates: [
      {
        label: 'Keyword/Skillset',
        name: 'Keyword_samplefile.csv',
        file: keywordSkillSetTemplateData,
      },
    ],
  },
  'Contract Document Extractor': {
    maxPage: 150,
  },
  'Sentiment Analysis': {
    typeFileUpload: [typeFileMap.csv],
    sampleDataList: [
      { comp: 'input', label: 'Select at least 2 samples below:' },
    ],
    resultInput: {
      renderResultInput(responseData) {
        if (!responseData?.data) {
          return null;
        }
        const { 'Sentiment scores': scores } = responseData.data;

        return (
          <div className="sample-data__result">
            <p className="sample-data__label">Sentiment scores:</p>
            {['Positive', 'Neutral', 'Negative'].map(key => (
              <div key={key}>
                <div className="sample-data__title">
                  {key}
                  <p className="sample-data__title-right">
                    {(scores[key] * 100).toFixed(1)}%
                  </p>
                </div>
                <div className="sample-data__progress">
                  <p
                    className="sample-data__filler"
                    style={{ width: `${(scores[key] * 100).toFixed(1)}%` }}
                  />
                </div>
              </div>
            ))}
          </div>
        );
      },
    },
    async validate(fileUpload) {
      const file = await readFile(fileUpload, 'text');
      if (!file) {
        return { error: 'No content' };
      }
      const lines = file.split(/\r?\n/);
      if (!lines[0].toLowerCase().split(',').includes('text')) {
        return {
          error: `User must specify the text column by typing in "text"`,
        };
      }
      if (oneLineCsvToArray(lines[1]).length === 0) {
        return {
          error: `User must upload file with at least 1 row of content`,
        };
      }
      return true;
    },
  },
  'Document Summarizer': {
    sampleTryModel: 'input',
    sampleDataList: [
      { comp: 'input', label: 'Select one of the samples below:' },
    ],
    resultInput: {
      rows: 7,
      renderResultInput(responseData) {
        if (!responseData?.data) {
          return null;
        }
        const { text } = responseData.data;

        return (
          <div className="sample-data__result">
            <p className="sample-data__label">Summarized Text</p>
            <div className="sample-data__text output-result">
              <span>{text}</span>
            </div>
          </div>
        );
      },
    },
    typeFileUpload: [
      typeFileMap.pdf,
      typeFileMap.doc,
      typeFileMap.docx,
      typeFileMap.txt,
    ],
    directDownload: true,
  },
  'Text Labelling': {
    sampleTryModel: 'template',
    typeFileUpload: [typeFileMap.csv],
    sampleDataList: [
      {
        comp: 'text',
        label: 'Predefined Labels (comma-separated):',
        type: 'textarea',
        name: 'labels',
      },
      {
        comp: 'preview',
        label: 'Select the sample below:',
      },
    ],
    formInput: [
      {
        label: 'Predefined Labels (comma-separated):',
        type: 'textarea',
        name: 'labels',
        placeholder: 'Insert Labels',
        rules: {
          required: true,
          maxLength: 1000,
        },
      },
    ],
  },
  'Named Entity Recognition': {
    typeFileUpload: [typeFileMap.pdf, typeFileMap.docx, typeFileMap.txt],
  },
  'Text Clustering': {
    typeFileUpload: [typeFileMap.csv],
  },
  'CIDB Documents - Kad Pendaftaran Personel Binaan': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'CIDB Documents - Perakuan Pendaftaran Personel Binaan': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'CIDB Documents - Perakuan Personel Binaan Sementara': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'AIM Visual Inspection Report': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Well Inspection Report - Pressure Data': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Text to Image Generator': {
    sampleTryModel: 'custom',
    renderCustomTryModel(prop) {
      return <TextToImageModel {...prop} />;
    },
  },
  'Virtual Pipeline System - Delivery Note': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Water Analysis Report': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Pressure Safety Relief Valve - GPU Inspection Report': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Q&A Generation': {
    maxPage: 300,
    limitUpload: 1,
  },
  'Gas and Condensate Metering System Report': {
    typeFileUpload: LIST_UPLOAD_CUSTOM,
  },
  'Platts Asian Petchem Olefinscan - Inventory and Shipping Routes': {
    maxPage: 25,
  },
  'Platts Asian Petchem Olefinscan - Olefins, Methanol Bunker, Fibre, Intermediate':
    {
      maxPage: 25,
    },
  'Platts Asian Petchem Olefinscan - Weekly Polymer Assessment': {
    maxPage: 25,
  },
  'Platts Asian Petchem Olefinscan - Assessments': {
    maxPage: 25,
  },
  'Platts Asian Petchem Olefinscan - Global Production Updates': {
    maxPage: 25,
  },
};

export const getModelConfig = modelName => ({
  ...defaultModelConfig,
  ...(modelConfig[modelName] || {}),
});

export const uploadFileMessages = {
  success: <>Successfully uploaded!</>,
  fail: (
    <>
      Your uploaded data doesn&apos;t meet the criteria please read the{' '}
      <u>requirements section</u>
    </>
  ),
};

export const remarkDataDefault =
  'Need to use this model in your daily application? Just drop us a message at enterprise.data@petronas.com with your queries and we will get back to you.';

export const introductionPopUpContent = [
  {
    header: 'Edit header',
    description:
      'Click to customize the skill set and its percentage according to your preference.',
    elementId: 'cell-A-introduce-step-1',
  },
  {
    header: 'Edit content',
    description:
      'Click to customize the skills defined in the header by specifying specific areas.',
    elementId: 'cell-A-introduce-step-2',
  },
  {
    header: 'Clear all data',
    description:
      'Click the "Clear All" button to remove all placeholder text and pre-populated content, giving you a blank canvas to start fresh.',
    elementId: 'clear-all-introduce-step-3',
  },
];

export const TEXT_UNDER_WATERMARK =
  'This image is generated by OpenAI (DALL-E), based on the user prompt';
