import React, { memo, useEffect, useMemo } from 'react';
import { PropTypes } from 'prop-types';

import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';
import useDevice from 'hooks/useDevice';
import useAPI from 'hooks/useAPI';
import { useDidUpdate } from 'hooks/useDidUpdate';
import useQuery from 'hooks/useQuery';
import { runModelSelector } from 'slices/analyticsMarketplace';

import LoadingBalls from 'components/core/LoadingBalls';
import { useSelector } from 'react-redux';
import ModelPreview from './ModelPreview';
import ModelResult from './ModelResult';
import OfflineTryModel from './OfflineTryModel';
import TryModel from './TryModel';

import './styles.scss';

const AMTryAndPreview = ({ detail }) => {
  const {
    aiModel: { status },
    updateRunModelResult,
  } = useAnalyticsMarketplace();
  const { isMobileDevice } = useDevice();
  const { data: dataRunModel } = useAPI();
  const { query, onRemoveQuery } = useQuery();

  const model = useSelector(runModelSelector).result?.[detail.title] || null;

  useDidUpdate(() => {
    if (
      dataRunModel.status === 'succeeded' &&
      dataRunModel?.data?.aiModelName
    ) {
      const { aiModelName, ...rest } = dataRunModel?.data;
      updateRunModelResult({
        modelName: aiModelName,
        status: 'success',
        ...rest,
      });
    }
  }, [dataRunModel?.status]);

  useEffect(() => {
    if (query.scrollTo === 'try-model') {
      document
        .getElementById('preview-model-header')
        .scrollIntoView({ behavior: 'smooth' });

      if (model?.status !== 'idle' && model?.status !== 'loading') {
        onRemoveQuery('scrollTo');
      }
    }
  }, [model, query]);

  const isDisableFunction =
    isMobileDevice && detail.modelType === 'CitizenAnalyticsModels';

  const renderModelPreview = useMemo(
    () => (
      <div className="am-preview-subtab__model-body preview-model">
        <ModelPreview data={detail} />
      </div>
    ),
    [detail],
  );

  if (status === 'loading') {
    return (
      <div style={{ minHeight: 400 }}>
        <LoadingBalls className="edh-loading-balls" isLoading />
      </div>
    );
  }

  return (
    <div className="am-preview-subtab">
      <div className="am-preview-subtab__model-section">
        <div className="am-preview-subtab__model-header">
          <div className="am-preview-subtab__model-header--title">
            Model Preview
          </div>
        </div>
        {renderModelPreview}
      </div>
      <div className="am-preview-subtab__model-section">
        <div className="am-preview-subtab__model-header">
          <div
            className="am-preview-subtab__model-header--title"
            id="preview-model-header"
          >
            {!detail?.downloadable ? 'Try Model' : 'Download & Try Model'}
          </div>
        </div>
        <div
          className={`am-preview-subtab__model-body ${
            detail?.downloadable ? 'download-model' : 'try-model'
          }`}
        >
          {isDisableFunction ? (
            <div className="try-model__model-content">
              <div className="try-model__model-content--title">
                Not Availability
              </div>
              This function is not available for Mobile. Please open by desktop
              website to use it.
            </div>
          ) : detail?.downloadable ? (
            <OfflineTryModel data={detail} />
          ) : (
            <>
              <TryModel data={detail} />
              {model && model.aiModelId ? (
                <ModelResult modelId={detail.id} modelName={detail.title} />
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

AMTryAndPreview.propTypes = {
  detail: PropTypes.any,
};
export default memo(AMTryAndPreview);
