import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import BOverlay from 'react-bootstrap/Overlay';
import BButton from 'react-bootstrap/Button';
import BSpinner from 'react-bootstrap/Spinner';

import classnames from 'services/classnames';

import { BsThreeDots } from 'react-icons/bs';

import './styles.scss';

const TableCellActions = ({ row }) => {
  const [showMenuActions, setShowMenuActions] = useState(false);
  const [isPendingAction, setIsPendingAction] = useState(false);

  const popoverRef = useRef();
  const bOverlayRef = useRef();

  const { actions = [] } = row?.original || {};

  const renderTitle = (name, rowProps) => {
    if (typeof name === 'string') {
      return name;
    }
    if (typeof name === 'function') {
      return name(rowProps);
    }
  };

  useEffect(() => {
    const handleScroll = event => {
      if (bOverlayRef.current && !bOverlayRef.current.contains(event.target)) {
        setShowMenuActions(false);
      }
    };

    if (showMenuActions) {
      window.addEventListener('scroll', handleScroll, true);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [showMenuActions]);

  return (
    <div className="edh-cell-actions">
      {isPendingAction ? (
        <div className="edh-cell-actions__pending-wrap">
          <BSpinner animation="border" size="sm" />
        </div>
      ) : (
        <BButton
          ref={popoverRef}
          className={classnames('edh-cell-actions__btn-three-dots', {
            'no-action': !actions.length,
          })}
          disabled={actions.length === 0}
          onClick={() => {
            if (actions.length > 0) {
              setShowMenuActions(!showMenuActions);
            }
          }}
        >
          <BsThreeDots size={12} color="#9E9E9E" />
        </BButton>
      )}
      <BOverlay
        target={popoverRef.current}
        ref={bOverlayRef}
        show={showMenuActions}
        placement="bottom-end"
        className="edh-cell-actions__overplay"
        rootClose
        rootCloseEvent="click"
        onHide={() => setShowMenuActions(false)}
      >
        {({ style, arrowProps, show: _show, popper, ...props }) => (
          <div {...props} style={style} className="edh-cell-actions__menus">
            {actions?.map?.(action => (
              <div
                key={action.id}
                className={[
                  'edh-cell-actions__menu-item',
                  action.isDisabled ? ' disabled' : '',
                ].join(' ')}
                aria-hidden="true"
                onClick={() => {
                  if (action.isAsyncAction) setIsPendingAction(true);
                  if (action.clickEvent) {
                    action.clickEvent(row?.original, () => {
                      setIsPendingAction(false);
                    });
                  }
                  if (action.rowEvent) {
                    action.rowEvent(row);
                  }
                  setShowMenuActions(false);
                }}
              >
                <span>{renderTitle(action.name, row)}</span>
              </div>
            ))}
          </div>
        )}
      </BOverlay>
    </div>
  );
};

TableCellActions.propTypes = {
  row: PropTypes.any.isRequired,
};

export default memo(TableCellActions);
