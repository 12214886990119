import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import ModalTitle from 'components/core/ModalTitle';
import Checkbox from 'components/core/Check';

import './styles.scss';

const AMPurgeModal = ({
  isShow,
  isClickOutSide,
  pageTitle,
  modalTitle,
  message,
  suggestion,
  onClose,
}) => {
  const [checkedPurgeConfirm, setCheckedPurgeConfirm] = useState(false);
  const handleSetNotify = isCheck => {
    setCheckedPurgeConfirm(isCheck);
  };

  useEffect(() => {
    setCheckedPurgeConfirm(false);
  }, [isShow]);

  return (
    <Modal
      show={isShow}
      centered
      backdrop={isClickOutSide || 'static'}
      dialogClassName="am-purge-modal"
      onHide={() => onClose(false, false)}
    >
      <Modal.Header closeButton>
        <ModalTitle category={pageTitle} title={modalTitle} />
      </Modal.Header>
      <Modal.Body>
        <div className="am-purge-modal__content">
          <p>{message}</p>
          <span>{suggestion}</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Checkbox
          key="purgeConfirm"
          label="Don't show this message again"
          size="md"
          disabled={false}
          checked={checkedPurgeConfirm}
          onChange={isChecked => {
            handleSetNotify(isChecked);
          }}
        />
        <Button
          variant="custom-primary"
          onClick={() => onClose(checkedPurgeConfirm, true)}
        >
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AMPurgeModal.propTypes = {
  isShow: PropTypes.bool,
  isClickOutSide: PropTypes.bool,
  message: PropTypes.string,
  pageTitle: PropTypes.string,
  modalTitle: PropTypes.string,
  suggestion: PropTypes.string,
  onClose: PropTypes.func,
};

AMPurgeModal.defaultProps = {
  isShow: false,
  isClickOutSide: false,
  message: '',
  pageTitle: '',
  modalTitle: '',
  suggestion: '',
  onClose() {},
};

export default memo(AMPurgeModal);
