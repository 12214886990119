import axios from 'axios';
import moment from 'moment-timezone';

import { ID_TOKEN } from '../../constants/storageKeys';

import { getStorage } from '../storage';
import settings from '../../settings';

export const setHeader = async (isAuthenticated, contentType) => {
  const headers = {
    'Content-Type': contentType || 'application/json; charset=utf-8',
    'X-Timezone-Offset': moment.tz.guess(),
    Authorization: 'Bearer ',
  };
  if (isAuthenticated) {
    const token = await getStorage(ID_TOKEN);
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

const instance = axios.create({
  baseURL: settings.BASE_URL,
});

export default instance;

export const getData = async ({
  url,
  isAuthenticated = true,
  responseType,
  params,
}) =>
  instance({
    method: 'get',
    headers: await setHeader(isAuthenticated),
    url,
    params,
    responseType,
  });

export const postData = async ({
  url,
  isAuthenticated = true,
  responseType,
  contentType,
  data,
}) =>
  instance({
    method: 'post',
    headers: await setHeader(isAuthenticated, contentType),
    url,
    data,
    responseType,
  });

export const putData = async ({
  url,
  isAuthenticated = true,
  responseType,
  contentType,
  data,
}) =>
  instance({
    method: 'put',
    headers: await setHeader(isAuthenticated, contentType),
    url,
    data,
    responseType,
  });

export const deleteData = async ({ url, isAuthenticated = true }) =>
  instance({
    method: 'delete',
    headers: await setHeader(isAuthenticated),
    url,
  });

export const patchData = async ({
  url,
  isAuthenticated = true,
  responseType,
  data,
}) =>
  instance({
    method: 'patch',
    headers: await setHeader(isAuthenticated),
    url,
    data,
    responseType,
  });
