import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import useSampleFilePagination from 'hooks/useSampleFilePagination';

import PreviewSample from 'components/layout/PreviewSample';

const RenderSample = ({ title, sample = [], multi }) => {
  const { pageNumber, renderPagination } = useSampleFilePagination(
    sample?.map(item => item.fileName),
    sample?.length,
  );

  const samplePreview = useMemo(
    () => (
      <div className="am-preview-subtab__model-panel">
        <div className="am-preview-subtab__model-title">{title}</div>
        <PreviewSample
          className="am-preview-subtab__model-content"
          type={sample?.[0]?.contentType}
          sampleData={[sample?.[0]?.fileUrl]}
        />
      </div>
    ),
    [title, sample],
  );

  if (multi) {
    return (
      <div className="am-preview-subtab__model-panel">
        <div className="am-preview-subtab__model-header">
          <div className="am-preview-subtab__model-title">{title}</div>
          <div className="am-preview-subtab__model-control">
            {renderPagination()}
          </div>
        </div>
        <PreviewSample
          className="am-preview-subtab__model-content"
          type={sample?.[pageNumber]?.contentType}
          sampleData={[sample?.[pageNumber]?.fileUrl]}
        />
      </div>
    );
  }
  return samplePreview;
};

RenderSample.propTypes = {
  title: PropTypes.string,
  sample: PropTypes.array,
  multi: PropTypes.bool,
};

export default memo(RenderSample);
