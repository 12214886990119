require('dotenv').config({ override: true })

export default {
  BASE_URL: `${process.env?.BASE_URL}api/`,
  ROOT_BASE_URL: process.env?.BASE_URL,
  TENANT_ID: process.env?.TENANT_ID,
  CLIENT_ID: process.env?.CLIENT_ID,
  NOTIFICATION_ID: process.env?.NOTIFICATION_ID,
  NOTIFICATION_URL: process.env?.NOTIFICATION_URL,
  GA_ID: process.env?.GA_ID,
};
