import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ReactComponent as InformationCircle } from 'assets/icons/common/information_white.svg';

import './styles.scss';

const ErrorForbiddenMessage = ({ rejectionData }) => {
  const { submittedOn, rejectedBy, rejectedAt, reason } = rejectionData || {};
  return (
    <div className="edh-forbidden-alert">
      <InformationCircle
        className="alert-icon"
        width={20}
        height={20}
        viewBox="-1.25 0 20 20"
      />
      <h6 className="alert-header">Access Request Rejected</h6>
      <p className="alert-content">
        Your access request, submitted on{' '}
        {moment(submittedOn).format('DD MMM YYYY hh:mm A')}, has been rejected.
        You may submit a new request at any time. We appreciate your
        understanding.
      </p>
      <b className="alert-title">Rejected by</b>
      <p className="alert-content">
        {rejectedBy}, {moment(rejectedAt).format('DD MMM YYYY hh:mm A')}
      </p>
      <b className="alert-title">Reason for Rejection</b>
      <p className="alert-content">{reason}</p>
    </div>
  );
};

ErrorForbiddenMessage.propTypes = {
  rejectionData: PropTypes.object,
};

export default memo(ErrorForbiddenMessage);
