import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { IoIosArrowDown } from 'react-icons/io';
import { ReactComponent as CheckModuleIcon } from 'assets/icons/common/check-module.svg';

import Tooltip from '../../layout/Tooltip';
import LoadingBalls from '../LoadingBalls';

import './styles.scss';

const DropdownBtn = ({
  isHeaderTitleDropdown,
  isLoading,
  id,
  Icon,
  title,
  placeholder,
  classNameTitle,
  align,
  drop,
  variant,
  menu,
  tooltipText,
  tooltipBsPrefix,
  isShowTitle,
  isMobileDevice,
  isTooltipItem,
  isPI,
  isShowCheckIcon,
  isSorted,
  disabled,
  extMenu,
  onSelect,
  onItemSelect,
  onToggle,
}) => {
  const toggleBtn = (
    <Dropdown.Toggle
      variant={variant}
      align={align}
      disabled={disabled}
      bsPrefix={`dropdown-toggle  ${!isShowTitle && 'none-title'}`}
    >
      <div className="edh-dropdown-button__title-group">
        {isMobileDevice ? (
          Icon &&
          isShowTitle && (
            <Icon
              fill={isSorted ? '#00a19c' : '#c4c3d1'}
              className="edh-dropdown-button__icon"
            />
          )
        ) : (
          <>
            {Icon && (
              <div>
                <Icon className="edh-dropdown-button__icon" />
              </div>
            )}
            {isShowTitle && (
              <span id={id} className={title ? classNameTitle : ''}>
                {title || placeholder || 'Filter'}
              </span>
            )}
            {isPI && <IoIosArrowDown style={{ marginLeft: '4px' }} />}
          </>
        )}
      </div>
    </Dropdown.Toggle>
  );

  const formateTitle =
    tooltipText && !isMobileDevice ? (
      <Tooltip
        placement="top"
        content={tooltipText}
        bsPrefix={tooltipBsPrefix}
        disabled={!tooltipText}
        id={id}
      >
        {toggleBtn}
      </Tooltip>
    ) : (
      toggleBtn
    );

  return (
    <Dropdown
      className="edh-dropdown-button"
      onSelect={onSelect}
      onToggle={onToggle}
      drop={drop}
      align={align}
    >
      {formateTitle}
      <Dropdown.Menu>
        <LoadingBalls isLoading={isLoading} />
        {menu
          ?.filter(item => !!item.title)
          .map((item, index) => (
            <Tooltip
              key={item.accessor}
              id={`${item.accessor}-${id}`}
              placement="top"
              disabled={!isTooltipItem}
              content={item.title}
            >
              <Dropdown.Item
                id={`${item.accessor}-${id}`}
                key={item.title}
                className={[
                  title === item.title ? 'selected-item' : '',
                  index === 0 && isHeaderTitleDropdown ? 'header-title' : '',
                  item.className,
                ].join(' ')}
                eventKey={item.accessor}
                disabled={item.isDisabled}
                onClick={() => onItemSelect(item)}
              >
                {item.title}
                {isShowCheckIcon && title === item.title && <CheckModuleIcon />}
              </Dropdown.Item>
            </Tooltip>
          ))}
        {extMenu && extMenu.length > 0 && (
          <div className="edh-dropdown-button__ext-menu">
            {extMenu.map(item => (
              <Dropdown.Item
                key={item.title}
                eventKey={item.accessor}
                onClick={() => onItemSelect(item)}
              >
                {item.title}
              </Dropdown.Item>
            ))}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownBtn.propTypes = {
  isHeaderTitleDropdown: PropTypes.bool,
  isLoading: PropTypes.bool,
  Icon: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  classNameTitle: PropTypes.string,
  id: PropTypes.string,
  align: PropTypes.string,
  drop: PropTypes.string,
  variant: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipBsPrefix: PropTypes.string,
  disabled: PropTypes.bool,
  isSorted: PropTypes.bool,
  isShowTitle: PropTypes.bool,
  isShowCheckIcon: PropTypes.bool,
  isMobileDevice: PropTypes.bool,
  isTooltipItem: PropTypes.bool,
  isPI: PropTypes.bool,
  menu: PropTypes.array.isRequired,
  extMenu: PropTypes.array,
  onSelect: PropTypes.func,
  onItemSelect: PropTypes.func,
  onToggle: PropTypes.func,
};

DropdownBtn.defaultProps = {
  isHeaderTitleDropdown: true,
  isLoading: false,
  title: '',
  id: '',
  placeholder: '',
  classNameTitle: '',
  align: 'end',
  variant: '',
  drop: '',
  tooltipText: '',
  tooltipBsPrefix: null,
  disabled: false,
  isSorted: false,
  isEllipsis: false,
  isShowTitle: true,
  isShowCheckIcon: false,
  isMobileDevice: false,
  isTooltipItem: false,
  isPI: false,
  extMenu: [],
  onSelect() {},
  onItemSelect() {},
  onToggle() {},
};

export default memo(DropdownBtn);
