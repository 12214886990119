import { call, put, delay, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  DATA_QUALITY_EXPORT_HISTORY,
  DATA_QUALITY_DELETE_EXPORT_HISTORY_ITEM,
} from 'features/DQ/constants/paths';
import { getData, putData } from '../services/api';

import {
  getWorkspaceDQRequest,
  getWorkspaceDQSuccess,
  getWorkspaceDQFailure,
  deleteWorkspaceItemRequest,
  deleteWorkspaceItemSuccess,
  deleteWorkspaceItemFailure,
} from '../slices/dataQuality';

export function* getWorkspaceDQ({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: DATA_QUALITY_EXPORT_HISTORY,
      params: payload,
    });
    yield delay(800);
    yield put(getWorkspaceDQSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getWorkspaceDQFailure(message));
  }
}

export function* deleteWorkspaceItem({ payload }) {
  try {
    const { data } = yield call(putData, {
      url: DATA_QUALITY_DELETE_EXPORT_HISTORY_ITEM,
      data: payload.id,
    });

    if (data?.isSuccess) {
      yield put(deleteWorkspaceItemSuccess(data));
    } else {
      yield put(deleteWorkspaceItemFailure(data));
    }
  } catch (error) {
    yield put(deleteWorkspaceItemFailure(error));
  }
}

export default function* dataQualitySaga() {
  yield takeLatest(deleteWorkspaceItemRequest().type, deleteWorkspaceItem);
  yield takeEvery(getWorkspaceDQRequest().type, getWorkspaceDQ);
}
