import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'myWorkspace';
const initialState = {
  workspaceAM: {
    data: { items: [] },
    status: 'idle',
    error: null,
  },
};

const myWorkspaceSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getWorkspaceAMRequest(state) {
      state.workspaceAM.status = 'loading';
    },
    getWorkspaceAMSuccess(state, action) {
      state.workspaceAM.data = action.payload;
      state.workspaceAM.status = 'succeeded';
    },
    getWorkspaceAMFailure(state, action) {
      state.workspaceAM.status = 'failed';
      state.workspaceAM.data = [];
      state.workspaceAM.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = myWorkspaceSlice;

// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getWorkspaceAMFailure,
  getWorkspaceAMRequest,
  getWorkspaceAMSuccess,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const workspaceAMSelector = createSelector(
  rootSelector,
  ({ workspaceAM }) => workspaceAM,
);
