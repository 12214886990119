import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  INTERNAL_DATASETS_PAGE,
  EXTERNAL_DATASETS_PAGE,
} from 'constants/breakcrumb';

import Tooltip from 'components/layout/Tooltip';

import media from 'assets/icons/common/media.svg';

import { utf8ToBase64 } from 'services/string';

import './styles.scss';

const AMDataExtract = ({ title, description, isInternal }) => (
  <div className="am-data-extract">
    <div>
      <Tooltip placement="top" content={title}>
        <p className="am-data-extract__title ellipsis">{title}</p>
      </Tooltip>
    </div>
    <div>
      <Tooltip placement="top" content={description}>
        <p className="am-data-extract__description ">{description}</p>
      </Tooltip>
    </div>
    <div>
      <Link
        rel="noreferrer noopener"
        to={`${
          isInternal ? INTERNAL_DATASETS_PAGE.href : EXTERNAL_DATASETS_PAGE.href
        }?keyword=${utf8ToBase64(title)}`}
        target="_blank"
        className="am-data-extract__link"
      >
        View in Data Marketplace
        <img src={media} alt="Media Icon" className="am-data-extract__icon" />
      </Link>
    </div>
  </div>
);

AMDataExtract.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isInternal: PropTypes.bool,
};

AMDataExtract.defaultProps = {
  title: '',
  description: '',
};
export default memo(AMDataExtract);
