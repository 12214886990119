import {
  ANALYTICS_MARKETPLACE_GET_USER_TRACKING,
  ANALYTICS_MARKETPLACE_UPDATE_USER_TRACKING,
  ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_GET_MODEL_TYPE,
  ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_UPDATE_CONTRIBUTED_RESULT,
  ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_SEND_EMAIL_PATH,
  ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_GET_LIST_DOCUMENT_TYPE,
  ANALYTICS_MARKETPLACE_MODEL_RESULT,
  ANALYTICS_MARKETPLACE_DELETE_MODEL_RESULT,
} from '../constants/paths';

import { deleteData, getData, postData } from '../services/api';

export const getUserTracking = async params => {
  const url = ANALYTICS_MARKETPLACE_GET_USER_TRACKING;
  const response = await getData({ url, params });
  return response.data.data;
};

export const updateUserTracking = params => {
  const url = ANALYTICS_MARKETPLACE_UPDATE_USER_TRACKING;
  return postData({ url, data: params });
};

export const getDocumentType = params => {
  const url = ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_GET_MODEL_TYPE;
  const { files } = params;
  const formData = new FormData();
  [...files].forEach(file => {
    formData.append('files', file);
  });
  return postData({ url, data: formData });
};

export const postContributedResult = params => {
  const url =
    ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_UPDATE_CONTRIBUTED_RESULT;
  const {
    documentType,
    fileImage,
    fileImageUnSuccess,
    fileJsonMapping,
    modelType,
    isAgree,
  } = params;
  const formData = new FormData();
  formData.append('documentType', documentType);
  if (fileImage) {
    formData.append('fileImageSuccess', fileImage);
  }
  if (fileImageUnSuccess) {
    formData.append('fileImageUnSuccess', fileImageUnSuccess);
  }
  if (fileJsonMapping) {
    [...fileJsonMapping].forEach(file => {
      formData.append('fileJsonMapping', file);
    });
  }
  formData.append('isAgree', isAgree);
  formData.append('modelType', modelType);

  return postData({
    url,
    contentType:
      'multipart/form-data; boundary=<calculated when request is sent>',
    data: formData,
  });
};

export const sendToEmailCognitive = params => {
  const url = ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_SEND_EMAIL_PATH;
  const { documentType, fileImage, fileNameResults, modelType } = params;
  const formData = new FormData();
  formData.append('documentType', documentType);
  formData.append('fileImage', fileImage);
  if (fileNameResults) {
    [...fileNameResults].forEach(file => {
      formData.append('fileNameResults', file);
    });
  }
  formData.append('modelType', modelType);
  return postData({
    url,
    contentType:
      'multipart/form-data; boundary=<calculated when request is sent>',
    data: formData,
  });
};

export const getCDListDocumentType = async () => {
  const url =
    ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_GET_LIST_DOCUMENT_TYPE;
  const response = await getData({ url });
  return response.data.data;
};

export const getModelResult = async analyticDocumentId => {
  const url = ANALYTICS_MARKETPLACE_MODEL_RESULT;

  const response = await getData({
    url,
    params: { analyticDocumentId },
  });

  return response.data.data;
};

export const deleteModelResult = analyticDocumentId => {
  const url = `${ANALYTICS_MARKETPLACE_DELETE_MODEL_RESULT}?analyticDocumentId=${analyticDocumentId}`;

  return deleteData({
    url,
  });
};
