import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import Tooltip from 'components/layout/Tooltip';

import AngleDown from 'assets/icons/common/angle-down.svg';
import CheckIcon from 'assets/icons/common/check.svg';
import useEllipsis from 'hooks/useEllipsis';
import useDevice from 'hooks/useDevice';

import { SHORT_DATE_FORMAT_3 } from 'constants/dateTime';

import './styles.scss';

const HistoryItem = ({
  id,
  icon,
  title,
  description,
  createdAt,
  showItem,
  setShowId,
}) => {
  const itemId = `history-item-${id}`;
  const { isEllipsis } = useEllipsis(itemId);
  const { isMobileDevice } = useDevice();

  return (
    <div
      key={id}
      className={['step-progress-item', showItem ? 'show' : 'hide']
        .filter(Boolean)
        .join(' ')}
    >
      <img
        className="item__progress-icon"
        src={icon || CheckIcon}
        alt="ProgressIcon"
      />
      <Tooltip
        content={title}
        id={`version-history-${title}`}
        bsPrefix="edh-version-history"
        placement="top"
        disabled={isMobileDevice}
      >
        <h3 className="item__title">{title}</h3>
      </Tooltip>
      <span className="item__updated-time">
        {moment(createdAt).format(SHORT_DATE_FORMAT_3)}
      </span>

      {description && (
        <p id={itemId} className="item__description">
          {description}
        </p>
      )}
      {isEllipsis && (
        <img
          aria-hidden
          onClick={() => setShowId(showItem ? '' : id)}
          className="item__icon"
          src={AngleDown}
          alt="angle down"
        />
      )}
    </div>
  );
};

HistoryItem.propTypes = {
  icon: PropTypes.any,
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  createdAt: PropTypes.string,
  showItem: PropTypes.bool,
  setShowId: PropTypes.func,
};

export default HistoryItem;
