import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Group = ({ children, icon, title, maxHeight }) => {
  const style = {};

  if (maxHeight) {
    style.maxHeight = maxHeight;
    style.overflowY = 'auto';
  }

  return (
    <div className="edh-user-guide-group">
      <div className="edh-user-guide-group__heading">
        {icon}
        <h4>{title}</h4>
      </div>
      <div className="edh-user-guide-group__content" style={style}>
        {children}
      </div>
    </div>
  );
};

Group.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(Group);
