import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';

const Expire = props => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (props.unmountAction) {
        props.unmountAction();
      }
    }, props.delay);

    return () => {
      clearTimeout(timer);
    };
  }, [props.delay]);

  return visible && <div className="edh-expire">{props.children}</div>;
};

Expire.propTypes = {
  delay: PropTypes.number,
  children: PropTypes.any,
  unmountAction: PropTypes.any,
};

export default memo(Expire);
