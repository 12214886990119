import React, {
  memo,
  useRef,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useClickOutside } from 'hooks';
import useUser from 'hooks/useUser';
import useWindowSize from 'hooks/useWindowSize';
import useDevice from 'hooks/useDevice';

import AdminIcon from 'assets/icons/common/admin.svg';

import Avatar from 'components/core/Avatar';
import TextEditor from '../TextEditor';

import '../TextEditor/styles.scss';
import './styles.scss';

const CommentInput = forwardRef(
  (
    {
      id,
      fullName,
      avatarImg,
      onSubmit,
      autofocus,
      onInputFocus,
      isShowCannedResponseDropdown,
      ...props
    },
    ref,
  ) => {
    const [value, setValue] = useState('');
    const [focus, setFocus] = useState(false);
    const [ReadOnly, setReadOnly] = useState(true);
    const containerRef = useRef();
    const commentBox = useRef();
    const { isAMAdmin } = useUser();
    const size = useWindowSize();
    const { isMobileDevice } = useDevice();

    const handleGetWidthCommentBox = useMemo(() => {
      if (isAMAdmin) return containerRef?.current?.offsetWidth + 2;
    }, [containerRef, size, isMobileDevice]);

    const commentName = isAMAdmin ? 'Analytics Marketplace Admin' : fullName;

    const avatarProps = {
      name: commentName,
      src: avatarImg,
    };

    if (isAMAdmin) {
      avatarProps.icon = AdminIcon;
      avatarProps.style = { background: 'none' };
    }

    useEffect(() => {
      if (focus) {
        setReadOnly(false);
      }
    }, [focus]);

    useEffect(() => {
      if (autofocus) {
        setReadOnly(false);
        setFocus(true);
      }
    }, []);

    useImperativeHandle(ref, () => ({
      toggleBlur() {
        setFocus(false);
        setReadOnly(true);
      },
      toggleFocus() {
        setFocus(true);
        setReadOnly(false);
      },
      clearContent() {},
      containerRef: containerRef.current,
    }));

    const onClick = () => {
      if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
        return;
      }
      onSubmit(value.replace(/^(<p><br><\/p>)+|(<p><br><\/p>)+$/g, ''));
      setValue('');
    };

    useClickOutside(containerRef, () => {
      setReadOnly(true);
    });

    return (
      <div {...props} className="edh-comment-input">
        <Avatar className="reply__avatar" {...avatarProps} />
        <div
          ref={containerRef}
          className={`edh-comment-input__body${focus ? ' focus' : ''}`}
        >
          <div
            ref={commentBox}
            className="edh-comment-input__wrap"
            role="button"
            tabIndex={0}
            onMouseEnter={() => {
              setReadOnly(false);
            }}
            onClick={() => {
              setFocus(true);
              setReadOnly(false);
              onInputFocus();
            }}
          >
            <TextEditor
              maxLength={1500}
              widthCommentBox={handleGetWidthCommentBox}
              id={id}
              className={`edh-comment-input__textarea${focus ? ' focus' : ''}`}
              value={value}
              onSend={onClick}
              placeholder="Write a comment..."
              isReadOnly={ReadOnly}
              keepPasteSelection={false}
              isRootContainerEmojiPicker
              isShowCannedResponseDropdown={isShowCannedResponseDropdown}
              onChange={setValue}
            />
          </div>
        </div>
      </div>
    );
  },
);
CommentInput.propTypes = {
  id: PropTypes.string,
  fullName: PropTypes.string,
  avatarImg: PropTypes.string,
  autofocus: PropTypes.bool,
  isShowCannedResponseDropdown: PropTypes.bool,
  onSubmit: PropTypes.func,
  onInputFocus: PropTypes.func,
};

CommentInput.defaultProps = {
  id: '',
  fullName: '',
  avatarImg: '',
  autofocus: false,
  isShowCannedResponseDropdown: false,
  onSubmit() {},
  onInputFocus() {},
};

export default memo(CommentInput);
