import {
  call,
  put,
  delay,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects';

import { getData, postData } from '../services/api';

import {
  HISTORY_EXPORT_API_PATH,
  DATASET_API_PATH,
  DATASET_VALIDATE_API_PATH,
  EXPORT_DATASET,
} from '../constants/paths';

import {
  getExportHistoryRequest,
  getExportHistorySuccess,
  getExportHistoryFailure,
  exportDataRequest,
  exportDataSuccess,
  exportDataFailure,
  validateExportRequest,
  historyExportSelector,
} from '../slices/exportHistory';

export function* getExportHistory({ payload }) {
  try {
    const notificationsAll = yield select(historyExportSelector);
    const {
      data: { data },
    } = yield call(getData, {
      url: HISTORY_EXPORT_API_PATH,
      params: payload.params || {
        pageSize: notificationsAll?.data?.pageSize || 1,
        pageIndex: notificationsAll?.data?.currentPage || 10,
      },
    });

    yield delay(800);
    yield put(getExportHistorySuccess({ prop: payload.prop, data }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getExportHistoryFailure(message));
  }
}

export function* exportData({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: EXPORT_DATASET,
      data: payload?.data,
    });

    yield delay(800);
    yield put(exportDataSuccess(data));

    if (payload.callback) {
      payload.callback(true);
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(exportDataFailure(message));
  }
}

export function* validateExport({ payload }) {
  const { data, callback } = payload;

  try {
    const {
      data: {
        data: { message, isValidRecord },
      },
    } = yield call(postData, {
      url: `${DATASET_API_PATH}/${data.id}/${DATASET_VALIDATE_API_PATH}`,
      data,
    });

    if (callback) callback({ message, isValidRecord });
  } catch (error) {
    if (callback) {
      callback({
        message: 'Something went wrong! Please try again!',
        isValidRecord: true,
      });
    }
  }
}

export default function* historyExportSaga() {
  yield takeEvery(getExportHistoryRequest().type, getExportHistory);
  yield takeEvery(exportDataRequest().type, exportData);
  yield takeLatest(validateExportRequest().type, validateExport);
}
