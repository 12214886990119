import React, { useCallback, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { ReactComponent as UploadIcon } from 'assets/icons/common/upload.svg';
import TrashIcon from 'assets/icons/common/trash.svg';
import CheckIcon from 'assets/icons/common/check-long.svg';

import { getNumPages } from 'services/file';
import { splitModelInput } from 'services/am-utils';
import { Document, Page } from 'components/core/ReactPdf';
import Expire from 'components/core/Expire';
import Tooltip from 'components/layout/Tooltip';
import useDevice from 'hooks/useDevice';

import { wordFilePageApi } from 'api';

import {
  typeFileMap,
  fileSizeOnSystemOS,
  getModelConfig,
} from '../../../constants';

const checkDuplicateUpload = (fileList, file) => {
  let isDuplicated = false;
  isDuplicated = !!fileList.find(
    existingFile =>
      existingFile.name === file.name &&
      existingFile.lastModified === file.lastModified &&
      existingFile.size === file.size &&
      existingFile.type === file.type,
  );
  return isDuplicated;
};

const SampleDataLocal = ({
  data,
  previewFiles,
  setPreviewFiles,
  setMessageUpload,
  disableUpload,
}) => {
  const inputRef = useRef(null);

  const { isMobileDevice } = useDevice();

  const { maxPage, typeFileUpload, limitUpload, limitSize, validate } =
    getModelConfig(data?.title);
  const { png, jpg, jpeg, pdf, csv, doc, docx, xlsx } = typeFileMap;

  const modelInput = data?.modelInput
    ? data.modelInput.split(/\s*[0-9]\s*\.\s*/g)
    : '';

  const renderModelRequirement = (
    <div className="am-preview-subtab__local-files__text">
      <span className="requirement">{modelInput[0]}</span>
      <ol>
        {modelInput &&
          modelInput.slice(1).map(input => {
            const [li, ...ul] = splitModelInput(input);
            const note = input.match(/Note:.*/g);
            return (
              <React.Fragment key={li}>
                <li>
                  <span className="order-item">{li}</span>
                  {ul.length > 0 && (
                    <ul>
                      {ul.map(bi => (
                        <li key={bi} className="un-order-item">
                          {bi}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
                {note?.length && (
                  <>
                    <div className="order-item">{note[0].split(/- /g)[0]}</div>
                    {note[0].includes('- ') &&
                      note[0]
                        .split(/- /g)
                        .slice(1)
                        .map(item => (
                          <div className="order-item">
                            - {item}
                          </div>
                        ))}
                  </>
                )}
              </React.Fragment>
            );
          })}
      </ol>
    </div>
  );

  const renderPreviewTryModel = file => {
    if (file.type === pdf) {
      return (
        <Document file={file}>
          <Page pageNumber={1} width={78} renderAnnotationLayer={false} />
        </Document>
      );
    }
    if ([png, jpg, jpeg].includes(file.type)) {
      return (
        <img
          alt="Preview file"
          className="am-preview-subtab__local-files__preview--image"
          src={URL.createObjectURL(file)}
        />
      );
    }
    if ([csv, doc, docx, xlsx].includes(file.type)) {
      return (
        <div className="am-preview-subtab__local-files__preview--file-name">
          File: {file.name}
        </div>
      );
    }
  };

  const onUploadFiles = async e => {
    if (previewFiles.length >= limitUpload) return;
    let uploadedFiles = [...previewFiles];
    const uploadingFiles = [...e.target.files];
    const finalFiles = uploadingFiles.map(async (file, i) => {
      if (
        !typeFileUpload.includes(file.type) ||
        file.size / (fileSizeOnSystemOS * fileSizeOnSystemOS) > limitSize
      ) {
        return false;
      }
      const uploadFile = async () =>
        new Promise(resolve => {
          if (uploadedFiles.length < limitUpload) {
            file.id = new Date().getTime() + i;
            uploadedFiles = [...uploadedFiles, file];
            resolve(true);
          } else {
            resolve(false);
          }
        });
      const valid = await validate(file);
      if (valid?.error) {
        return false;
      }
      if (file.type === pdf) {
        const numPages = await getNumPages(file);
        if (numPages && numPages.length > maxPage) return false;
      }
      if ([docx, doc].includes(file.type)) {
        if (!checkDuplicateUpload(previewFiles, file)) {
          const { isUploaded } = await wordFilePageApi.getWordFilePage({
            id: data?.id,
            file,
          });
          if (!isUploaded) return false;
        }
      }
      const res = await uploadFile();
      return res;
    });
    const flag = await Promise.all(finalFiles);
    setMessageUpload(!flag.includes(false));
    setPreviewFiles([...uploadedFiles]);
  };

  const onRemoveFile = useCallback(
    fileIndex => {
      const files = [...previewFiles].filter((_, i) => i !== fileIndex);
      setPreviewFiles(files);
    },
    [previewFiles],
  );

  return (
    <div className="am-preview-subtab__model-content">
      <div className="am-preview-subtab__local-files">
        <label
          htmlFor="try-mode-am"
          aria-hidden
          className={`am-preview-subtab__local-files__input${disableUpload ? ' inActivated' : ''
            }`}
          onClick={() => {
            inputRef.current.value = null;
          }}
        >
          <input
            id="try-mode-am"
            ref={inputRef}
            multiple
            type="file"
            accept={typeFileUpload?.join(',')}
            onChange={onUploadFiles}
            disabled={disableUpload}
          />
          <UploadIcon />
          <p className="am-preview-subtab__local-files__input--title">
            Upload from {isMobileDevice ? 'Devices' : 'Computer'}
          </p>
        </label>
        <div className="am-preview-subtab__local-files__list">
          <div className="am-preview-subtab__local-files__preview">
            {previewFiles.length
              ? [...previewFiles].map((file, i) => (
                <div
                  key={file.id}
                  className="am-preview-subtab__local-files__preview--file"
                >
                  {renderPreviewTryModel(file)}
                  <Tooltip placement="top" content={file.name}>
                    <div className="am-preview-subtab__local-files__preview--backdrop">
                      <button
                        type="button"
                        className="am-preview-subtab__local-files__preview--trash"
                        onClick={() => onRemoveFile(i)}
                      >
                        <img src={TrashIcon} alt="Remove" />
                      </button>
                    </div>
                  </Tooltip>
                  <Expire delay={10000}>
                    <img
                      className="am-preview-subtab__local-files__preview--success"
                      alt="Success"
                      src={CheckIcon}
                    />
                  </Expire>
                </div>
              ))
              : null}
          </div>
        </div>
      </div>
      <span className="am-preview-subtab__local-files__text requirement">
        {renderModelRequirement}
      </span>
    </div>
  );
};

SampleDataLocal.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    modelInput: PropTypes.string,
  }),
  disableUpload: PropTypes.any,
  previewFiles: PropTypes.array,
  setMessageUpload: PropTypes.func,
  setPreviewFiles: PropTypes.func,
};

export default SampleDataLocal;
