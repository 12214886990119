/* eslint-disable */
import React, {
  useState,
  useEffect,
  memo,
  useMemo,
  useCallback,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';

import Check from '../../core/Check';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const [isChecked, setIsChecked] = useState(!!rest?.checked);

  useEffect(() => {
    setIsChecked(rest?.checked);
  }, [rest?.checked, indeterminate]);

  const checkedNumber = useMemo(() => {
    if (rest?.hasSelectionNumner && rest?.tableState?.selectedRowIds) {
      const selectedRowIds = Object.keys(rest?.tableState?.selectedRowIds);
      const rowIndex = selectedRowIds.findIndex(id => id === rest?.row?.id);

      if (rowIndex >= 0) {
        return rowIndex + 1;
      }
    }

    return 0;
  }, [
    rest?.hasSelectionNumner,
    rest?.tableState?.selectedRowIds,
    rest?.row?.id,
  ]);

  const onChange = useCallback(
    (checked, event) => {
      rest.onChange(event);
    },
    [rest?.onChange],
  );

  return (
    <Check
      ref={ref}
      size="sm"
      checked={isChecked}
      checkedNumber={checkedNumber}
      onChange={onChange}
    />
  );
});

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.any,
};

export default memo(IndeterminateCheckbox);
