export const USER_HUB_PATH = 'hubs/user';
export const COMMENT_HUB_PATH = 'hubs/comment';
export const AUTHENTICATION_API_PATH = 'v1/authentication';
export const HISTORY_EXPORT_API_PATH = 'v1/histories-page/histories';
export const DOMAIN_API_PATH = 'v1/dataset-categories';
export const ALL_DOMAIN_API_PATH = 'v1/dataset-categories/get-total-domains';
export const DATASET_API_PATH = 'v1/datasets';
export const DATASET_EXPORT_API_PATH = 'export';
export const DATASET_VALIDATE_API_PATH = 'export-validation';
export const DATASET_TABLE_RELATIONSHIP = 'table-relationship';
export const SAMPLE_HEADER_DATASET = 'sample-header';
export const EBG_API_PATH = 'v1/ebg/search-and-filter';
export const EBG_SECTION_API_PATH = 'v1/ebg-categories';
export const DATASET_RECOMMENDATION_SECTION = 'dataset-recommendation-section';
export const NESTED_JSON_HEADERS = 'nested-json-headers';
export const NESTED_JSON_DETAIL = 'nested-json-detail';

// overview
export const OVERVIEWS = 'v1/overviews';

// landing pages
export const LANDING_PAGES = 'v1/landing-pages';
export const SUGGESTED_KEYWORD_API_PATH = `${LANDING_PAGES}/suggested-keyword`;
export const USER_GUIDE_API_PATH = `${LANDING_PAGES}/tutorial`;
export const QUICK_GUIDE_API_PATH = `${LANDING_PAGES}/quick-guide`;
export const DONUT_CHART_API_PATH = `${LANDING_PAGES}/donut-chart-data`;
export const SEARCH_SUGGESTION_LANDING_PAGES_API_PATH = `${LANDING_PAGES}/suggested-data`;

// data-marketplace
export const DATA_MARKETPLACE = 'v1/data-marketplaces';
export const DATA_MARKETPLACE_FILTER_OPTION_API_PATH = `${DATA_MARKETPLACE}/filter-options`;
export const DATA_MARKETPLACE_SAMPLE_DATA_API_PATH = `${DATA_MARKETPLACE}/sample-data`;
export const DATA_MARKETPLACE_OVERALL_DATA_API_PATH = `${DATA_MARKETPLACE}/overall-data-quality`;
export const DATA_MARKETPLACE_LINEAGE_DATA_API_PATH = `${DATA_MARKETPLACE}/data-lineage`;
export const DATA_MARKETPLACE_SUGGESTED_KEYWORD_API_PATH = `${DATA_MARKETPLACE}/suggested-keywords`;
export const DATA_MARKETPLACE_DETAIL = `${DATA_MARKETPLACE}/get-data-market-place-detail`;
export const DATA_MARKETPLACE_GET_CONFIDENCE_SCORE_API_PATH = `${DATA_MARKETPLACE}/confidence-score`;
export const GET_MOST_DOWNLOADED_API_PATH = `${DATA_MARKETPLACE}/most-downloaded`;
export const OVERALL_DATA_OVERVIEW_API_PATH = `${DATA_MARKETPLACE}/data-quality-score`;
export const DATA_MARKETPLACE_SUGGESTION_API_PATH = `${DATA_MARKETPLACE}/suggest-domain-dataset`;
export const DATA_MARKETPLACE_SEARCH_RESULT_API_PATH = `${DATA_MARKETPLACE}/search-domain-dataset`;
export const DATA_MARKETPLACE_FOOTPRINT_API_PATH = `${DATA_MARKETPLACE}/footprint`;

// Favourite
export const FAVOURITE = '/v1/favorites';
export const FAVOURITE_VIEW_DATASET_API_PATH = `${FAVOURITE}/favorite-dataset`;
export const FAVOURITE_DOMAIN = `${FAVOURITE}/dataset-category`;

// notifications
export const NOTIFICATIONS_API_PATH = '/v1/notifications';
export const NOTIFICATIONS_MARK_ALL_READ_API_PATH = `${NOTIFICATIONS_API_PATH}/mark-all-as-read`;
export const NOTIFICATIONS_SAVE_TIME_API_PATH = `${NOTIFICATIONS_API_PATH}/check`;
export const NOTIFICATIONS_NEW_QUANTITY_API_PATH = `${NOTIFICATIONS_API_PATH}/count-unchecked`;

// requested data
export const MY_REQUEST_API_PATH = 'v1/requested-data';
export const MY_REQUEST_REQUESTED_DATA_API_PATH = `${MY_REQUEST_API_PATH}/datasets`;
export const UPDATE_STATUS_REQUEST_API_PATH = `${MY_REQUEST_API_PATH}/update-status`;
export const SAVE_TIME_ACCESS_TO_USER_MANAGEMENT_API_PATH = `${MY_REQUEST_API_PATH}/check`;
export const NEW_USER_MANAGEMENT_QUANTITY_API_PATH = `${MY_REQUEST_API_PATH}/count-unchecked`;
export const REQUESTED_CATEGORIES = 'v1/requested-data/categories';

// comment
const EBG_COMMENT = 'v1/ebg-comments';
export const EBG_COMMENT_GET = `${EBG_COMMENT}/get-comments`;
export const EBG_COMMENT_ADD = `${EBG_COMMENT}/add-comment`;

// datasets
export const GET_DATASETS_API_PATH = 'v1/datasets/search-datasets';
export const DATASET_SHARING_API_PATH = 'v1/datasets/#id/dataset-sharing';
export const SUGGESTED_KEYWORD_MODULAR_DATASETS_API_PATH =
  'v1/datasets/search-modular-datasets';
export const PUT_DATASETS_MY_WORKSPACE_PATH = 'v1/my-workspace/dataset-tab';
export const GET_DATASETS_DOWNLOAD_INFO_API_PATH = 'v1/datasets/export/info';
export const PREVIEW_DYNAMIC_FILTER_DOWNLOAD_API_PATH =
  'v1/datasets/export/preview-data';
export const GET_DATASET_EXPORT_VALUES_BY_COLUMN =
  'v1/datasets/export/values-by-column';
export const POST_DATASET_EXPORT_COLUMNS = 'v1/datasets/export/columns';
export const GET_NUMBER_DATA_EXPORT = 'v1/datasets/export/count-data';
export const VALIDATE_EXPORT_DYNAMIC_FILTERING =
  'v1/datasets/export/validation';
export const EXPORT_DATASET = `${DATASET_API_PATH}/${DATASET_EXPORT_API_PATH}`;

export const POST_DQ_RULES_LIBRARY_DOWNLOAD =
  'v1/data-quality/rules-library/download';

// Feedback
export const ADD_FEEDBACK = '/v1/feedbacks';
export const SURVEY = `${ADD_FEEDBACK}/survey`;

// newsfeed page
export const NEWSFEED = 'v1/newsfeed-pages';
export const GET_NEWSFEED_API_PATH = `${NEWSFEED}`;
export const GET_NEWSFEED_MANAGE_API_PATH = `${NEWSFEED}/management`;
export const GET_NEW_NEWSFEED_QUANTITY_API_PATH = `${NEWSFEED}/count-unviewed`;
export const GET_TAG_OR_CATEGORY_NEWSFEED = `${NEWSFEED}/get-tag-or-category-list`;
export const GET_HIGHLIGHT_NEWSFEED = `${NEWSFEED}/get-highlights`;

// analytics marketplace
export const ANALYTICS_MARKETPLACE = 'v1/analytics-marketplaces';
export const ANALYTICS_MARKETPLACE_GET_SUGGESTED_KEYWORD_API_PATH = `${ANALYTICS_MARKETPLACE}/suggested-keywords`;
export const ANALYTICS_MARKETPLACE_GET_FILTER_OPTION_API_PATH = `${ANALYTICS_MARKETPLACE}/filter-options`;
export const ANALYTICS_MARKETPLACE_GET_AIMODEL_DETAIL = `${ANALYTICS_MARKETPLACE}/get-data-ai-detail`;
export const ANALYTICS_MARKETPLACE_GET_LIST_AIMODEL_DETAIL = `${ANALYTICS_MARKETPLACE}/search-ai-models`;
export const ANALYTICS_MARKETPLACE_GET_LIST_USER_REVIEW_DETAIL = `${ANALYTICS_MARKETPLACE}/get-user-review`;
export const ANALYTICS_MARKETPLACE_GET_LIST_GET_MY_REVIEW = `${ANALYTICS_MARKETPLACE}/my-review`;
export const ANALYTICS_MARKETPLACE_UPDATE_USER_REVIEW_DETAIL = `${ANALYTICS_MARKETPLACE}/update-user-review-description`;
export const ANALYTICS_MARKETPLACE_DELETE_USER_REVIEW_DETAIL = `${ANALYTICS_MARKETPLACE}/delete-user-review`;
export const ANALYTICS_MARKETPLACE_SUBMIT_USER_REVIEW_DETAIL = `${ANALYTICS_MARKETPLACE}/submit-user-review`;
export const ANALYTICS_MARKETPLACE_SUBMIT_VERSION_HISTORY_DETAIL = `${ANALYTICS_MARKETPLACE}/get-analytic-history`;
export const UPDATE_AI_MODEL_BEHAVIOR_PATH = `${ANALYTICS_MARKETPLACE}/update-ai-model-behavior`;
export const ANALYTICS_MARKETPLACE_GET_PRESET_QUESTIONS_PATH = `${ANALYTICS_MARKETPLACE}/get-preset-questions`;
export const ANALYTICS_MARKETPLACE_RUN_MODEL_PATH = `${ANALYTICS_MARKETPLACE}/run-model`;
export const ANALYTICS_MARKETPLACE_RUN_SAMPLE_DATA_PATH = `${ANALYTICS_MARKETPLACE}/run-sample-data`;
export const ANALYTICS_MARKETPLACE_DOWNLOAD_PATH = `${ANALYTICS_MARKETPLACE}/download`;
export const ANALYTICS_MARKETPLACE_FIRST_ACCESS = `${ANALYTICS_MARKETPLACE}/first-access-info`;
export const ANALYTICS_MARKETPLACE_OFFLINE_SAMPLE_DOWNLOAD = `${ANALYTICS_MARKETPLACE}/offline-models/download`;
export const ANALYTICS_MARKETPLACE_OFFLINE_SAMPLE_DOWNLOAD_2 = `${ANALYTICS_MARKETPLACE}/offline-models/download-v2`;
export const ANALYTICS_MARKETPLACE_TOTAL_DATA_AI_MODEL = `${ANALYTICS_MARKETPLACE}/get-total-ai-model`;
export const ANALYTICS_MARKETPLACE_DO_ACTION_ON_USER_REVIEW = `${ANALYTICS_MARKETPLACE}/do-action-on-user-review`;
export const ANALYTICS_MARKETPLACE_BANNER_SEARCH_SUGGESTIONS = `${ANALYTICS_MARKETPLACE}/suggested-keywords`;
export const ANALYTICS_MARKETPLACE_ADD_TO_WORKSPACE = `v1/favorites/analytic-document`;
export const ANALYTICS_MARKETPLACE_UPDATE_NOTIFY = `v1/favorites/analytic-document-update-notify`;
export const ANALYTICS_MARKETPLACE_SHARING_MODEL_PATH = `${ANALYTICS_MARKETPLACE}/am-share-model`;
export const ANALYTICS_MARKETPLACE_REQUEST_ADD_MODEL = `${ANALYTICS_MARKETPLACE}/request-add-new`;
export const ANALYTICS_MARKETPLACE_REQUEST_LIST_DATASET = `${ANALYTICS_MARKETPLACE}/request-get-list`;
export const ANALYTICS_MARKETPLACE_REQUEST_DETAIL_DATASET = `${ANALYTICS_MARKETPLACE}/request-get-detail`;
export const ANALYTICS_MARKETPLACE_REQUEST_UPDATE_STATUS_DATASET = `${ANALYTICS_MARKETPLACE}/request-update-status`;
export const ANALYTICS_MARKETPLACE_REQUEST_UPDATE_NOTE_DATASET = `${ANALYTICS_MARKETPLACE}/request-update-note`;
export const ANALYTICS_MARKETPLACE_GET_THEMES = `${ANALYTICS_MARKETPLACE}/get-themes`;
export const ANALYTICS_MARKETPLACE_VALIDATE_WORD_FILE = `${ANALYTICS_MARKETPLACE}/validate-word-file`;
export const ANALYTICS_MARKETPLACE_GET_USER_TRACKING = `${ANALYTICS_MARKETPLACE}/check-by-user-tracking-type`;
export const ANALYTICS_MARKETPLACE_UPDATE_USER_TRACKING = `${ANALYTICS_MARKETPLACE}/add-new-tracking-data`;
export const ANALYTICS_MARKETPLACE_RUN_COGNITIVE_DIGITALIZATION_MODEL_PATH = `${ANALYTICS_MARKETPLACE}/run-model-cognitive-digitalization`;
export const ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_GET_MODEL_TYPE = `${ANALYTICS_MARKETPLACE}/get-model-type`;
export const ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_UPDATE_CONTRIBUTED_RESULT = `${ANALYTICS_MARKETPLACE}/contribute-updated-result`;
export const ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_SAVE_MODEL_PATH = `${ANALYTICS_MARKETPLACE}/save-to-one-drive`;
export const ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_SEND_EMAIL_PATH = `${ANALYTICS_MARKETPLACE}/cdflow-send-result`;
export const ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_GET_LIST_DOCUMENT_TYPE = `${ANALYTICS_MARKETPLACE}/get-cd-documenttype`;
export const ANALYTICS_MARKETPLACE_MODEL_RESULT = `${ANALYTICS_MARKETPLACE}/model-result`;
export const ANALYTICS_MARKETPLACE_DELETE_MODEL_RESULT = `${ANALYTICS_MARKETPLACE}/hide-model-result`;
export const ANALYTICS_MARKETPLACE_RUN_MODEL_BY_TEXT = `${ANALYTICS_MARKETPLACE}/run-model-by-text`;

// management dashboard
export const DATASET_MANAGEMENT = 'v1/management-dashboard';
export const DATASET_MANAGEMENT_STATUS_BLOCK = `${DATASET_MANAGEMENT}/data-entitlement-overall`;
export const DATASET_MANAGEMENT_TABLE_ENTITLEMENT = `${DATASET_MANAGEMENT}/data-entitlement-management`;
export const DATASET_MANAGEMENT_TABLE_DETAIL = `${DATASET_MANAGEMENT}/data-entitlement-requests`;
export const MY_DATASETS_RECENTLY = `${DATASET_MANAGEMENT}/recently`;
export const MY_DATASETS_ENTITLEMENT = `${DATASET_MANAGEMENT}/my-entitled-data`;
export const DATASET_MANAGEMENT_UPDATE_LIST_REQUEST = `${DATASET_MANAGEMENT}/update-status`;
export const DATASET_MANAGEMENT_TDC = `${DATASET_MANAGEMENT}/search-tdc-datasets`;
export const UPDATE_DATASET = `${DATASET_MANAGEMENT}/#id/update-dataset`;
export const UPDATE_STATUS_DATASET = `${DATASET_MANAGEMENT}/#id/update-tdc-status`;

// Tdc Management
export const TDC_MANAGEMENT = 'v1/tdc-management';
export const TDC_MANAGEMENT_CATEGORIES_TERMS = `${TDC_MANAGEMENT}/categories-terms`;
export const TDC_MANAGEMENT_GET_STATUS = `${TDC_MANAGEMENT}/{id}/categories-terms`;
export const TDC_MANAGEMENT_UPDATE_STATUS = `v1/tdc-management/categories-terms/status`;
export const TDC_MANAGEMENT_DUPLICATE = `${TDC_MANAGEMENT}/duplicate-template`;
export const TDC_MANAGEMENT_SUBMIT_FOR_REVIEW = `${TDC_MANAGEMENT}/submit-for-review`;
export const TDC_MANAGEMENT_SAMPLE_TEMPLATE = `${TDC_MANAGEMENT}/sample-template`;
export const TDC_MANAGEMENT_UPLOAD_TEMPLATE = `${TDC_MANAGEMENT}/upload-template`;
export const TDC_MANAGEMENT_UPDATE_TEMPLATE = `${TDC_MANAGEMENT}/update-template`;
export const TDC_MANAGEMENT_VIEW_TEMPLATE = `${TDC_MANAGEMENT}/view-template`;
export const USER_ASSIGN_MODULAR_ROLE = `${TDC_MANAGEMENT}/update-status-request`;
export const TDC_MANAGEMENT_TRANSFORM_EXCEL_TEMPLATE = `${TDC_MANAGEMENT}/transform-excel-template`;
export const TDC_MANAGEMENT_EMPTY_TEMPLATE_DOWNLOAD = `${TDC_MANAGEMENT}/empty-template/download`;
export const TDC_MANAGEMENT_CONSTRAINTS = `${TDC_MANAGEMENT}/tdc-template-constraints`;
export const TDC_MANAGEMENT_PATH_FINDER = `${TDC_MANAGEMENT}/path-finder`;

// user
export const USERS = 'v1/users';
export const USER_SEARCH_GET_SUGGESTED_USERS_API_PATH = `${USERS}/search`;
export const USER_SEARCH_USER_MANAGEMENT = `${USERS}/management`;
export const USER_LIST_SUBMIT_TEMPLATE = `${USERS}/search-submitted-template`;
export const USER_VIEW_PRIVACY_CONSENT = `${USERS}/accept-privacy-consent-popup`;

// user management
export const USER_MANAGEMENT = 'v1/user-role-management';
export const USER_MANAGEMENT_ROLE_REQUESTS = `${USER_MANAGEMENT}/role-requests`;
export const USER_MANAGEMENT_ROLE_ASSIGN_CARD = `${USER_MANAGEMENT}/role-assign-card`;
export const USER_MANAGEMENT_CREATE_REQUEST = `${USER_MANAGEMENT}/create-request`;
export const USER_MANAGEMENT_TDC_REQUEST = 'v1/tdc-management';
export const USER_MANAGEMENT_SUBMISSION_ID = `${USER_MANAGEMENT}/get-submitted-template`;
export const USER_MANAGEMENT_ASSIGN_EDITOR = `${USER_MANAGEMENT}/assign-request`;

// data quality management
export const DATA_QUALITY_MANAGEMENT = 'v1/data-quality-management';
export const DATA_QUALITY_MANAGEMENT_DQ_REQUEST = `${DATA_QUALITY_MANAGEMENT}/dq-requests`;
export const DATA_QUALITY_MANAGEMENT_CREATE_REQUEST = `${DATA_QUALITY_MANAGEMENT}/create-request`;
export const GET_DATA_QUALITY_MANAGEMENT_ROLE_ASSIGN_CARD = `${DATA_QUALITY_MANAGEMENT}/role-assign-card`;
export const GET_DATA_QUALITY_MANAGEMENT_REQUIRED_REVIEWERS = `${DATA_QUALITY_MANAGEMENT}/required-reviewers`;
export const POST_DATA_QUALITY_MANAGEMENT_SUBMITTED_IDS = `${DATA_QUALITY_MANAGEMENT}/submitted-ids`;
export const POST_DATA_QUALITY_MANAGEMENT_UPDATE_STATUS_REQUEST = `${DATA_QUALITY_MANAGEMENT}/update-status-request`;
export const DATA_QUALITY_MANAGEMENT_DQ_RULES_SAMPLE_TEMPLATE_DOWNLOAD = `${DATA_QUALITY_MANAGEMENT}/dq-rules/sample-template/download`;
export const GET_DATA_QUALITY_MANAGEMENT_DQ_RULES_SAMPLE_TEMPLATE = `${DATA_QUALITY_MANAGEMENT}/sample-template`;
export const POST_DATA_QUALITY_MANAGEMENT_DQ_RULES_UPLOAD_TEMPLATE = `${DATA_QUALITY_MANAGEMENT}/upload-template`;
export const GET_DATA_QUALITY_MANAGEMENT_DQ_RULES_TEMPLATE_CONSTRAINTS = `${DATA_QUALITY_MANAGEMENT}/dq-rules-template-constraints`;
export const POST_DATA_QUALITY_MANAGEMENT_TRANSFORM_EXCEL_TEMPLATE = `${DATA_QUALITY_MANAGEMENT}/transform-excel-template`;
export const POST_DATA_QUALITY_MANAGEMENT_ASSIGN_REQUEST = `${DATA_QUALITY_MANAGEMENT}/assign-request`;
export const DATA_QUALITY_MANAGEMENT_UPDATE_STATUS = `${DATA_QUALITY_MANAGEMENT}/dq-submission/status`;
export const GET_DATA_QUALITY_MANAGEMENT_VIEW_TEMPLATE = `${DATA_QUALITY_MANAGEMENT}/view-template`;
export const PUT_DATA_QUALITY_MANAGEMENT_UPDATE_TEMPLATE = `${DATA_QUALITY_MANAGEMENT}/update-template`;

// search landing page
export const SEARCH_LP = 'v1/landing-pages';
export const SEARCH_LP_DATA_MARKETPLACE = `${SEARCH_LP}/data-marketplace-module`;
export const SEARCH_LP_ANALYTICS_MARKETPLACE = `${SEARCH_LP}/analytics-marketplace-module`;
export const SEARCH_LP_DATA_QUALITY = `${SEARCH_LP}/data-quality-module`;

// Get Started Page
export const GET_STARTED = 'v1/get-started';
export const GET_DATA_SAMPLE = `${GET_STARTED}/sample-use-case-list`;
export const GET_STARTED_RECENTLY = `${GET_STARTED}/recently`;
export const GET_STARTED_SUGGESTION = `${GET_STARTED}/suggestion`;
export const GET_STARTED_SUGGESTION_KEYWORD = `${GET_STARTED}/suggestion-keyword`;
export const GET_STARTED_ACQUIRING_STEPS = `${GET_STARTED}/step-detail`;
export const GET_STARTED_DOWNLOAD_POWER_BI = `${GET_STARTED}/#id/download-power-bi`;

// history page
export const HISTORY_PAGE = `v1/histories-page`;
export const HISTORY_PAGE_INCREASE_DOWNLOAD = `${HISTORY_PAGE}/#id/increase-download-all-dataset`;

export const DATA_QUALITY_RULES_SUBMISSION =
  'v1/data-quality-management/rules-submissions';

// dataset footprint
export const DATASET_FOOTPRINT = 'v1/plant-information';
export const DATASET_FOOTPRINT_DATA_TABLE = `${DATASET_FOOTPRINT}/data-table`;
export const DATASET_FOOTPRINT_HIERARCHY_TREE = `${DATASET_FOOTPRINT}/hierarchy-tree`;
export const DATASET_TABLE_FOOTPRINT_HIERARCHY_TREE = `${DATASET_FOOTPRINT}/data-table`;
export const DATASET_FOOTPRINT_FILTER_OPTIONS = `${DATASET_FOOTPRINT}/filter-options`;
export const TAG_COUNTER_FOOTPRINT = `${DATASET_FOOTPRINT}/count-pitags`;

// dashboard
export const DASHBOARD = 'v1/dashboard';
export const DASHBOARD_STATISTICS = `${DASHBOARD}/modules-filter`;
export const DASHBOARD_AUDIT_TRAILS = `${DASHBOARD}/audit-trails`;

// blacklist
export const BLACKLIST = 'v1/black-list';
export const BLACKLIST_REQUEST = `${BLACKLIST}/request`;
export const BLACKLIST_LATEST_REQUEST = `${BLACKLIST}/latest-request`;

// plant information
export const PLANT_INFORMATION = 'v1/plant-information';
export const POST_PLANT_INFORMATION_PI_TAGS = `${PLANT_INFORMATION}/pitags`;
