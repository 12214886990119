import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';

import { utf8ToBase64 } from 'services/string';

import LoadingBalls from 'components/core/LoadingBalls';

import ReportForm from './ReportForm';
import ReportContent from './ReportContent';

import './styles.scss';

const typeDir = {
  All: 'All',
  Review: 0,
  Question: 1,
  ReportBugs: 2,
  Suggestion: 3,
};

const AMReportTab = ({ detail }) => {
  const { onPostUserReview, onGetUserReportModel } = useAnalyticsMarketplace();
  const [filterType, setFilterType] = useState(typeDir.All);

  const onSubmitForm = useCallback(
    data => {
      const { typeOfForm, description, ...rest } = data;
      const type = typeOfForm;
      const params = {
        analyticDocumentId: detail?.id || '',
        description: utf8ToBase64(description),
        type,
        ...rest,
        callback() {
          setFilterType(typeDir.All);
          onGetUserReportModel({
            analyticDocumentId: detail?.id || '',
            type: 4,
            pageIndex: 1,
            pageSize: 10,
            actionType: null,
          });
        },
      };
      onPostUserReview(params);
    },
    [filterType, detail],
  );

  if (!detail?.id) {
    return <LoadingBalls isLoading />;
  }

  return (
    <div className="am-tab-report">
      <ReportForm onSubmitForm={onSubmitForm} />
      <hr className="am-tab-report__divider" />
      <ReportContent
        id={detail?.id}
        filterType={filterType}
        onChangeFilter={setFilterType}
      />
    </div>
  );
};

AMReportTab.propTypes = { detail: PropTypes.any };

export default memo(AMReportTab);
