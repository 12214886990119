import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoInfo } from 'react-icons/go';
import { useHistory } from 'react-router-dom';

import { ReactComponent as SuccessIcon } from 'assets/icons/common/success.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/common/warning.svg';

import { LOADING_STATUS } from 'constants/apis';
import { ALERT_MESSAGE_DO_NOT_PERMISSION } from 'constants/alertMessages';
import {
  newsfeedSelector,
  newsfeedManageSelector,
  getNewNewsfeedSelector,
  highLightNewsSelector,
  getHighLightNewsfeedRequest,
  getNewsfeedRequest,
  getNewsfeedManageRequest,
  deleteNewsfeedManageRequest,
  getNewNewsfeedQuantityRequest,
  tagsSelector,
  categoriesSelector,
  getTagsRequest,
  getCategoriesRequest,
  newNewsFeedSelector,
  createNewsFeedRequest,
  newsDetailSelector,
  getNewsDetailRequest,
  publishNewsSelector,
  publishNewsRequest,
  updateNewsSelector,
  updateNewsRequest,
  clearIsNewArticle,
  getSectionNewsfeedRequest,
} from '../slices/newsfeed';
import useAlerts from './useAlerts';
import useUser from './useUser';

const useNews = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const news = useSelector(newsfeedSelector);
  const newsManage = useSelector(newsfeedManageSelector);
  const newNewsfeedQuantity = useSelector(getNewNewsfeedSelector);
  const tags = useSelector(tagsSelector);
  const categories = useSelector(categoriesSelector);
  const newsCreate = useSelector(newNewsFeedSelector);
  const newsDetail = useSelector(newsDetailSelector);
  const publishNews = useSelector(publishNewsSelector);
  const updateNews = useSelector(updateNewsSelector);
  const highLightNews = useSelector(highLightNewsSelector);
  const { onAddAlertMessage } = useAlerts();
  const { userInfo, isCommunicator, isAdmin } = useUser();

  const onGetNews = useCallback(params => {
    dispatch(getNewsfeedRequest(params));
  }, []);

  const onClearIsNewArticle = useCallback(() => {
    dispatch(clearIsNewArticle());
  }, []);

  const onGetNewsManage = useCallback(params => {
    dispatch(getNewsfeedManageRequest(params));
  }, []);

  const onGetNewNewsfeedQuantity = useCallback(params => {
    dispatch(getNewNewsfeedQuantityRequest(params));
  }, []);

  const onDeleteNewsManage = useCallback(({ id, callback }) => {
    dispatch(deleteNewsfeedManageRequest({ id, callback }));
  }, []);

  const onGetTags = useCallback(() => {
    dispatch(getTagsRequest());
  }, []);

  const onGetCategories = useCallback(() => {
    dispatch(getCategoriesRequest());
  }, []);

  const onCreateNews = useCallback(({ data, callback }) => {
    dispatch(createNewsFeedRequest({ data, callback }));
  }, []);

  const onGetNewsDetail = useCallback(id => {
    dispatch(getNewsDetailRequest({ id }));
  }, []);

  const onPublishNews = useCallback(({ id, callback }) => {
    dispatch(publishNewsRequest({ id, callback }));
  }, []);

  const onUpdateNews = useCallback(params => {
    dispatch(updateNewsRequest(params));
  }, []);

  const onGetHighLightNews = useCallback(() => {
    dispatch(getHighLightNewsfeedRequest());
  }, []);

  const onGetSectionNewsfeed = useCallback(params => {
    dispatch(getSectionNewsfeedRequest(params));
  }, []);

  const onShowAlertHighlight = isHighlight => {
    onAddAlertMessage({
      id: Date.now(),
      variant: 'success',
      isAutoHide: true,
      content: (
        <p style={{ fontWeight: 'bold' }}>
          <SuccessIcon style={{ marginRight: '10px' }} />
          <span>
            {isHighlight
              ? 'This article is now removed from Highlight in Landing Page'
              : 'This article is now highlighted in Landing Page'}
          </span>
        </p>
      ),
    });
  };

  const onShowAlertLimit = () => {
    onAddAlertMessage({
      id: Date.now(),
      variant: 'warning',
      isAutoHide: true,
      content: (
        <p className="edh-export-data-modal__alert-content">
          <GoInfo size={18} color="#3F3C4C" style={{ marginRight: '10px' }} />
          <b>
            You have reached the limit of 12 articles Highlighted in Landing
            Page
          </b>
        </p>
      ),
    });
  };

  const onHandlePermissions = useCallback(() => {
    if (
      !isCommunicator &&
      !isAdmin &&
      userInfo.status === LOADING_STATUS.SUCCEEDED
    ) {
      onAddAlertMessage({
        id: Date.now(),
        variant: 'warning',
        isAutoHide: true,
        content: (
          <p
            className="edh-export-data-modal__alert-content"
            style={{ width: 'max-content' }}
          >
            <WarningIcon
              size={18}
              color="#3F3C4C"
              style={{ marginRight: '10px' }}
            />
            <b>{ALERT_MESSAGE_DO_NOT_PERMISSION}</b>
          </p>
        ),
      });
      history.push('/');
    } else {
      onGetTags();
      onGetCategories();
    }
  }, [userInfo]);

  return {
    news,
    newsManage,
    tags,
    categories,
    newsCreate,
    newNewsfeedQuantity,
    newsDetail,
    publishNews,
    updateNews,
    highLightNews,
    onGetNews,
    onClearIsNewArticle,
    onGetNewsManage,
    onDeleteNewsManage,
    onGetTags,
    onGetCategories,
    onCreateNews,
    onGetNewNewsfeedQuantity,
    onGetNewsDetail,
    onGetSectionNewsfeed,
    onPublishNews,
    onUpdateNews,
    onGetHighLightNews,
    onShowAlertHighlight,
    onShowAlertLimit,
    onHandlePermissions,
  };
};

export default useNews;
