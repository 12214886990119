import React, { useMemo } from 'react';
import FlipMove from 'react-flip-move';
import { uniqBy } from 'lodash';

import useAlerts from 'hooks/useAlerts';

import AlertItem from './AlertItem';

import './styles.scss';

const Alerts = () => {
  const { alertsMessages, onRemoveAlertMessage } = useAlerts();

  const updatedAlertsMessages = useMemo(
    () =>
      uniqBy(alertsMessages?.data, 'id').map(message => ({
        ...message,
        onClose() {
          message.onClose?.();
          onRemoveAlertMessage(message);
        },
      })),
    [alertsMessages],
  );

  return (
    <div className="edh-alerts">
      <FlipMove leaveAnimation="none">
        {updatedAlertsMessages.map(
          ({
            isAutoHide,
            id,
            variant = 'default',
            content,
            onClose,
            duration,
            className,
          }) => (
            <AlertItem
              key={id}
              isAutoHide={isAutoHide}
              variant={variant}
              duration={duration}
              content={content}
              className={className}
              onClose={onClose}
            />
          ),
        )}
      </FlipMove>
    </div>
  );
};

export default Alerts;
