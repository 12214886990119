import { call, put, delay, takeLatest, takeEvery } from 'redux-saga/effects';

import { getData, postData, putData } from '../services/api';

import {
  ANALYTICS_MARKETPLACE_GET_FILTER_OPTION_API_PATH,
  ANALYTICS_MARKETPLACE_GET_AIMODEL_DETAIL,
  ANALYTICS_MARKETPLACE_GET_LIST_AIMODEL_DETAIL,
  ANALYTICS_MARKETPLACE_SUBMIT_USER_REVIEW_DETAIL,
  ANALYTICS_MARKETPLACE_GET_LIST_USER_REVIEW_DETAIL,
  ANALYTICS_MARKETPLACE_SUBMIT_VERSION_HISTORY_DETAIL,
  ANALYTICS_MARKETPLACE_RUN_MODEL_PATH,
  ANALYTICS_MARKETPLACE_FIRST_ACCESS,
  ANALYTICS_MARKETPLACE_RUN_SAMPLE_DATA_PATH,
  ANALYTICS_MARKETPLACE_OFFLINE_SAMPLE_DOWNLOAD,
  ANALYTICS_MARKETPLACE_TOTAL_DATA_AI_MODEL,
  ANALYTICS_MARKETPLACE_DO_ACTION_ON_USER_REVIEW,
  ANALYTICS_MARKETPLACE_GET_LIST_GET_MY_REVIEW,
  ANALYTICS_MARKETPLACE_UPDATE_USER_REVIEW_DETAIL,
  ANALYTICS_MARKETPLACE_DELETE_USER_REVIEW_DETAIL,
  ANALYTICS_MARKETPLACE_ADD_TO_WORKSPACE,
  ANALYTICS_MARKETPLACE_GET_PRESET_QUESTIONS_PATH,
  ANALYTICS_MARKETPLACE_RUN_COGNITIVE_DIGITALIZATION_MODEL_PATH,
  ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_SAVE_MODEL_PATH,
} from '../constants/paths';
import { ACCESS_TOKEN } from '../constants/storageKeys';

import {
  getFilterOptionsRequest,
  getFilterOptionsSuccess,
  getFilterOptionsFailure,
  getAIModelRequest,
  getAIModelSuccess,
  getAIModelFailure,
  getListUserReviewSuccess,
  getListUserReviewFailure,
  getListUserReviewRequest,
  postUserReviewRequest,
  postUserReviewSuccess,
  postUserReviewFailure,
  getVersionHistoryRequest,
  getVersionHistorySuccess,
  getVersionHistoryFailure,
  getFirstAccessRequest,
  getFirstAccessSuccess,
  getFirstAccessFailure,
  runModelRequest,
  runModelSuccess,
  runModelFailure,
  runSampleDataRequest,
  runSampleDataSuccess,
  runSampleDataFailure,
  downloadOfflineModelRequest,
  downloadOfflineModelSuccess,
  downloadOfflineModelFailure,
  getTotalAIModelRequest,
  getTotalAIModelSuccess,
  getTotalAIModelFailure,
  doActionUserReviewRequest,
  doActionUserReviewSuccess,
  doActionUserReviewFailure,
  getMyUserReviewRequest,
  getMyUserReviewSuccess,
  getMyUserReviewFailure,
  updateMyUserReviewSuccess,
  updateMyUserReviewFailure,
  updateMyUserReviewRequest,
  deleteMyUserReviewRequest,
  deleteMyUserReviewSuccess,
  deleteMyUserReviewFailure,
  getListAIModelSuccess,
  getListAIModelFailure,
  getListAIModelRequest,
  updateAIModelBehaviorRequest,
  getListUserReportRequest,
  getListUserReportFailure,
  getListUserReportSuccess,
  addToWorkspaceRequest,
  addToWorkspaceSuccess,
  addToWorkspaceFailure,
  getWorkspaceAMRequest,
  getWorkspaceAMSuccess,
  getWorkspaceAMFailure,
  getPresetQuestionsSuccess,
  getPresetQuestionsFailure,
  getPresetQuestionsRequest,
  runCognitiveModelRequest,
  runCognitiveModelSuccess,
  runCognitiveModelFailure,
  saveCognitiveModelRequest,
  saveCognitiveModelSuccess,
  saveCognitiveModelFailure,
} from '../slices/analyticsMarketplace';

export function* getFilterOption() {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: ANALYTICS_MARKETPLACE_GET_FILTER_OPTION_API_PATH,
    });

    yield delay(800);
    yield put(getFilterOptionsSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getFilterOptionsFailure(message));
  }
}

export function* getListAIModels({ payload }) {
  const { modelTypes, isGetMore } = payload;
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: ANALYTICS_MARKETPLACE_GET_LIST_AIMODEL_DETAIL,
      data: payload,
    });
    yield delay(800);
    yield put(getListAIModelSuccess({ ...data, modelTypes, isGetMore }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getListAIModelFailure({ message, modelTypes }));
  }
}

export function* getAIModelDetail({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: ANALYTICS_MARKETPLACE_GET_AIMODEL_DETAIL,
      params: payload,
    });

    yield delay(800);
    yield put(getAIModelSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getAIModelFailure(message));
  }
}

export function* getListUserReviewDetail({ payload }) {
  try {
    const params = {
      type: 0,
      pageIndex: 1,
      pageSize: 10,
      ...payload,
    };
    const {
      data: { data },
    } = yield call(postData, {
      url: ANALYTICS_MARKETPLACE_GET_LIST_USER_REVIEW_DETAIL,
      data: params,
    });
    yield delay(800);
    yield put(getListUserReviewSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getListUserReviewFailure(message));
  }
}

export function* getMyUserReviewDetail({ payload }) {
  try {
    const params = {
      AnalyticDocumentId: payload.id,
    };
    const {
      data: { data },
    } = yield call(getData, {
      url: ANALYTICS_MARKETPLACE_GET_LIST_GET_MY_REVIEW,
      params,
    });
    yield delay(800);
    if (!data) {
      throw new Error('Something went wrong!');
    } else {
      yield put(getMyUserReviewSuccess(data));
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getMyUserReviewFailure(message));
  }
}

export function* updateMyUserReviewDetail({ payload }) {
  try {
    const { callback = () => {}, ...params } = payload;
    const {
      data: { data },
    } = yield call(postData, {
      url: ANALYTICS_MARKETPLACE_UPDATE_USER_REVIEW_DETAIL,
      data: params,
    });

    yield delay(800);
    yield put(updateMyUserReviewSuccess(data));
    callback();
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(updateMyUserReviewFailure(message));
  }
}

export function* deleteMyUserReviewDetail({ payload }) {
  try {
    const { id, callback = () => {} } = payload;
    const {
      data: { data },
    } = yield call(postData, {
      url: `${ANALYTICS_MARKETPLACE_DELETE_USER_REVIEW_DETAIL}/${id}`,
    });
    yield delay(800);
    yield put(deleteMyUserReviewSuccess(data));
    callback();
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(deleteMyUserReviewFailure(message));
  }
}

export function* postUserReviewDetail({ payload }) {
  try {
    const { callback = () => {}, ...rest } = payload;
    const params = {
      type: 0,
      parentId: null,
      ...rest,
    };
    const {
      data: { data },
    } = yield call(postData, {
      url: ANALYTICS_MARKETPLACE_SUBMIT_USER_REVIEW_DETAIL,
      data: params,
    });
    yield delay(800);
    yield put(postUserReviewSuccess(data));
    callback();
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(postUserReviewFailure(message));
  }
}

export function* getVersionHistoryDetail({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: ANALYTICS_MARKETPLACE_SUBMIT_VERSION_HISTORY_DETAIL,
      params: payload,
    });

    yield delay(800);
    yield put(getVersionHistorySuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getVersionHistoryFailure(message));
  }
}

export function* runModel({ payload }) {
  const {
    callback,
    analyticDocumentId,
    files,
    modelName,
    deviceType,
    parameters,
  } = payload;
  const formData = new FormData();
  formData.append('analyticDocumentId', analyticDocumentId);
  formData.append('deviceType', deviceType);
  formData.append('parameters', parameters);
  [...files].forEach(file => {
    formData.append('files', file);
  });

  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: ANALYTICS_MARKETPLACE_RUN_MODEL_PATH,
      contentType:
        'multipart/form-data; boundary=<calculated when request is sent>',
      data: formData,
    });

    yield delay(800);

    if (typeof callback === 'function') {
      callback(true);
    }
    yield put(runModelSuccess({ modelName, data }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(runModelFailure(message));
    if (typeof callback === 'function') {
      callback(false);
    }
  }
}

export function* runSampleData({ payload }) {
  const {
    callback,
    analyticDocumentId,
    fileNames,
    modelName,
    deviceType,
    parameters,
  } = payload;

  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: ANALYTICS_MARKETPLACE_RUN_SAMPLE_DATA_PATH,
      data: {
        analyticDocumentId,
        fileNames,
        deviceType,
        parameters,
      },
    });

    yield delay(800);
    if (typeof callback === 'function') {
      callback(true);
    }
    yield put(runSampleDataSuccess({ modelName, data }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(runSampleDataFailure(message));
    if (typeof callback === 'function') {
      callback(false);
    }
  }
}

export function* getFirstAccess() {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: ANALYTICS_MARKETPLACE_FIRST_ACCESS,
    });

    yield delay(800);
    yield put(getFirstAccessSuccess(!data.hasFirstAccess));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getFirstAccessFailure(message));
  }
}

export function* downloadOfflineModel({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: ANALYTICS_MARKETPLACE_OFFLINE_SAMPLE_DOWNLOAD,
      data: {
        storageConnectionString: payload.storageConnectionString,
        containerName: payload.containerName,
        modelName: payload.modelName,
      },
    });

    if (data?.isSuccess) {
      if (typeof payload.callback === 'function') {
        payload.callback(true);
      }
      yield put(downloadOfflineModelSuccess(data));
      yield put(
        updateAIModelBehaviorRequest({
          analyticDocumentId: payload.analyticDocumentId,
          type: 1,
        }),
      );
    } else {
      yield put(downloadOfflineModelFailure(data?.data?.error));
      payload.callback(false, data?.data?.error);
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(downloadOfflineModelFailure(message));
    if (typeof payload.callback === 'function') {
      payload.callback(false);
    }
  }
}

export function* getTotalAIModel() {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: ANALYTICS_MARKETPLACE_TOTAL_DATA_AI_MODEL,
    });
    yield delay(800);
    yield put(getTotalAIModelSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getTotalAIModelFailure(message));
  }
}

export function* doActionUserReview({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: ANALYTICS_MARKETPLACE_DO_ACTION_ON_USER_REVIEW,
      data: payload,
    });

    yield delay(800);
    yield put(doActionUserReviewSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(doActionUserReviewFailure(message));
  }
}

export function* getListUserReportDetail({ payload }) {
  try {
    const { status, callback = () => {}, ...rest } = payload;
    const params = {
      pageIndex: 1,
      pageSize: 10,
      ...rest,
    };
    const {
      data: { data },
    } = yield call(postData, {
      url: ANALYTICS_MARKETPLACE_GET_LIST_USER_REVIEW_DETAIL,
      data: params,
    });
    yield delay(800);
    yield put(getListUserReportSuccess(data));
    callback();
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getListUserReportFailure(message));
  }
}

export function* getPresetQuestions() {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: ANALYTICS_MARKETPLACE_GET_PRESET_QUESTIONS_PATH,
    });
    yield delay(800);
    yield put(getPresetQuestionsSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getPresetQuestionsFailure(message));
  }
}

export function* getWorkspaceAM({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: ANALYTICS_MARKETPLACE_GET_LIST_AIMODEL_DETAIL,
      data: payload,
    });
    yield delay(800);
    yield put(getWorkspaceAMSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getWorkspaceAMFailure(message));
  }
}

export function* addToWorkspace({ payload }) {
  try {
    const { data } = yield call(putData, {
      url: ANALYTICS_MARKETPLACE_ADD_TO_WORKSPACE,
      data: {
        analyticDocumentId: payload.id,
      },
    });

    if (data?.isSuccess) {
      yield put(addToWorkspaceSuccess(data));
    } else {
      yield put(addToWorkspaceFailure(data));
    }
  } catch (error) {
    yield put(addToWorkspaceFailure(error));
  }
}

export function* runCognitiveModel({ payload }) {
  const { callback, files, modelName } = payload;
  const formData = new FormData();
  formData.append('modelName', modelName);
  [...files].forEach(file => {
    formData.append('files', file);
  });
  try {
    const {
    data: { data },
    } = yield call(postData, {
    url: ANALYTICS_MARKETPLACE_RUN_COGNITIVE_DIGITALIZATION_MODEL_PATH,
    contentType:
    'multipart/form-data; boundary=<calculated when request is sent>',
    data: formData,
    });

    yield delay(800);

    if (typeof callback === 'function') {
      callback(true);
    }
    yield put(runCognitiveModelSuccess({ modelName, data }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(runCognitiveModelFailure(message));
    if (typeof callback === 'function') {
      callback(false);
    }
  }
}

export function* saveCognitiveModel({payload}) {
  const { modelName = '', files = [], blobFileNames = [] } = payload;
  const formData = new FormData();
  formData.append('accessToken', localStorage.getItem(ACCESS_TOKEN).replace(/"/g, ''));
  [...files].forEach(file => {
    formData.append('files', file);
  });
  [...blobFileNames].forEach(blob => {
    formData.append('blobFileNames', blob);
  });
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: ANALYTICS_MARKETPLACE_COGNITIVE_DIGITALIZATION_SAVE_MODEL_PATH,
      contentType:
        'multipart/form-data; boundary=<calculated when request is sent>',
      data: formData
    });
    yield delay(800);
    yield put(saveCognitiveModelSuccess({ modelName, data, status: 'idle' }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(saveCognitiveModelFailure(message));
  }
};

export default function* analyticsMarketplaceSaga() {
  yield takeLatest(getFilterOptionsRequest().type, getFilterOption);
  yield takeLatest(getAIModelRequest().type, getAIModelDetail);
  yield takeEvery(getListAIModelRequest().type, getListAIModels);
  yield takeLatest(getFirstAccessRequest().type, getFirstAccess);
  yield takeLatest(getListUserReviewRequest().type, getListUserReviewDetail);
  yield takeLatest(getMyUserReviewRequest().type, getMyUserReviewDetail);
  yield takeLatest(postUserReviewRequest().type, postUserReviewDetail);
  yield takeLatest(updateMyUserReviewRequest().type, updateMyUserReviewDetail);
  yield takeLatest(deleteMyUserReviewRequest().type, deleteMyUserReviewDetail);
  yield takeLatest(getVersionHistoryRequest().type, getVersionHistoryDetail);
  yield takeLatest(runModelRequest().type, runModel);
  yield takeLatest(runSampleDataRequest().type, runSampleData);
  yield takeLatest(downloadOfflineModelRequest().type, downloadOfflineModel);
  yield takeLatest(getTotalAIModelRequest().type, getTotalAIModel);
  yield takeLatest(doActionUserReviewRequest().type, doActionUserReview);
  yield takeLatest(getListUserReportRequest().type, getListUserReportDetail);
  yield takeLatest(getPresetQuestionsRequest().type, getPresetQuestions);
  yield takeLatest(getWorkspaceAMRequest().type, getWorkspaceAM);
  yield takeLatest(addToWorkspaceRequest().type, addToWorkspace);
  yield takeLatest(runCognitiveModelRequest().type, runCognitiveModel);
  yield takeLatest(saveCognitiveModelRequest().type, saveCognitiveModel);
}
