import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import LoadingBalls from 'components/core/LoadingBalls';
import Pagination from 'components/layout/Pagination';
import UserReviewCard from 'components/layout/UserReviewCard';
import AMSelect from 'components/core/AMSelect';
import { PAGE_SIZE_OPTIONS } from 'constants/pageSize';

import {
  FILTER_USER_REVIEW,
  FILTER_VALUE,
  TAB_ID,
} from 'pages/AMDetailedInformationPage/constants';

import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';
import useQuery from 'hooks/useQuery';
import useDevice from 'hooks/useDevice';
import { useDidUpdate } from 'hooks/useDidUpdate';
import { useDidMount } from 'hooks/useDidMount';
import { utf8ToBase64 } from 'services/string';

import './styles.scss';

const ReportContent = ({ id, filterType, onChangeFilter }) => {
  // State
  const [idElement, setIdElement] = useState(`report-content-${id}`);
  const [filterValue, setFilterValue] = useState(FILTER_USER_REVIEW[0]);
  const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 1 });
  const { query, onAddToQuery, onRemoveQuery } = useQuery();
  const { is13InchDevice } = useDevice();
  const location = useLocation();

  const {
    onPostUserReview,
    onDeleteMyUserReview,
    onGetUserReportModel,
    selectUserReportModel: {
      data: {
        items = [],
        totalCount,
        totalPages,
        pageSize = 10,
        currentPage = 1,
        hasNextPage,
        hasPreviousPage,
      },
      status: reportStatus,
    },
  } = useAnalyticsMarketplace();

  useEffect(() => {
    setIdElement(`report-content-${id}`);
  }, [id]);

  const getUserReport = (data = {}) => {
    const { type = FILTER_VALUE.ALL, actionType = null } = filterValue;
    onGetUserReportModel({
      analyticDocumentId: id,
      type,
      actionType,
      pageIndex: currentPage,
      pageSize,
      ...data,
      callback() {
        if (query.reportId) {
          setTimeout(() => {
            const elm = document.getElementById(
              `review-card-${query.reportId}`,
            );
            elm.scrollIntoView({ behavior: 'smooth' });
            const mentionElm = document.getElementById(query.mentionId);
            mentionElm.scrollIntoView({ behavior: 'smooth', block: 'center' });
          });
        }
      },
    });
  };

  useDidMount(() => {
    const params = {
      type: FILTER_VALUE.ALL,
      pageIndex: +query.page || 1,
      pageSize: +query.size || 10,
      userReviewId: query.reportId || null,
      actionType: filterValue.actionType || null,
    };
    if (query.reportId) {
      params.userReviewId = query.reportId;
      params.type = FILTER_VALUE.ALL;
    }
    getUserReport(params);
  });

  useDidUpdate(() => {
    if (id) {
      onGetUserReportModel({
        analyticDocumentId: id,
        actionType: filterValue.actionType || null,
        type: filterValue.type,
        ...pagination,
      });
      onAddToQuery({
        ...query,
        page: pagination.pageIndex,
        size: pagination.pageSize,
      });
    }
  }, [pagination, id]);

  useDidUpdate(() => {
    if (query.reportId && query.mentionId && query.tab === TAB_ID.INQUIRY) {
      getUserReport({
        userReviewId: query.reportId,
        type: FILTER_VALUE.ALL,
        analyticDocumentId: query?.subModel ? id : query.id,
        actionType: filterValue.actionType || null,
        ...pagination,
      });
    }
  }, [location]);

  useDidUpdate(() => {
    if (isNaN(filterType)) {
      setFilterValue(FILTER_USER_REVIEW[0]);
    }
  }, [filterType]);

  const onSelect = ({ type, actionType, value }) => {
    onRemoveQuery(['reportId', 'mentionId', 'page']);
    const filter = { type, actionType, value };
    if (typeof onChangeFilter === 'function') {
      onChangeFilter(filter.type);
    }
    setFilterValue({ ...filter });
    getUserReport({ ...filter, pageIndex: 1 });
  };

  const getContentType = title => {
    const obj = FILTER_USER_REVIEW.find(i => i.label === title);
    return obj.type;
  };

  const onSubmitComment = (value, commentId, type) => {
    const commentParams = {
      analyticDocumentId: id,
      parentId: commentId,
      description: utf8ToBase64(value),
      type,
      callback() {
        getUserReport({ status: 'fetching', userReviewId: query.reportId });
      },
    };
    onPostUserReview(commentParams);
  };

  const onDeleteComment = (commentId, type) => {
    const commentParams = {
      analyticDocumentId: id,
      id: commentId,
      type,
      callback() {
        getUserReport({ status: 'fetching', userReviewId: query.reportId });
      },
    };
    onDeleteMyUserReview(commentParams);
  };

  const onSetPageSize = useCallback(newPageSize => {
    setPagination(previousPagination => ({
      ...previousPagination,
      pageIndex: 1,
      pageSize: +newPageSize,
    }));
  }, []);

  const onNextPage = useCallback(() => {
    setPagination(previousPagination => ({
      ...previousPagination,
      pageIndex: currentPage + 1,
    }));
  }, [currentPage, pageSize]);

  const onPreviousPage = useCallback(() => {
    setPagination(previousPagination => ({
      ...previousPagination,
      pageIndex: currentPage - 1,
    }));
  }, [currentPage, pageSize]);

  const onGotoPage = useCallback(
    newPageIndex => {
      setPagination(previousPagination => ({
        ...previousPagination,
        pageIndex: newPageIndex + 1,
      }));
    },
    [pageSize],
  );

  const onFooterClick = () => {
    setFilterValue({ ...filterValue });
    getUserReport({ ...filterValue });
  };

  const renderUserReviewCard = useCallback(
    (list = []) => {
      if (list.length === 0) {
        return (
          <p className="report-content__body__no-data">
            No Inquiry, Question / Report Bugs
          </p>
        );
      }
      return list.map(i => (
        <UserReviewCard
          key={i.id}
          ratingScore={null}
          showTag
          isReply
          mentionId={i.id === query.reportId ? query.mentionId : ''}
          onSubmit={(value, parentId) =>
            onSubmitComment(value, parentId || i.id, getContentType(i.type))
          }
          onDelete={value => onDeleteComment(value, getContentType(i.type))}
          onFooterAction={onFooterClick}
          {...i}
        />
      ));
    },
    [query, onFooterClick],
  );

  return (
    <div className="report-content">
      <div className="report-content__header">
        <h3>All Inquiry, Question / Report Bugs</h3>
        <AMSelect
          value={query.mentionId ? null : filterValue.value}
          options={FILTER_USER_REVIEW}
          defaultLabel={query.mentionId && '\u00A0'}
          disabled={
            items?.length === 0 &&
            filterValue.type === FILTER_VALUE.ALL &&
            filterValue.actionType !== 'Saved'
          }
          onSelect={onSelect}
        />
      </div>
      <LoadingBalls isLoading={reportStatus === 'loading'} />
      <div
        id={idElement}
        className="report-content__body"
        style={{ maxHeight: 900 }}
      >
        {renderUserReviewCard(items)}
      </div>
      {totalCount > 10 && (
        <Pagination
          page={items}
          totalCount={totalCount}
          totalPages={totalPages}
          pageSize={pageSize}
          pageIndex={currentPage - 1}
          canNextPage={hasNextPage}
          canPreviousPage={hasPreviousPage}
          onSetPageSize={onSetPageSize}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          onGotoPage={onGotoPage}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          isShowPageSize={!is13InchDevice}
          isShowTotalCount={!is13InchDevice}
          isShowGotoPage={!is13InchDevice}
        />
      )}
    </div>
  );
};
ReportContent.propTypes = {
  id: PropTypes.string,
  filterType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeFilter: PropTypes.any,
};

ReportContent.defaultProps = {
  id: 'am-report-list',
};

export default ReportContent;
