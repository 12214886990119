import { call, put, takeLatest, delay } from 'redux-saga/effects';

import { postData } from '../services/api';
import {
  SEARCH_LP_DATA_MARKETPLACE,
  SEARCH_LP_ANALYTICS_MARKETPLACE,
  SEARCH_LP_DATA_QUALITY,
  SEARCH_SUGGESTION_LANDING_PAGES_API_PATH,
} from '../constants/paths';

import {
  getResultDMRequest,
  getResultDMSuccess,
  getResultDMFailure,
  getResultAMRequest,
  getResultAMSuccess,
  getResultAMFailure,
  getResultDQRequest,
  getResultDQSuccess,
  getResultDQFailure,
  postLandingPageSuggestionRequest,
  postLandingPageSuggestionSuccess,
  postLandingPageSuggestionFailure,
} from '../slices/searchLandingPage';

export function* getResultDM({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: SEARCH_LP_DATA_MARKETPLACE,
      data: payload,
    });

    yield delay(800);
    yield put(getResultDMSuccess({ data, isPagination: payload.isPagination }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getResultDMFailure(message));
  }
}

export function* getResultAM({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: SEARCH_LP_ANALYTICS_MARKETPLACE,
      data: payload,
    });

    yield delay(800);
    yield put(getResultAMSuccess({ data, isPagination: payload.isPagination }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getResultAMFailure(message));
  }
}

export function* getResultDQ({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: SEARCH_LP_DATA_QUALITY,
      data: payload,
    });

    yield delay(800);
    yield put(getResultDQSuccess({ data, isPagination: payload.isPagination }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getResultDQFailure(message));
  }
}

export function* PostLandingPageSuggestion({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: SEARCH_SUGGESTION_LANDING_PAGES_API_PATH,
      data: payload,
    });

    yield delay(800);
    yield put(postLandingPageSuggestionSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(postLandingPageSuggestionFailure(message));
  }
}

export default function* searchLandingPageSaga() {
  yield takeLatest(getResultDMRequest().type, getResultDM);
  yield takeLatest(getResultAMRequest().type, getResultAM);
  yield takeLatest(getResultDQRequest().type, getResultDQ);
  yield takeLatest(
    postLandingPageSuggestionRequest().type,
    PostLandingPageSuggestion,
  );
}
