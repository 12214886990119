import {
  call,
  put,
  delay,
  takeLatest,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { getData, patchData, postData, putData } from '../services/api';

import {
  DATASET_MANAGEMENT_STATUS_BLOCK,
  DATASET_MANAGEMENT_TABLE_ENTITLEMENT,
  DATASET_MANAGEMENT_TABLE_DETAIL,
  DATASET_MANAGEMENT_UPDATE_LIST_REQUEST,
  TDC_MANAGEMENT_CATEGORIES_TERMS,
  TDC_MANAGEMENT_SUBMIT_FOR_REVIEW,
  TDC_MANAGEMENT_SAMPLE_TEMPLATE,
  TDC_MANAGEMENT_UPLOAD_TEMPLATE,
  TDC_MANAGEMENT_VIEW_TEMPLATE,
  TDC_MANAGEMENT_UPDATE_TEMPLATE,
  TDC_MANAGEMENT_TRANSFORM_EXCEL_TEMPLATE,
  TDC_MANAGEMENT_CONSTRAINTS,
  TDC_MANAGEMENT_PATH_FINDER,
  GET_DATA_QUALITY_MANAGEMENT_DQ_RULES_SAMPLE_TEMPLATE,
  POST_DATA_QUALITY_MANAGEMENT_DQ_RULES_UPLOAD_TEMPLATE,
  GET_DATA_QUALITY_MANAGEMENT_DQ_RULES_TEMPLATE_CONSTRAINTS,
  POST_DATA_QUALITY_MANAGEMENT_TRANSFORM_EXCEL_TEMPLATE,
  ANALYTICS_MARKETPLACE_REQUEST_LIST_DATASET,
  GET_DATA_QUALITY_MANAGEMENT_VIEW_TEMPLATE,
  PUT_DATA_QUALITY_MANAGEMENT_UPDATE_TEMPLATE,
} from '../constants/paths';

import {
  getListStatusBlockRequest,
  getListStatusBlockSuccess,
  getListStatusBlockFailure,
  getListEntitlementRequest,
  getListEntitlementSuccess,
  getListEntitlementFailure,
  getListRequestDetailRequest,
  getListRequestDetailSuccess,
  getListRequestDetailFailure,
  updateListRequestRequest,
  updateListRequestFailure,
  listEntitlementParamSelector,
  postTdcManagementCategoriesTermsSuccess,
  postTdcManagementCategoriesTermsFailure,
  postTdcManagementCategoriesTermsRequest,
  postTdcSubmitForReviewRequest,
  postTdcSubmitForReviewSuccess,
  postTdcSubmitForReviewFailure,
  getTdcManagementSampleTemplateSuccess,
  getTdcManagementSampleTemplateFailure,
  getTdcManagementSampleTemplateRequest,
  postTdcUploadTemplateSuccess,
  postTdcUploadTemplateFailure,
  postTdcUploadTemplateRequest,
  getTdcViewDetailTemplateRequest,
  getTdcViewDetailTemplateSuccess,
  getTdcViewDetailTemplateFailure,
  putTdcUpdateTemplateSuccess,
  putTdcUpdateTemplateFailure,
  putTdcUpdateTemplateRequest,
  postTdcTransformExcelTemplateRequest,
  postTdcTransformExcelTemplateSuccess,
  postTdcTransformExcelTemplateFailure,
  getTdcTemplateConstraintsSuccess,
  getTdcTemplateConstraintsFailure,
  getTdcTemplateConstraintsRequest,
  getTdcPathFinderRequest,
  getTdcPathFinderSuccess,
  getTdcPathFinderFailure,
  getDQRulesSampleTemplateSuccess,
  getDQRulesSampleTemplateFailure,
  getDQRulesSampleTemplateRequest,
  postDQRulesUploadTemplateSuccess,
  postDQRulesUploadTemplateFailure,
  postDQRulesUploadTemplateRequest,
  getDQRulesTemplateConstraintsRequest,
  getDQRulesTemplateConstraintsSuccess,
  getDQRulesTemplateConstraintsFailure,
  postDQRulesTransformExcelTemplateRequest,
  postDQRulesTransformExcelTemplateSuccess,
  postDQRulesTransformExcelTemplateFailure,
  getModelRequestSuccess,
  getModelRequestFailure,
  getModelRequestRequest,
  getDQRulesViewDetailTemplateRequest,
  getDQRulesViewDetailTemplateSuccess,
  getDQRulesViewDetailTemplateFailure,
  putDQRulesUpdateTemplateRequest,
  putDQRulesUpdateTemplateSuccess,
  putDQRulesUpdateTemplateFailure,
} from '../slices/datasetManagement';

export function* getListStatusBlock() {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: DATASET_MANAGEMENT_STATUS_BLOCK,
    });

    yield delay(800);
    yield put(getListStatusBlockSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getListStatusBlockFailure(message));
  }
}

export function* getListEntitlement({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(putData, {
      url: DATASET_MANAGEMENT_TABLE_ENTITLEMENT,
      data: payload,
    });

    yield delay(800);
    yield put(getListEntitlementSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getListEntitlementFailure(message));
  }
}

export function* getListRequestDetail({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(putData, {
      url: DATASET_MANAGEMENT_TABLE_DETAIL,
      data: payload,
    });

    yield delay(800);
    yield put(getListRequestDetailSuccess({ data, id: payload.dataSetId }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getListRequestDetailFailure({ message, id: payload.dataSetId }));
  }
}

export function* postTdcManagementCategoriesTerms({ payload }) {
  const { formData, callback } = payload;

  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: TDC_MANAGEMENT_CATEGORIES_TERMS,
      data: formData,
    });
    yield put(postTdcManagementCategoriesTermsSuccess(data));
    callback?.();
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(postTdcManagementCategoriesTermsFailure(message));
  }
}

export function* postTdcSubmitForReview({ payload }) {
  const { formData, callback } = payload;

  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: TDC_MANAGEMENT_SUBMIT_FOR_REVIEW,
      data: formData,
    });
    yield put(postTdcSubmitForReviewSuccess(data));
    callback?.();
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(postTdcSubmitForReviewFailure(message));
  }
}

export function* updateListRequest({ payload }) {
  const { params, callback } = payload;
  try {
    yield callback?.();
    yield call(patchData, {
      url: DATASET_MANAGEMENT_UPDATE_LIST_REQUEST,
      data: params,
    });
    const listEntitlementParam = yield select(listEntitlementParamSelector);
    yield put(
      getListEntitlementRequest(
        listEntitlementParam || {
          pageIndex: 1,
          pageSize: 10,
        },
      ),
    );

    yield put(getListStatusBlockRequest());
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(updateListRequestFailure(message));
  }
}

export function* getTdcManagementSampleTemplate({ payload }) {
  const { callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: TDC_MANAGEMENT_SAMPLE_TEMPLATE,
    });

    yield delay(800);
    yield put(getTdcManagementSampleTemplateSuccess(data));
    callback?.(data);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getTdcManagementSampleTemplateFailure(message));
  }
}

export function* postTdcUploadTemplate({ payload }) {
  const { formData, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: TDC_MANAGEMENT_UPLOAD_TEMPLATE,
      data: formData,
    });

    yield delay(800);
    yield put(postTdcUploadTemplateSuccess(data));
    callback?.();
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(postTdcUploadTemplateFailure(message));
  }
}

export function* getTdcViewDetailTemplate({ payload }) {
  const { submissionId, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: `${TDC_MANAGEMENT_VIEW_TEMPLATE}?submissionId=${submissionId}`,
    });

    yield delay(800);
    yield put(getTdcViewDetailTemplateSuccess(data));
    callback?.(data);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getTdcViewDetailTemplateFailure(message));
  }
}

export function* putTdcUpdateTemplate({ payload }) {
  const { formData, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(putData, {
      url: TDC_MANAGEMENT_UPDATE_TEMPLATE,
      data: formData,
    });

    yield delay(800);
    yield put(putTdcUpdateTemplateSuccess(data));
    callback?.();
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(putTdcUpdateTemplateFailure(message));
  }
}

export function* postTdcTransformExcelTemplate({ payload }) {
  const { file, callback } = payload;
  const formData = new FormData();
  formData.append('file', file);
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: TDC_MANAGEMENT_TRANSFORM_EXCEL_TEMPLATE,
      data: formData,
      contentType:
        'multipart/form-data; boundary=<calculated when request is sent>',
    });
    yield put(postTdcTransformExcelTemplateSuccess(data));
    callback?.(data);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(postTdcTransformExcelTemplateFailure(message));
  }
}

export function* getTdcTemplateConstraints() {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: TDC_MANAGEMENT_CONSTRAINTS,
    });

    yield delay(800);
    yield put(getTdcTemplateConstraintsSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getTdcTemplateConstraintsFailure(message));
  }
}

export function* getTdcPathFinder({ payload }) {
  const { params, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: TDC_MANAGEMENT_PATH_FINDER,
      params: { path: params || '' },
    });

    yield delay(800);
    yield put(getTdcPathFinderSuccess(data));
    callback?.(params, data);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getTdcPathFinderFailure(message));
  }
}

export function* getDQRulesSampleTemplate({ payload }) {
  const { callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_DATA_QUALITY_MANAGEMENT_DQ_RULES_SAMPLE_TEMPLATE,
    });

    yield delay(800);
    yield put(getDQRulesSampleTemplateSuccess(data));
    callback?.(data);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getDQRulesSampleTemplateFailure(message));
  }
}

export function* postDQRulesUploadTemplate({ payload }) {
  const { formData, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: POST_DATA_QUALITY_MANAGEMENT_DQ_RULES_UPLOAD_TEMPLATE,
      data: formData,
    });

    yield delay(800);
    yield put(postDQRulesUploadTemplateSuccess(data));
    callback?.();
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(postDQRulesUploadTemplateFailure(message));
  }
}

export function* getDQRulesTemplateConstraints() {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: GET_DATA_QUALITY_MANAGEMENT_DQ_RULES_TEMPLATE_CONSTRAINTS,
    });

    yield delay(800);
    yield put(getDQRulesTemplateConstraintsSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getDQRulesTemplateConstraintsFailure(message));
  }
}

export function* postDQRulesTransformExcelTemplate({ payload }) {
  const { file, callback } = payload;
  const formData = new FormData();
  formData.append('file', file);
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: POST_DATA_QUALITY_MANAGEMENT_TRANSFORM_EXCEL_TEMPLATE,
      data: formData,
      contentType:
        'multipart/form-data; boundary=<calculated when request is sent>',
    });
    yield put(postDQRulesTransformExcelTemplateSuccess(data));
    callback?.(data);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(postDQRulesTransformExcelTemplateFailure(message));
  }
}

export function* getModelRequestSaga({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: ANALYTICS_MARKETPLACE_REQUEST_LIST_DATASET,
      params: payload,
    });
    yield delay(800);
    yield put(getModelRequestSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getModelRequestFailure(message));
  }
}

export function* getDQRulesViewDetailTemplate({ payload }) {
  const { submissionId, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: `${GET_DATA_QUALITY_MANAGEMENT_VIEW_TEMPLATE}?submissionId=${submissionId}`,
    });

    yield delay(800);
    yield put(getDQRulesViewDetailTemplateSuccess(data));
    callback?.(data);
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getDQRulesViewDetailTemplateFailure(message));
  }
}

export function* putDQRulesUpdateTemplate({ payload }) {
  const { formData, callback } = payload;
  try {
    const {
      data: { data },
    } = yield call(putData, {
      url: PUT_DATA_QUALITY_MANAGEMENT_UPDATE_TEMPLATE,
      data: formData,
    });

    yield delay(800);
    yield put(putDQRulesUpdateTemplateSuccess(data));
    callback?.();
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(putDQRulesUpdateTemplateFailure(message));
  }
}

export default function* datasetManagementSaga() {
  yield takeLatest(getListStatusBlockRequest().type, getListStatusBlock);
  yield takeLatest(getListEntitlementRequest().type, getListEntitlement);
  yield takeEvery(getListRequestDetailRequest().type, getListRequestDetail);
  yield takeLatest(
    postTdcManagementCategoriesTermsRequest().type,
    postTdcManagementCategoriesTerms,
  );
  yield takeLatest(
    postTdcSubmitForReviewRequest().type,
    postTdcSubmitForReview,
  );
  yield takeLatest(updateListRequestRequest().type, updateListRequest);
  yield takeLatest(
    getTdcManagementSampleTemplateRequest().type,
    getTdcManagementSampleTemplate,
  );
  yield takeLatest(postTdcUploadTemplateRequest().type, postTdcUploadTemplate);
  yield takeLatest(
    getTdcViewDetailTemplateRequest().type,
    getTdcViewDetailTemplate,
  );
  yield takeLatest(putTdcUpdateTemplateRequest().type, putTdcUpdateTemplate);
  yield takeLatest(
    postTdcTransformExcelTemplateRequest().type,
    postTdcTransformExcelTemplate,
  );
  yield takeLatest(
    getTdcTemplateConstraintsRequest().type,
    getTdcTemplateConstraints,
  );
  yield takeLatest(getTdcPathFinderRequest().type, getTdcPathFinder);
  yield takeLatest(
    getDQRulesSampleTemplateRequest().type,
    getDQRulesSampleTemplate,
  );
  yield takeLatest(
    postDQRulesUploadTemplateRequest().type,
    postDQRulesUploadTemplate,
  );
  yield takeLatest(
    getDQRulesTemplateConstraintsRequest().type,
    getDQRulesTemplateConstraints,
  );
  yield takeLatest(
    postDQRulesTransformExcelTemplateRequest().type,
    postDQRulesTransformExcelTemplate,
  );
  yield takeLatest(getModelRequestRequest().type, getModelRequestSaga);
  yield takeLatest(
    getDQRulesViewDetailTemplateRequest().type,
    getDQRulesViewDetailTemplate,
  );
  yield takeLatest(
    putDQRulesUpdateTemplateRequest().type,
    putDQRulesUpdateTemplate,
  );
}
