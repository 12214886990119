import { createSlice } from '@reduxjs/toolkit';
import { concat, uniqBy } from 'lodash';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'alerts';
const initialState = {
  alertsMessages: {
    data: [],
    status: 'succeeded', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
};

const alertsSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    addAlertMessage(state, action) {
      state.alertsMessages.data = uniqBy(
        concat(state.alertsMessages.data, [action.payload]),
        'id',
      );
    },
    removeAlertMessage(state, action) {
      state.alertsMessages.data = state.alertsMessages.data.filter(
        message => message.id !== action.payload?.id,
      );
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = alertsSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const { addAlertMessage, removeAlertMessage } = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const alertsMessagesSelector = createSelector(
  rootSelector,
  ({ alertsMessages }) => alertsMessages,
);
