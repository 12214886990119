import { useEffect, useState } from 'react';

const useMultiClamp = ({ id, text, clamp }, dependencies = []) => {
  const [active, setActive] = useState(true);
  const [lastText, setLastText] = useState(text);

  const num = str => `${str}`.replace(/[^\d.]/g, '') - 0;

  const number = n => {
    if (typeof n === 'string') {
      const convert = `${n}`.replace(/[^\d.]/g, '') - 0;
      return Math.round(convert * 100) / 100;
    }
    if (typeof n === 'number') {
      return Math.round(n * 100) / 100;
    }
  };

  const getStyle = (elm, style) => {
    if (window.getComputedStyle)
      return window.getComputedStyle(elm, null).getPropertyValue(style);
    return elm.currentStyle.getAttribute(
      style.replace(/-(\w)/g, (v, v1) => v1.toUpperCase()),
    );
  };

  const getHeight = elm => {
    const height = getStyle(elm, 'height');
    if (height.indexOf('px') > -1) return num(height);
    const acc =
      num(elm.clientHeight) -
      num(getStyle(elm, 'padding-top')) -
      num(getStyle(elm, 'padding-bottom'));
    return Math.round(acc * 100) / 100;
  };

  const getMaxHeight = elm => {
    let singleLineHeight = 0;
    const lineHeight = getStyle(elm, 'line-height');
    if (lineHeight.indexOf('px') > -1) {
      singleLineHeight = number(lineHeight);
    }
    if (!isNaN(lineHeight)) {
      const fontSize = getStyle(elm, 'font-size');
      const fontSizeNumber = num(fontSize);
      if (fontSize.indexOf('px') > -1)
        singleLineHeight = Math.round(fontSizeNumber * lineHeight * 100) / 100;
      if (fontSize.indexOf('pt') > -1)
        singleLineHeight =
          Math.round(((fontSizeNumber * 4) / 3) * lineHeight * 100) / 100;
    }
    return (singleLineHeight + 1) * clamp;
  };

  const createMoreBtn = (textButton, clickHandler) => {
    const moreBtn = document.createElement('span');
    moreBtn.setAttribute('id', 'multi-clamp-button');
    moreBtn.innerHTML = textButton;
    if (moreBtn.addEventListener) {
      moreBtn.addEventListener('click', clickHandler, false);
    } else if (moreBtn.attachEvent) {
      moreBtn.attachEvent('onclick', clickHandler);
    } else {
      moreBtn.onclick = clickHandler;
    }
    return moreBtn;
  };

  const fill = (fillText, self, isLast) => {
    self.element.innerText = fillText + self.ellipsis;
    if (typeof self.button === 'object') {
      self.element.appendChild(
        isLast ? self.button : self.button.cloneNode(true),
      );
    }
    if (isLast) {
      setLastText(fillText);
    }
  };

  const half = n => parseInt(n / 2, 10) || 1;

  const multiClamp = elementId => {
    const element = document.getElementById(elementId);
    const trunkSlice = (trunk, maxHeight, increase, len, isDecrease) => {
      const self = {
        element,
        ellipsis: '...',
        button: createMoreBtn('Show more', () => setActive(false)),
      };
      const slicedTrunk = trunk.slice(0, len);
      fill(slicedTrunk, self);
      let i;
      if (getHeight(self.element) > maxHeight) {
        i = isDecrease ? increase : half(increase);
        trunkSlice(trunk, maxHeight, i, len - i, true);
      } else {
        if (increase === 1 && isDecrease) {
          fill(slicedTrunk, self, true);
          return;
        }
        i = isDecrease ? half(increase) : increase;
        trunkSlice(trunk, maxHeight, i, len + i, false);
      }
    };

    const increase = half(text.length);

    trunkSlice(
      element.innerText,
      getMaxHeight(element),
      increase,
      increase,
      false,
    );
  };

  useEffect(() => {
    const elm = document.getElementById(id);
    if (!elm) return;
    if ((elm.clientHeight || 0) <= getMaxHeight(elm)) {
      setActive(false);
    } else {
      setActive(true);
      multiClamp(id);
    }
  }, [id, ...dependencies]);

  useEffect(() => {
    const elm = document.getElementById(id);
    if (elm && !active) {
      elm.innerText = text;
    }
  }, [active]);

  return { active, lastText, setActive };
};

export default useMultiClamp;
