import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const RSDropdownIndicator = ({
  dropdownIndicatorElement,
  dropdownIndicatorElementType: Component,
  ...restProps
}) => (
  <components.DropdownIndicator {...restProps}>
    {Component ? <Component {...restProps} /> : dropdownIndicatorElement}
  </components.DropdownIndicator>
);

RSDropdownIndicator.propTypes = {
  dropdownIndicatorElement: PropTypes.element,
  dropdownIndicatorElementType: PropTypes.elementType,
};

export default memo(RSDropdownIndicator);
