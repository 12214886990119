import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  alertsMessagesSelector,
  addAlertMessage,
  removeAlertMessage,
} from '../slices/alerts';

const useAlerts = () => {
  const alertsMessages = useSelector(alertsMessagesSelector);

  const dispatch = useDispatch();

  const onAddAlertMessage = useCallback(data => {
    dispatch(addAlertMessage(data));
  }, []);

  const onRemoveAlertMessage = useCallback(data => {
    dispatch(removeAlertMessage(data));
  }, []);

  return {
    alertsMessages,
    onAddAlertMessage,
    onRemoveAlertMessage,
  };
};

export default useAlerts;
