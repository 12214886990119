/* eslint-disable camelcase */
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  exportDataSelector,
  historyExportPopupSelector,
  getExportHistoryRequest,
  exportDataRequest,
} from 'slices/exportHistory';

import useToasts from './useToasts';
import useTakeEventGA from './useTakeEventGA';

const useExportHistory = () => {
  const { onAddToastsMessage, onRemoveToastsMessage } = useToasts();

  const { onSendExportDataset } = useTakeEventGA();

  const exportHistoryPopup = useSelector(historyExportPopupSelector);
  const exportDataStatus = useSelector(exportDataSelector);

  const dispatch = useDispatch();

  const onGetExportHistoryPopup = useCallback(({ params, hasLoading }) => {
    dispatch(
      getExportHistoryRequest({
        params,
        hasLoading,
      }),
    );
  }, []);

  const onExportDataset = useCallback(
    (
      {
        data,
        variant,
        titleToast,
        description,
        onClosePopupExport = () => {},
        addToastInvalid,
      },
      title,
    ) => {
      const callback = isSuccess => {
        if (isSuccess) {
          const toastId = Date.now();
          onClosePopupExport();

          onAddToastsMessage({
            id: toastId,
            variant,
            title: titleToast,
            description,
            onClick: () => onRemoveToastsMessage({ id: toastId }),
          });

          if (data.isValidRecord === false && addToastInvalid) {
            addToastInvalid();
          }

          onSendExportDataset(title);
        }
      };

      const newData =
        typeof data.dataSetsId === 'string' ||
        typeof data.fileNames === 'string'
          ? {
              ...data,
              dataSetsId: [data.dataSetsId],
              fileNames: [data.fileNames],
            }
          : data;
      dispatch(exportDataRequest({ data: newData, callback }));
    },
    [],
  );

  return {
    exportHistoryPopup,
    exportDataStatus,
    onGetExportHistoryPopup,
    onExportDataset,
  };
};

export default useExportHistory;
