import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Highlighter from 'react-highlight-words';

import './styles.scss';

const KeywordsSuggestion = ({
  textToHighlight,
  searchWords,
  onClickKeyWordSuggestion = () => {},
}) => (
  <div
    role="button"
    tabIndex="0"
    onClick={() => onClickKeyWordSuggestion(textToHighlight)}
    className="edh-searchbar-keywords-suggest-option"
  >
    <Highlighter
      highlightClassName="edh-highlighter"
      searchWords={[searchWords]}
      autoEscape
      textToHighlight={textToHighlight}
    />
  </div>
);

KeywordsSuggestion.propTypes = {
  searchWords: PropTypes.string,
  textToHighlight: PropTypes.string,
  onClickKeyWordSuggestion: PropTypes.func,
};

export default memo(KeywordsSuggestion);
