import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { idElementScrollToTop, onScrollToTop } from 'services/scroll';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ScrollToTopComponent = ({ children, isScrollToTop }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isScrollToTop) {
      setTimeout(() => {
        onScrollToTop();
      }, 0);
    }
  }, [pathname]);

  return isScrollToTop ? (
    <div id={idElementScrollToTop}>{children}</div>
  ) : (
    children
  );
};

ScrollToTopComponent.propTypes = {
  children: PropTypes.object,
  isScrollToTop: PropTypes.bool,
};

ScrollToTopComponent.defaultProps = {
  children: {},
  isScrollToTop: true,
};

export default memo(ScrollToTopComponent);
