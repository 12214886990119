export const idElementScrollToTop = 'edh-element-scroll-to-top';

export const onScrollToTop = () => {
  setTimeout(() => {
    const modalElement = document.querySelectorAll('.modal-dialog');
    const element =
      modalElement[modalElement.length - 1] ||
      document.getElementById(idElementScrollToTop);

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, 0);
};
