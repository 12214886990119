import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import MessageNoData from 'components/core/MessageNoData';
import LoadingBalls from 'components/core/LoadingBalls';

import EmptyHistoryImg from 'assets/images/common/empty-history.svg';

import useAnalyticsMarketplace from 'hooks/useAnalyticsMarketplace';

import HistoryItem from './HistoryItem';

import './styles.scss';

const AMHistoryTab = ({ title, detail }) => {
  const [showId, setShowId] = useState('');
  const { selectVersionHistory, onGetVersionHistory } =
    useAnalyticsMarketplace();

  const { data, status } = selectVersionHistory;

  useEffect(() => {
    if (detail?.id) {
      onGetVersionHistory({ id: detail?.id });
    }
  }, [detail?.id]);

  if (status === 'loading') {
    return (
      <div style={{ height: 250 }}>
        <LoadingBalls className="edh-loading-balls__custom" isLoading />
      </div>
    );
  }
  return (
    <div className="am-tab-history">
      {data && data.length ? (
        <>
          <h1 className="am-tab-history__title">
            {title || 'Model Version History'}
          </h1>
          <div className="step-progress">
            {data.map(item => (
              <HistoryItem
                key={item.id}
                {...item}
                showItem={showId === item.id}
                setShowId={setShowId}
              />
            ))}
          </div>
        </>
      ) : (
        <MessageNoData
          img={EmptyHistoryImg}
          title="No Version History"
          description="Version History will be appear here."
        />
      )}
    </div>
  );
};

AMHistoryTab.propTypes = {
  detail: PropTypes.any,
  title: PropTypes.string,
};

export default memo(AMHistoryTab);
