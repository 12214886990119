import React from 'react';
// import PropTypes from 'prop-types';

import logo from '../../../assets/images/full-logo.png';

import './styles.scss';

const GlobalLoading = () => (
  <div className="edh-global-loading">
    <div className="edh-global-loading__loader" />
    <img alt="Petronas Logo" src={logo} className="edh-global-loading__logo" />
  </div>
);

GlobalLoading.propTypes = {};

export default GlobalLoading;
