import { createSlice } from '@reduxjs/toolkit';

import { createSelector } from 'reselect';
import { FIRST_LOGIN_MODAL, OVERVIEW_MODAL } from 'constants/storages';

import { getStorage, setStorage } from 'services/storage';

export const ROOT_STATE_NAME = 'modal';
const initialState = {
  modalDisplayOrders: {
    [FIRST_LOGIN_MODAL]: getStorage(FIRST_LOGIN_MODAL),
    [OVERVIEW_MODAL]: getStorage(OVERVIEW_MODAL),
  },
};

const modalSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    setHideGlobalModal(state, action) {
      state.modalDisplayOrders[action.payload] = true;
      setStorage({ key: action.payload, val: true });
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = modalSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const { setHideGlobalModal } = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const modalDisplayOrdersSelector = createSelector(
  rootSelector,
  ({ modalDisplayOrders }) => modalDisplayOrders,
);
