import React, { useCallback, useEffect, useState } from 'react';
import ImageThumbnail from 'assets/images/common/image-thumbnail.png';
import LoadingBalls from 'components/core/LoadingBalls';
import { TEXT_WATERMARK } from '../constants/analyticsMarketplace';

const getLines = (ctx, text, maxWidth) => {
  if (!text) return [];

  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const testLine = `${currentLine} ${words[i]}`;
    const metrics = ctx.measureText(testLine);
    const testWidth = metrics.width;
    if (testWidth > maxWidth) {
      lines.push(currentLine);
      currentLine = words[i];
    } else {
      currentLine = testLine;
    }
  }
  lines.push(currentLine);
  return lines;
};

const drawImageWithBorderRadius = (image, borderRadius) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const { width, height } = image;

  canvas.width = width;
  canvas.height = height;

  ctx.beginPath();
  ctx.moveTo(borderRadius, 0);
  ctx.lineTo(width - borderRadius, 0);
  ctx.arcTo(width, 0, width, borderRadius, borderRadius);
  ctx.lineTo(width, height - borderRadius);
  ctx.arcTo(width, height, width - borderRadius, height, borderRadius);
  ctx.lineTo(borderRadius, height);
  ctx.arcTo(0, height, 0, height - borderRadius, borderRadius);
  ctx.lineTo(0, borderRadius);
  ctx.arcTo(0, 0, borderRadius, 0, borderRadius);
  ctx.closePath();
  ctx.clip();

  ctx.drawImage(image, 0, 0);

  return canvas;
};

const drawTextOnImage = ({
  src,
  config: { text, font, caption = '' },
  callback,
}) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const image = new Image();
  image.src = src;
  image.setAttribute('crossorigin', 'anonymous');
  image.onload = () => {
    const { width: imgWidth, height: imgHeight } = image;
    const FONT_SIZE = 30;
    const FOOTER_FONT_SIZE = 26;
    const FOOTER_LINE_HEIGHT = 30;
    const PADDING = 16;

    context.font = `${FOOTER_FONT_SIZE}px ${font}`;
    const lines = getLines(context, caption, imgWidth);

    const imageProperties = {
      x: PADDING,
      y: PADDING,
    };

    const canvasProperties = {
      width: imgWidth + PADDING * 2,
      height: imgHeight + PADDING * 2 + lines.length * FOOTER_LINE_HEIGHT,
    };

    canvas.width = canvasProperties.width;
    canvas.height = canvasProperties.height;

    context.fillStyle = '#efeff3';
    context.fillRect(0, 0, canvasProperties.width, canvasProperties.height);

    const imageWithBorder = drawImageWithBorderRadius(image, 10);
    context.drawImage(imageWithBorder, PADDING, PADDING);

    const drawTextProperties = [
      text,
      canvasProperties.width - PADDING - FONT_SIZE,
      image.height + PADDING - FONT_SIZE,
    ];
    context.font = `${FONT_SIZE}px ${font}`;
    context.lineWidth = Math.round(FONT_SIZE / 10);
    context.strokeStyle = 'white';
    context.textAlign = 'right';
    context.strokeText(...drawTextProperties);

    context.fillStyle = 'black';
    context.textAlign = 'right';
    context.fillText(...drawTextProperties);

    context.fillStyle = '#424242';
    context.font = `${FOOTER_FONT_SIZE}px ${font}`;
    context.textAlign = 'left';
    lines.forEach((item, index) => {
      context.fillText(
        item,
        imageProperties.x,
        imageProperties.y +
          image.height +
          FOOTER_LINE_HEIGHT +
          FOOTER_FONT_SIZE * index,
      );
    });

    const dataURL = canvas.toDataURL('image/jpeg');
    callback(dataURL);
  };
  canvas.remove();
};

const useWatermarkImage = ({
  prefix = '',
  src = '',
  defaultImg = ImageThumbnail,
  defaultMessage = 'No Image',
  loading = false,
  config = {},
  caption,
}) => {
  const { text = TEXT_WATERMARK, font = 'Inter' } = config;
  const [imageSrc, setImageSrc] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const getClass = str =>
    ['edh-watermark-image', prefix]
      .map(item => item + str)
      .filter(Boolean)
      .join(' ');

  const downloadImage = useCallback(
    fileName => {
      const anchor = document.createElement('a');
      anchor.href = imageSrc;
      anchor.download = `${fileName}.jpeg`;
      anchor.style.display = 'none';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
    [imageSrc],
  );

  useEffect(() => {
    if (!src) {
      setImageSrc('');
      setImageLoading(false);
      setIsDownload(false);
      return;
    }

    setImageLoading(true);
    drawTextOnImage({
      src,
      config: {
        text,
        font,
        caption,
      },
      callback(image) {
        setIsDownload(true);
        setImageSrc(image);
        setImageLoading(false);
      },
    });
  }, [src]);

  const renderImage = () => {
    if (imageLoading || loading) {
      return (
        <div className={getClass('__image-container')}>
          <LoadingBalls isLoading />
        </div>
      );
    }
    return (
      <div className={getClass('__image-container')}>
        <div className={getClass('__image-inner')}>
          <img
            loading="lazy"
            className={src ? getClass('__image') : getClass('__image-nodata')}
            src={src ? imageSrc : defaultImg}
            alt="model-content__image"
          />
          {(!src || imageLoading) && (
            <div className={getClass('__no-data')}>{defaultMessage}</div>
          )}
        </div>
      </div>
    );
  };

  return {
    isDownload,
    imageLoading,
    downloadImage,
    renderImage,
  };
};

export default useWatermarkImage;
