import React, { useEffect, useState, memo, useRef } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';

import './styles.scss';

const TableCellTextEditor = ({
  disabled,
  customNullValue,
  cellProps,
  onUpdateDataEdit,
  focusAble,
}) => {
  const { column, row, value: initialValue } = cellProps;

  const [value, setValue] = useState(initialValue);
  const [valueFocus, setValueFocus] = useState();
  const [isFocus, setIsFocus] = useState(false);

  const ref = useRef(null);

  const onChange = e => {
    setValue(e.target.value);
  };

  const onFocus = () => {
    setValueFocus(value);
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
    if (valueFocus !== value) {
      onUpdateDataEdit(row, column, value);
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    function updateData() {
      if (valueFocus !== value && isFocus) {
        onUpdateDataEdit(row, column, value);
      }
    }
    window.addEventListener('touchstart', updateData);
    return function cleanup() {
      window.removeEventListener('touchstart', updateData);
    };
  }, [value, isFocus]);

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={onFocus}
      className="edh-table-call-text-editor"
    >
      {!isFocus || !focusAble ? (
        <Tooltip content={value} id={`${Date.now()}-${value}`}>
          <div
            id={`${Date.now()}-${value}`}
            className="edh-table-call-text-editor__text"
          >
            {value || customNullValue}
          </div>
        </Tooltip>
      ) : (
        <textarea
          className={`edh-table-call-text-editor__text-editor ${
            disabled ? 'disabled' : ''
          }`}
          disabled={disabled}
          ref={ref}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onFocus={element => {
            element.target.focus();
            element.target.setSelectionRange(
              element.target.value.length,
              element.target.value.length,
            );
          }}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    </div>
  );
};

TableCellTextEditor.propTypes = {
  disabled: PropTypes.bool,
  focusAble: PropTypes.bool,
  customNullValue: PropTypes.string,
  cellProps: PropTypes.object,
  onUpdateDataEdit: PropTypes.func,
};

TableCellTextEditor.defaultProps = {
  disabled: false,
  focusAble: true,
  customNullValue: '-',
  cellProps: {},
  onUpdateDataEdit() {},
};

export default memo(TableCellTextEditor);
