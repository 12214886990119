import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BiChevronRight } from 'react-icons/bi';

import Tooltip from 'components/layout/Tooltip';

import InformationIcon from '../../../assets/icons/common/information-black-20.svg';

import './styles.scss';

const Breadcrumb = ({
  items,
  isShowCurrentPath,
  isShowIcon,
  tooltip,
  eleCustom,
}) => {
  const title =
    items[items.length - 1]?.subLabel ||
    items[items.length - 1]?.title ||
    items[items.length - 1]?.label;

  const renderItem = (item, idx) => {
    const isActive = idx === items.length - 1;
    if (isActive) {
      return (
        <span
          key={item?.id}
          className="edh-breadcrumb__item edh-breadcrumb__item--active"
        >
          {item?.label}
        </span>
      );
    }
    if (item.isDisable) {
      return (
        <span key={item?.id} className="edh-breadcrumb__item">
          {item?.label}
          {idx < items.length - 1 && <BiChevronRight size={16} />}
        </span>
      );
    }
    return (
      <Link
        key={item?.id}
        to={{
          pathname: item?.href,
        }}
        className="edh-breadcrumb__item"
      >
        <span>{item?.label}</span>
        {idx < items.length - 1 && <BiChevronRight size={16} />}
      </Link>
    );
  };

  return (
    <div className="edh-breadcrumb">
      <div className="edh-breadcrumb__items">
        {items.map((item, idx) => (
          <div key={idx.toString()}>{renderItem(item, idx)}</div>
        ))}
      </div>
      {isShowCurrentPath && (
        <div className="edh-breadcrumb__title-page">
          <h1 className="title-page__current-path">{title}</h1>
          {isShowIcon && (
            <Tooltip content={tooltip} placement="top">
              <img
                src={InformationIcon}
                alt="information icon"
                style={{ marginLeft: '8px' }}
              />
            </Tooltip>
          )}
          {eleCustom}
        </div>
      )}
    </div>
  );
};

Breadcrumb.propTypes = {
  eleCustom: PropTypes.any,
  items: PropTypes.array,
  isShowCurrentPath: PropTypes.bool,
  isShowIcon: PropTypes.bool,
  tooltip: PropTypes.string,
};

Breadcrumb.defaultProps = {
  eleCustom: null,
  items: [],
  isShowCurrentPath: true,
  isShowIcon: false,
  tooltip: '',
};

export default memo(Breadcrumb);
