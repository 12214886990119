/* eslint-disable react/no-danger */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useDidMount } from 'hooks/useDidMount';
import { useDidUpdate } from 'hooks/useDidUpdate';
import { useWillUnmount } from 'hooks/useWillUnmount';

import './styles.scss';

const canvasStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: 0,
  overflow: 'hidden',
  'padding-top': 0,
  'padding-bottom': 0,
  border: 'none',
};

const mirrorProps = [
  'box-sizing',
  'width',
  'font-size',
  'font-weight',
  'font-family',
  'font-style',
  'letter-spacing',
  'text-indent',
  'white-space',
  'word-break',
  'overflow-wrap',
  'padding-left',
  'padding-right',
];

const dummySpan = text => {
  const span = document.createElement('span');
  span.className = 'lines-ellipsis-unit';
  span.textContent = text;
  return span;
};

const splitNode = (node, basedOn) => {
  if (node.nodeType === Node.TEXT_NODE) {
    const frag = document.createDocumentFragment();
    let units;
    switch (basedOn) {
      case 'words':
        units = node.textContent.split(/(\b[^\s]+\b)/);
        break;
      case 'letters':
        units = Array.from(node.textContent);
        break;
      default:
        units = Array.from(node.textContent);
        break;
    }
    units.forEach(unit => {
      frag.appendChild(dummySpan(unit));
    });
    node.parentNode.replaceChild(frag, node);
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    const nodes = [].slice.call(node.childNodes);
    const len = nodes.length;
    for (let i = 0; i < len; i++) {
      splitNode(nodes[i], basedOn);
    }
  }
};

const unwrapTextNode = node => {
  node.parentNode.replaceChild(document.createTextNode(node.textContent), node);
};

const removeFollowingElementLeaves = (node, root) => {
  if (!root.contains(node) || node === root) return;
  while (node.nextElementSibling) {
    node.parentNode.removeChild(node.nextElementSibling);
  }
  removeFollowingElementLeaves(node.parentNode, root);
};

const findBlockAncestor = node => {
  let nodeAncestor = node;
  while (nodeAncestor) {
    if (
      /p|div|main|section|h\d|ul|ol|li/.test(nodeAncestor.tagName.toLowerCase())
    ) {
      return nodeAncestor;
    }
    nodeAncestor = nodeAncestor.parentNode;
  }
};

const affectLayout = ndUnit =>
  !!(
    ndUnit.offsetHeight &&
    (ndUnit.offsetWidth || /\S/.test(ndUnit.textContent))
  );

const RichTextClamp = ({
  component: Component = 'div',
  className,
  unsafeHTML,
  maxLine = 1,
  ellipsisHTML,
  basedOn = 'letters',
  defaultClamp = true,
  ...rest
}) => {
  const clampRef = useRef(null);
  const canvasRef = useRef(null);
  const nodeListUnitsRef = useRef(null);
  const [isClamped, setIsClamped] = useState(defaultClamp);
  const [richText, setRichText] = useState(unsafeHTML);

  const getIndexes = () => {
    const indexes = [0];
    nodeListUnitsRef.current = Array.from(
      canvasRef.current.querySelectorAll('.lines-ellipsis-unit'),
    );
    const nodeListUnits = nodeListUnitsRef.current;
    const len = nodeListUnits.length;
    if (!nodeListUnits.length) return indexes;

    const firstNodeUnit = nodeListUnits.find(affectLayout);
    if (!firstNodeUnit) return indexes;

    let line = 1;
    let { offsetTop } = firstNodeUnit;
    for (let i = 1; i < len; i++) {
      if (
        affectLayout(nodeListUnits[i]) &&
        nodeListUnits[i].offsetTop - offsetTop > 1
      ) {
        line += 1;
        indexes.push(i);
        offsetTop = nodeListUnits[i].offsetTop;
        if (line > maxLine) {
          break;
        }
      }
    }
    return indexes;
  };

  const createEllipsisSpan = () => {
    const nodeEllipsis = document.createElement('span');
    nodeEllipsis.appendChild(document.createElement('wbr'));
    const textContent = document.createElement('span');
    textContent.className = 'lines-ellipsis-button';
    textContent.innerHTML = ellipsisHTML;
    nodeEllipsis.appendChild(textContent);
    return nodeEllipsis;
  };

  const putEllipsis = indexes => {
    if (indexes.length <= maxLine) return false;
    nodeListUnitsRef.current = nodeListUnitsRef.current.slice(
      0,
      indexes[maxLine],
    );
    let nodePrevUnit = nodeListUnitsRef.current.pop();
    const ndEllipsis = createEllipsisSpan();
    do {
      removeFollowingElementLeaves(nodePrevUnit, canvasRef.current);
      findBlockAncestor(nodePrevUnit).appendChild(ndEllipsis);
      nodePrevUnit = nodeListUnitsRef.current.pop();
    } while (
      nodePrevUnit &&
      (!affectLayout(nodePrevUnit) ||
        ndEllipsis.offsetHeight > nodePrevUnit.offsetHeight ||
        ndEllipsis.offsetTop > nodePrevUnit.offsetTop)
    );

    if (nodePrevUnit) {
      unwrapTextNode(nodePrevUnit);
    }
    nodeListUnitsRef.current.forEach(unwrapTextNode);
    return true;
  };

  const copyStyleToCanvas = () => {
    const targetStyle = window.getComputedStyle(clampRef.current);
    mirrorProps.forEach(key => {
      canvasRef.current.style[key] = targetStyle[key];
    });
  };

  const reflow = () => {
    canvasRef.current.innerHTML = unsafeHTML;
    splitNode(canvasRef.current, basedOn);
    const clamped = putEllipsis(getIndexes());
    setIsClamped(clamped);
    setRichText(canvasRef.current.innerHTML);
  };

  const initCanvas = () => {
    if (canvasRef.current) return;
    canvasRef.current = document.createElement('div');
    const canvas = canvasRef.current;
    canvas.className = `lines-ellipsis-canvas ${className}`;
    canvas.setAttribute('aria-hidden', 'true');
    copyStyleToCanvas();
    Object.keys(canvasStyle).forEach(key => {
      canvas.style[key] = canvasStyle[key];
    });
    document.body.appendChild(canvas);
  };

  useDidMount(() => {
    if (isClamped) {
      initCanvas();
      reflow();
    }
  });

  useDidUpdate(() => {
    if (isClamped) {
      const ndEllipsis = clampRef.current.querySelector(
        '.lines-ellipsis-button',
      );
      ndEllipsis.addEventListener('click', () => {
        setIsClamped(false);
      });
    }
  }, [isClamped, richText]);

  useWillUnmount(() => {
    canvasRef.current.parentNode.removeChild(canvasRef.current);
  });

  return (
    <Component
      className={`lines-ellipsis ${
        isClamped ? 'lines-ellipsis--clamped' : ''
      } ${className}`}
      ref={clampRef}
      {...rest}
    >
      <div
        dangerouslySetInnerHTML={{ __html: isClamped ? richText : unsafeHTML }}
      />
    </Component>
  );
};

RichTextClamp.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
  ellipsisHTML: PropTypes.string,
  basedOn: PropTypes.string,
  unsafeHTML: PropTypes.string,
  defaultClamp: PropTypes.bool,
  maxLine: PropTypes.number,
};

export default RichTextClamp;
