/* eslint-disable camelcase */
import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import ModalTitle from 'components/core/ModalTitle';
import Input from 'components/fields/Input';

import useToasts from 'hooks/useToasts';
import useAPI from 'hooks/useAPI';

import { CONFIRM_MESSAGES } from 'constants/requestTypesPopup';
import { LIMIT_CHARACTERS } from 'constants/dataRequestForm';
import { BLACKLIST_REQUEST } from 'constants/paths';
import { METHOD } from 'constants/apis';

import PropTypes from 'prop-types';

import './styles.scss';

const RequestAccessModal = ({
  show,
  onHide,
  pageTitle,
  modalTitle,
  onGetBlacklistLatestRequest,
}) => {
  const { onAddToastsMessage, onRemoveToastsMessage } = useToasts();
  const [isDisabled, setIsDisabled] = useState(true);

  const { data: blacklistRequest, onCallAPI: onRequestBlacklist } = useAPI();

  const {
    handleSubmit,
    reset,
    clearErrors,
    setError,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
      justification: '',
    },
  });

  const rulesOfFields = useMemo(
    () => ({
      name: {
        required: true,
        maxLength: 300,
      },
      email: {
        required: true,
        maxLength: 300,
      },
      phoneNumber: {
        required: true,
        maxLength: 300,
      },
      justification: {
        required: true,
        maxLength: 1500,
      },
    }),
    [],
  );

  const onCloseModal = () => {
    reset();
    clearErrors();
    onHide();
    setIsDisabled(true);
  };

  const onValidateSubmissionFields = () => {
    const nameRegexPattern = /^[a-zA-Z ]+$/;
    const phoneNumberRegexPattern = /^\d+$/;
    const emailRegexPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    let isAllowSubmit = true;
    const { name, email, phoneNumber } = getValues();

    if (!nameRegexPattern.test(name)) {
      isAllowSubmit = false;
      setError('name', {
        type: 'custom',
        message: 'Please input text only',
      });
    }
    if (!phoneNumberRegexPattern.test(phoneNumber)) {
      isAllowSubmit = false;
      setError('phoneNumber', {
        type: 'manual',
        message: 'Please input numbers only',
      });
    }
    if (!emailRegexPattern.test(email)) {
      isAllowSubmit = false;
      setError('email', {
        type: 'manual',
        message: 'Please input correct email address',
      });
    }

    return isAllowSubmit;
  };

  const onSubmit = useCallback(data => {
    if (errors.length !== 0 && onValidateSubmissionFields()) {
      onRequestBlacklist({
        method: METHOD.POST,
        url: BLACKLIST_REQUEST,
        data,
      });
      onCloseModal();
      const toastId = Date.now();
      onAddToastsMessage({
        id: toastId,
        variant: 'success',
        title: CONFIRM_MESSAGES.requestAccess.submit.title,
        description: <p>{CONFIRM_MESSAGES.requestAccess.submit.description}</p>,
        buttonText: 'Okay',
        onClick: () => onRemoveToastsMessage({ id: toastId }),
      });
    }
  }, []);

  useEffect(() => {
    if (
      errors.length === 0 ||
      watch('name')?.length > 0 ||
      watch('email')?.length > 0 ||
      watch('phoneNumber')?.length > 0 ||
      watch('justification')?.length > 0
    ) {
      setIsDisabled(false);
    }
  }, [
    errors,
    watch('name'),
    watch('email'),
    watch('phoneNumber'),
    watch('justification'),
  ]);

  useEffect(() => {
    if (blacklistRequest.status === 'succeeded') {
      onGetBlacklistLatestRequest();
    }
  }, [blacklistRequest.status]);

  return (
    <Modal
      centered
      show={show}
      onHide={onCloseModal}
      dialogClassName="edh-forbidden-request-access-modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <ModalTitle category={pageTitle} title={modalTitle} />
        </Modal.Header>
        <Modal.Body>
          <Input
            label="Name"
            name="name"
            placeholder="Insert Name"
            rules={rulesOfFields?.name}
            error={errors?.name}
            limit={LIMIT_CHARACTERS.input}
            control={control}
          />
          <Row className="input-group">
            <Col>
              <Input
                label="Email"
                name="email"
                placeholder="Insert Email"
                rules={rulesOfFields?.email}
                error={errors?.email}
                limit={LIMIT_CHARACTERS.input}
                control={control}
              />
            </Col>
            <Col>
              <Input
                label="Phone Number"
                name="phoneNumber"
                placeholder="Insert Phone Number"
                rules={rulesOfFields?.phoneNumber}
                error={errors?.phoneNumber}
                limit={LIMIT_CHARACTERS.input}
                control={control}
              />
            </Col>
          </Row>
          <Input
            type="textarea"
            label="Justification"
            name="justification"
            placeholder="Please insert the justification."
            rules={rulesOfFields?.justification}
            error={errors?.justification}
            limit={LIMIT_CHARACTERS.textarea}
            control={control}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="custom-cancel" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button variant="custom-primary" type="submit" disabled={isDisabled}>
            Send Request
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

RequestAccessModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onGetBlacklistLatestRequest: PropTypes.func,
  pageTitle: PropTypes.string,
  modalTitle: PropTypes.string,
};

RequestAccessModal.defaultProps = {
  show: false,
  onHide() {},
  onGetBlacklistLatestRequest() {},
  pageTitle: 'DATA+',
  modalTitle: 'Request Access',
};

export default memo(RequestAccessModal);
