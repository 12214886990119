const getIsShowJAIFeature = startDate => {
  const currentDate = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'Asia/Kuala_Lumpur' }),
  );

  return currentDate.getTime() >= new Date(startDate).getTime();
};

const useVA = () => {
  const isShowJAIVA = getIsShowJAIFeature('07/26/2023 22:00:00');

  return { isShowJAIVA };
};

export default useVA;
