import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useBlockLayout } from 'react-table';
import { FixedSizeList } from 'react-window';

import './styles.scss';

const TableTemplate = ({ columns, data, isViewMode }) => {
  const {
    rows,
    headerGroups,
    totalColumnsWidth,
    prepareRow,
    getTableProps,
    getTableBodyProps,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
  );

  const renderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <div {...row.getRowProps({ style })} className="tr">
          {row.cells.map(cell => (
            <div
              {...cell.getCellProps({
                id: `cell-${cell.column.id}-${row.original.id}`,
              })}
              className="td"
            >
              {cell.render('Cell')}
            </div>
          ))}
        </div>
      );
    },
    [prepareRow, rows],
  );

  return (
    <div
      className={`keyword-skillset-template-table ${
        isViewMode && 'keyword-skillset-template-table--view-mode'
      }`}
    >
      <div {...getTableProps()}>
        <div className="table-header">
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map(column => (
                <div {...column.getHeaderProps()} className="th">
                  <span>{column.render('Header')}</span>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="table-body" {...getTableBodyProps()}>
          {isViewMode ? (
            rows.map((_, index) => renderRow({ index, style: {} }))
          ) : (
            <FixedSizeList
              height={478}
              itemCount={rows.length}
              itemSize={57}
              width={
                totalColumnsWidth + 18 // Scrollbar Width
              }
              style={{ willChange: 'unset !important' }}
            >
              {renderRow}
            </FixedSizeList>
          )}
        </div>
      </div>
    </div>
  );
};

TableTemplate.propTypes = {
  isViewMode: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
};

TableTemplate.defaultProps = {
  isViewMode: false,
  columns: [],
  data: [],
};

export default TableTemplate;
