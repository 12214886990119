import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { omit } from 'lodash';
import { BiTrash } from 'react-icons/bi';

import { ANALYTICS_MARKETPLACE } from 'constants/breakcrumb';
import { KEYWORD_SKILLSET_TEMPLATE } from 'features/AM/constants/analyticsMarketplace';

import { uid } from 'services/uid';
import { convertJsonToExcelFileFormat } from 'services/download';

import SampleZoomOut from 'assets/icons/common/sample-data-zoom-out.svg';
import { ReactComponent as AddIcon } from 'assets/icons/common/add-action.svg';

import useDevice from 'hooks/useDevice';

import Tooltip from 'components/layout/Tooltip';
import ModalTitle from 'components/core/ModalTitle';
import Button from 'components/core/Button';
import AMImageZoom from 'components/core/AMImageZoom';
import IntroductionPopup from './IntroductionPopup';
import TableCells from './TableCells';
import TableTemplate from './TableTemplate';

import './styles.scss';

const checkClearAll = rows =>
  []
    .concat(...rows)
    .every(row =>
      Object.values({ ...row, headerIndex: '', id: '', action: '' }).every(
        x => x === '',
      ),
    );

const checkAllowSubmit = rows => {
  const tableRows = rows.map(item =>
    Object.entries(item).reduce(
      (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
      {},
    ),
  );

  const keys = Object.keys(
    Object.assign(
      {},
      ...tableRows.map(item => omit(item, ['id', 'headerIndex', 'action'])),
    ),
  );
  const headerKeys = Object.keys(
    omit(tableRows[0], ['id', 'headerIndex', 'action']),
  );
  const rowKeys = Object.keys(
    Object.assign(
      {},
      ...tableRows
        .slice(1)
        .map(item => omit(item, ['id', 'headerIndex', 'action'])),
    ),
  );
  const headerExist = keys.every(key => headerKeys.includes(key));
  const rowsExist = headerKeys.every(key => rowKeys.includes(key));
  if (keys.length > 0) {
    return !(headerExist && rowsExist);
  }
  return checkClearAll(tableRows);
};
const renderColumns = ({
  data,
  editable,
  updatedRows,
  setUpdatedRows,
  customWidth,
  onAddRow,
  onRemoveRow,
}) =>
  data.columns.map(col => {
    const isHeaderIndexColumn = col.accessor === 'headerIndex';
    const isActionColumn = col.accessor === 'action';
    const colWidth = (() => {
      if (isHeaderIndexColumn) {
        return 58;
      }
      if (isActionColumn) {
        return 74;
      }
      return customWidth;
    })();

    return {
      width: colWidth,
      ...col,
      Cell: cellProps => {
        if (isHeaderIndexColumn) {
          return cellProps.value && editable ? (
            <span>{cellProps.value}</span>
          ) : (
            <span>{cellProps?.row?.index + 1}</span>
          );
        }
        if (isActionColumn) {
          return cellProps.value ? (
            <span>{cellProps.value}</span>
          ) : (
            <div className={`action-column ${!editable && 'disabled'}`}>
              <AddIcon onClick={() => onAddRow(cellProps?.row?.index)} />
              <BiTrash
                onClick={() => onRemoveRow(cellProps?.row?.index)}
                color="#e82121"
                fontSize={18}
              />
            </div>
          );
        }
        return (
          <TableCells
            isEditAble={editable}
            cellProps={{
              column: cellProps.column,
              row: cellProps.row,
              value: cellProps.value,
            }}
            updatedDataRows={updatedRows}
            setUpdatedDataRows={setUpdatedRows}
          />
        );
      },
    };
  });

const renderTable = ({ rows, columns, isEditAble }) => (
  <TableTemplate columns={columns} data={rows} isViewMode={!isEditAble} />
);

const KeywordSkillSetTemplateModal = ({
  data,
  title,
  isShowModal,
  isEditAble,
  onClose,
  onSubmit,
  customWidth,
}) => {
  const [updatedDataRows, setUpdatedDataRows] = useState([]);
  const [defaultRows, setDefaultRows] = useState(
    data.rows.map(e => ({
      id: uid(),
      ...e,
    })),
  );

  const [showPopup, setShowPopup] = useState(false);

  const { isMobileDevice } = useDevice();

  if (isShowModal) {
    setTimeout(() => setShowPopup(true), 500);
  }

  const getRows = updatedRow => {
    if (updatedRow.length > 0) {
      const newUpdatedRows = [...defaultRows];

      updatedRow.forEach(dataRow => {
        const index = newUpdatedRows.findIndex(e => e.id === dataRow.id);
        newUpdatedRows[index] = dataRow;
      });

      return newUpdatedRows;
    }

    return defaultRows;
  };

  const onAddRow = useCallback(
    index => {
      const cloneArray = [...defaultRows];
      cloneArray.splice(index, 0, { id: uid() });
      setDefaultRows(cloneArray);
    },
    [defaultRows, updatedDataRows],
  );

  const onRemoveRow = useCallback(
    index => {
      const cloneArray = [...defaultRows];
      cloneArray.splice(index, 1);
      setDefaultRows(cloneArray);
    },
    [defaultRows, updatedDataRows],
  );

  const rows = useMemo(
    () => getRows(updatedDataRows),
    [updatedDataRows, defaultRows],
  );

  const allowSubmit = useMemo(() => checkAllowSubmit(rows), [rows]);

  const columns = useMemo(
    () =>
      renderColumns({
        data,
        editable: isEditAble,
        updatedRows: updatedDataRows,
        setUpdatedRows: setUpdatedDataRows,
        customWidth,
        onAddRow,
        onRemoveRow,
      }),
    [defaultRows, setUpdatedDataRows, onAddRow, onRemoveRow, customWidth],
  );

  const table = useMemo(
    () => renderTable({ rows, columns, isEditAble }),
    [rows, columns, isEditAble],
  );

  return (
    <Modal
      centered
      show={isShowModal}
      onHide={onClose}
      className="edh-am-keyword-skillset-template-modal"
      id="edh-am-keyword-skillset-template-modal"
    >
      <Modal.Header closeButton>
        <ModalTitle
          category={isEditAble ? ANALYTICS_MARKETPLACE.label : title}
          title={isEditAble ? KEYWORD_SKILLSET_TEMPLATE : ''}
        />
      </Modal.Header>
      {isEditAble ? (
        <>
          <Modal.Body>{table}</Modal.Body>
          {allowSubmit && isMobileDevice && (
            <div className="requirement-note">
              Please fill in minimum 1 header and 1 row of content to proceed.
            </div>
          )}
          <Modal.Footer>
            <Button
              id="clear-all-introduce-step-3"
              variant="outlined"
              onClick={() => {
                setUpdatedDataRows(
                  defaultRows.map(item => ({
                    id: item.id,
                    action: item.action,
                    headerIndex: item.headerIndex,
                  })),
                );
                setDefaultRows(
                  defaultRows.map(item => ({
                    id: item.id,
                    action: item.action,
                    headerIndex: item.headerIndex,
                  })),
                );
              }}
            >
              Clear All
            </Button>

            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>

            <div style={{ position: 'relative' }}>
              {allowSubmit && !isMobileDevice && (
                <Tooltip
                  content="Please fill in minimum 1 header and 1 row of content to proceed."
                  id={`${Date.now()}-${'skillset-template-submit-button'}`}
                  placement="top"
                >
                  <div
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      cursor: 'not-allowed',
                    }}
                  />
                </Tooltip>
              )}
              <Button
                variant="contained"
                type="submit"
                className="edh-am-keyword-skillset-template-modal__submit-button"
                disabled={allowSubmit}
                onClick={() => {
                  const jsonData = [...rows].map(
                    ({ id, headerIndex, action, ...keepRestAttrs }) =>
                      keepRestAttrs,
                  );
                  onSubmit(
                    convertJsonToExcelFileFormat({
                      jsonData,
                      fileName: 'keywords',
                      exportOpts: { skipHeader: 1 },
                    }),
                  );
                  onClose();
                }}
              >
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </>
      ) : (
        <Modal.Body
          style={{
            overflow: 'hidden',
            height: isMobileDevice ? '485px' : 'unset',
          }}
        >
          <AMImageZoom
            center={false}
            scaleOffset={1}
            step={0.5}
            range={[0.5, 6]}
          >
            <div className="template-modal__table-zoom">{table}</div>
          </AMImageZoom>
          <Modal.Footer>
            <button
              type="button"
              className="template-modal__zoom-out"
              onClick={onClose}
            >
              <img alt="Zoom-out" src={SampleZoomOut} />
            </button>
          </Modal.Footer>
        </Modal.Body>
      )}
      {showPopup && isEditAble && <IntroductionPopup />}
    </Modal>
  );
};

KeywordSkillSetTemplateModal.propTypes = {
  customWidth: PropTypes.number,
  title: PropTypes.string,
  data: PropTypes.object,
  isShowModal: PropTypes.bool,
  isEditAble: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

KeywordSkillSetTemplateModal.defaultProps = {
  customWidth: 223,
  title: 'KEYWORD_SKILLSET_SAMPLEFILE.CSV',
  data: {},
  isShowModal: false,
  isEditAble: true,
  onClose() {},
  onSubmit() {},
};

export default memo(KeywordSkillSetTemplateModal);
