import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { dragAction, pinchAction, createUseGesture } from '@use-gesture/react';
import { animated, useSpring } from 'react-spring';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import ZoomInIcon from 'assets/icons/common/zoom-in.svg';
import ZoomOutIcon from 'assets/icons/common/zoom-out.svg';
import ZoomResetIcon from 'assets/icons/common/zoom-reset.svg';

import useDevice from 'hooks/useDevice';
import { useEffectOnceWhen } from 'hooks/useEffectOnceWhen';

import './styles.scss';

const useGesture = createUseGesture([dragAction, pinchAction]);

const AMImageZoom = ({
  children,
  scaleOffset,
  step,
  range,
  setScale = () => {},
  center,
  canvasMode,
}) => {
  const [zoomScale, setZoomScale] = useState(scaleOffset);
  const [offset, setOffset] = useState({ width: 0, height: 0 });
  const innerRef = useRef(null);
  const [minZoom, maxZoom] = range;
  const { isMobileDevice } = useDevice();

  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    rotateZ: 0,
    touchAction: 'none',
  }));

  useLayoutEffect(() => {
    const ref = innerRef.current;
    const observer = new ResizeObserver(
      debounce(item => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(item) || !item.length) {
            return;
          }
          const { width: wrapperWidth, height: wrapperHeight } =
            item[0].target.parentElement.getBoundingClientRect();
          const { width, height } = item[0].target.getBoundingClientRect();

          setOffset({
            width: Math.abs(wrapperWidth - width),
            height: Math.abs(wrapperHeight - height),
          });
        });
      }, 250),
    );

    if (ref && ref.children.length > 0) {
      observer.observe(ref);
    }
    return () => {
      observer.disconnect();
    };
  }, [innerRef, zoomScale, scaleOffset]);

  useEffect(() => {
    const ref = innerRef.current;
    if (ref && !center && !isMobileDevice) {
      const { width: wrapperWidth, height: wrapperHeight } =
        ref.parentElement.getBoundingClientRect();
      const { width, height } = ref.getBoundingClientRect();
      api.start({
        x: Math.abs(wrapperWidth - width) / 2,
        y: Math.abs(wrapperHeight - height) / 2,
        immediate: true,
        scale: zoomScale,
      });
    }
  }, [innerRef.current, zoomScale]);

  useEffectOnceWhen(() => {
    const ref = innerRef.current;
    if (ref && !center && isMobileDevice) {
      const { width: wrapperWidth, height: wrapperHeight } =
        ref.parentElement.getBoundingClientRect();
      const { width, height } = ref.getBoundingClientRect();
      api.start({
        x: Math.abs(wrapperWidth - width) / 2,
        y: Math.abs(wrapperHeight - height) / 2,
        immediate: true,
      });
    }
  }, innerRef.current !== null);

  const onDrag = ({ pinching, cancel, down, offset: [ox, oy] }) => {
    if (pinching) return cancel();
    api.start({ x: ox, y: oy, immediate: down });
  };

  const onPinch = ({
    origin: [ox, oy],
    first,
    cancel,
    movement: [ms],
    offset: [s],
    memo,
  }) => {
    if (!isMobileDevice) {
      return cancel();
    }
    if (first && innerRef.current) {
      const { width, height, x, y } = innerRef.current.getBoundingClientRect();
      const px = (ox || 0) - (x + width / 2);
      const py = (oy || 0) - (y + height / 2);
      // eslint-disable-next-line no-param-reassign
      memo = [style.x.get(), style.y.get(), px, py];
    }
    const x = memo[0] - (ms - 1) * memo[2];
    const y = memo[1] - (ms - 1) * memo[3];
    setZoomScale(s);
    setScale(s);
    api.start({ scale: s, x, y });
    return memo;
  };
  useGesture(
    {
      onDrag,
      onPinch,
    },
    {
      target: innerRef,
      drag: {
        bounds: {
          top: -offset.height / 2,
          bottom: offset.height / 2,
          left: -offset.width / 2,
          right: offset.width / 2,
        },
        rubberband: true,
        preventDefault: true,
        eventOptions: { passive: false },
        from: () => [style.x.get(), style.y.get()],
      },
      pinch: {
        scaleBounds: { min: 1, max: 6 },
        rubberband: true,
        preventDefault: true,
      },
    },
  );

  const handleZoom = percent => {
    let zoomPercent;
    if (maxZoom) {
      zoomPercent = Math.min(Math.max(percent, minZoom), maxZoom);
    } else {
      zoomPercent = Math.max(percent, minZoom);
    }
    setZoomScale(zoomPercent);
    setScale(zoomPercent);
  };

  const handleFitView = () => {
    setZoomScale(1);
    setScale(1);
    if (center) {
      api.start({ x: 0, y: 0, immediate: true });
    } else {
      const ref = innerRef.current;
      const { width: wrapperWidth, height: wrapperHeight } =
        ref.parentElement.getBoundingClientRect();
      const { width, height } = ref.getBoundingClientRect();
      api.start({
        x: Math.abs(wrapperWidth - width) / 2 / zoomScale,
        y: Math.abs(wrapperHeight - height) / 2 / zoomScale,
        immediate: true,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('gesturestart', e => e.preventDefault());
    document.addEventListener('gesturechange', e => e.preventDefault());
  }, []);

  return (
    <div
      className="edh-am-animated-container"
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <animated.div
        ref={innerRef}
        style={{ ...style, scale: canvasMode ? 1 : zoomScale }}
      >
        {children}
      </animated.div>
      <div className="edh-zoombutton">
        <button
          type="button"
          onClick={handleFitView}
          className="edh-zoombutton__item"
        >
          <img
            src={ZoomResetIcon}
            className="edh-zoombutton__reset"
            alt="zoomReset-icon"
          />
        </button>
        <button
          type="button"
          onClick={() => handleZoom(zoomScale - step)}
          className="edh-zoombutton__item"
        >
          <img
            src={ZoomOutIcon}
            className="edh-zoombutton__zoomout"
            alt="zoomOut-icon"
          />
        </button>
        <div className="edh-zoombutton__item">
          <div className="edh-zoombutton__percent">
            {`${Math.round(zoomScale * 100)}%`}
          </div>
        </div>
        <button
          type="button"
          onClick={() => handleZoom(zoomScale + step)}
          className="edh-zoombutton__item"
        >
          <img
            src={ZoomInIcon}
            className="edh-zoombutton__zoom"
            alt="zoomIn-icon"
          />
        </button>
      </div>
    </div>
  );
};

AMImageZoom.propTypes = {
  children: PropTypes.node,
  scaleOffset: PropTypes.number,
  step: PropTypes.number,
  range: PropTypes.array,
  setScale: PropTypes.func,
  canvasMode: PropTypes.bool,
  center: PropTypes.bool,
};

AMImageZoom.defaultProps = {
  scaleOffset: 1,
  center: true,
};

export default AMImageZoom;
