import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const LoadingBalls = ({ backdrop, isLoading, loadingText, className }) => {
  const styleClasses = [
    'edh-loading-balls',
    className || '',
    backdrop && 'edh-loading-balls--backdrop',
  ];

  if (isLoading) {
    return (
      <div className={styleClasses.filter(Boolean).join(' ')}>
        <div className="edh-loading-balls__balls-container">
          <p className="edh-loading-balls__text">{loadingText}</p>
          <div className="edh-loading-balls__ball edh-loading-balls__ball--style-one" />
          <div className="edh-loading-balls__ball edh-loading-balls__ball--style-two" />
          <div className="edh-loading-balls__ball edh-loading-balls__ball--style-three" />
        </div>
      </div>
    );
  }

  return null;
};

LoadingBalls.propTypes = {
  backdrop: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  className: PropTypes.string,
};

LoadingBalls.defaultProps = {
  backdrop: true,
  isLoading: false,
  loadingText: 'Loading',
  className: '',
};

export default memo(LoadingBalls);
