import { isEmpty } from 'lodash';

export const gettingNameInitials = name => {
  if (name) {
    const getUserNameArr = name.split(' (');

    if (!getUserNameArr.length) return '';

    const newName = getUserNameArr[0].replace(/[^a-zA-Z ]/g, '');

    const nameArr = newName ? newName.split(' ') : '';
    const len = nameArr.length;

    if (!len) {
      return '';
    }

    if (len === 1) {
      return nameArr[0][0].toUpperCase();
    }

    return [nameArr[0][0], nameArr[nameArr.length - 1][0]]
      .join('')
      .toUpperCase();
  }
  return null;
};

export const trimText = (limit, text = '') => {
  const updatedDate =
    text.length > limit ? `${text.substring(0, limit)}...` : text;

  return updatedDate;
};

export const utf8ToBase64 = str => {
  if (str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }
};

export const base64ToUtf8 = str => {
  if (str) {
    return decodeURIComponent(escape(window.atob(str)));
  }
};

export const trimSpace = str => {
  if (!str) {
    return str;
  }
  return str.trim().replace(/  +/g, ' ');
};

export const validateEmail = email => {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexEmail.test(email);
};

export const convertStringInArrayToLowerCase = array =>
  array?.map(ele => ele.toLowerCase());

export const oneLineCsvToArray = line => {
  if (!line) return [];
  let stop = true;
  let item = '';
  const row = [];
  for (let i = 0; i < line.length; i++) {
    if (line[i] === '"') {
      if (stop && line[i] === line[i - 1]) item += line[i];
      stop = !stop;
    } else if (line[i] === ',' && stop) {
      row.push(item);
      item = '';
    } else item += line[i];
  }
  row.push(item);
  return row;
};

export const convertCsvToJson = parsedCsv => {
  if (!parsedCsv || isEmpty(parsedCsv)) return { headers: [], data: [] };
  const lines = parsedCsv.split(/\r?\n/);
  const headers = oneLineCsvToArray(lines[0]);
  const keys = headers.map(item => ({
    key: item.replace(/\s/g, '').toLowerCase() || `_${item}`,
    header: item,
    length: item?.length || 0,
  }));
  const jsonResult = [];
  for (let i = 1; i < lines.length; i++) {
    const currentLine = oneLineCsvToArray(lines[i]);
    if (currentLine?.length > 0) {
      const jsonObject = {};
      for (let j = 0; j < keys.length; j++) {
        jsonObject[keys[j].key] = currentLine[j] || j;
        if (currentLine[j]?.length > keys[j].length) {
          keys[j].length = currentLine[j].length;
        }
      }
      jsonObject.id = Date.now() + i;
      jsonResult.push(jsonObject);
    }
  }
  return { headers: keys, data: jsonResult };
};

const removeHTMLtag = htmlContent => htmlContent.replace(/<[^>]*>/g, '');

const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

const urlFindingWithListItem = list => {
  const paragraphsList = list.map(l => {
    const paragraphsItemArray = l.split(/<\/?li>/).filter(Boolean);
    const paragraphsItem = paragraphsItemArray.map(i =>
      i.replace(urlRegex, (url, _, c) => {
        const href = c === 'www.' ? `http://${url}` : url;
        return `<a style="color: #0b69ff; text-decoration: underline;" className="edh-url" href="${removeHTMLtag(
          href,
        )}" target="_blank">${removeHTMLtag(url)}</a>`;
      }),
    );

    return `<li>${paragraphsItem.join('</li><li>')}</li>`;
  });
  return paragraphsList;
};

export const urlFinding = text => {
  if (!text) {
    return text;
  }

  const paragraphs = text.match(/<p>.*?<\/p>|<ol>.*?<\/ol>|<ul>.*?<\/ul>/gs);

  if (!paragraphs?.length) {
    return text;
  }

  const processedParagraphs = paragraphs.map(paragraph => {
    if (paragraph.includes('ol>')) {
      const paragraphsListArray = paragraph.split(/<\/?ol>/).filter(Boolean);
      return `<ol>${urlFindingWithListItem(paragraphsListArray).join(
        '</ol><ol>',
      )}</ol>`;
    }
    if (paragraph.includes('ul>')) {
      const paragraphsListArray = paragraph.split(/<\/?ul>/).filter(Boolean);
      return `<ul>${urlFindingWithListItem(paragraphsListArray).join(
        '</ul><ul>',
      )}</ul>`;
    }
    return paragraph.replace(urlRegex, (url, _, c) => {
      const href = c === 'www.' ? `http://${url}` : url;
      return `<a style="color: #0b69ff; text-decoration: underline;" className="edh-url" href="${removeHTMLtag(
        href,
      )}" target="_blank">${removeHTMLtag(url)}</a>`;
    });
  });

  return `${processedParagraphs.join('<p>')}</p>`;
};

export const capitalizeFirstLetter = str =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getMainPath = str => str.match(/^\/[^/]*/g)[0];
