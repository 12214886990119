export const SEARCH_BAR_PLACEHOLDER =
  'Search by Use Cases name; e.g. Business Use Cases One';
export const OVERVIEW_TITLE =
  'Have you ever wondered on how to make sense of data that are in front of you?';
export const OVERVIEW_DESCRIPTION = `Insights Scoop is a place that gives data citizens a taste of how to combine different datasets and transform them into something of value. 
<br>Let's start to explore this feature that could spark ideas on how to generate bright insights!`;
export const SWITCH_OPTIONS = [
  {
    key: 'list',
    value: 'list',
  },
  {
    key: 'solid',
    value: 'solid',
  },
];
export const MODULE_FILTER_TAG = [
  {
    key: 'dataMarketplace',
    name: 'Data Marketplace',
  },
  {
    key: 'analyticsMarketplace',
    name: 'Analytics Marketplace',
  },
];
export const MORE_FILTER = [
  {
    key: 'businessDomain',
    name: 'Business Domain',
    data: [
      { key: 'downstream', name: 'Downstream' },
      { key: 'Gas & New Energy', name: 'Gas & New Energy' },
      { key: 'groupFinance', name: 'Group Finance' },
      { key: 'groupHSSE', name: 'Group HSSE' },
      { key: 'upstream', name: 'Upstream' },
    ],
  },
  {
    key: 'dataset',
    name: 'Dataset',
    data: [],
  },
  {
    key: 'structuredSources',
    name: 'structured sources',
    data: [],
  },
  {
    key: 'roles',
    name: 'Roles',
    data: [],
  },
];
export const DEFAULT_SORT = {
  sort: null,
  title: 'Sort by',
};

export const DOWNLOADED_COUNT = 'downloaded-count';
export const WATCHED_COUNT = 'watched-count';
export const MR_SEARCH_BAR_PLACEHOLDER = 'Search';
export const MR_OVERVIEW_TITLE = `What's in it for you`;
