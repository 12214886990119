export const TYPES = [
  {
    label: 'Sample Data',
    value: 'current-sample',
  },
  {
    label: 'All Data',
    value: 'all-data',
  },
];

export const DATASET_DOWNLOAD_ACTIONS = [
  {
    label: 'API',
    value: 'api',
    disabled: true,
  },
  {
    label: 'Download',
    value: 'download',
  },
];

export const DATASET_DATA_RANGE_TYPES = [
  {
    title: 'Date',
    accessor: 'date',
  },
  {
    title: 'Record Size',
    accessor: 'recordSize',
  },
];

export const DATASET_DATA_RANGE_TIME_PERIOD = [
  {
    title: 'Last 1 month',
    accessor: 1,
  },
  {
    title: 'Last 6 months',
    accessor: 6,
  },
  {
    title: 'Last 1 year',
    accessor: 12,
  },
  {
    title: 'Last 3 years',
    accessor: 36,
  },
  {
    title: 'Last 5 years',
    accessor: 60,
  },
  {
    title: 'Custom',
    accessor: 'Custom',
  },
];

export const PI_TAG_DATA_RANGE_TIME_PERIOD = [
  {
    title: 'Last 1 month',
    accessor: 1,
  },
  {
    title: 'Last 2 month',
    accessor: 2,
  },
  {
    title: 'Last 3 month',
    accessor: 3,
  },
  {
    title: 'Custom',
    accessor: 'Custom',
  },
];

export const DATASET_DATA_RANGE_RECORD_PERIOD = [
  {
    title: 'Last 1K Records',
    accessor: 1000,
  },
  {
    title: 'Last 10K Records',
    accessor: 10000,
  },
  {
    title: 'Last 100K Records',
    accessor: 100000,
  },
  {
    title: 'Last 1M Records',
    accessor: 1000000,
  },
];

export const CUSTOM_COLUMN_TYPE = [
  {
    label: 'All Column',
    value: 'all-column',
  },
  {
    label: 'Custom Column',
    value: 'custom-column',
  },
];

export const CUSTOM_RECORD_TYPE = [
  {
    label: 'All Record',
    value: 'all-record',
  },
  {
    label: 'Custom Record',
    value: 'custom-record',
  },
];

export const FILE_TYPE = [
  {
    label: 'Local XLSX file',
    value: 'XLSX',
  },
  {
    label: 'Local CSV file',
    value: 'CSV',
  },
];

export const DQ_EXPORT_DATA_FILE_TYPE = [
  {
    label: 'CSV file',
    value: 'CSV',
  },
  // {
  //   label: 'XLSX file',
  //   value: 'XLSX',
  // },
];

export const DQ_EXPORT_DATA_MESSAGE = [
  {
    type: 'data-quality',
    titleToast: 'Preparation for Export Successful',
    description: 'Your Data Quality is now ready to be downloaded.',
    buttonText: 'Go to download',
  },
  {
    type: 'bad-data',
    titleToast: 'Preparation for Export Successful',
    description: 'Your Bad Data is now ready to be downloaded.',
    buttonText: 'Go to download',
  },
];
