import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { msalConfig } from 'msalConfig';
import ga from 'services/ga';
import elastic from 'services/elastic-search';

import configureAppStore from './store/configureAppStore';

import App from './app';

ga.initialize();
elastic.initialize();

const store = configureAppStore();

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </MsalProvider>,
  document.getElementById('root'),
);

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then()
        .catch(registrationError => {
          // eslint-disable-next-line no-console
          console.log('SW registration failed: ', registrationError);
        });
    });
  }
}
