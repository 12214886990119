import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import BModal from 'react-bootstrap/Modal';

import './styles.scss';

const keyModalProps = ['centered', 'show', 'backdrop', 'onHide'];

const getZIndex = el => {
  const zIndex = document.defaultView
    .getComputedStyle(el, null)
    .getPropertyValue('z-index');

  return zIndex;
};

const Modal = ({ children, modalClassName, dialogClassName, ...rest }) => {
  const modalProps = keyModalProps.reduce((previousValue, currentValue) => {
    if (rest[currentValue]) {
      previousValue[currentValue] = rest[currentValue];
    }
    return previousValue;
  }, {});

  const classes = {
    className: ['edh-modal'],
    dialogClassName: ['edh-modal__dialog'],
  };

  if (modalClassName) {
    classes.className.push(modalClassName);
  }

  if (dialogClassName) {
    classes.dialogClassName.push(dialogClassName);
  }

  useLayoutEffect(() => {
    if (!modalProps.show) return;

    const modalElements = document.querySelectorAll('.modal');
    const backdropElements = document.querySelectorAll('.modal-backdrop');
    const backdropCurrentElement =
      backdropElements[backdropElements.length - 1];
    const modalCurrentElement = modalElements[modalElements.length - 1];

    if (modalElements.length < 2 || backdropElements.zIndex < 2) return;

    let zIndex = 0;

    for (let i = 0; i < modalElements.length - 1; i++) {
      const modalZIndex = getZIndex(modalElements[i]);
      const backdropZIndex = getZIndex(backdropElements[i]);

      if (modalZIndex > zIndex) {
        zIndex = modalZIndex;
      }

      if (backdropZIndex > zIndex) {
        zIndex = modalZIndex;
      }
    }

    backdropCurrentElement.style.zIndex = zIndex + 1;
    modalCurrentElement.style.zIndex = zIndex + 1;
  }, [modalProps.show]);

  return (
    <BModal
      className={classes.className.join(' ')}
      dialogClassName={classes.dialogClassName.join(' ')}
      {...modalProps}
    >
      {children}
    </BModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  modalClassName: PropTypes.string,
  dialogClassName: PropTypes.string,
};

export default Modal;
