import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { base64ToUtf8 } from 'services/string';

import { DATASET_SEARCH_TYPE } from 'constants/datasetTypes';
import { MY_WORKSPACE } from 'constants/breakcrumb';
import { LOADING_STATUS, METHOD } from 'constants/apis';
import { HISTORY_PAGE_INCREASE_DOWNLOAD } from 'constants/paths';

import useAPI from './useAPI';

import {
  datasetSelector,
  suggestedKeywordSelector,
  myDatasetsEntitlementParamSelector,
  getSuggestedKeywordRequest,
  getDatasetRequest,
  datasetsSelectorByProp,
  getDatasetsRequest,
  updateDatasets,
  myDatasetsRecentlySelector,
  getMyDatasetsRecentlyRequest,
  favouriteMyDatasetRecently,
  myDatasetsEntitlementSelector,
  getMyDatasetsEntitlementRequest,
  getSampleHederRequest,
  sampleHederSelector,
  suggestedKeywordModularDatasetsSelector,
  getSuggestedKeywordModularDatasetsRequest,
  putDatasetMyWorkspaceRequest,
  getDatasetSuccess,
  datasetExportValuesByColumnSelector,
  getDatasetExportValuesByColumnRequest,
  datasetExportColumnsSelector,
  postDatasetExportColumnsRequest,
} from '../slices/dataset';

const PARAMS_DATASET_MY_WORKSPACE = {
  keywords: '',
  type: 'dataset',
  searchDataSetTypes: DATASET_SEARCH_TYPE.internalDatasetfavourite,
};

const useDataset = prop => {
  const suggestedKeyword = useSelector(suggestedKeywordSelector);
  const suggestedKeywordModularDatasets = useSelector(
    suggestedKeywordModularDatasetsSelector,
  );
  const dataset = useSelector(datasetSelector);
  const datasets = useSelector(datasetsSelectorByProp(prop));
  const datasetExportValuesColumn = useSelector(
    datasetExportValuesByColumnSelector,
  );
  const datasetColumns = useSelector(datasetExportColumnsSelector);
  const myDatasetsRecently = useSelector(myDatasetsRecentlySelector);
  const myDatasetsEntitlement = useSelector(myDatasetsEntitlementSelector);
  const myEntitledDataParam = useSelector(myDatasetsEntitlementParamSelector);
  const sampleHeader = useSelector(sampleHederSelector);
  const dispatch = useDispatch();

  const history = useHistory();

  const { onCallAPI: getIncreaseDownloadAllDataOption } = useAPI();

  const onIncreaseDownloadAllDataOption = useCallback(
    id =>
      getIncreaseDownloadAllDataOption({
        method: METHOD.PUT,
        url: `${HISTORY_PAGE_INCREASE_DOWNLOAD.replace('#id', id)}`,
      }),
    [],
  );

  const onGetSuggestedKeyword = useCallback(params => {
    dispatch(
      getSuggestedKeywordRequest({
        keyword: window.btoa(params || 'a'),
      }),
    );
  }, []);

  const onGetSuggestedKeywordModularDatasets = useCallback(params => {
    dispatch(
      getSuggestedKeywordModularDatasetsRequest({
        keyword: window.btoa(params || 'a'),
      }),
    );
  }, []);

  const onGetDataset = useCallback(params => {
    dispatch(getDatasetRequest(params));
  }, []);

  const onResetDataset = useCallback(() => {
    dispatch(
      getDatasetSuccess({
        data: {},
        status: LOADING_STATUS.IDE,
      }),
    );
  }, []);

  const onGetDatasets = useCallback(
    ({ params, isLoadMore, hasLoading = true }) => {
      if (params) {
        dispatch(
          getDatasetsRequest({
            prop,
            formData: params,
            isLoadMore,
            hasLoading,
          }),
        );
      }
      if (!params && history.location.pathname === MY_WORKSPACE.id) {
        const newParams = {};
        for (const [key, value] of new URLSearchParams(
          history.location.search,
        )) {
          newParams[key] = value;
        }
        const newPagination = newParams?.pagination
          ? JSON.parse(base64ToUtf8(newParams?.pagination))
          : { pageIndex: 1, pageSize: 10 };

        const newKeyWord = newParams?.keyword || '';
        dispatch(
          getDatasetsRequest({
            prop,
            formData: {
              ...PARAMS_DATASET_MY_WORKSPACE,
              ...newPagination,
              keywords: newKeyWord,
            },
            isLoadMore,
            hasLoading,
          }),
        );
      }
    },
    [prop, history.location],
  );

  const onUpdateDatasets = useCallback(
    data => {
      dispatch(updateDatasets({ prop, data }));
    },
    [prop],
  );

  const onGetMyDatasetsRecently = useCallback(params => {
    dispatch(getMyDatasetsRecentlyRequest(params));
  }, []);

  const onFavouriteMyDatasetsRecently = useCallback(id => {
    dispatch(favouriteMyDatasetRecently({ id }));
  }, []);

  const onGetMyDatasetsEntitlement = useCallback(params => {
    dispatch(getMyDatasetsEntitlementRequest(params));
  }, []);

  const onGetSampleHeder = useCallback(params => {
    dispatch(getSampleHederRequest(params));
  }, []);

  const onPutDatasetMyWorkspace = useCallback(({ workspaceItem, callback }) => {
    dispatch(putDatasetMyWorkspaceRequest({ workspaceItem, callback }));
  }, []);

  const onGetDatasetExportValuesColumn = useCallback(params => {
    dispatch(getDatasetExportValuesByColumnRequest(params));
  }, []);

  const onPostDatasetExportColumns = useCallback(params => {
    dispatch(postDatasetExportColumnsRequest(params));
  }, []);
  
  return {
    dataset,
    suggestedKeyword,
    suggestedKeywordModularDatasets,
    datasets,
    myDatasetsRecently,
    myDatasetsEntitlement,
    myEntitledDataParam,
    sampleHeader,
    datasetExportValuesColumn,
    datasetColumns,
    onGetDataset,
    onResetDataset,
    onGetSuggestedKeyword,
    onGetSuggestedKeywordModularDatasets,
    onGetDatasets,
    onUpdateDatasets,
    onGetMyDatasetsRecently,
    onFavouriteMyDatasetsRecently,
    onGetMyDatasetsEntitlement,
    onGetSampleHeder,
    onPutDatasetMyWorkspace,
    onIncreaseDownloadAllDataOption,
    onGetDatasetExportValuesColumn,
    onPostDatasetExportColumns,
  };
};

export default useDataset;
