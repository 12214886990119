import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ForbiddenHeaderLogo } from 'assets/icons/logo/forbidden-header-logo.svg';
import { ReactComponent as AccessDeniedImage } from 'assets/images/common/access-denied.svg';

import BButton from 'react-bootstrap/Button';

import { BLACKLIST_LATEST_REQUEST } from 'constants/paths';
import { FORBIDDEN_STATUS } from 'constants/forbidden';
import { METHOD } from 'constants/apis';

import useAuth from 'hooks/useAuth';
import useAPI from 'hooks/useAPI';

import RequestAccessModal from 'pages/ForBiddenPage/RequestAccessModal';
import ForbiddenAlert from './ForbiddenAlert';

import './styles.scss';

const ErrorForbiddenMessage = ({ message, description }) => {
  const [showRequestAccessModal, setShowRequestAccessModal] = useState(false);

  const { data: blacklistLatestRequest, onCallAPI: getBlacklistLatestRequest } =
    useAPI();
  const { onLogout } = useAuth();
  const latestRequestStatus = blacklistLatestRequest?.data?.status;

  const onHandleClickRequestAccess = useCallback(() => {
    setShowRequestAccessModal(prev => !prev);
  }, []);

  const onGetBlacklistLatestRequest = useCallback(() => {
    getBlacklistLatestRequest({
      method: METHOD.GET,
      url: BLACKLIST_LATEST_REQUEST,
    });
  }, []);

  useEffect(() => {
    onGetBlacklistLatestRequest();
  }, []);

  return (
    <div className="edh-error-forbidden-page">
      <div className="edh-error-forbidden-page__header">
        <ForbiddenHeaderLogo />
        <BButton
          variant="custom-primary"
          className="edh-error-forbidden-page__header-logout-btn"
          onClick={onLogout}
        >
          Logout
        </BButton>
      </div>
      <div className="edh-error-forbidden-page__content">
        {latestRequestStatus === FORBIDDEN_STATUS.REJECTED && (
          <div className="edh-error-forbidden-page__content__alert">
            <ForbiddenAlert rejectionData={blacklistLatestRequest.data} />
          </div>
        )}
        <div className="edh-error-forbidden-page__content--image">
          <AccessDeniedImage />
        </div>
        <div className="edh-error-forbidden-page__content--message">
          {message}
        </div>
        <div className="edh-error-forbidden-page__content--description">
          {description}
        </div>
        {latestRequestStatus === FORBIDDEN_STATUS.UNSUBMITTED && (
          <BButton
            variant="custom-primary"
            onClick={onHandleClickRequestAccess}
          >
            Request Access
          </BButton>
        )}
      </div>
      <RequestAccessModal
        show={showRequestAccessModal}
        onHide={setShowRequestAccessModal}
        onGetBlacklistLatestRequest={onGetBlacklistLatestRequest}
      />
    </div>
  );
};

ErrorForbiddenMessage.propTypes = {
  message: PropTypes.string,
  description: PropTypes.string,
};

export default memo(ErrorForbiddenMessage);
