import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import useUser from 'hooks/useUser';
import useAuth from 'hooks/useAuth';

import './styles.scss';

const PrivacyConsentPopup = ({ show, onHide }) => {
  const { onLogout } = useAuth();

  const { onViewPrivacyRequest } = useUser();

  const handleAccept = () => {
    onHide();
    onViewPrivacyRequest();
  };

  const handleReject = () => {
    onLogout();
  };

  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      dialogClassName="edh-privacy-consent-popup"
    >
      <div className="edh-privacy-consent-popup__content">
        <div className="edh-privacy-consent-popup__title">Welcome to Data+</div>
        <div className="edh-privacy-consent-popup__text">
          <p>
            PETRONAS is fully committed in handling data ethically, securely and
            comply with all legislation and legal regulations on data usage,
            storage and processing. This involves ensuring that all appropriate
            safeguards are in place to preserve data confidentiality, integrity
            and availability.
          </p>
          <p>
            By clicking “Accept”, you consent to the PETRONAS Data Ethics
            guideline.
          </p>
          <p>
            {'Read our '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://mypetronas.com/group-digital/ed-data-policy-governance"
              style={{ color: '#005CB9', textDecoration: 'underline' }}
            >
              Data Ethics Framework
            </a>
            {' for more information.'}
          </p>
        </div>
        <div className="edh-privacy-consent-popup__button">
          <Button variant="custom-primary" onClick={handleAccept}>
            Accept
          </Button>
          <Button variant="custom-primary" onClick={handleReject}>
            Reject
          </Button>
        </div>
      </div>
    </Modal>
  );
};

PrivacyConsentPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default memo(PrivacyConsentPopup);
