import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { ReactComponent as BookmarkIcon } from 'assets/icons/common/bookmark.svg';
import { ReactComponent as LikeIcon } from 'assets/icons/common/like.svg';

import useDevice from 'hooks/useDevice';
import useMultiClamp from 'hooks/useMultiClamp';
import useUser from 'hooks/useUser';
import useAPI from 'hooks/useAPI';

import { graphConfig } from 'msalConfig';
import { numberToUnitSuffix } from 'services/number';
import { uid } from 'services/uid';

import Avatar from 'components/core/Avatar';
import RichTextClamp from 'components/core/RichTextClamp';
import AMTagLists from 'components/layout/AMTagLists';
import CommentInput from 'components/layout/CommentInput';
import RatingStar from 'components/layout/RatingStar';
import Tooltip from 'components/layout/Tooltip';

import { METHOD } from 'constants/apis';
import { SHORT_DATE_FORMAT_3 } from 'constants/dateTime';
import { ANALYTICS_MARKETPLACE_DO_ACTION_ON_USER_REVIEW } from 'constants/paths';

import CommentCard from './CommentCard';
import TooltipHover from '../TooltipHover';
import useCommentData from './useCommentData';

import './styles.scss';

const mapType = {
  liked: 0,
  saved: 1,
};

const UserReviewCard = ({
  id,
  type,
  position,
  updateTime,
  title,
  description,
  children,
  ratingScore,
  likedCount,
  savedCount,
  liked,
  saved,
  isReply,
  showTag,
  mentionId = '',
  onFooterAction,
  onSubmit: onSubmitComment,
  onDelete,
  fullName,
  avatarImg,
}) => {
  const [isLiked, setIsLiked] = useState(liked);
  const [isSaved, setIsSaved] = useState(saved);
  const [replyVisible, setReplyVisible] = useState(false);
  const [autofocus, setAutofocus] = useState(false);
  const [commentVisible, setCommentVisible] = useState([]);
  const commentRef = useRef([]);
  const {
    listData,
    hasNewer,
    hasOlder,
    onChangeStatus,
    onAddNewComment,
    onDeleteComment,
  } = useCommentData({
    data: children,
    mentionId,
    status: 'mention',
  });
  const { isMobileDevice } = useDevice();
  const { onCallAPI: onDoActionUserReview } = useAPI();

  const {
    userInfo: {
      data: { fullName: userFullName, userName, id: userId, email },
    },
    isAMAdmin,
  } = useUser();

  const replyListId = `${id} - ${Date.now()}`;
  const subTitle = 'comment';
  const hideReplyTitle = 'Hide Answer';
  const replyMargin = isMobileDevice ? 25 : 45;
  const inquiryType = {
    text: [type],
    color: 'FFFFFF',
    background: '000000',
  };

  const inputAvatarImg = useMemo(
    () => (email ? graphConfig.getGraphUserPhotoEndpoint(email) : ''),
    [email, userId],
  );

  useMultiClamp({ id, text: description, clamp: isMobileDevice ? 4 : 2 });

  const onClickAction = ({ type: typeAction }) => {
    onDoActionUserReview({
      method: METHOD.POST,
      url: ANALYTICS_MARKETPLACE_DO_ACTION_ON_USER_REVIEW,
      data: { UserReviewId: id, type: mapType[typeAction] },
      callback() {
        onFooterAction();
      },
    });
  };

  const onSubmit = (value, parentId) => {
    onAddNewComment({
      id: uid(),
      parentId,
      userId,
      userName,
      fullName: userFullName,
      description: value,
      children: [],
      isPosting: true,
      isAMAdmin,
    });
    onSubmitComment(value, parentId);
  };

  const onClickDelete = value => {
    onDeleteComment(value);
    onDelete(value);
  };

  const renderReplyCard = (item, index, cmtBoxId) => {
    const level = index;
    return (
      <div
        key={item.id}
        id={item.id}
        className={`item__thread__container ${level > 1 && 'nested'}`}
      >
        <CommentCard
          {...item}
          index={index}
          isComment={level < 2}
          isDelete={item?.hasPermission}
          onReply={() => {
            commentRef.current[cmtBoxId].toggleBlur();
            if (commentVisible?.[item.id]) {
              setCommentVisible({});
            } else {
              setCommentVisible({ [item.id]: true });
            }
          }}
          isClamp={mentionId !== item?.id}
          onDelete={onClickDelete}
        />
        {level < 2 && item?.children.length > 0 && (
          <div className="item__thread__reply">
            <div
              className="item__reply__list"
              id={replyListId}
              style={{ maxHeight: 80 }}
            >
              {item?.children.map(i => renderReplyCard(i, level + 1))}
            </div>
          </div>
        )}
        {commentVisible[item.id] && (
          <CommentInput
            autofocus
            id={item.id}
            isShowCannedResponseDropdown
            style={{
              marginLeft: replyMargin + replyMargin * index,
              padding: index > 0 ? (isMobileDevice ? '14px 8px' : '8px') : '0',
            }}
            fullName={userFullName}
            avatarImg={inputAvatarImg}
            onInputFocus={() => {
              commentRef.current[cmtBoxId].toggleBlur();
            }}
            onSubmit={value => {
              onSubmit(value, item.id);
            }}
          />
        )}
      </div>
    );
  };
  const renderCommentCard = () => (
    <div className="item__reply" id={id}>
      <CommentInput
        autofocus={autofocus}
        id={id}
        isShowCannedResponseDropdown
        fullName={userFullName}
        avatarImg={inputAvatarImg}
        onSubmit={onSubmit}
        onInputFocus={() => setCommentVisible({})}
        ref={node => {
          commentRef.current[id] = node;
        }}
      />
      <hr
        className="item__reply__divider"
        style={{ margin: isEmpty(commentVisible) ? '24px 0 14px' : '14px 0' }}
      />
      <div className="item__thread__comment">
        {hasNewer && (
          <div className="item__reply__load-more">
            <button
              type="button"
              onClick={() => {
                onChangeStatus('newer');
              }}
            >
              Load last {subTitle}
            </button>
          </div>
        )}
        {listData?.map(i => renderReplyCard(i, 1, id))}
        {hasOlder && (
          <div className="item__reply__load-more">
            <button
              type="button"
              onClick={() => {
                onChangeStatus('older');
              }}
            >
              Load more {subTitle}
            </button>
          </div>
        )}
      </div>
    </div>
  );

  const renderDescription = text => (
    <>
      {text && (
        <div id={id} className="item__review-description">
          <RichTextClamp
            unsafeHTML={description}
            maxLine={isMobileDevice ? 4 : 2}
            basedOn="letters"
            ellipsisHTML="<span>...Show more</span>"
          />
        </div>
      )}
      {isReply && (
        <span
          className="item__reply__title"
          role="button"
          tabIndex={-1}
          onClick={() => {
            setReplyVisible(prevState => !prevState);
            if (!replyVisible) {
              setCommentVisible({});
            }
            setAutofocus(!replyVisible);
          }}
        >
          {!replyVisible ? 'Reply' : hideReplyTitle}
        </span>
      )}
      {replyVisible && renderCommentCard()}
    </>
  );

  useEffect(() => {
    if (mentionId && mentionId !== id) {
      setReplyVisible(true);
    }
  }, [mentionId]);

  const savedNumber = useMemo(
    () => numberToUnitSuffix(savedCount, 1, 'round'),
    [savedCount],
  );

  const likedNumber = useMemo(
    () => numberToUnitSuffix(likedCount, 1, 'round'),
    [likedCount],
  );

  return (
    <div id={`review-card-${id}`} className="am-user-review-card">
      <div className="card__container">
        <div className="card__header">
          <Avatar className="item__avatar" name={fullName} src={avatarImg} />
          <div className="item__user-info">
            <TooltipHover className="item__fullName" content={fullName} />
            <TooltipHover className="item__role-title" content={position} />
            {ratingScore ? (
              <div className="item__rating-star">
                <RatingStar
                  starSize={12.5}
                  ratingScore={ratingScore}
                  disabled
                />
              </div>
            ) : null}
          </div>
          <div className="item__update-time">
            {moment(updateTime).format(SHORT_DATE_FORMAT_3)}
          </div>
        </div>
        <div className="card__body">
          <TooltipHover className="item__review-title" content={title} />
          {renderDescription(description)}
        </div>
      </div>
      <div className="card__footer">
        <div className="item__vote-icon item__bookmark-icon">
          <BookmarkIcon
            className={isSaved ? 'active' : ''}
            onClick={() => {
              onClickAction({ type: 'saved' });
              setIsSaved(!isSaved);
            }}
          />
          <span className="card__footer__label">{savedNumber}</span>
        </div>
        <div className="item__vote-icon item__like-icon">
          <LikeIcon
            className={isLiked ? 'active' : ''}
            onClick={() => {
              onClickAction({ type: 'liked' });
              setIsLiked(!isLiked);
            }}
          />
          <span className="card__footer__label">{likedNumber}</span>
        </div>
        {showTag && (
          <div className="item__tag">
            <AMTagLists
              id={id}
              classification={inquiryType}
              isClickTag
              onClick={() => null}
            />
          </div>
        )}
      </div>
    </div>
  );
};

UserReviewCard.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  avatarImg: PropTypes.string,
  position: PropTypes.string,
  updateTime: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  mentionId: PropTypes.string,
  fullName: PropTypes.string,
  children: PropTypes.array,
  ratingScore: PropTypes.number,
  likedCount: PropTypes.number,
  savedCount: PropTypes.number,
  isReply: PropTypes.bool,
  showTag: PropTypes.bool,
  liked: PropTypes.bool,
  saved: PropTypes.bool,
  onFooterAction: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
};

Tooltip.defaultProps = {
  isReply: false,
  showTag: false,
  type: null,
  children: [],
  likedCount: 0,
  savedCount: 0,
  liked: false,
  saved: false,
  onSubmit() {},
  onDelete() {},
};

export default memo(UserReviewCard);
