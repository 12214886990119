import React, { useRef, useState, memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import BButton from 'react-bootstrap/Button';
import BOverlay from 'react-bootstrap/Overlay';
import BPopover from 'react-bootstrap/Popover';
import BCard from 'react-bootstrap/Card';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BsList } from 'react-icons/bs';

import { APPLY } from 'constants/buttonName';

import ListIcon from 'assets/icons/common/list.svg';

import useDraggableInPortal from 'hooks/useDraggableInPortal';
import useDevice from 'hooks/useDevice';

import Check from 'components/core/Check';
import Tooltip from '../Tooltip';

import './styles.scss';

const TableViewColumn = ({
  allColumns,
  columns,
  hasResetViewColumn,
  onSetHiddenColumns,
  onSetColumnOrder,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [currentStateAllColumns, setCurrentStateAllColumns] = useState([]);
  const { isMobileDevice } = useDevice();

  useEffect(() => {
    if (isShow) {
      setCurrentStateAllColumns(allColumns.slice(0, allColumns.length - 1));
    }
  }, [isShow]);

  const renderDraggable = useDraggableInPortal();

  const ref = useRef();
  const popoverRef = useRef();

  const offsetOverlay = isMobileDevice ? [0, 10] : [];

  const changePosition = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const arrIndexDisabledItem = currentStateAllColumns.reduce(
      (arr, item, index) => {
        if (item.disableStateChange) arr.push(index);
        return arr;
      },
      [],
    );

    const destinationIndex = result?.destination?.index;
    const sourceIndex = result?.source?.index;
    if (
      destinationIndex === sourceIndex ||
      arrIndexDisabledItem.includes(destinationIndex)
    ) {
      return;
    }

    const newColumnArr = changePosition(
      currentStateAllColumns,
      sourceIndex,
      destinationIndex,
    );

    setCurrentStateAllColumns(newColumnArr);
  };

  const handleChangeChecked = column => {
    const newColumnObj = {};
    const newColumnArr = currentStateAllColumns.map((item, index) => {
      const newItem = { ...item };

      if (item.id === column.id) {
        newItem.isVisible = !item.isVisible;
      }

      newColumnObj[item.id] = {
        show: newItem.isVisible,
        index,
      };

      return newItem;
    });

    setCurrentStateAllColumns(newColumnArr);
  };

  const handleApply = () => {
    onSetHiddenColumns(
      currentStateAllColumns
        .filter(({ isVisible }) => !isVisible)
        .map(({ id, accessor }) => id || accessor),
    );
    onSetColumnOrder(
      currentStateAllColumns.map(({ id, accessor }) => id || accessor),
    );
    setIsShow(false);
  };

  const handleReset = () => {
    onSetHiddenColumns(
      columns
        .filter(
          ({ defaultHiddenColumn, disableStateChange }) =>
            defaultHiddenColumn && !disableStateChange,
        )
        .map(({ id, accessor }) => id || accessor) || [],
    );
    onSetColumnOrder(columns.map(({ id, accessor }) => id || accessor));
    setIsShow(false);
  };

  const renderItem = useCallback(
    (item, index) => {
      const { Header, id, isVisible, disableStateChange } = item;
      const disabled = disableStateChange;

      return (
        <Draggable
          draggableId={id}
          index={index}
          key={id}
          isDragDisabled={disabled || isMobileDevice}
        >
          {renderDraggable(provided => (
            <div
              className={[
                'edh-table-view-column__item edh-table-view-column__input_checkbox',
                disabled ? 'edh-table-view-column__item-disable' : '',
              ].join(' ')}
              key={id}
              ref={provided.innerRef}
              {...(disableStateChange ? {} : provided.draggableProps)}
            >
              <div {...provided.dragHandleProps}>
                <BsList size={25} />
              </div>
              <Check
                key={id}
                size="md"
                label={Header}
                isReverse
                checked={isVisible}
                disabled={disabled}
                onChange={() => handleChangeChecked(item)}
              />
            </div>
          ))}
        </Draggable>
      );
    },
    [handleChangeChecked, renderDraggable],
  );

  return (
    <div ref={ref} className="edh-table-view-column">
      <Tooltip
        content="To view more column"
        placement="left"
        disabled={isMobileDevice}
      >
        <BButton
          ref={popoverRef}
          onClick={() => {
            setIsShow(!isShow);
          }}
        >
          <img alt="List Icon" src={ListIcon} />
        </BButton>
      </Tooltip>

      <BOverlay
        target={popoverRef.current}
        show={isShow}
        flip
        rootClose
        rootCloseEvent="click"
        offset={offsetOverlay}
        placement="bottom-end"
        onHide={() => setIsShow(false)}
      >
        {({ style, ...props }) => (
          <BPopover
            {...props}
            style={style}
            className="edh-table-view-column__menus"
          >
            <BCard>
              <BCard.Header>view columns</BCard.Header>
              <BCard.Body>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="list">
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="edh-table-view-column__list"
                      >
                        {currentStateAllColumns.map((item, index) =>
                          renderItem(item, index),
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </BCard.Body>
              <BCard.Footer>
                <BButton variant="custom-primary" onClick={handleApply}>
                  {APPLY}
                </BButton>
                {hasResetViewColumn && (
                  <BButton
                    className="edh-table-view-column__footer-reset-btn"
                    onClick={handleReset}
                  >
                    Reset
                  </BButton>
                )}
              </BCard.Footer>
            </BCard>
          </BPopover>
        )}
      </BOverlay>
    </div>
  );
};

TableViewColumn.propTypes = {
  allColumns: PropTypes.array,
  columns: PropTypes.array,
  defaultSortBy: PropTypes.array,
  columnFilter: PropTypes.object,
  hasResetViewColumn: PropTypes.bool,
  onSetHiddenColumns: PropTypes.func,
  onSetColumnOrder: PropTypes.func,
  onSetSortBy: PropTypes.func,
  onChangeViewColumn: PropTypes.func,
};

export default memo(TableViewColumn);
