import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import classnames from 'services/classnames';

import InfoIcon from 'assets/icons/common/information-black.svg';

import Tooltip from 'components/layout/Tooltip';
import InnerHTML from 'components/core/InnerHTML';

import './styles.scss';

const Field = ({
  children,
  rules,
  id,
  className,
  label,
  placeholder,
  disabled,
  error,
  infoContent,
  isShowIconInfo,
  onClickIconInfo,
}) => {
  const styleClasses = ['edh-field'];
  if (className) {
    styleClasses.push(className);
  }

  const tooltipContent = useMemo(() => {
    if (infoContent) {
      return infoContent;
    }

    return placeholder === 'Search Dataset Name'
      ? 'You must select one suggestion'
      : 'You must select one suggestion or leave field blank';
  }, [infoContent, placeholder]);
  return (
    <div className={styleClasses.join(' ')}>
      {label && (
        <label htmlFor={id} className="edh-field__label">
          <InnerHTML
            tag="span"
            className={classnames({
              'edh-field__label-required': rules?.required,
            })}
            data={label}
          />

          {isShowIconInfo && (
            <Tooltip
              placement="top"
              content={tooltipContent}
              disabled={onClickIconInfo !== null}
            >
              <img
                role="presentation"
                style={{ marginLeft: 3 }}
                src={InfoIcon}
                alt="Info icon"
                onClick={onClickIconInfo}
              />
            </Tooltip>
          )}
        </label>
      )}
      <div
        className={`edh-field__field ${
          disabled && 'edh-field__field--disabled'
        }`}
      >
        {children}
      </div>
      <div
        className="edh-field__message"
        style={{ visibility: error?.type ? 'visible' : 'hidden' }}
      >
        {error?.message}
      </div>
    </div>
  );
};

Field.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  infoContent: PropTypes.string,
  disabled: PropTypes.bool,
  isShowIconInfo: PropTypes.bool,
  rules: PropTypes.object,
  error: PropTypes.object,
  onClickIconInfo: PropTypes.func,
};

Field.defaultProps = {
  children: '',
  id: '',
  className: '',
  label: '',
  placeholder: '',
  infoContent: '',
  disabled: false,
  isShowIconInfo: false,
  rules: {},
  error: {},
  onClickIconInfo: null,
};

export default memo(Field);
