import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { feedbackApi } from 'api';

import { CATEGORY_FEEDBACK } from 'constants/categoryFeedbackForm';
import { LIMIT_CHARACTERS } from 'constants/dataRequestForm';

import feedbackSucceed from 'assets/images/common/succeededFeedback.jpg';

import InputField from 'components/fields/Input';
import SelectField from 'components/fields/Select';
import LoadingBalls from 'components/core/LoadingBalls';

import './styles.scss';

const rules = {
  category: {
    required: true,
  },
  description: {
    required: true,
  },
};

const FeedbackFormModal = ({ show, onHide }) => {
  const {
    handleSubmit,
    clearErrors,
    reset,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm();

  const onSubmit = async ({ category, description }) => {
    if (!category || !description) return;

    await feedbackApi.sendFeedback(category.value, description);
  };

  const onClose = () => {
    onHide(false);
    clearErrors();
    reset();
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      dialogClassName="edh-feedback-form-modal"
      backdrop="static"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <div>
            <div className="modal-header__title">
              Data+ User Experience Feedback Form
            </div>
          </div>
        </Modal.Header>
        <LoadingBalls isLoading={isSubmitting} />
        {isSubmitSuccessful ? (
          <Modal.Body>
            <div className="edh-feedback-form-modal__succeed">
              <img src={feedbackSucceed} alt="feedbackSucceed" />
              <h3>Thank You!</h3>
              <p>Your submission has been received.</p>
              <div className="edh-feedback-form-modal__succeed__done">
                <Button
                  className="btn btn-custom-out-primary"
                  onClick={onClose}
                >
                  Done
                </Button>
              </div>
            </div>
          </Modal.Body>
        ) : (
          <>
            <Modal.Body>
              <SelectField
                id="edh-request-form-field-category"
                label="Category"
                name="category"
                placeholder="Select Category"
                menuListTitle="CATEGORY"
                isMulti={false}
                options={CATEGORY_FEEDBACK}
                rules={rules.category}
                error={errors?.category}
                control={control}
              />

              <InputField
                id="edh-request-form-field-description"
                type="textarea"
                label="Description"
                name="description"
                placeholder="Insert Description "
                rules={rules.description}
                error={errors?.description}
                control={control}
                limit={LIMIT_CHARACTERS.textarea}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="custom-primary" type="submit">
                Send
              </Button>
            </Modal.Footer>
          </>
        )}
      </form>
    </Modal>
  );
};

FeedbackFormModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

FeedbackFormModal.defaultValues = {
  show: false,
  onHide() {},
};
export default FeedbackFormModal;
