import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'toasts';
const initialState = {
  toastsMessages: {
    data: [],
    status: 'succeeded', // or: 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  },
};

const toastsSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    addToastsMessage(state, action) {
      state.toastsMessages.data = [
        ...state.toastsMessages.data.slice(-1),
        action.payload,
      ];
    },
    removeToastsMessage(state, action) {
      state.toastsMessages.data = state.toastsMessages.data.filter(
        message => message.id !== action.payload?.id,
      );
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = toastsSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const { addToastsMessage, removeToastsMessage } = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const toastsMessagesSelector = createSelector(
  rootSelector,
  ({ toastsMessages }) => toastsMessages,
);
