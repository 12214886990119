import React, { useEffect, useState } from 'react';
import routes from 'routes';

import {
  Sidebar,
  Alerts,
  Toasts,
  TopNavigate,
  IdleTimer,
  Authorizing,
  FirstAccess,
} from 'layouts';
import PageAccess from 'layouts/PageAccess';
import TrackingSpendTime from 'layouts/TrackingSpendTime';

import useUser from 'hooks/useUser';
import useTakeEventGA from 'hooks/useTakeEventGA';

const MainLayout = () => {
  const [sidebarIsShow, setSidebarIsShow] = useState(false);
  const { userInfo } = useUser();
  const { onSendUserLogin } = useTakeEventGA();

  const handleHideSidebar = () => {
    setSidebarIsShow(false);
  };

  const handleShowSidebar = () => {
    setSidebarIsShow(true);
  };

  useEffect(() => {
    onSendUserLogin();
  }, []);

  return (
    <>
      <Sidebar show={sidebarIsShow} onHideSidebar={handleHideSidebar} />
      <main className="edh-main">
        <TopNavigate userInfo={userInfo} onShowSidebar={handleShowSidebar} />
        <Authorizing routes={routes} />
      </main>
      <IdleTimer />
      <Toasts />
      <Alerts />
      <FirstAccess />
      <PageAccess />
      <TrackingSpendTime />
    </>
  );
};

export default MainLayout;
