import PropTypes from 'prop-types';
import React from 'react';
import { pdfjs, Document as PDFDocument, Page } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const Document = props => (
  <PDFDocument
    {...props}
    options={{
      ...props.options,
      cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
      cMapPacked: true,
      standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
      // verbosity for disable waring
      verbosity: pdfjs.VerbosityLevel.ERRORS,
    }}
  />
);

Document.propTypes = {
  options: PropTypes.any,
};

export { Document, Page };
