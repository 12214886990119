import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Successful from '../../../assets/icons/history/successful.svg';
import Progress from '../../../assets/icons/history/progress.svg';
import Failed from '../../../assets/icons/history/failed.svg';

import { SUCCESSFUL, PROGRESS, FAILED } from '../../../constants/status';

import './styles.scss';

const DownloadStatus = ({ status }) => {
  const styleClasses = ['edh-download-status'];

  let icon;
  switch (status.toUpperCase()) {
    case SUCCESSFUL:
      styleClasses.push('edh-download-status--successful');
      icon = Successful;
      break;
    case PROGRESS:
      styleClasses.push('edh-download-status--progress');
      icon = Progress;
      break;
    case FAILED:
      styleClasses.push('edh-download-status--failed');
      icon = Failed;
      break;
    default:
      break;
  }

  return (
    <div className={styleClasses.join(' ')}>
      <span style={{ marginRight: '0.5rem' }}>
        <img src={icon} alt="Icon download status" />
      </span>
      <span className="edh-download-status__name">{status}</span>
    </div>
  );
};

DownloadStatus.propTypes = {
  status: PropTypes.string,
};

DownloadStatus.defaultProps = {
  status: '',
};

export default memo(DownloadStatus);
