import { call, put, delay, takeEvery, takeLatest } from 'redux-saga/effects';

import { getData, patchData } from '../services/api';

import {
  AUTHENTICATION_API_PATH,
  USER_SEARCH_GET_SUGGESTED_USERS_API_PATH,
  USER_VIEW_PRIVACY_CONSENT,
} from '../constants/paths';

import {
  getUserInfoRequest,
  getUserInfoSuccess,
  getUserInfoFailure,
  searchUserRequest,
  searchUserSuccess,
  searchUserFailure,
  viewPrivacyRequest,
  viewPrivacySuccess,
  viewPrivacyFailure,
} from '../slices/user';

export function* getUserInfo() {
  try {
    const {
      data: { data },
    } = yield call(getData, { url: AUTHENTICATION_API_PATH });

    yield delay(800);
    yield put(getUserInfoSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getUserInfoFailure(message));
  }
}

export function* searchUser({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: USER_SEARCH_GET_SUGGESTED_USERS_API_PATH,
      params: payload,
    });

    yield delay(800);
    yield put(searchUserSuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(searchUserFailure(message));
  }
}

export function* viewPrivacy() {
  try {
    const {
      data: { data },
    } = yield call(patchData, {
      url: USER_VIEW_PRIVACY_CONSENT,
    });

    yield delay(800);
    yield put(viewPrivacySuccess(data));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(viewPrivacyFailure(message));
  }
}

export default function* userSaga() {
  yield takeEvery(getUserInfoRequest().type, getUserInfo);
  yield takeLatest(searchUserRequest().type, searchUser);
  yield takeLatest(viewPrivacyRequest().type, viewPrivacy);
}
