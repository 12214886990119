import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'searchLandingPage';
const initialState = {
  resultDM: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  resultAM: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  resultDQ: {
    data: {
      items: [],
    },
    status: 'idle',
    error: null,
  },
  searchSuggestion: {
    data: [],
    status: 'idle',
    error: null,
  },
};

const searchLandingPageSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    // get result of data market place
    getResultDMRequest(state) {
      state.resultDM.status = 'loading';
    },
    getResultDMSuccess(state, action) {
      const { data, isPagination } = action.payload;

      if (!isPagination && data.currentPage !== 1) {
        data.items = [...state.resultDM.data.items, ...data.items];
      }

      state.resultDM.data = data;
      state.resultDM.status = 'succeeded';
      state.resultDM.error = null;
    },
    getResultDMFailure(state, action) {
      state.resultDM.status = 'failed';
      state.resultDM.data = {};
      state.resultDM.error = action.payload;
    },

    // get result of analytics market place
    getResultAMRequest(state) {
      state.resultAM.status = 'loading';
    },
    getResultAMSuccess(state, action) {
      const { data, isPagination } = action.payload;

      if (!isPagination && data.currentPage !== 1) {
        data.items = [...state.resultAM.data.items, ...data.items];
      }

      state.resultAM.data = data;
      state.resultAM.status = 'succeeded';
      state.resultAM.error = null;
    },
    getResultAMFailure(state, action) {
      state.resultAM.status = 'failed';
      state.resultAM.data = {};
      state.resultAM.error = action.payload;
    },

    // get result of data quality
    getResultDQRequest(state) {
      state.resultDQ.status = 'loading';
    },
    getResultDQSuccess(state, action) {
      const { data, isPagination } = action.payload;

      if (!isPagination && data.currentPage !== 1) {
        data.items = [...state.resultDQ.data.items, ...data.items];
      }

      state.resultDQ.data = data;
      state.resultDQ.status = 'succeeded';
      state.resultDQ.error = null;
    },
    getResultDQFailure(state, action) {
      state.resultDQ.status = 'failed';
      state.resultDQ.data = {};
      state.resultDQ.error = action.payload;
    },
    postLandingPageSuggestionRequest(state) {
      state.searchSuggestion.status = 'loading';
    },
    postLandingPageSuggestionSuccess(state, action) {
      state.searchSuggestion.data = action.payload;
      state.searchSuggestion.status = 'succeeded';
    },
    postLandingPageSuggestionFailure(state, action) {
      state.searchSuggestion.status = 'failed';
      state.searchSuggestion.data = [];
      state.searchSuggestion.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = searchLandingPageSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getResultDMRequest,
  getResultDMSuccess,
  getResultDMFailure,
  getResultAMRequest,
  getResultAMSuccess,
  getResultAMFailure,
  getResultDQRequest,
  getResultDQSuccess,
  getResultDQFailure,
  postLandingPageSuggestionRequest,
  postLandingPageSuggestionSuccess,
  postLandingPageSuggestionFailure,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const resultDMSelector = createSelector(
  rootSelector,
  ({ resultDM }) => resultDM,
);
export const resultAMSelector = createSelector(
  rootSelector,
  ({ resultAM }) => resultAM,
);
export const resultDQSelector = createSelector(
  rootSelector,
  ({ resultDQ }) => resultDQ,
);
export const resultLandingPageSuggestionSelector = createSelector(
  rootSelector,
  ({ searchSuggestion }) => searchSuggestion,
);
