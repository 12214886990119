import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as _ from 'lodash';

import roles from 'constants/roles';
import { LOADING_STATUS } from 'constants/apis';

import {
  getUserInfoRequest,
  searchUserRequest,
  viewPrivacyRequest,
  userInfoSelector,
  searchUserSelector,
  isAuthenticatedSelector,
  updateIsAuthenticated,
  updateIsAccessModelRequestPage,
} from 'slices/user';

const useUser = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const userInfo = useSelector(userInfoSelector);
  const searchUser = useSelector(searchUserSelector);

  const { roles: userRoles, modularRoles } = userInfo.data || {};
  const userConfig = userInfo?.data?.config;
  const isAdmin = userRoles?.includes(roles.admin);
  const isDGAAdmin = userRoles?.includes(roles.dgaAdmin);
  const isDQAdmin = userRoles?.includes(roles.dqAdmin);
  const isAMAdmin = userRoles?.includes(roles.amAdmin);
  const isDataSteward = userRoles?.includes(roles.dataSteward);
  const isCommunicator = userRoles?.includes(roles.communicator);
  const isEDS = userRoles?.includes(roles.eds);
  const isEditor = modularRoles?.includes(roles.editor);
  const isCreateRequestDQM = userConfig?.isCreateRequestDQM;
  const isCreateRequestDCM = userConfig?.isCreateRequestDCM;
  const isAccessUMDCM = userConfig?.isAccessUMDCM;
  const isAccessUMDQM = userConfig?.isAccessUMDQM;
  const isAccessDMDCM = userConfig?.isAccessDMDCM;
  const isAccessDMDQM = userConfig?.isAccessDMDQM;
  const isAccessDashboard = userConfig?.isAccessDashboard;

  const isViewMyRequestPage = isAdmin === false || isDGAAdmin || isDataSteward;
  const isGotUserData = useMemo(
    () =>
      userInfo?.status === LOADING_STATUS.SUCCEEDED &&
      !_.isEmpty(userInfo?.data),
    [userInfo],
  );

  const dispatch = useDispatch();

  const onUpdateIsAuthenticated = useCallback(() => {
    dispatch(updateIsAuthenticated(true));
  }, []);

  const onGetUserInfo = useCallback(() => {
    dispatch(getUserInfoRequest());
  }, []);

  const onSearchUserRequest = useCallback(params => {
    dispatch(searchUserRequest(params));
  }, []);

  const onViewPrivacyRequest = useCallback(() => {
    dispatch(viewPrivacyRequest());
  }, []);

  const onUpdateIsAccessModelRequestPage = useCallback(params => {
    dispatch(updateIsAccessModelRequestPage(params));
  }, []);

  return {
    isAuthenticated,
    isGotUserData,
    userInfo,
    roles: userRoles,
    modularRoles,
    searchUser,
    isAdmin,
    isDGAAdmin,
    isDQAdmin,
    isAMAdmin,
    isDataSteward,
    isCommunicator,
    isEDS,
    isEditor,
    isViewMyRequestPage,
    isCreateRequestDQM,
    isCreateRequestDCM,
    isAccessUMDCM,
    isAccessUMDQM,
    isAccessDMDCM,
    isAccessDMDQM,
    isAccessDashboard,
    onUpdateIsAuthenticated,
    onGetUserInfo,
    onSearchUserRequest,
    onViewPrivacyRequest,
    onUpdateIsAccessModelRequestPage,
  };
};

export default useUser;
