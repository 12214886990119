import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { current } from 'immer';

export const ROOT_STATE_NAME = 'datasetFootprint';

const initialState = {
  datasetFootprint: {
    data: {},
    status: 'idle',
    error: null,
  },
  hierarchyTree: {
    data: [],
    status: 'idle',
    error: null,
  },
  datasetTableFootprint: {
    data: [],
    status: 'idle',
    error: null,
  },
  tagCounterFootprint: {
    data: [],
    status: 'idle',
    error: null,
  },
};

const datasetFootprintSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getDatasetFootprintRequest(state) {
      state.datasetFootprint.status = 'loading';
    },
    getDatasetFootprintSuccess(state, action) {
      state.datasetFootprint.data = action.payload;
      state.datasetFootprint.status = 'succeeded';
    },
    getDatasetFootprintFailure(state, action) {
      state.datasetFootprint.status = 'failed';
      state.datasetFootprint.data = {};
      state.datasetFootprint.error = action.payload;
    },
    getHierarchyTreeRequest(state) {
      state.hierarchyTree.status = 'loading';
    },
    getHierarchyTreeSuccess(state, action) {
      const { treeData, ancestors } = action.payload.params;
      const { data } = action.payload;
      const updateTreeNode = (
        tree,
        parentNodeValue,
        childrenNodeValue,
        childrenData,
      ) => {
        if (!tree) {
          return null;
        };
        
        return tree.map(node => {
          if (
            node.label === parentNodeValue &&
            node.value === childrenNodeValue
          ) {
            return {
              ...node,
              parent: node?.parent || [],
              // first node
              children: [
                ...childrenData.map(children => ({
                  ...children,
                  path: children.path?.concat(children.label),
                  parent: {...node, children: childrenData.map(child => ({...child, value: child?.label.concat(`_${node.value}`)}))},
                  value: children?.label.concat(`_${node.value}`),
                  treeDepth: children?.treeDepth,
                })),
              ],
            };
          }
          // lastest node in tree
          if (node.children) {
            return {
              ...node,
              treeDepth: node?.treeDepth,
              children: [
                ...updateTreeNode(
                  node.children,
                  parentNodeValue,
                  childrenNodeValue,
                  childrenData,
                ).map(item => ({
                  ...item,
                  value: item?.label.concat(`_${node.value}`),
                })),
              ],
            };
          }
          return node;
        });
      };
      const parentNodeValue = ancestors[ancestors.length - 1];
      state.hierarchyTree.data = updateTreeNode(
        treeData === undefined ? data.items : current(state.hierarchyTree.data),
        parentNodeValue,
        data.parent?.value,
        data.items,
      );
      state.hierarchyTree.status = 'succeeded';
    },
    getHierarchyTreeFailure(state, action) {
      state.hierarchyTree.status = 'failed';
      state.hierarchyTree.data = {};
      state.hierarchyTree.error = action.payload;
    },
    getDatasetTableFootprintRequest(state) {
      state.datasetTableFootprint.status = 'loading';
    },
    getDatasetTableFootprintSuccess(state, action) {
      state.datasetTableFootprint.data = action.payload;
      state.datasetTableFootprint.status = 'succeeded';
    },
    getDatasetTableFootprintFailure(state, action) {
      state.datasetTableFootprint.status = 'failed';
      state.datasetTableFootprint.data = {};
      state.datasetTableFootprint.error = action.payload;
    },
    getTagCounterFootprintRequest(state) {
      state.tagCounterFootprint.status = 'loading';
    },
    getTagCounterFootprintSuccess(state, action) {
      state.tagCounterFootprint.data = action.payload;
      state.tagCounterFootprint.status = 'succeeded';
    },
    getTagCounterFootprintFailure(state, action) {
      state.tagCounterFootprint.status = 'failed';
      state.tagCounterFootprint.data = {};
      state.tagCounterFootprint.error = action.payload;
    },
  },
});

const { actions, reducer } = datasetFootprintSlice;

export default reducer;

export const {
  getDatasetFootprintRequest,
  getDatasetFootprintSuccess,
  getDatasetFootprintFailure,
  getHierarchyTreeRequest,
  getHierarchyTreeSuccess,
  getHierarchyTreeFailure,
  getDatasetTableFootprintRequest,
  getDatasetTableFootprintSuccess,
  getDatasetTableFootprintFailure,
  getTagCounterFootprintRequest,
  getTagCounterFootprintSuccess,
  getTagCounterFootprintFailure,
} = actions;

export const rootSelector = state => state[ROOT_STATE_NAME] || {};

export const datasetFootprintSelector = createSelector(
  rootSelector,
  ({ datasetFootprint }) => datasetFootprint,
);

export const hierarchyTreeSelector = createSelector(
  rootSelector,
  ({ hierarchyTree }) => hierarchyTree,
);

export const datasetTableFootprintSelector = createSelector(
  rootSelector,
  ({ datasetTableFootprint }) => datasetTableFootprint,
);

export const tagCounterFootprintSelector = createSelector(
  rootSelector,
  ({ tagCounterFootprint }) => tagCounterFootprint,
);
