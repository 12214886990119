import ExploreBlackIcon from 'assets/icons/common/explore-black.svg';
import ExploreGreenIcon from 'assets/icons/common/explore-green.svg';

import AMOverViewTab from './AMOverviewTab';
import AMExploreTab from './AMExploreTab';
import AMHistoryTab from './AMHistoryTab';
import AMReportTab from './AMReportTab';

export const TAB_ID = {
  OVERVIEW: 'overview',
  EXPLORE: 'explore',
  VERSION_HISTORY: 'versionHistory',
  INQUIRY: 'inquiry',
};

export const LIST_AM_DETAIL_TABS = {
  [TAB_ID.OVERVIEW]: {
    id: TAB_ID.OVERVIEW,
    title: 'Overview',
    comp: AMOverViewTab,
  },
  [TAB_ID.EXPLORE]: {
    id: TAB_ID.EXPLORE,
    title: 'Explore',
    activatedIcon: ExploreGreenIcon,
    inActivatedIcon: ExploreBlackIcon,
    comp: AMExploreTab,
  },
  [TAB_ID.VERSION_HISTORY]: {
    id: TAB_ID.VERSION_HISTORY,
    title: 'Version History',
    comp: AMHistoryTab,
  },
  [TAB_ID.INQUIRY]: {
    id: TAB_ID.INQUIRY,
    title: 'Submit Your Inquiry',
    comp: AMReportTab,
  },
};

export const FILTER_VALUE = {
  ALL: 4,
  QUESTION: 1,
  REPORT_BUGS: 2,
  SUGGESTION: 3,
  USER_REVIEW: 0,
};

export const SELECT_FIELD_OPTION = [
  { label: 'Question', value: FILTER_VALUE.QUESTION.toString() },
  { label: 'Report Bug', value: FILTER_VALUE.REPORT_BUGS.toString() },
  { label: 'Suggestion', value: FILTER_VALUE.SUGGESTION.toString() },
  { label: 'User Review', value: FILTER_VALUE.USER_REVIEW.toString() },
];

export const FILTER_USER_REVIEW = [
  { label: 'All', value: FILTER_VALUE.ALL.toString(), type: FILTER_VALUE.ALL },
  {
    label: 'Saved',
    value: 'Saved',
    type: FILTER_VALUE.ALL.toString(),
    actionType: 'Saved',
  },
  {
    label: 'Question',
    value: FILTER_VALUE.QUESTION.toString(),
    type: FILTER_VALUE.QUESTION,
  },
  {
    label: 'Report Bug',
    value: FILTER_VALUE.REPORT_BUGS.toString(),
    type: FILTER_VALUE.REPORT_BUGS,
  },
  {
    label: 'Suggestion',
    value: FILTER_VALUE.SUGGESTION.toString(),
    type: FILTER_VALUE.SUGGESTION,
  },
  {
    label: 'User Review',
    value: FILTER_VALUE.USER_REVIEW.toString(),
    type: FILTER_VALUE.USER_REVIEW,
  },
];

export const PAGE_SIZE_OPTIONS = [
  {
    title: 'Select page',
    accessor: 12,
    sort: '12 per page',
  },
  {
    title: '12 per page',
    accessor: 12,
    sort: '12 per page',
  },
  {
    title: '24 per page',
    accessor: 24,
    sort: '24 per page',
  },
  {
    title: '36 per page',
    accessor: 36,
    sort: '36 per page',
  },
];
