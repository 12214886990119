import React, { useEffect, useState } from 'react';
import { BsCaretLeftFill, BsCaretRightFill } from 'react-icons/bs';

import Tooltip from '../components/layout/Tooltip';

const useSampleFilePagination = (fileName, totalPage) => {
  const [totalPages, setTotalPages] = useState(totalPage);
  const [pageNumber, setPageNumber] = useState(0);
  const onClickPrevious = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };
  const onClickNext = () => {
    if (pageNumber < totalPages - 1) {
      setPageNumber(pageNumber + 1);
    }
  };

  useEffect(() => {
    setTotalPages(totalPage);
    setPageNumber(0);
  }, [totalPage]);

  const displayedPageNumber = pageNumber < totalPage ? pageNumber : 0;

  const renderPagination = () => {
    if (totalPages && totalPages > 1) {
      const label = fileName[displayedPageNumber]
        .split('?')[0]
        .replace(/^.*\//, '')
        .replace(/-/g, ' ');
      return (
        <div className="sample-file-pagination">
          <button
            type="button"
            className="sample-file-pagination__previous"
            onClick={onClickPrevious}
            disabled={displayedPageNumber === 0}
          >
            <BsCaretLeftFill
              color="#C4C3D1"
              size={10}
              style={{ marginBottom: '2px' }}
            />
          </button>
          <Tooltip content={label} id={label} placement="top">
            <span id={label} className="sample-file-pagination__label">
              {label}
            </span>
          </Tooltip>
          <button
            type="button"
            className="sample-file-pagination__next"
            onClick={onClickNext}
            disabled={totalPages - 1 === displayedPageNumber}
          >
            <BsCaretRightFill
              color="#C4C3D1"
              size={10}
              style={{ marginBottom: '2px' }}
            />
          </button>
        </div>
      );
    }

    return null;
  };

  return {
    totalPages,
    setTotalPages,
    pageNumber,
    setPageNumber,
    renderPagination,
  };
};

export default useSampleFilePagination;
