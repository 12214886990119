import React, {
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useBlockLayout,
  useSortBy,
  useExpanded,
  useRowSelect,
  usePagination,
  useAsyncDebounce,
  useColumnOrder,
  useRowState,
} from 'react-table';
import { useSticky } from 'react-table-sticky';

import { BiSortUp, BiSortDown } from 'react-icons/bi';
import { IoIosInformationCircleOutline } from 'react-icons/io';

import { PAGE_SIZE_OPTIONS } from 'constants/pageSize';

import useSortOption from 'hooks/useSortOption';
import useWindowSize from 'hooks/useWindowSize';
import useDevice from 'hooks/useDevice';

import {
  onGetDefaultColumn,
  onUpdateRowStyle,
  onUpdateHeaderGroups,
} from 'services/table';
import classnames from 'services/classnames';

import ListIcon from 'assets/icons/common/list.svg';

import LoadingBalls from '../../core/LoadingBalls';
import MessageNoData from '../../core/MessageNoData';
import IndeterminateCheckbox from '../IndeterminateCheckbox';
import TableCellActions from '../TableCellActions';
import Pagination from '../Pagination';
import TableViewColumnModal from '../TableViewColumn';
import Tooltip from '../Tooltip';
import EllipseCellTable from '../EllipseCellTable';

import './styles.scss';

const ReactTableCustom = ({
  id,
  columns,
  data,
  className,
  defaultSortBy,
  defaultExpanded,
  currentSeletedRows,
  pageSizeOptions,
  defaultPageSize,
  totalCount,
  totalPages,
  currentPage,
  messageTitleNoData,
  messageDescriptionNoData,
  expandedRows,
  actionWidth,
  filters: controlledFilters,
  isLoading,
  isCellClick,
  hasCustomExpandRow,
  hasExpand,
  hasBorder,
  hasStriped,
  disableCollapseDefaultExpand,
  enableSortOption,
  hasSelection,
  hasSelectionNumner,
  hasCustomSelection,
  hasActions,
  hasViewColumn,
  hasStickyAction,
  hasStickyHeader,
  hasPagination,
  hasResetViewColumn,
  hasDescription,
  manualPagination,
  manualSortBy,
  autoResetPage,
  autoResetHiddenColumns,
  autoResetSortBy,
  autoResetSelect,
  autoResetExpanded,
  updateMyData,
  onRenderColumnAction,
  onRenderRowSubComponent,
  onSelectedRows,
  onRowDoubleClick,
  onRowClick,
  onSetCurrentVisibleColumns,
  onFetchData,
  onBlurRow,
  keywords,
  img,
  onPrincipleRuleClick,
  elementNoData,
}) => {
  const [hasSticky, setHasSticky] = useState(false);
  const [focusedCell, setFocusedCell] = useState(null);
  const [blurCell, setBlurCell] = useState();
  const { isMobileDevice } = useDevice();

  const defaultColumn = useMemo(() => onGetDefaultColumn(), [hasSticky]);
  const defaultHiddenColumns = useMemo(
    () =>
      columns
        .filter(({ defaultHiddenColumn }) => defaultHiddenColumn)
        .map(({ id: idColumn, accessor }) => idColumn || accessor) || [],
    [columns],
  );

  const tableRef = useRef(null);
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow, // The rest of these things are super handy, too ;)
    headerGroups,
    rows,
    canPreviousPage,
    canNextPage,
    page,
    pageOptions,
    // pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setSortBy,
    // toggleRowSelected,
    selectedFlatRows,
    state: { pageIndex, pageSize, sortBy, filters, selectedRowIds },
    visibleColumns,
    setHiddenColumns,
    setColumnOrder,
    // dispatch,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualPagination,
      manualSortBy,
      autoResetPage,
      autoResetHiddenColumns,
      updateMyData,
      pageCount: totalPages,
      initialState: {
        filters: {},
        pageIndex: 0,
        pageSize: defaultPageSize,
        sortBy: defaultSortBy,
        selectedRowIds: currentSeletedRows || {},
        hiddenColumns: defaultHiddenColumns,
        expanded: defaultExpanded,
      },
      autoResetSortBy,
      autoResetSelectedRows: !hasSelection && !autoResetSelect,
      autoResetExpanded,
      disableSortRemove: true,
      getRowId: row => row.id,
      useControlledState: state =>
        useMemo(() => {
          const newState = {
            ...state,
            filters: controlledFilters,
          };
          if (autoResetSortBy) {
            newState.sortBy = defaultSortBy;
          }
          return newState;
        }, [state, controlledFilters, autoResetSortBy, autoResetPage]),
    },
    useBlockLayout,
    useSticky,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useRowState,
    hooks => {
      hooks.visibleColumns.push(currentColumns => {
        const currentColumnsClone = [...currentColumns];
        if (hasSelection) {
          currentColumnsClone.unshift({
            id: 'selection',
            className: 'edh-react-table__col-selection',
            minWidth: 40,
            width: 40,
            maxWidth: 40,
            sticky: hasSticky ? 'left' : '',
            disableSortBy: true,
            Header: headerProps => (
              <div
                style={{
                  margin: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <IndeterminateCheckbox
                  {...headerProps?.getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            Cell: cellProps => (
              <div
                style={{
                  margin: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <IndeterminateCheckbox
                  {...cellProps?.row?.getToggleRowSelectedProps()}
                  hasSelectionNumner={hasSelectionNumner}
                  row={cellProps?.row}
                  tableState={cellProps?.state}
                />
              </div>
            ),
          });
        }
        if (!hasSelection && hasCustomSelection) {
          currentColumnsClone.unshift(hasCustomSelection);
        }
        if (hasActions) {
          const width = isMobileDevice ? 40 : actionWidth;

          currentColumnsClone.push({
            id: 'actions',
            className: 'edh-react-table__col-actions',
            minWidth: width,
            width,
            maxWidth: width,
            sticky: hasSticky && hasStickyAction ? 'right' : '',
            disableSortBy: true,
            Header: headerProps =>
              hasViewColumn ? (
                <TableViewColumnModal
                  allColumns={headerProps?.allColumns}
                  columns={headerProps?.columns}
                  hasResetViewColumn={hasResetViewColumn}
                  onSetHiddenColumns={setHiddenColumns}
                  onSetColumnOrder={setColumnOrder}
                />
              ) : (
                <div className="edh-react-table__col-actions-header">
                  <img alt="List Icon" src={ListIcon} />
                </div>
              ),
            Cell: props =>
              onRenderColumnAction ? (
                onRenderColumnAction(props)
              ) : (
                <TableCellActions {...props} />
              ),
          });
        }

        return currentColumnsClone;
      });
    },
  );

  const onSetPageSize = useCallback(
    newPageSize => {
      setPageSize(newPageSize);
      gotoPage(0);
    },
    [setPageSize],
  );

  useEffect(() => {
    if (visibleColumns) {
      onSetCurrentVisibleColumns(visibleColumns);
    }
  }, [visibleColumns]);

  useEffect(() => {
    if (filters) {
      setSortBy(defaultSortBy);
    }
  }, [filters]);

  useEffect(() => {
    setSortBy(defaultSortBy);
  }, [defaultSortBy]);

  useEffect(() => {
    if (gotoPage) {
      gotoPage(0);
    }
  }, [controlledFilters]);

  useEffect(() => {
    if (gotoPage) {
      gotoPage(currentPage);
    }
  }, [currentPage]);

  // Debounce our onFetchData call for 100ms
  const sortOption = useSortOption(sortBy, setSortBy, enableSortOption);
  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 100);

  // When these table states change, fetch new data!
  useEffect(() => {
    // Every change will call our debounced function
    onFetchDataDebounced({
      pageIndex,
      pageSize,
      sortBy: sortOption,
      filters,
      keywords,
    });
    // Only the last call after the 100ms debounce is over will be fired!
  }, [
    onFetchData,
    onFetchDataDebounced,
    pageIndex,
    pageSize,
    sortBy,
    filters,
    sortOption,
    keywords,
  ]);

  useEffect(() => {
    if (selectedRowIds) {
      onSelectedRows({ selectedRowIds, selectedFlatRows });
    }
  }, [selectedRowIds]);

  const screenSize = useWindowSize();

  useEffect(() => {
    // This useEffect will hande sticky in table by screen size
    if (headerGroups[0] && tableRef?.current?.clientWidth) {
      const tableContainerWidth = tableRef?.current?.clientWidth;
      const headerGroupProps = headerGroups[0].getHeaderGroupProps();
      const [headerWidth] = headerGroupProps.style.width.split('px');

      setHasSticky(tableContainerWidth < +headerWidth);
    }
  }, [screenSize, tableRef?.current?.clientWidth, headerGroups[0]]);

  const updatedHeaderGroups = useMemo(
    () => onUpdateHeaderGroups(headerGroups),
    [headerGroups],
  );

  const onCellFocus = cell => {
    setFocusedCell(cell);
  };

  const onCellBlur = cell => {
    setBlurCell(cell);
  };

  useEffect(() => {
    if (blurCell && blurCell?.row?.id !== focusedCell?.row?.id) {
      onBlurRow(blurCell, focusedCell);
    }
  }, [blurCell, focusedCell]);

  return (
    <div
      className={classnames(className, 'edh-react-table', {
        'edh-react-table--border': hasBorder,
        'edh-react-table--sticky': hasSticky || hasStickyHeader,
        'edh-react-table--striped': hasStriped,
      })}
    >
      <LoadingBalls isLoading={isLoading} />
      <div
        {...getTableProps()}
        className="edh-react-table__table"
        ref={tableRef}
        id={id}
      >
        <div
          className={classnames(
            'edh-react-table__table-header',
            `${
              rows.length === 0 ? 'edh-react-table__table-header--no-data' : ''
            }`,
          )}
        >
          {updatedHeaderGroups.map(headerGroup => {
            const headerGroupProps = headerGroup.getHeaderGroupProps();
            headerGroupProps.style = onUpdateRowStyle(
              headerGroupProps.style,
              hasSticky,
            );

            return (
              <div
                {...headerGroupProps}
                key={headerGroupProps.key}
                className="tr"
              >
                {headerGroup.headers.map(column => {
                  const {
                    id: idHeader,
                    originalId,
                    flex,
                    minWidth,
                    width,
                    maxWidth,
                    canSort,
                    isSorted,
                    desc,
                    icon,
                    status,
                    preventSortFunction,
                    isRemoveSort,
                  } = column;

                  let columnProps = {
                    style: {},
                  };

                  if (canSort && !preventSortFunction) {
                    columnProps = column.getSortByToggleProps({
                      title: undefined,
                    });
                  }
                  if (flex) {
                    columnProps.style.flex = flex;
                  }
                  if (minWidth) {
                    columnProps.style.minWidth = minWidth;
                  }
                  if (width) {
                    columnProps.style.width = width;
                  }
                  if (maxWidth) {
                    columnProps.style.maxWidth = maxWidth;
                  }

                  const { onClick, style, ...restColumnProps } =
                    column.getHeaderProps(columnProps);

                  return (
                    <div
                      {...restColumnProps}
                      key={column?.id}
                      className="th"
                      style={{ ...style, cursor: 'default' }}
                    >
                      <div className="cell">
                        <div>
                          {column.render('Header')}
                          {hasDescription &&
                            idHeader !== 'actions' &&
                            idHeader !== 'selection' && (
                              <Tooltip
                                content={desc || 'No description'}
                                placement="top"
                              >
                                <div style={{ marginLeft: 6 }}>
                                  <IoIosInformationCircleOutline
                                    size={12}
                                    style={{ color: '#7AC8C5' }}
                                  />
                                </div>
                              </Tooltip>
                            )}
                          {status}
                          {icon}
                        </div>
                        {!isRemoveSort && !originalId && (canSort || isSorted) && (
                          <Tooltip
                            content="Sort"
                            placement="top"
                            disabled={isMobileDevice}
                          >
                            <div onClick={onClick} role="button" tabIndex={0}>
                              {column.isSortedDesc ? (
                                <BiSortDown
                                  color={
                                    column?.isSorted ? '#00A19C' : '#9F9EAE'
                                  }
                                  size={12}
                                />
                              ) : (
                                <BiSortUp
                                  color={
                                    column?.isSorted ? '#00A19C' : '#9F9EAE'
                                  }
                                  size={12}
                                />
                              )}
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {rows.length > 0 ? (
          <div {...getTableBodyProps()} className="edh-react-table__table-body">
            {rows.map(row => {
              prepareRow(row); // Lazily preparing a row for rendering
              const { original = {} } = row;
              const rowProps = row.getRowProps();
              rowProps.style = onUpdateRowStyle(
                { ...rowProps.style, cursor: 'default' },
                hasSticky,
              );
              const isExpanded = expandedRows.includes(row.id);
              return (
                <React.Fragment key={rowProps.key}>
                  <div
                    {...rowProps}
                    className={[
                      'tr',
                      original?.isActive ? 'active' : '',
                      (hasCustomExpandRow ? isExpanded : row.isExpanded)
                        ? 'expanded'
                        : '',
                      hasExpand ? 'has-expand' : '',
                      row?.isSelected ? 'is-selected' : '',
                    ]
                      .filter(Boolean)
                      .join(' ')}
                    role="button"
                    tabIndex="0"
                    onDoubleClick={() => onRowDoubleClick(row?.original)}
                    onClick={() => onRowClick(row)}
                    onMouseEnter={() => {
                      row?.setState(state => ({ ...state, isHover: true }));
                    }}
                    onMouseLeave={() => {
                      row?.setState(state => ({ ...state, isHover: false }));
                    }}
                  >
                    {row.cells.map(cell => {
                      const { flex, minWidth, width, maxWidth, onCellClick } =
                        cell?.column;
                      const cellProps = cell.getCellProps();

                      if (flex) {
                        cellProps.style.flex = flex;
                      }
                      if (minWidth) {
                        cellProps.style.minWidth = minWidth;
                      }
                      if (width) {
                        cellProps.style.width = width;
                      }
                      if (maxWidth) {
                        cellProps.style.maxWidth = maxWidth;
                      }

                      if (onCellClick) {
                        cellProps.onClick = () => {
                          onCellClick(cell);
                        };
                        cellProps.style.cursor = 'pointer';
                      }

                      return (
                        <div
                          onFocus={() => onCellFocus(cell)}
                          onBlur={() => onCellBlur(cell)}
                          {...cellProps}
                          key={cellProps.key}
                          className={[
                            'td',
                            focusedCell?.row?.id === cell.row.id &&
                            focusedCell?.column?.id === cell.column.id &&
                            isCellClick
                              ? 'active-cell'
                              : '',
                          ].join(' ')}
                        >
                          {hasExpand &&
                          !cell?.column?.disableExpand &&
                          !cell?.column?.disableExpandFunc?.(cell.row) &&
                          cell?.column?.id !== 'actions' &&
                          cell?.column?.id !== 'selection' ? (
                            disableCollapseDefaultExpand &&
                            defaultExpanded[cell.row.id] ? (
                              <div className="cell">{cell.render('Cell')}</div>
                            ) : (
                              <span {...row.getToggleRowExpandedProps()}>
                                <div
                                  className="cell"
                                  onClick={() => onPrincipleRuleClick(row)}
                                  role="button"
                                  tabIndex={-1}
                                >
                                  {cell.render('Cell')}
                                </div>
                              </span>
                            )
                          ) : (
                            <div
                              className="cell"
                              style={{
                                cursor:
                                  cell?.column?.disableExpandFunc && 'default',
                              }}
                            >
                              {cell.column.preventDefaultRender ? (
                                cell.render('Cell')
                              ) : cell?.column?.isClickHereToView ? (
                                onRenderRowSubComponent(
                                  cell?.column?.id,
                                  cell?.row?.original?.cosmosId,
                                )
                              ) : (
                                <EllipseCellTable
                                  column={cell?.column}
                                  row={cell?.row}
                                >
                                  {cell.render('Cell')}
                                </EllipseCellTable>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  {(hasCustomExpandRow || hasExpand) &&
                  onRenderRowSubComponent &&
                  (hasCustomExpandRow ? isExpanded : row.isExpanded)
                    ? onRenderRowSubComponent({ row })
                    : null}
                </React.Fragment>
              );
            })}
          </div>
        ) : (
          <div
            {...getTableBodyProps()}
            style={{ width: '100%' }}
            className="edh-react-table__table-body no-data"
          >
            <div className="tr">
              <div className="edh-react-table__no-data td">
                <MessageNoData
                  title={messageTitleNoData}
                  description={messageDescriptionNoData}
                  isLoading={isLoading}
                  img={img}
                >
                  {elementNoData}
                </MessageNoData>
              </div>
            </div>
          </div>
        )}
      </div>
      {hasPagination && rows.length > 0 && (
        <Pagination
          totalPages={totalPages}
          totalCount={totalCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          pageOptions={pageOptions}
          page={page}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          onGotoPage={gotoPage}
          onNextPage={nextPage}
          onPreviousPage={previousPage}
          onSetPageSize={onSetPageSize}
        />
      )}
    </div>
  );
};

ReactTableCustom.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.any,
  data: PropTypes.any,
  className: PropTypes.string,
  defaultSortBy: PropTypes.array,
  defaultExpanded: PropTypes.object,
  currentSeletedRows: PropTypes.object,
  pageSizeOptions: PropTypes.array,
  filters: PropTypes.object,
  defaultPageSize: PropTypes.number,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  actionWidth: PropTypes.number,
  messageTitleNoData: PropTypes.string,
  messageDescriptionNoData: PropTypes.node,
  expandedRows: PropTypes.array,
  hasCustomExpandRow: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCellClick: PropTypes.bool,
  hasExpand: PropTypes.bool,
  hasBorder: PropTypes.bool,
  hasStriped: PropTypes.bool,
  enableSortOption: PropTypes.bool,
  hasScrollBtn: PropTypes.bool,
  hasPagination: PropTypes.bool,
  hasSelection: PropTypes.bool,
  hasCustomSelection: PropTypes.any,
  hasSelectionNumner: PropTypes.bool,
  hasActions: PropTypes.bool,
  hasViewColumn: PropTypes.bool,
  hasStickyAction: PropTypes.bool,
  hasStickyHeader: PropTypes.bool,
  hasResetViewColumn: PropTypes.bool,
  hasDescription: PropTypes.bool,
  manualPagination: PropTypes.bool,
  manualSortBy: PropTypes.bool,
  autoResetPage: PropTypes.bool,
  autoResetHiddenColumns: PropTypes.bool,
  autoResetSortBy: PropTypes.bool,
  autoResetSelect: PropTypes.bool,
  autoResetExpanded: PropTypes.bool,
  disableCollapseDefaultExpand: PropTypes.bool,
  onRenderColumnAction: PropTypes.func,
  onRenderRowSubComponent: PropTypes.func,
  onSelectedRows: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowClick: PropTypes.func,
  onPrincipleRuleClick: PropTypes.func,
  onSetCurrentVisibleColumns: PropTypes.func,
  onFetchData: PropTypes.func,
  onBlurRow: PropTypes.func,
  updateMyData: PropTypes.func,
  keywords: PropTypes.string,
  img: PropTypes.any,
  elementNoData: PropTypes.node,
};

ReactTableCustom.defaultProps = {
  id: 'table-custom',
  columns: [],
  data: [],
  className: '',
  defaultSortBy: [],
  defaultExpanded: {},
  currentSeletedRows: {},
  filters: {},
  defaultPageSize: PAGE_SIZE_OPTIONS[0]?.accessor,
  totalCount: 0,
  totalPages: 0,
  currentPage: 0,
  actionWidth: 55,
  messageTitleNoData: '',
  messageDescriptionNoData: '',
  expandedRows: [],
  hasCustomExpandRow: false,
  isLoading: false,
  isCellClick: false,
  hasExpand: false,
  hasBorder: false,
  hasStriped: false,
  enableSortOption: false,
  hasScrollBtn: false,
  hasSelection: false,
  hasSelectionNumner: false,
  hasActions: false,
  hasViewColumn: false,
  hasStickyAction: false,
  hasStickyHeader: false,
  hasPagination: true,
  hasResetViewColumn: false,
  hasDescription: false,
  manualPagination: true,
  manualSortBy: true,
  autoResetPage: false,
  autoResetHiddenColumns: false,
  autoResetSortBy: false,
  autoResetExpanded: true,
  disableCollapseDefaultExpand: false,
  onRenderColumnAction: null,
  updateMyData() {},
  onRenderRowSubComponent() {},
  onSelectedRows() {},
  onRowDoubleClick() {},
  onRowClick() {},
  onPrincipleRuleClick() {},
  onSetCurrentVisibleColumns() {},
  onFetchData() {},
  onBlurRow() {},
  elementNoData: null,
};

export default memo(ReactTableCustom);
