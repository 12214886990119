import { useEffect } from 'react';

/**
 * useDidMount hook
 * @param {Function} callback Callback function to be called on mount
 */

function useDidMount(callback) {
  useEffect(() => {
    if (typeof callback === 'function') {
      callback();
    }
  }, []);
}

export { useDidMount };
