import { call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  addNewCommentFailure,
  addNewCommentRequest,
  addNewCommentSuccess,
  getCommentsFailure,
  getCommentsRequest,
  getCommentsSuccess,
} from '../slices/comments';
import { getData, postData } from '../services/api';
import { EBG_COMMENT_ADD, EBG_COMMENT_GET } from '../constants/paths';

export function* getComments({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(getData, {
      url: EBG_COMMENT_GET,
      params: payload.data,
    });

    yield delay(800);
    yield put(
      getCommentsSuccess({
        data,
      }),
    );
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getCommentsFailure({ error: message }));
  }
}

export function* addComment({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(postData, {
      url: EBG_COMMENT_ADD,
      data: payload.data,
    });

    yield put(addNewCommentSuccess({ data }));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(addNewCommentFailure({ error: message }));
  }
}

function* commentSaga() {
  yield takeLatest(getCommentsRequest().type, getComments);
  yield takeLatest(addNewCommentRequest().type, addComment);
}

export default commentSaga;
