export const isNullOrUndefined = value =>
  value === null || value === undefined || value === '';

export const numberToUnitSuffix = (number, float = 1, method = 'floor') => {
  if (isNullOrUndefined(number)) return 0;
  const map = [
    {
      suffix: 'B',
      value: 9,
    },
    {
      suffix: 'M',
      value: 6,
    },
    {
      suffix: 'K',
      value: 3,
    },
  ];
  for (const item of map) {
    if (number / 10 ** item.value >= 1) {
      const quotient = Math[method](number / 10 ** (item.value - float));
      return `${quotient / 10 ** float}${item.suffix}`;
    }
  }
  return number;
};
