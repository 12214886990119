import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const PROPS = {
  INTERNAL_RESULT: 'internalResult',
  EXTERNAL_RESULT: 'externalResult',
};

export const ROOT_STATE_NAME = 'dataMarketplace';
const initialState = {
  filterOption: {
    data: [],
    status: 'idle',
    error: null,
  },
  sampleData: {
    data: {
      items: [],
    },
    status: 'idle', // or: 'loading', 'succeeded', 'failed'
  },
  suggestedKeywordDataMarketplace: {
    data: [],
    status: 'idle',
    error: null,
  },
  dataLineage: {
    data: [],
    status: 'idle',
    error: null,
  },
  overallData: {
    data: {
      items: [],
    },
    status: 'loading',
    error: null,
  },
  confidenceScore: {
    data: {},
    status: 'loading',
    error: null,
  },
  dataMarketplaceSuggestion: {
    data: [],
    status: 'idle',
    error: null,
  },
  [PROPS.INTERNAL_RESULT]: {
    data: [],
    status: 'idle',
    error: null,
  },
  [PROPS.EXTERNAL_RESULT]: {
    data: [],
    status: 'idle',
    error: null,
  },
};

const dataMarketplaceSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    favouriteDataset(state, action) {
      const index = state.datasets.data.items.findIndex(
        item => item.id === action.payload.id,
      );

      if (index !== -1) {
        state.datasets.data.items[index].isFavorite =
          !state.datasets.data.items[index].isFavorite;
      }
    },
    getFilterOptionRequest(state) {
      state.filterOption.status = 'loading';
    },
    getFilterOptionSuccess(state, action) {
      state.filterOption.data = action.payload;
      state.filterOption.status = 'succeeded';
    },
    getFilterOptionFailure(state, action) {
      state.filterOption.status = 'failed';
      state.filterOption.data = [];
      state.filterOption.error = action.payload;
    },
    getDataLineageRequest(state) {
      state.dataLineage.status = 'loading';
    },
    getDataLineageSuccess(state, action) {
      state.dataLineage.data = action.payload;
      state.dataLineage.status = 'succeeded';
    },
    getDataLineageFailure(state, action) {
      state.dataLineage.status = 'failed';
      state.filterOption.data = [];
      state.dataLineage.error = action.payload;
    },
    clearDataLineageRequest(state) {
      state.dataLineage.status = 'loading';
      state.dataLineage.data = [];
    },
    getSampleDataActionsRequest(state) {
      state.sampleData.status = 'loading';
    },
    getSampleDataActionsSuccess(state, action) {
      state.sampleData.data = action.payload;
      state.sampleData.status = 'succeeded';
    },
    getSampleDataActionsFailure(state, action) {
      state.sampleData.status = 'failed';
      state.sampleData.data = {
        items: [],
      };
      state.sampleData.error = action.payload;
    },
    postSuggestedKeywordDataMarketplaceRequest(state) {
      state.suggestedKeywordDataMarketplace.status = 'loading';
    },
    postSuggestedKeywordDataMarketplaceSuccess(state, action) {
      state.suggestedKeywordDataMarketplace.data = action.payload;
      state.suggestedKeywordDataMarketplace.status = 'succeeded';
    },
    postSuggestedKeywordDataMarketplaceFailure(state, action) {
      state.suggestedKeywordDataMarketplace.status = 'failed';
      state.suggestedKeywordDataMarketplace.data = [];
      state.suggestedKeywordDataMarketplace.error = action.payload;
    },
    getOverallDataRequest(state) {
      state.overallData.status = 'loading';
    },
    getOverallDataSuccess(state, action) {
      state.overallData.data = action.payload;
      state.overallData.status = 'succeeded';
    },
    getOverallDataFailure(state, action) {
      state.overallData.status = 'failed';
      state.overallData.data = {
        items: [],
      };
      state.overallData.error = action.payload;
    },
    getConfidenceScoreRequest(state) {
      state.confidenceScore.status = 'loading';
    },
    getConfidenceScoreSuccess(state, action) {
      state.confidenceScore.data = action.payload;
      state.confidenceScore.status = 'succeeded';
    },
    getConfidenceScoreFailure(state, action) {
      state.confidenceScore.status = 'failed';
      state.confidenceScore.data = {};
      state.confidenceScore.error = action.payload;
    },
    postDataMarketplaceSuggestionRequest(state) {
      state.dataMarketplaceSuggestion.status = 'loading';
    },
    postDataMarketplaceSuggestionSuccess(state, action) {
      state.dataMarketplaceSuggestion.data = action.payload;
      state.dataMarketplaceSuggestion.status = 'succeeded';
    },
    postDataMarketplaceSuggestionFailure(state, action) {
      state.dataMarketplaceSuggestion.status = 'failed';
      state.dataMarketplaceSuggestion.data = [];
      state.dataMarketplaceSuggestion.error = action.payload;
    },
    postDataMarketplaceSearchResultRequest(state, action) {
      state[action.payload.prop].status = 'loading';
    },
    postDataMarketplaceSearchResultSuccess(state, action) {
      const { prop, data, isPagination } = action.payload;

      if (!isPagination && data.currentPage !== 1) {
        data.items = [...state[prop].data.items, ...data.items];
      }

      state[prop].data = data;
      state[prop].status = 'succeeded';
    },
    postDataMarketplaceSearchResultFailure(state, action) {
      state[action.payload.prop].status = 'failed';
      state[action.payload.prop].data = {};
      state[action.payload.prop].error = action.payload.error;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = dataMarketplaceSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  favouriteDataset,
  getFilterOptionRequest,
  getFilterOptionSuccess,
  getFilterOptionFailure,
  getDataLineageRequest,
  getDataLineageSuccess,
  getDataLineageFailure,
  clearDataLineageRequest,
  getSampleDataActionsRequest,
  getSampleDataActionsSuccess,
  getSampleDataActionsFailure,
  postSuggestedKeywordDataMarketplaceRequest,
  postSuggestedKeywordDataMarketplaceSuccess,
  postSuggestedKeywordDataMarketplaceFailure,
  getOverallDataRequest,
  getOverallDataSuccess,
  getOverallDataFailure,
  getConfidenceScoreRequest,
  getConfidenceScoreSuccess,
  getConfidenceScoreFailure,
  postDataMarketplaceSuggestionRequest,
  postDataMarketplaceSuggestionSuccess,
  postDataMarketplaceSuggestionFailure,
  postDataMarketplaceSearchResultRequest,
  postDataMarketplaceSearchResultSuccess,
  postDataMarketplaceSearchResultFailure,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const filterOptionsSelector = createSelector(
  rootSelector,
  ({ filterOption }) => filterOption,
);

export const sampleDataSelector = createSelector(
  rootSelector,
  ({ sampleData }) => sampleData,
);

export const dataLineageSelector = createSelector(
  rootSelector,
  ({ dataLineage }) => dataLineage,
);

export const suggestedKeywordDataMarketplaceSelector = createSelector(
  rootSelector,
  ({ suggestedKeywordDataMarketplace }) => suggestedKeywordDataMarketplace,
);

export const overallDataSelector = createSelector(
  rootSelector,
  ({ overallData }) => overallData,
);

export const confidenceScoreSelector = createSelector(
  rootSelector,
  ({ confidenceScore }) => confidenceScore,
);

export const dataMarketplaceSuggestionSelector = createSelector(
  rootSelector,
  ({ dataMarketplaceSuggestion }) => dataMarketplaceSuggestion,
);

export const dataMarketplaceSearchResultSelector = prop =>
  createSelector(rootSelector, state => state[prop]);
