const roles = {
  user: 'user',
  admin: 'Admin',
  dgaAdmin: 'DGAAdmin',
  dataSteward: 'DataSteward',
  dqAdmin: 'DQAdmin',
  amAdmin: 'AMAdmin',
  communicator: 'Communicator',
  eds: 'ExecutiveDataSteward',
  editor: 'Editor',
  reviewer: 'Reviewer',
  approver: 'Approver',
  publisher: 'Publisher',
};

export default roles;
