export const CLASSIFICATION_TYPES = {
  open: 'Open',
  internal: 'Internal',
  confidential: 'Confidential',
  secret: 'Secret',
};

export const TAG_LIST_TYPES = {
  classification: 'classifications',
  businessDomain: 'businessDomains',
  dataDomain: 'dataDomains',
  masterAndReferenceDomain: 'masterAndReferenceDomains',
  label: 'others',
};

export const MASTER_AND_REFERENCE_DOMAINS = {
  masterData: 'Master Data',
  referenceData: 'Reference Data',
};

export const DATASET_SEARCH_TYPE = {
  searchPage: 1,
  dataMarketplacePage: 2,
  internalDatasetfavourite: 3,
  AnalyticMarketplace: 4,
  externalDatasetfavourite: 5,
};

export const MY_WORKSPACE_DATASET_TYPES = {
  favorite: 'favorite',
  download: 'download',
};

export const SELECT_TYPE = {
  select: 'Select',
  selectRecord: 'Select Record',
  selectColumn: ' Select Column',
};
