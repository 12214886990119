import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';

import { ReactComponent as PlayRoundIcon } from 'assets/icons/common/play-round.svg';
import { renderModelInput } from 'services/am-utils';
import {TEXT_UNDER_WATERMARK} from '../../../../../constants';

const PromptComponent = ({
  id,
  resultInput = { maxLength: 1000, rows: 3 },
  modelInput = '',
  onSubmit,
  onSetModelDescription,
}) => {
  const { maxLength = 1000, rows = 3 } = resultInput;
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm({ defaultValues: { description: '' } });

  const submitHandler = ({ description }) => {
    onSubmit({ description });
    onSetModelDescription(`${TEXT_UNDER_WATERMARK}: "${description}"`);
  };

  return (
    <>
      <form className="sample-data__form-container">
        <label className="sample-data__label" htmlFor={id}>
          Prompt
        </label>        
        <textarea
          className="sample-data__text"
          rows={rows}
          placeholder="Insert Text"
          maxLength={maxLength}
          {...register('description', {
            required: true,
          })}
        />
        {renderModelInput(modelInput)}
      </form>
      <Button
        variant="custom-primary"
        className="sample-data__action-button run-button"
        onClick={handleSubmit(submitHandler)}
        disabled={!isDirty || errors?.sample}
      >
        <PlayRoundIcon width={16} height={16} viewBox="0 0 20 20" />
        <p>Run Model</p>
      </Button>
    </>
  );
};

PromptComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  modelInput: PropTypes.string,
  onSubmit: PropTypes.func,
  resultInput: PropTypes.shape({
    maxLength: PropTypes.number,
    rows: PropTypes.number,
  }),
  onSetModelDescription: PropTypes.func
};

export default memo(PromptComponent);
