import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const ModalTitle = ({ category, title, ...rest }) => (
  <div className="edh-modal-title" {...rest}>
    {category && <p className="edh-modal-title__category">{category}</p>}
    {title && <h1 className="edh-modal-title__value">{title}</h1>}
  </div>
);

ModalTitle.propTypes = {
  category: PropTypes.string,
  title: PropTypes.string,
};

export default memo(ModalTitle);
