import React, { memo } from 'react';
import PropTypes from 'prop-types';

import withModal from 'hocs/withModal';

import Popup, { PopupGroup } from 'components/core/Popup';

import './styles.scss';

const ConfirmModal = ({
  loading,
  type,
  className,
  title,
  message,
  category,
  yesText,
  customText,
  cancelText,
  isCancel,
  onHide,
  onYesClick,
  onCancelClick,
}) => {
  let actions = [
    {
      id: 2,
      color: type === 'success' ? 'primary' : 'danger',
      variant: 'contained',
      children: customText || type === 'success' ? yesText : 'Yes',
      onClick: onYesClick,
      loading,
    },
  ];

  if (isCancel) {
    actions = [
      {
        id: 1,
        variant: 'outlined',
        color: 'secondary',
        children: cancelText,
        onClick: onCancelClick,
      },
      ...actions,
    ];
  }

  return (
    <Popup
      onHide={onHide}
      category={category}
      title={title}
      actionsFooter={actions}
      className={className}
    >
      <PopupGroup>
        <p className="edh-confirm-modal__content">{message}</p>
      </PopupGroup>
    </Popup>
  );
};

ConfirmModal.propTypes = {
  loading: PropTypes.bool,
  isCancel: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  yesText: PropTypes.string,
  customText: PropTypes.string,
  cancelText: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  category: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onYesClick: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
  loading: false,
  isCancel: true,
  className: '',
  type: 'success',
  cancelText: 'Cancel',
  yesText: 'Yes, proceed',
  message: [],
};

export default memo(
  withModal(ConfirmModal, {
    backdrop: 'static',
    dialogClassName: 'edh-confirm-modal',
    centered: true,
  }),
);
