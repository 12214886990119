import React, { memo } from 'react';
import PropTypes from 'prop-types';
import BButton from 'react-bootstrap/Button';
import { BsXLg } from 'react-icons/bs';

import classnames from 'services/classnames';

import './styles.scss';

const SUCCESS = 'success';
const DANGER = 'danger';
const WARNING = 'warning';

const Toast = ({
  title,
  description,
  variant,
  buttonText,
  onClick,
  onClose,
}) => (
  <div
    className={classnames('edh-toast', {
      [`edh-toast--${variant}`]: [SUCCESS, DANGER, WARNING].includes(variant),
    })}
  >
    <div className="edh-toast__header">
      <span>{title}</span>
      <BButton onClick={onClose}>
        <BsXLg />
      </BButton>
    </div>
    <div className="edh-toast__body">{description}</div>
    <BButton onClick={onClick}>{buttonText}</BButton>
  </div>
);

Toast.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
    PropTypes.element,
  ]),
  variant: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

Toast.defaultProps = {
  title: '',
  description: '',
  variant: 'success',
  buttonText: 'Okay',
  onClick() {},
  onClose() {},
};

export default memo(Toast);
