import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { METHOD } from 'constants/apis';
import {
  ANALYTICS_MARKETPLACE_GET_THEMES,
  UPDATE_AI_MODEL_BEHAVIOR_PATH,
} from '../constants/paths';

import useAPI from './useAPI';

import {
  aiModelSelector,
  deleteMyUserReviewRequest,
  doActionUserReviewRequest,
  filterOptionsSelector,
  getAIModelRequest,
  getFilterOptionsRequest,
  getFirstAccessRequest,
  getListAIModelRequest,
  getListUserReportRequest,
  getListUserReviewRequest,
  getMyUserReviewRequest,
  getMyUserReviewSelector,
  getTotalAIModelRequest,
  getTotalAIModelSelector,
  getVersionHistoryRequest,
  isFirstAccessSelector,
  listAIModelGenericSelector,
  listAIModelOfflineSelector,
  listAIModelOnlineSelector,
  listUserReportSelector,
  listUserReviewSelector,
  listVersionHistorySelector,
  postUserReviewClean,
  postUserReviewRequest,
  postUserReviewSelector,
  runModelRequest,
  runModelSelector,
  runModelSuccess,
  runSampleDataRequest,
  savedFilterSelector,
  saveOptionsSelector,
  setSavedFilter,
  setSaveOptions,
  setSearchKeyword,
  updateMyUserReviewRequest,
  addToWorkspaceRequest,
  addToWorkspaceSelector,
  getWorkspaceAMRequest,
  workspaceAMSelector,
  getPresetQuestionsSelector,
  getPresetQuestionsRequest,
  setAiModel,
  updateBehaviorAIModel,
  runCognitiveModelRequest,
  getBehaviorModels,
  runCognitiveModelSuccess,
  runCognitiveModelSelector,
  saveCognitiveModelRequest,
  saveCognitiveModelSelector,
} from '../slices/analyticsMarketplace';

const useAnalyticsMarketplace = () => {
  const dispatch = useDispatch();

  const filterOptions = useSelector(filterOptionsSelector);
  const saveOptions = useSelector(saveOptionsSelector);
  const savedFilter = useSelector(savedFilterSelector);
  const aiModel = useSelector(aiModelSelector);
  const selectGetBehaviorModels = useSelector(getBehaviorModels);
  const selectUserReviewModel = useSelector(listUserReviewSelector);
  const selectUserReportModel = useSelector(listUserReportSelector);
  const selectUserReviewPost = useSelector(postUserReviewSelector);
  const selectMyReview = useSelector(getMyUserReviewSelector);
  const selectVersionHistory = useSelector(listVersionHistorySelector);
  const selectRunModel = useSelector(runModelSelector);
  const hasFirstAccess = useSelector(isFirstAccessSelector);
  const selectLandingPageAM = useSelector(getTotalAIModelSelector);
  const selectListAIModelOffline = useSelector(listAIModelOfflineSelector);
  const selectListAIModelOnline = useSelector(listAIModelOnlineSelector);
  const selectListAIModelGeneric = useSelector(listAIModelGenericSelector);
  const selectWorkspaceAM = useSelector(workspaceAMSelector);
  const selectPresetQuestions = useSelector(getPresetQuestionsSelector);
  const selectRunCognitiveModel = useSelector(runCognitiveModelSelector);
  const selectSaveCognitiveModel = useSelector(saveCognitiveModelSelector);

  const { data: listAMTheme, onCallAPI: onCallListAMTheme } = useAPI();
  const { onCallAPI: onUpdateAIModelBehavior } = useAPI();

  const selectListModelData = type => {
    switch (type) {
      case 'online':
        return selectListAIModelOnline;
      case 'offline':
        return selectListAIModelOffline;
      case 'generic':
        return selectListAIModelGeneric;
      default:
        break;
    }
  };
  const addToWorkspaceSelected = useSelector(addToWorkspaceSelector);

  const onSetSaveOptions = useCallback(params => {
    dispatch(setSaveOptions(params));
  }, []);

  const onResetSaveOptions = useCallback(() => {
    dispatch(
      setSaveOptions({
        InteractiveModels: '',
        GenericModels: '',
        CitizenAnalyticsModels: '',
      }),
    );
  }, []);

  const onSetSavedFilter = useCallback(params => {
    dispatch(setSavedFilter(params));
  }, []);

  const onSetSearchKeyword = useCallback(params => {
    dispatch(setSearchKeyword(params));
  }, []);

  const onGetFilterOptions = useCallback(() => {
    dispatch(getFilterOptionsRequest());
  }, []);

  const onGetListAIModel = useCallback((params, type) => {
    if (type === 'online') {
      dispatch(getListAIModelRequest({ ...params, modelTypes: [0] }));
    } else if (type === 'offline') {
      dispatch(getListAIModelRequest({ ...params, modelTypes: [1] }));
    } else if (type === 'generic') {
      dispatch(getListAIModelRequest({ ...params, modelTypes: [2] }));
    } else {
      dispatch(getListAIModelRequest({ ...params, modelTypes: [0, 1, 2] }));
    }
  }, []);

  const onGetAIModel = useCallback(params => {
    dispatch(getAIModelRequest({ id: params }));
  }, []);

  const onSetAiModel = useCallback(params => {
    dispatch(setAiModel(params));
  });

  const onGetUserReviewModel = useCallback(params => {
    dispatch(getListUserReviewRequest(params));
  }, []);

  const onGetMyUserReview = useCallback(params => {
    dispatch(getMyUserReviewRequest(params));
  }, []);

  const onUpdateMyUserReview = useCallback(params => {
    dispatch(updateMyUserReviewRequest(params));
  }, []);

  const onDeleteMyUserReview = useCallback(params => {
    dispatch(deleteMyUserReviewRequest(params));
  }, []);

  const onPostUserReview = useCallback(params => {
    dispatch(postUserReviewRequest(params));
  }, []);

  const onGetUserReportModel = useCallback(params => {
    dispatch(getListUserReportRequest(params));
  }, []);

  const onPostUserReviewClean = useCallback(() => {
    dispatch(postUserReviewClean());
  }, []);

  const onGetVersionHistory = useCallback(params => {
    dispatch(getVersionHistoryRequest(params));
  }, []);

  const updateAIModelBehavior = useCallback(params => {
    onUpdateAIModelBehavior({
      method: METHOD.POST,
      url: UPDATE_AI_MODEL_BEHAVIOR_PATH,
      data: params,
    }).then(res => {
      const { isSuccess, data } = res;
      if (isSuccess) {
        dispatch(updateBehaviorAIModel(data));
      }
    });
  }, []);

  const runModel = useCallback(params => {
    dispatch(runModelRequest(params));
  }, []);

  const updateRunModelResult = useCallback(params => {
    dispatch(runModelSuccess(params));
  }, []);

  const runSampleData = useCallback(params => {
    dispatch(runSampleDataRequest(params));
  }, []);

  const onGetFirstAccessAM = useCallback(params => {
    dispatch(getFirstAccessRequest(params));
  }, []);

  const onClearFirstAccessAM = useCallback(() => {
    dispatch(getFirstAccessRequest(false));
  }, []);

  const onGetTotalAIModel = useCallback(params => {
    dispatch(getTotalAIModelRequest(params));
  }, []);

  const onDoActionUserReview = useCallback(params => {
    dispatch(doActionUserReviewRequest(params));
  }, []);

  const onAddToWorkspace = useCallback(params => {
    dispatch(addToWorkspaceRequest(params));
  }, []);

  const onGetListWorkspaceAM = useCallback(params => {
    dispatch(getWorkspaceAMRequest(params));
  }, []);

  const onGetPresetQuestions = useCallback(() => {
    dispatch(getPresetQuestionsRequest());
  }, []);

  const onGetListAMTheme = useCallback(() => {
    onCallListAMTheme({
      method: METHOD.GET,
      url: ANALYTICS_MARKETPLACE_GET_THEMES,
    });
  }, []);

  const runCognitiveModel = useCallback(params => {
    dispatch(runCognitiveModelRequest(params));
  }, []);

  const updateRunCognitiveModelResult = useCallback(params => {
    dispatch(runCognitiveModelSuccess(params));
  }, []);

  const saveCognitiveModel = useCallback(params => {
    dispatch(saveCognitiveModelRequest(params));
  }, []);

  return {
    filterOptions,
    saveOptions,
    savedFilter,
    selectListAIModelOffline,
    selectListAIModelOnline,
    aiModel,
    selectGetBehaviorModels,
    selectUserReviewModel,
    selectMyReview,
    selectUserReviewPost,
    selectVersionHistory,
    selectRunModel,
    selectUserReportModel,
    hasFirstAccess,
    selectLandingPageAM,
    selectListAIModelGeneric,
    selectListModelData,
    selectWorkspaceAM,
    selectPresetQuestions,
    listAMTheme,
    onGetFirstAccessAM,
    onGetFilterOptions,
    onSetSaveOptions,
    onResetSaveOptions,
    onSetSearchKeyword,
    onGetListAIModel,
    onGetAIModel,
    onSetAiModel,
    onGetUserReviewModel,
    onGetMyUserReview,
    onUpdateMyUserReview,
    onDeleteMyUserReview,
    onPostUserReview,
    onPostUserReviewClean,
    onGetUserReportModel,
    onGetVersionHistory,
    updateAIModelBehavior,
    runModel,
    updateRunModelResult,
    runSampleData,
    onClearFirstAccessAM,
    onGetTotalAIModel,
    onDoActionUserReview,
    onSetSavedFilter,
    onAddToWorkspace,
    onGetListWorkspaceAM,
    addToWorkspaceSelected,
    onGetPresetQuestions,
    onGetListAMTheme,
    runCognitiveModel,
    updateRunCognitiveModelResult,
    selectRunCognitiveModel,
    saveCognitiveModel,
    selectSaveCognitiveModel,
  };
};

export default useAnalyticsMarketplace;
