import React, { memo } from 'react';
import PropTypes from 'prop-types';

import EmptyStateImg from '../../../assets/images/common/empty-state.jpg';

import './styles.scss';

const MessageNoData = ({ children, img, title, description, isLoading }) => (
  <div
    className={['edh-message-no-data', isLoading ? 'isLoading' : ''].join(' ')}
  >
    {img && (
      <img src={img} alt="No data" className="edh-message-no-data__img" />
    )}
    <h3 className="edh-message-no-data__title">{title}</h3>
    <p className="edh-message-no-data__description">{description}</p>
    {children}
  </div>
);

MessageNoData.propTypes = {
  children: PropTypes.any,
  img: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  isLoading: PropTypes.bool,
};

MessageNoData.defaultProps = {
  img: EmptyStateImg,
  title: 'No result found for “-”',
  description: 'We couldn’t find a match for “-”.',
};

export default memo(MessageNoData);
