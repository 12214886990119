import React, { memo } from 'react';
import { uid } from 'services/uid';

const formats = [
  [
    { className: 'ql-bold' },
    { className: 'ql-italic' },
    { className: 'ql-underline' },
    { className: 'ql-strike' },
  ],
  [
    { className: 'ql-script', value: 'sub' },
    { className: 'ql-script', value: 'super' },
  ],
  [
    { className: 'ql-list', value: 'ordered' },
    { className: 'ql-list', value: 'bullet' },
  ],
];

const renderSingle = formatData => {
  const { className, value } = formatData;
  return (
    <button
      type="button"
      key={`${uid()}_${className}`}
      className={className}
      value={value}
      label={value}
    />
  );
};

const CustomToolbar = () => (
  <div id="toolbar">
    {formats.map(classes => (
      <span key={`${uid()}_${classes}`} className="ql-formats">
        {classes.map(formatData => renderSingle(formatData))}
      </span>
    ))}
  </div>
);

export default memo(CustomToolbar);
