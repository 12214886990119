import React, { Suspense, useLayoutEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Switch, useHistory, useLocation, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useDevice from 'hooks/useDevice';

import {
  HOME,
  NEWSFEED,
  NEWSFEED_MANAGE,
  USER_MANAGEMENT,
} from 'constants/breakcrumb';

import { setNonDevelopedNotification } from 'slices/notifications';

import LoadingBalls from 'components/core/LoadingBalls';
import ScrollToTopComponent from 'components/layout/ScrollToTopComponent';

const Authorizing = ({ routes, isScrollToTop }) => {
  const { pathname } = useLocation();
  const { isMobileDevice } = useDevice();
  const dispatch = useDispatch();
  const history = useHistory();

  useLayoutEffect(() => {
    const isNonDev = [USER_MANAGEMENT, NEWSFEED, NEWSFEED_MANAGE].find(
      item => pathname === item.id,
    );
    if (isNonDev && isMobileDevice) {
      dispatch(
        setNonDevelopedNotification({ data: { domain: isNonDev.label } }),
      );
      history.push(HOME.id);
    }
  }, [pathname, isMobileDevice]);

  return (
    <Suspense fallback={<LoadingBalls isLoading backdrop={false} />}>
      <Switch>
        {routes.map(
          ({
            path,
            component: Component,
            layout: Layout,
            pageConfig = {},
            exact,
          }) => (
            <Route key={path} exact={exact} path={path}>
              <ScrollToTopComponent isScrollToTop={isScrollToTop}>
                {Layout ? (
                  <Layout pageConfig={pageConfig}>
                    <Component />
                  </Layout>
                ) : (
                  <Component />
                )}
              </ScrollToTopComponent>
            </Route>
          ),
        )}
      </Switch>
    </Suspense>
  );
};

Authorizing.propTypes = {
  routes: PropTypes.array.isRequired,
  isScrollToTop: PropTypes.bool,
};

Authorizing.defaultProps = {
  routes: [],
  isScrollToTop: true,
};

export default memo(Authorizing);
