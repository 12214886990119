import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  PROPS_NOTIFICATIONS,
  notificationsSelector,
  notificationsPopupSelector,
  newNotificationsQuantitySelector,
  getNotificationsRequest,
  removeNotification,
  markAllReadRequest,
  markReadRequest,
  deleteNotificationRequest,
  saveTimeOpenClosePopupRequest,
  getNewNotificationQuantityRequest,
  changeIsShowPopup,
} from '../slices/notifications';

const useNotifications = () => {
  const notifications = useSelector(notificationsSelector);
  const notificationsPopup = useSelector(notificationsPopupSelector);
  const newNotificationsQuantity = useSelector(
    newNotificationsQuantitySelector,
  );

  const dispatch = useDispatch();

  const onGetNotifications = useCallback(params => {
    dispatch(
      getNotificationsRequest({
        params,
        prop: PROPS_NOTIFICATIONS.NOTIFICATIONS,
      }),
    );
  }, []);

  const onGetNotificationsPopup = useCallback(params => {
    dispatch(
      getNotificationsRequest({
        params,
        prop: PROPS_NOTIFICATIONS.NOTIFICATIONS_POPUP,
      }),
    );
  }, []);

  const onRemoveNotification = useCallback(data => {
    dispatch(removeNotification(data));
  }, []);

  const onMarkAllRead = useCallback(() => {
    dispatch(markAllReadRequest({ prop: PROPS_NOTIFICATIONS.NOTIFICATIONS }));
  }, []);

  const onMarkAllReadPopup = useCallback(() => {
    dispatch(
      markAllReadRequest({ prop: PROPS_NOTIFICATIONS.NOTIFICATIONS_POPUP }),
    );
  }, []);

  const onMarkRead = useCallback(id => {
    dispatch(markReadRequest({ id, prop: PROPS_NOTIFICATIONS.NOTIFICATIONS }));
  }, []);

  const onDeleteNotification = useCallback(id => {
    dispatch(
      deleteNotificationRequest({
        id,
        prop: PROPS_NOTIFICATIONS.NOTIFICATIONS,
      }),
    );
  }, []);

  const onDeleteNotificationPopup = useCallback(id => {
    dispatch(
      deleteNotificationRequest({
        id,
        prop: PROPS_NOTIFICATIONS.NOTIFICATIONS_POPUP,
      }),
    );
  }, []);

  const onSaveTimeOpenClosePopup = useCallback(() => {
    dispatch(saveTimeOpenClosePopupRequest());
  }, []);

  const onGetNewNotificationQuantity = useCallback(() => {
    dispatch(getNewNotificationQuantityRequest());
  }, []);

  const onChangeIsShowPopup = useCallback(isShowPopup => {
    dispatch(changeIsShowPopup(isShowPopup));
  }, []);

  return {
    notifications,
    notificationsPopup,
    newNotificationsQuantity,
    onGetNotifications,
    onGetNotificationsPopup,
    onRemoveNotification,
    onMarkAllRead,
    onMarkAllReadPopup,
    onMarkRead,
    onDeleteNotification,
    onDeleteNotificationPopup,
    onSaveTimeOpenClosePopup,
    onGetNewNotificationQuantity,
    onChangeIsShowPopup,
  };
};

export default useNotifications;
