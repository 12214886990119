import React, { memo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import axiosInstance from 'services/api';

import useAxiosInterceptor from 'hooks/useAxiosInterceptor';
import useAuth from 'hooks/useAuth';

// eslint-disable-next-line import/named
import { MainLayout } from 'layouts';
import GlobalLoading from 'components/core/GlobalLoading';
import ForBiddenPage from 'pages/ForBiddenPage';

import { LOCKED } from './constants/errorCodes';

import './styles.scss';

const App = () => {
  const history = useHistory();

  const { isAuthenticated, msalInstance, onLogin } = useAuth();
  const { error } = useAxiosInterceptor(axiosInstance, msalInstance, history);

  useEffect(() => {
    onLogin();
  }, []);

  if (+error.status === LOCKED) return <ForBiddenPage />;

  if (!isAuthenticated) return <GlobalLoading />;

  return <MainLayout />;
};

export default memo(App);
