import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'exportHistory';

const initialState = {
  exportHistoryPopup: {
    data: {
      items: [],
    },
    status: 'loading',
    error: null,
  },
  exportData: {
    data: null,
    status: 'idle',
    error: null,
  },
  validateExport: {
    data: {
      isSuccess: true,
    },
    status: 'idle',
    error: null,
  },
};

const historyExportSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getExportHistoryRequest(state, action) {
      if (action.payload.hasLoading) {
        state.exportHistoryPopup.status = 'loading';
      }
    },
    getExportHistorySuccess(state, action) {
      state.exportHistoryPopup.data = action.payload.data;
      state.exportHistoryPopup.status = 'succeeded';
      state.exportHistoryPopup.error = null;
    },
    getExportHistoryFailure(state, action) {
      state.exportHistoryPopup.status = 'failed';
      state.exportHistoryPopup.data = [];
      state.exportHistoryPopup.error = action.payload.message;
    },
    exportDataRequest(state) {
      state.exportData.status = 'loading';
    },
    exportDataSuccess(state) {
      state.exportData.status = 'succeeded';
      state.exportData.error = null;
    },
    exportDataFailure(state, action) {
      state.exportData.status = 'failed';
      state.exportData.error = action.payload;
    },
    validateExportRequest(state) {
      state.validateExport.status = 'loading';
    },
    validateExportSuccess(state, action) {
      state.validateExport.status = 'succeeded';
      state.validateExport.data = action.payload;
    },
    validateExportFailure(state) {
      state.validateExport.status = 'failed';
      state.validateExport.data = { isSuccess: false };
    },
    clearValidateExport(state) {
      state.validateExport.status = 'idle';
      state.validateExport.data = {
        isSuccess: true,
      };
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = historyExportSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getExportHistoryRequest,
  getExportHistorySuccess,
  getExportHistoryFailure,
  exportDataRequest,
  exportDataSuccess,
  exportDataFailure,
  validateExportRequest,
  validateExportSuccess,
  validateExportFailure,
  clearValidateExport,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const historyExportSelector = createSelector(
  rootSelector,
  ({ exportHistory }) => exportHistory,
);
export const historyExportPopupSelector = createSelector(
  rootSelector,
  ({ exportHistoryPopup }) => exportHistoryPopup,
);
export const exportDataSelector = createSelector(
  rootSelector,
  ({ exportData }) => exportData,
);
export const validateExportDataSelector = createSelector(
  rootSelector,
  ({ validateExport }) => validateExport,
);
