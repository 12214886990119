import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'myRequest';
const initialState = {
  listRequest: {
    data: {
      items: [],
    },
    status: 'loading',
    error: null,
  },
  updateStatus: {
    data: {},
    status: 'loading', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
  postRequestedData: {
    data: {},
    status: 'loading',
    error: null,
  },
  getRequestedData: {
    data: [],
    status: 'idle',
    error: null,
  },
  getRequestedDataId: {
    data: {},
    status: 'idle',
    error: null,
  },
  categories: {
    data: [],
    status: 'loading',
    error: null,
  },
  newUserManagementQuantity: {
    data: {},
    status: 'loading',
    error: null,
  },
  timeAccessToUserManagement: {
    data: {},
    status: 'succeeded',
    error: null,
  },
  listRequestParam: null,
};

const myRequestSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    // user view
    postRequestedDataRequest(state) {
      state.postRequestedData.status = 'loading';
    },
    postRequestedDataSuccess(state, action) {
      state.postRequestedData.data = action.payload;
      state.postRequestedData.status = 'succeeded';
      state.postRequestedData.error = null;
    },
    postRequestedDataFailure(state, action) {
      state.postRequestedData.status = 'failed';
      state.postRequestedData.data = {};
      state.postRequestedData.error = action.payload;
    },
    clearPostRequestedDataRequest(state) {
      state.postRequestedData.data = {};
    },
    getRequestedDataRequest(state) {
      state.getRequestedData.status = 'loading';
    },
    getRequestedDataSuccess(state, action) {
      state.getRequestedData.data = action.payload;
      state.getRequestedData.status = 'succeeded';
      state.getRequestedData.error = null;
    },
    getRequestedDataFailure(state, action) {
      state.getRequestedData.status = 'failed';
      state.getRequestedData.data = {};
      state.getRequestedData.error = action.payload;
    },

    // admin view
    getNewUserManagementQuantityRequest(state) {
      state.newUserManagementQuantity.status = 'loading';
    },
    getNewUserManagementQuantitySuccess(state, action) {
      state.newUserManagementQuantity.data = action.payload;
      state.newUserManagementQuantity.status = 'succeeded';
    },
    getNewUserManagementQuantityFailure(state, action) {
      state.newUserManagementQuantity.status = 'failed';
      state.newUserManagementQuantity.data = {};
      state.newUserManagementQuantity.error = action.payload;
    },
    saveTimeAccessToUserManagementRequest(state) {
      state.timeAccessToUserManagement.status = 'loading';
      state.newUserManagementQuantity.data = {
        count: 0,
      };
    },
    saveTimeAccessToUserManagementSuccess(state) {
      state.timeAccessToUserManagement.status = 'succeeded';
    },
    saveTimeAccessToUserManagementFailure(state, action) {
      state.timeAccessToUserManagement.status = 'failed';
      state.timeAccessToUserManagement.error = action.payload;
    },

    // list request
    getListRequestRequest(state, action) {
      state.listRequest.status = 'loading';
      state.listRequestParam = action.payload;
    },
    getListRequestSuccess(state, action) {
      state.listRequest.data = action.payload;
      state.listRequest.status = 'succeeded';
      state.listRequest.error = null;
    },
    getListRequestFailure(state, action) {
      state.listRequest.status = 'failed';
      state.listRequest.data = {};
      state.listRequest.error = action.payload;
    },

    // view form
    getRequestedDataIdRequest(state) {
      state.getRequestedDataId.status = 'loading';
    },
    getRequestedDataIdSuccess(state, action) {
      state.getRequestedDataId.data = action.payload;
      state.getRequestedDataId.status = 'succeeded';
      state.getRequestedDataId.error = null;
    },
    getRequestedDataIdFailure(state, action) {
      state.getRequestedDataId.status = 'failed';
      state.getRequestedDataId.data = {};
      state.getRequestedDataId.error = action.payload;
    },

    // update status
    updateStatusRequest(state) {
      state.updateStatus.status = 'loading';
    },
    updateStatusSuccess(state, action) {
      state.listRequest.data.items = state.listRequest.data.items.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });
      state.updateStatus.status = 'succeeded';
      state.updateStatus.error = null;
    },
    updateStatusFailure(state, action) {
      state.updateStatus.status = 'failed';
      state.updateStatus.data = {};
      state.updateStatus.error = action.payload;
    },

    // Add new request form
    getCategoriesRequest(state) {
      state.categories.status = 'loading';
    },
    getCategoriesSuccess(state, action) {
      state.categories.data = action.payload;
      state.categories.status = 'succeeded';
      state.categories.error = null;
    },
    getCategoriesFailure(state, action) {
      state.categories.status = 'failed';
      state.categories.data = {};
      state.categories.error = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = myRequestSlice;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  postRequestedDataRequest,
  postRequestedDataSuccess,
  postRequestedDataFailure,
  clearPostRequestedDataRequest,
  getRequestedDataRequest,
  getRequestedDataSuccess,
  getRequestedDataFailure,
  getNewUserManagementQuantityRequest,
  getNewUserManagementQuantitySuccess,
  getNewUserManagementQuantityFailure,
  saveTimeAccessToUserManagementRequest,
  saveTimeAccessToUserManagementSuccess,
  saveTimeAccessToUserManagementFailure,
  getListRequestRequest,
  getListRequestSuccess,
  getListRequestFailure,
  updateStatusRequest,
  updateStatusSuccess,
  updateStatusFailure,
  getRequestedDataIdRequest,
  getRequestedDataIdSuccess,
  getRequestedDataIdFailure,
  getCategoriesRequest,
  getCategoriesSuccess,
  getCategoriesFailure,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};

export const listRequestSelector = createSelector(
  rootSelector,
  ({ listRequest }) => listRequest,
);
export const updateStatusSelector = createSelector(
  rootSelector,
  ({ updateStatus }) => updateStatus,
);
export const postRequestedDataSelector = createSelector(
  rootSelector,
  ({ postRequestedData }) => postRequestedData,
);
export const getRequestedDataSelector = createSelector(
  rootSelector,
  ({ getRequestedData }) => getRequestedData,
);
export const getRequestedDataIdSelector = createSelector(
  rootSelector,
  ({ getRequestedDataId }) => getRequestedDataId,
);
export const getCategoriesSelector = createSelector(
  rootSelector,
  ({ categories }) => categories,
);
export const getNewUserManagementQuantitySelector = createSelector(
  rootSelector,
  ({ newUserManagementQuantity }) => newUserManagementQuantity,
);
export const timeAccessToUserManagementSelector = createSelector(
  rootSelector,
  ({ timeAccessToUserManagement }) => timeAccessToUserManagement,
);
export const listRequestParamSelector = createSelector(
  rootSelector,
  ({ listRequestParam }) => listRequestParam,
);
