import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { VscFeedback } from 'react-icons/vsc';

import { ReactComponent as CloseIcon } from 'assets/icons/common/close.svg';
import { ReactComponent as ScreenIcon } from 'assets/icons/common/screen.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/common/question-answer.svg';
import { ReactComponent as QuickGuideIcon } from 'assets/icons/common/library-book.svg';
import { ReactComponent as SubscribeIcon } from 'assets/icons/common/mark-mail.svg';

import { USER_GUIDE_API_PATH } from 'constants/paths';

import { uid } from 'services/uid';

import useCountDown from 'hooks/useCountDown';
import useAxios from 'hooks/useAxios';

import FeedbackFormModal from 'components/layout/FeedbackFormModal';

import ModalTitle from 'components/core/ModalTitle';
import LoadingBalls from 'components/core/LoadingBalls';
import Group from './Group';
import VideoSection from './VideoSection';
import Question from './Question';

import './style.scss';

const UserGuidePopup = ({ show, countdown, onHide }) => {
  const [isShowFeedbackForm, setIsShowFeedbackForm] = useState(false);

  const isShowUserGuide = show && !isShowFeedbackForm;

  const handleShowFeedbackForm = () => {
    setIsShowFeedbackForm(true);
  };

  const handleHideFeedbackForm = () => {
    setIsShowFeedbackForm(false);
  };

  const handleClosePopup = () => {
    onHide();
  };

  const timeCount = useCountDown({
    count: countdown,
    timeout: 1000,
    dependencies: countdown,
  });

  const {
    isLoading,
    data: userGuide,
    onCallApi,
  } = useAxios({
    url: USER_GUIDE_API_PATH,
    autoRun: false,
    initialData: {},
  });

  const userGuideList = useMemo(
    () => [
      {
        id: uid(),
        icon: <ScreenIcon />,
        label: 'Tutorial Videos',
        groupStyle: {
          maxHeight: 180,
        },
        renderChildren: ({ videos = [] }) => <VideoSection videos={videos} />,
      },
      {
        id: uid(),
        icon: <QuickGuideIcon />,
        label: 'User Guide',
        groupStyle: {
          maxHeight: 180,
        },
        renderChildren: ({ guides = [] }) => (
          <div className="edh-user-guide-popup__role-guide">
            {guides?.map(guide => (
              <a
                key={guide.id}
                href={guide.src}
                target="_blank"
                rel="noreferrer"
                className="edh-user-guide-popup__link"
                style={{ display: 'inline-block' }}
              >
                {guide.title}
              </a>
            ))}
          </div>
        ),
      },
      {
        id: uid(),
        icon: <QuestionIcon />,
        label: 'Frequently Asked Question',
        renderChildren: ({ faqs = [] }) =>
          faqs?.map(faq => (
            <Question key={faq.id} type={faq.type} questions={faq.questions} />
          )),
      },
      {
        id: uid(),
        icon: <SubscribeIcon />,
        label: 'Contact Us',
        renderChildren: ({ subscribe = {} }) => (
          <>
            <a
              href={subscribe?.link}
              target="_blank"
              rel="noreferrer"
              className="edh-user-guide-popup__link"
            >
              {subscribe?.label}
            </a>
            <span style={{ fontSize: 14 }}>
              Email us at:
              <a
                style={{
                  paddingLeft: 2,
                  display: 'inline',
                  color: 'var(--first-color)',
                }}
                href={`mailto:${subscribe.email}`}
              >
                {subscribe.email}
              </a>
            </span>
          </>
        ),
      },
      {
        id: uid(),
        icon: <VscFeedback className="edh-user-guide-popup__feedback-icon" />,
        label: 'Feedback',
        renderChildren: () => (
          <p>
            Share your feedback with us on your Data+ experience via this
            Feedback{' '}
            <button
              type="button"
              className="edh-user-guide-popup__feedback-btn"
              onClick={handleShowFeedbackForm}
            >
              form
            </button>
          </p>
        ),
      },
    ],
    [handleShowFeedbackForm],
  );

  useEffect(() => {
    if (show) {
      onCallApi({ params: {} });
    }
  }, [show, onCallApi]);

  return (
    <>
      <Modal
        show={isShowUserGuide}
        centered
        backdrop="static"
        dialogClassName="edh-user-guide-popup"
      >
        <div className="edh-user-guide-popup__modal-content">
          <LoadingBalls isLoading={isLoading} />
          <header className="edh-user-guide-popup__header">
            <ModalTitle title="Welcome to Data+" category="HELP & CONTACTS" />
            {timeCount ? (
              <p className="edh-user-guide-popup__header-time">{timeCount}</p>
            ) : (
              <button type="button" onClick={handleClosePopup}>
                <CloseIcon />
              </button>
            )}
          </header>
          <p className="edh-user-guide-popup__desc">
            Data+ is the Enterprise Data Hub Self-Service Portal for PETRONAS
            employees to discover, understand and analyse data. The following
            material will help users to navigate the portal.
          </p>
          {userGuideList.map((item, index) => {
            const groupProps = {
              icon: item.icon,
              title: `${index + 1}. ${item.label}`,
              ...item.groupStyle,
            };

            const children = item.renderChildren(userGuide);

            return (
              <Group {...groupProps} key={item.id}>
                {children}
              </Group>
            );
          })}
          <Button
            variant="custom-primary"
            className="edh-user-guide-popup__btn"
            disabled={Boolean(timeCount)}
            onClick={handleClosePopup}
          >
            Got it, get started
          </Button>
        </div>
      </Modal>
      <FeedbackFormModal
        show={isShowFeedbackForm}
        onHide={handleHideFeedbackForm}
      />
    </>
  );
};

UserGuidePopup.propTypes = {
  show: PropTypes.bool,
  countdown: PropTypes.number,
  onHide: PropTypes.func.isRequired,
};

UserGuidePopup.defaultProps = {
  show: false,
  countdown: 0,
};

export default memo(UserGuidePopup);
