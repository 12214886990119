import { all } from 'redux-saga/effects';

import userSaga from './userSaga';
import historyExportSaga from './historyExportSaga';
import domainSaga from './domainSaga';
import overviewsSaga from './overviewsSaga';
import datasetSaga from './datasetSaga';
import dataMarketplaceSaga from './dataMarketplaceSaga';
import myRequestSaga from './myRequestSaga';
import favouriteSaga from './favouriteSaga';
import notificationSaga from './notificationSaga';
import commentSaga from './commentSaga';
import newsfeedSaga from './newsfeedSaga';
import analyticsMarketplaceSaga from './analyticsMarketplaceSaga';
import datasetManagementSaga from './datasetManagementSaga';
import userManagementSaga from './userManagementSaga';
import searchLandingPageSaga from './searchLandingPageSaga';
import myWorkspaceSaga from './myWorkspaceSaga';
import dataQuality from './dataQualitySaga';
import dataSample from './dataSampleSaga';
import datasetFootprintSaga from './datasetFootprintSaga';

export default function* rootSaga() {
  yield all([
    userSaga(),
    historyExportSaga(),
    domainSaga(),
    overviewsSaga(),
    datasetSaga(),
    dataMarketplaceSaga(),
    myRequestSaga(),
    favouriteSaga(),
    notificationSaga(),
    commentSaga(),
    newsfeedSaga(),
    analyticsMarketplaceSaga(),
    datasetManagementSaga(),
    userManagementSaga(),
    searchLandingPageSaga(),
    myWorkspaceSaga(),
    dataQuality(),
    dataSample(),
    datasetFootprintSaga(),
  ]);
}
