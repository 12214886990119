export const REALITY_SERIES_ENUM = 0;
export const DATA_KNOWLEDGE_SHARING_ENUM = 1;
export const EVENTS_AWARD_ENUM = 2;

export const REALITY_SERIES_KEY = 'realitySeries';
export const DATA_KNOWLEDGE_SHARING_KEY = 'dataKnowledgeSharing';
export const EVENTS_AWARD_KEY = 'eventsAward';
export const LATEST_NEWS_KEY = 'latestNews';

export const TAG_TYPE = 'tag';
export const CATEGORY_TYPE = 'category';

export const LATEST_NEWS_SECTION = {
  categoryKey: LATEST_NEWS_KEY,
  label: 'Latest News',
  category: null,
};

export const NEWSFEED_CATEGORY_INFO = {
  [REALITY_SERIES_KEY]: {
    key: REALITY_SERIES_ENUM,
    label: 'Reality Series',
    hrefViewAll: `/newsfeed/${REALITY_SERIES_KEY}`,
    category: REALITY_SERIES_ENUM,
  },
  [DATA_KNOWLEDGE_SHARING_KEY]: {
    key: DATA_KNOWLEDGE_SHARING_ENUM,
    label: 'Data & Knowledge Sharing',
    hrefViewAll: `/newsfeed/${DATA_KNOWLEDGE_SHARING_KEY}`,
    category: DATA_KNOWLEDGE_SHARING_ENUM,
  },
  [EVENTS_AWARD_KEY]: {
    key: EVENTS_AWARD_ENUM,
    label: 'Events & Award',
    hrefViewAll: `/newsfeed/${EVENTS_AWARD_KEY}`,
    category: EVENTS_AWARD_ENUM,
  },
};

export const CATEGORY_NEWSFEED = {
  LAST_UPDATE: 'lastUpdate',
  REALITY_SERIES: 'realitySeries',
  KNOWLEDGE_SHARING: 'dataKnowledgeSharing',
  EVENT_AWARD: 'eventsAward',
};
