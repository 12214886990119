const { GTM_CODE, GTM_AUTH, GTM_PREVIEW } = process.env;
const prefix = `gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_PREVIEW}&gtm_cookies_win=x`;

const initialGTM = (w, d, s, l, i) => {
  w[l] = w[l] || [];
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
  const f = d.getElementsByTagName(s)[0];
  const j = d.createElement(s);
  const dl = l !== 'dataLayer' ? `&l=${l}` : '';
  j.async = true;
  j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}&${prefix}`;
  f.parentNode.insertBefore(j, f);
};

const initialize = () => {
  initialGTM(window, document, 'script', 'dataLayer', GTM_CODE);

  const noscriptElement = document.createElement('noscript');
  noscriptElement.innerHTML = `<iframe
  src="https://www.googletagmanager.com/ns.html?id=${GTM_CODE}&${prefix}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  document.body.appendChild(noscriptElement);
};

const ga = { initialize };

export default ga;
